import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { getProjectByIDAPI } from "api/users/projects/about";
import { AxiosError } from "axios";
import { optionUseQuery } from "utils/optionUseQuery";
import {
  getProjectsMetadataByIdAPI,
  getPublicOrganizationsAPI,
} from "./request";
import { IProjectsAbout, IResMetadata, ISuggestProject } from "./types";

export const useGetProjectsMetadataByID = (
  id: string,
  option?: UseQueryOptions<IResMetadata, Error>
) => {
  return useQuery<IResMetadata, Error>(
    ["get-projects-metadata", id],
    () => getProjectsMetadataByIdAPI(id),
    {
      ...optionUseQuery,
      ...option,
    }
  );
};

export const useGetProjectsByID = (
  id: string,
  option?: UseQueryOptions<IProjectsAbout, AxiosError<{ message: string }>>
) => {
  return useQuery<IProjectsAbout, AxiosError<{ message: string }>>(
    ["get-projects-by-id", id],
    () => getProjectByIDAPI(id),
    {
      ...optionUseQuery,
      ...option,
    }
  );
};

export const useGetPublicOrganizations = (
  option?: UseQueryOptions<ISuggestProject[], AxiosError>
) => {
  return useQuery<ISuggestProject[], AxiosError>(
    ["get-public-organizations"],
    () => getPublicOrganizationsAPI(),
    {
      ...optionUseQuery,
      ...option,
    }
  );
};

