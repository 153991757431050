export const ClockInTagIcon = () => {
  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 14.5C10.0376 14.5 12.5 12.0376 12.5 9C12.5 5.96243 10.0376 3.5 7 3.5C3.96243 3.5 1.5 5.96243 1.5 9C1.5 12.0376 3.96243 14.5 7 14.5Z"
        stroke="#FF5722"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M7 9.00026L9.47487 6.52539"
        stroke="#FF5722"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.5 1.5H8.5"
        stroke="#FF5722"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
