import { Button, Typography } from "antd";
import styled from "styled-components";

const { Text } = Typography;

export const WorkExperience = styled.div`
  display: flex;
  align-items: center;
  background: #ffffff;
  border: 1px solid rgba(156, 138, 193, 0.16);
  border-radius: 4px;
  padding: 20px;
  margin-bottom: 10px;
  justify-content: space-between;
`;

export const LeftContainer = styled.div`
  display: grid;
  grid-template-columns: 4.3fr 1fr 2fr;
  gap: 20px;
`;

export const InfoItem = styled.div`
  display: flex;
`;

export const RightContainer = styled.div`
  margin-left: auto;
`;

export const ImageWorkExperiences = styled.div`
  margin-right: 8px;
  display: flex;
  align-items: center;
`;

export const InfoContainer = styled.div``;

export const InfoContainerName = styled(InfoContainer)`
  max-width: 80%;
  display: flex;
  flex-direction: column;
`;

export const NameCompany = styled(Text)`
  font-size: 16px;
  line-height: 125%;
  letter-spacing: -0.02em;
  color: #080f6c;
  margin-bottom: 2px;
  margin-top: 6px;
`;

export const DescRole = styled(Text)`
  font-weight: 400;
  font-size: 14px;
  line-height: 125%;
  color: rgba(41, 46, 115, 0.42);
  vertical-align: top !important;
`;

export const Name = styled.div`
  font-size: 16px;
  line-height: 125%;
  letter-spacing: -0.02em;
  color: #080f6c;
  margin-bottom: 2px;
  margin-top: 6px;
`;

export const Desc = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 125%;
  color: rgba(41, 46, 115, 0.42);
`;

export const ButtonEdit = styled(Button)`
  cursor: pointer;
  background: rgba(156, 138, 193, 0.16);
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  color: #7e00fd;
  display: flex;
  align-items: center;

  svg {
    margin-right: 4px;
  }

  &:hover,
  &:focus {
    background: rgb(156 138 193 / 36%);
    color: #7e00fd;
    border: 1px solid #ffff;
  }
`;

export const ButtonDelete = styled(Button)`
  cursor: pointer;
  background: rgba(255, 87, 34, 0.1);
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  color: #ff5722;

  &:hover,
  &:focus {
    background: rgb(255 61 0 / 22%);
    color: #fb3c00;
    border: 1px solid #ffff;
  }
`;

