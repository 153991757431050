import { Input } from "antd";
import styled from "styled-components";

export const InputSearch = styled(Input)`
  background: #ffffff;
  border: 1px solid rgba(115, 137, 149, 0.2);
  border-radius: 8px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 156%;
  color: rgba(8, 10, 37, 0.8);
`;
