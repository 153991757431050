import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { getLocalStorage } from "utils/storage";
import { KEY_COOKIE } from "utils/constant";
export interface AuthState {
  isLoggedIn: boolean;
}
const initialState: AuthState = getLocalStorage(KEY_COOKIE.LOGIN_INFO) ?? {
  isLoggedIn: false,
};

const authSlice = createSlice({
  name: "auth-us",
  initialState,
  reducers: {
    logIn: (state, action: PayloadAction<AuthState>) => {
      const data = action.payload;
      return { ...state, ...data };
    },
    logOut: () => {
      return {
        isLoggedIn: false,
      };
    },
  },
});

const { actions, reducer } = authSlice;

export const authActions = actions;

export default reducer;
