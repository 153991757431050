import { FC, memo } from "react";
import { IButtonTransparentProps } from "./buttonTransparent.props";
import { ButtonTransparentAntd } from "./buttonTransparent.style";

const ButtonTransparent: FC<IButtonTransparentProps> = ({
  children,
  ...props
}: IButtonTransparentProps) => {
  return <ButtonTransparentAntd {...props}>{children}</ButtonTransparentAntd>;
};

export default memo(ButtonTransparent);
