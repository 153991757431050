import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ReactDOM from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import store from "redux/store";
import Router from "./pages/routes.tsx";
import reportWebVitals from "./reportWebVitals";
import "./styles/index.scss";
import { DAppProvider } from "@usedapp/core";
import { config } from "utils/connectors";
import "react-loading-skeleton/dist/skeleton.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
const queryClient = new QueryClient();

root.render(
  <HelmetProvider>
    <Provider store={store}>
      <DAppProvider config={config}>
        <QueryClientProvider client={queryClient}>
          <Router />
        </QueryClientProvider>
      </DAppProvider>
    </Provider>
  </HelmetProvider>
);

reportWebVitals();
