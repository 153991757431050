import { lazy } from "react";

const overrideLazy = (str: string) => {
  return lazy(() => {
    return new Promise((resolve) => {
      setTimeout(() => resolve(import(`${str}`)), 200);
    });
  });
};

/* PLOP EXPORT */
export const BlogAd = overrideLazy("./blogAd");
export const LicenseAd = overrideLazy("./licenseAd");
export const DefaultAvatarAd = overrideLazy("./defaultAvatarAd");
export const DataSetupAd = overrideLazy("./dataSetupAd");
export const UserAd = overrideLazy("./userAd");
export const ProjectAd = overrideLazy("./projectAd");
export const SiginAd = overrideLazy("./auth/siginAd");
export const LoginAd = overrideLazy("./auth/loginAd");
export const HomeAd = overrideLazy("./homeAd");

