import moment, { Moment } from "moment";
const convertToTimeDate = (time: Date | undefined | string) => {
  if (typeof time === "string") time = new Date(time);
  return time ? moment(time).format("HH:mmA • DD-MMM-YYYY") : "";
};

const convertTimeInTracsactionPage = (time: Date | undefined | string) => {
  return time ? moment(time).format("Do MMMM, YYYY") : "";
};

const getTimeSince = (time: Date | undefined) => {
  return time ? moment(time).fromNow() : "";
};

const timeUtils = {
  convertToTimeDate,
  getTimeSince,
  convertTimeInTracsactionPage,
};

export const convertStringToMoment = (
  dateString: Moment | string | undefined
) => {
  if (typeof dateString === "object") return dateString;
  return moment(dateString);
};
export default timeUtils;
