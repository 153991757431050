import axios from "api/axios";
import { IProjectTbl } from "types";
import { IAddProjectReq, ILettersReq, IProjectReq, IProjectRes } from "./types";

const END_POINT = {
  GET_ALL_PROJECT: "/projects/all",
  CREATE_PROJECT: "/projects",
  GET_FILTER_PARAMS: "/projects/filter-data",
  LETTERS: "/letters",
};

export const getAllProjectAPI = async (
  params: IProjectReq
): Promise<IProjectRes> => {
  const { data } = await axios({
    url: END_POINT.GET_ALL_PROJECT,
    method: "POST",
    data: params,
  });
  return data;
};

export const createProjectsAPI = async (
  params: IAddProjectReq
): Promise<IProjectTbl> => {
  const { data } = await axios({
    url: END_POINT.CREATE_PROJECT,
    method: "POST",
    data: params,
  });
  return data;
};

export const postLettersAPI = async (params: ILettersReq): Promise<any> => {
  const { data } = await axios({
    url: END_POINT.LETTERS,
    method: "POST",
    data: params,
  });
  return data;
};

export const getFilterParamsAPI = async (): Promise<any> => {
  const { data } = await axios({
    url: END_POINT.GET_FILTER_PARAMS,
    method: "GET",
  });
  return data;
};

