import axios from "api/axios";
import { IEducationTbl, IWorkExperienceTbl } from "types";
import { IProfile } from "../auth";
import { IParamsEducation, IParamsWorkExpe, IProfileParams } from "./types";

const END_POINT = {
  USERS_PROFILE: "/users/profile",
  USERS_INVITE_FRIENDS: "/users/invite-friends",
  USERS_PROFILE_WALLET: "/users/profile/wallet",
  USERS_PROFILE_WORK_EXPERIENCE: "/users/profile/work-experience",
  USERS_PROFILE_EDUCATION: "/users/profile/education",
};

export const putProfileAPI = async (
  params: IProfileParams
): Promise<IProfile> => {
  const { data } = await axios({
    url: END_POINT.USERS_PROFILE,
    method: "PUT",
    data: params,
  });

  return data;
};

export const postUsersInviteFriendsAPI = async (email: string) => {
  return await axios({
    url: END_POINT.USERS_INVITE_FRIENDS,
    method: "POST",
    data: {
      email,
    },
  });
};

export const putProfileWalletAPI = async (wallet?: string) => {
  return await axios({
    url: END_POINT.USERS_PROFILE_WALLET,
    method: "PUT",
    data: {
      wallet,
    },
  });
};

export const getWorkExperienceAPI = async (): Promise<IWorkExperienceTbl[]> => {
  const { data } = await axios({
    url: END_POINT.USERS_PROFILE_WORK_EXPERIENCE,
    method: "GET",
  });

  return data;
};

export const postWorkExperienceAPI = async (
  params: IParamsWorkExpe
): Promise<any> => {
  const { data } = await axios({
    url: END_POINT.USERS_PROFILE_WORK_EXPERIENCE,
    method: "POST",
    data: params,
  });

  return data;
};

export const putWorkExperienceAPI = async (
  params: IWorkExperienceTbl
): Promise<any> => {
  const { data } = await axios({
    url: END_POINT.USERS_PROFILE_WORK_EXPERIENCE,
    method: "PUT",
    data: params,
  });

  return data;
};

export const delWorkExperienceAPI = async (id: string): Promise<any> => {
  const { data } = await axios({
    url: END_POINT.USERS_PROFILE_WORK_EXPERIENCE,
    method: "DELETE",
    data: { id },
  });

  return data;
};

// --

export const getEducationAPI = async (): Promise<IEducationTbl[]> => {
  const { data } = await axios({
    url: END_POINT.USERS_PROFILE_EDUCATION,
    method: "GET",
  });

  return data;
};

export const postEducationAPI = async (
  params: IParamsEducation
): Promise<any> => {
  const { data } = await axios({
    url: END_POINT.USERS_PROFILE_EDUCATION,
    method: "POST",
    data: params,
  });

  return data;
};

export const putEducationAPI = async (params: IEducationTbl): Promise<any> => {
  const { data } = await axios({
    url: END_POINT.USERS_PROFILE_EDUCATION,
    method: "PUT",
    data: params,
  });

  return data;
};

export const delEducationAPI = async (id: string): Promise<any> => {
  const { data } = await axios({
    url: END_POINT.USERS_PROFILE_EDUCATION,
    method: "DELETE",
    data: { id },
  });

  return data;
};

