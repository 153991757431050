import { useMutation } from "@tanstack/react-query";
import { Button, Checkbox, Collapse, Input, Row } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import TextArea from "antd/lib/input/TextArea";
import {
  createEventPollItemsAPI,
  deleteEventPollItemByIdAPI,
  getAllEventPollItemsAPI, getEventPollItemByIdAPI, getEventPollItemItemByIdAPI, IGetAllEventPollItemsReq,
  IGetAllEventPollItemsRes,
  updateEventPollItemByIdAPI
} from "api/users/eventPollItems";
import { AxiosError } from "axios";
import { FC, Fragment, memo, useRef, useState } from "react";
import ReactJson from "react-json-view";
import { IEventPollItemTbl } from "types";
import { METHOD_API } from "../apiTest";
import { CollapseShowJson } from "../commonUi.style";
const { Panel } = Collapse;

const EventPollItemsAPI: FC<any> = () => {
  const checkBox = useRef<any>(null);
  const [isShow, setIsShow] = useState<boolean>(true);
  const [createdResult, setCreateResult] = useState<IEventPollItemTbl>({});
  const [updateResult, setUpdateResult] = useState<any>({});
  const [deleteResult, setDeleteResult] = useState<any>({});
  const [eventPollItem, setEventPollItem] = useState<IEventPollItemTbl>({});
  const [eventPollItemId, setEventPollItemId] = useState<string>("");
  const [eventPollItemItemId, setEventPollItemItemId] = useState<string>("");
  const [eventPollItemItem, setEventPollItemItem] = useState<IEventPollItemTbl>(
    {}
  );
  const [updateEventPollItemId, setUpdateEventPollItemId] =
    useState<string>("");
  const [deleteEventPollItemId, setDeleteEventPollItemId] =
    useState<string>("");

  const [allEventPollItem, setAllEventPollItem] =
    useState<IGetAllEventPollItemsRes>({});
  const [textInputForUpdate, setTextInputForUpdate] = useState<string>(`
    {
      "name": "string"
    }
  `);
  const [textInputForGetAll, setTextInputForGetAll] = useState<string>(`
  {
    "page": 1,
    "limit": 10
  }
`);
  const [textInputForCreate, setTextInputForCreate] = useState<string>(`{
    "event_poll_id": 0,
    "name": "string"
  }
  `);

  const { mutate: createEventPollItems } = useMutation(
    createEventPollItemsAPI,
    {
      onSuccess: async (rs: IEventPollItemTbl) => {
        setCreateResult(rs);
      },
      onError: (err: AxiosError) => {
        setCreateResult(JSON.parse(JSON.stringify(err)));
      },
    }
  );

  const { mutate: getAllEventPollItems } = useMutation(
    getAllEventPollItemsAPI,
    {
      onSuccess: async (rs: IGetAllEventPollItemsReq) => {
        setAllEventPollItem(rs);
      },
      onError: (err: any) => {
        setAllEventPollItem(JSON.parse(JSON.stringify(err)));
      },
    }
  );

  const { mutate: getEventPollItemById } = useMutation(
    getEventPollItemByIdAPI,
    {
      onSuccess: async (rs: IEventPollItemTbl) => {
        setEventPollItem(rs);
      },
      onError: (err: any) => {
        setEventPollItem(JSON.parse(JSON.stringify(err)));
      },
    }
  );

  const { mutate: updateEventPollItemById } = useMutation(
    updateEventPollItemByIdAPI,
    {
      onSuccess: async (rs: any) => {
        setUpdateResult(rs);
      },
      onError: (err: any) => {
        setUpdateResult(JSON.parse(JSON.stringify(err)));
      },
    }
  );

  const { mutate: deleteEventPollItemById } = useMutation(
    deleteEventPollItemByIdAPI,
    {
      onSuccess: async (rs: any) => {
        setDeleteResult(rs);
      },
      onError: (err: any) => {
        setDeleteResult(JSON.parse(JSON.stringify(err)));
      },
    }
  );

  const { mutate: getEventPollItemItemById } = useMutation(
    getEventPollItemItemByIdAPI,
    {
      onSuccess: async (rs: IEventPollItemTbl) => {
        setEventPollItemItem(rs);
      },
      onError: (err: any) => {
        setEventPollItemItem(JSON.parse(JSON.stringify(err)));
      },
    }
  );

  const handleChageTextInputForCreate = (event: any) => {
    setTextInputForCreate(event.target.value);
  };

  const handleChangeTextInputForGetAll = (event: any) => {
    setTextInputForGetAll(event.target.value);
  };

  const handleChangeEventPollItemId = (event: any) => {
    setEventPollItemId(event.target.value);
  };

  const handleChangeUpdateEventPollItemId = (event: any) => {
    setUpdateEventPollItemId(event.target.value);
  };

  const handleChangeTextInputForUpdate = (event: any) => {
    setTextInputForUpdate(event.target.value);
  };

  const handleChangeDeleteEventPollItemId = (event: any) => {
    setDeleteEventPollItemId(event.target.value);
  };

  const handleChangeEventPollItemItemId = (event: any) => {
    setEventPollItemItemId(event.target.value);
  };

  const createEventPollItemsClick = () => {
    createEventPollItems(JSON.parse(textInputForCreate));
  };

  const getAllEventPollItemsAPIClick = () => {
    getAllEventPollItems(JSON.parse(textInputForGetAll));
  };

  const getEventByIdClick = () => {
    getEventPollItemById(eventPollItemId);
  };

  const updateEventPollItemByIdClick = () => {
    updateEventPollItemById({
      id: updateEventPollItemId,
      updatedEventPollItem: JSON.parse(textInputForUpdate),
    });
  };

  const deleteEventPollItemByIdClick = () => {
    deleteEventPollItemById(deleteEventPollItemId);
  };

  const getEventPollItemItemByIdClick = () => {
    getEventPollItemItemById(eventPollItemItemId);
  };

  return (
    <>
      <Row style={{ marginTop: "10px" }}>
        <h1>
          <b>Event poll items</b>
        </h1>
        <Checkbox
          ref={checkBox}
          onChange={(e: CheckboxChangeEvent) => {
            setIsShow(e.target.checked);
          }}
          style={{
            marginLeft: "10px",
          }}
          checked={isShow}
        />
      </Row>
      {isShow && (
        <Collapse accordion>
          <Panel header="/event-poll-items" key="1" extra={METHOD_API.POST}>
            <Button
              type="primary"
              onClick={createEventPollItemsClick}
              style={{
                marginBottom: "10px",
              }}
            >
              Try it out
            </Button>
            <h1>Request body</h1>
            <TextArea
              style={{
                marginBottom: "10px",
              }}
              value={textInputForCreate}
              defaultValue={textInputForCreate}
              onChange={handleChageTextInputForCreate}
              placeholder=""
              rows={10}
            />
            <Fragment>
              <h1>Result</h1>
              <CollapseShowJson ghost>
                <ReactJson src={JSON.parse(JSON.stringify(createdResult))} />
              </CollapseShowJson>
            </Fragment>
          </Panel>
          <Panel header="/event-poll-items/alls" key="2" extra={METHOD_API.GET}>
            <Button
              type="primary"
              onClick={getAllEventPollItemsAPIClick}
              style={{
                marginBottom: "10px",
              }}
            >
              Try it out
            </Button>
            <h1>Request body</h1>
            <TextArea
              style={{
                marginBottom: "10px",
              }}
              value={textInputForGetAll}
              defaultValue={textInputForGetAll}
              onChange={handleChangeTextInputForGetAll}
              placeholder=""
              rows={10}
            />
            <Fragment>
              <h1>Result</h1>
              <CollapseShowJson ghost>
                <ReactJson src={JSON.parse(JSON.stringify(allEventPollItem))} />
              </CollapseShowJson>
            </Fragment>
          </Panel>
          <Panel header="/event-poll-items/{id}" key="3" extra={METHOD_API.GET}>
            <Button
              type="primary"
              onClick={getEventByIdClick}
              style={{
                marginBottom: "10px",
              }}
            >
              Try it out
            </Button>
            <h1>Request body</h1>
            <Input
              placeholder="Enter event id"
              allowClear
              onChange={handleChangeEventPollItemId}
              value={eventPollItemId}
              style={{
                marginBottom: "10px",
              }}
            />
            <Fragment>
              <h1>Result</h1>
              <CollapseShowJson ghost>
                <ReactJson src={JSON.parse(JSON.stringify(eventPollItem))} />
              </CollapseShowJson>
            </Fragment>
          </Panel>
          <Panel header="/event-poll-items/{id}" key="4" extra={METHOD_API.PUT}>
            <Button
              type="primary"
              onClick={updateEventPollItemByIdClick}
              style={{
                marginBottom: "10px",
              }}
            >
              Try it out
            </Button>
            <Input
              placeholder="Enter event id"
              allowClear
              onChange={handleChangeUpdateEventPollItemId}
              value={updateEventPollItemId}
              style={{
                marginBottom: "10px",
              }}
            />
            <h1>Request body</h1>
            <TextArea
              style={{
                marginBottom: "10px",
              }}
              value={textInputForUpdate}
              defaultValue={textInputForUpdate}
              onChange={handleChangeTextInputForUpdate}
              placeholder=""
              rows={10}
            />
            <Fragment>
              <h1>Result</h1>
              <CollapseShowJson ghost>
                <ReactJson src={JSON.parse(JSON.stringify(updateResult))} />
              </CollapseShowJson>
            </Fragment>
          </Panel>
          <Panel
            header="/event-poll-items/{id}"
            key="5"
            extra={METHOD_API.DELETE}
          >
            <Button
              type="primary"
              onClick={deleteEventPollItemByIdClick}
              style={{
                marginBottom: "10px",
              }}
            >
              Try it out
            </Button>
            <h1>Request body</h1>
            <Input
              placeholder="Enter event id"
              allowClear
              onChange={handleChangeDeleteEventPollItemId}
              value={deleteEventPollItemId}
              style={{
                marginBottom: "10px",
              }}
            />
            <Fragment>
              <h1>Result</h1>
              <CollapseShowJson ghost>
                <ReactJson src={JSON.parse(JSON.stringify(deleteResult))} />
              </CollapseShowJson>
            </Fragment>
          </Panel>
          <Panel
            header="/event-poll-items/items/{id}"
            key="6"
            extra={METHOD_API.GET}
          >
            <Button
              type="primary"
              onClick={getEventPollItemItemByIdClick}
              style={{
                marginBottom: "10px",
              }}
            >
              Try it out
            </Button>
            <h1>Request body</h1>
            <Input
              placeholder="Enter event id"
              allowClear
              onChange={handleChangeEventPollItemItemId}
              value={eventPollItemItemId}
              style={{
                marginBottom: "10px",
              }}
            />
            <Fragment>
              <h1>Result</h1>
              <CollapseShowJson ghost>
                <ReactJson
                  src={JSON.parse(JSON.stringify(eventPollItemItem))}
                />
              </CollapseShowJson>
            </Fragment>
          </Panel>
        </Collapse>
      )}
    </>
  );
};

export default memo(EventPollItemsAPI);
