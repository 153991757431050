import { forwardRef, memo, useImperativeHandle, useState } from "react";
import {
  IFormItemWithHlMethods,
  IFormItemWithHlProps,
} from "./formItemWithHl.props";
import { Label } from "./formItemWithHl.style";

const FormItemWithHl = forwardRef<IFormItemWithHlMethods, IFormItemWithHlProps>(
  ({ children, label, colorBefore, colorAfter = "#7e00fd", ...props }, ref) => {
    const [active, setActive] = useState<boolean>(false);

    useImperativeHandle(ref, () => ({
      handleFocus: () => {
        setActive(true);
      },
      handleBlur: () => {
        setActive(false);
      },
    }));

    return (
      <>
        <Label
          active={active}
          colorAfter={colorAfter}
          colorBefore={colorBefore}
        >
          {label}
        </Label>
        {children}
      </>
    );
  }
);

export default memo(FormItemWithHl);
