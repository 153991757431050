import bgSidebar from "assets/users/svg/bg_sidebar.svg";
import { Link } from "react-router-dom";
import styled from "styled-components";

const MENU_DETAIL: number = 15;

export const MenuText = styled.div``;

export const ContainerContentProject = styled.div`
  background: #ffffff;

  .ant-menu {
    padding: 20px;
    background-image: url(${bgSidebar});
    border-radius: 24px;
    background-repeat: revert;
    background-size: cover;
    border-right: unset;
  }

  .ant-menu-item:nth-child(${MENU_DETAIL}) {
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    margin-top: 30px;
    height: 48px;
    cursor: pointer;
  }

  .ant-menu-item:nth-child(${MENU_DETAIL + 1}) {
    cursor: pointer;
    height: 50px;
  }

  .ant-menu-item:nth-child(${MENU_DETAIL + 2}) {
    cursor: pointer;
    height: 70px;
  }

  .ant-menu-item {
    span > div {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 120%;
      color: rgba(8, 10, 37, 0.6);
    }
    &:last-child {
      background-color: #080a25 !important;
      margin: unset;
      margin-left: -19px;
      width: 116%;
      border-top-left-radius: unset;
      border-top-right-radius: unset;
      border-bottom-left-radius: 24px;
      border-bottom-right-radius: 24px;
      height: 148px;
      padding: unset;
      padding-left: unset !important;
      /* padding-left: 20px; */
      span > p {
        text-align: center;
        font-weight: 400;
        font-size: 21px;
        line-height: 100%;
        color: #ffffff !important;
        opacity: 0.7;
      }
      img {
        width: 100%;
      }
    }
    svg {
      fill: #080a25 !important;
      stroke: #080a25 !important;
    }
  }

  .ant-menu-item-selected {
    background: #ffffff !important;
    border: 1px solid rgba(8, 10, 37, 0.1);
    border-radius: 8px;

    span > div {
      font-weight: 500;
      line-height: 125%;
      color: #080a25;
    }

    :after {
      display: none;
    }
  }

  .ant-menu-item-active {
    background: rgba(255, 255, 255, 0.5);
    border-radius: 8px;
  }

  .ant-menu-item-disabled {
    .ant-menu-item-icon {
      svg {
        fill: #d5d5d5 !important;
        stroke: #d5d5d5 !important;
      }
    }
    .ant-menu-title-content {
      div {
        color: #d5d5d5 !important;
      }
    }
  }
`;

export const QuickPages = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const QuickPagesItem = styled(Link)`
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: rgba(8, 10, 37, 0.6) !important;
`;

export const Copywriter = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: rgba(8, 10, 37, 0.8);
`;

export const AdvertMenu = styled.img`
  width: 100%;
  object-fit: cover;
`;

export const Social = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SocialItem = styled(Link)`
  color: #343538 !important;
  svg {
    font-size: 24px;
  }
`;

export const DragIconBlock = styled.div`
  display: flex;
  align-items: center;
`;
