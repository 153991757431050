import { Checkbox, Collapse, Row } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { FC, memo, useRef, useState } from "react";
import { METHOD_API } from "../apiTest";
const { Panel } = Collapse;

const BookmarksAPI: FC<any> = () => {
  const [isShow, setIsShow] = useState<boolean>(true);

  const checkBox = useRef<any>(null);

  return (
    <>
      <Row style={{ marginTop: "10px" }}>
        <h1>
          <b>Bookmarks</b>
        </h1>
        <Checkbox
          ref={checkBox}
          onChange={(e: CheckboxChangeEvent) => {
            setIsShow(e.target.checked);
          }}
          style={{
            marginLeft: "10px",
          }}
          checked={isShow}
        />
      </Row>
      {isShow && (
        <Collapse accordion>
          <Panel header="/bookmarks" key="1" extra={METHOD_API.POST}>
            1
          </Panel>
          <Panel header="/bookmarks" key="2" extra={METHOD_API.DELETE}>
            2
          </Panel>
        </Collapse>
      )}
    </>
  );
};

export default memo(BookmarksAPI);
