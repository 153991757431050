import { Collapse } from "antd";
import styled from "styled-components";

export const Container = styled.div``;

export const CollapseShowJson = styled(Collapse)`
  .ant-collapse-header {
    padding: unset !important;
  }
`;

export const WrapComponent = styled.div`
  display: grid;
  grid-template-columns: repeat(6, auto);
  gap: 20px;
  div {
    max-width: 300px;
  }
`;
