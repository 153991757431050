import { Select } from "antd";
import styled from "styled-components";

export const AntdSelect = styled(Select)`
  .ant-select-selector {
    border-radius: 4px !important;
  }
  width: 100% !important;
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 4px !important;
    width: 100% !important;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 4px !important;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 4px !important;
    height: 36px !important;
  }
`;
