export const FolderIcon = () => {
  return (
    <svg
      width="15"
      height="12"
      viewBox="0 0 15 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 11V2C1 1.86739 1.05268 1.74021 1.14645 1.64645C1.24021 1.55268 1.36739 1.5 1.5 1.5H4.83333C4.94152 1.5 5.04679 1.53509 5.13333 1.6L6.86667 2.9C6.95321 2.96491 7.05848 3 7.16667 3H11.5C11.6326 3 11.7598 3.05268 11.8536 3.14645C11.9473 3.24021 12 3.36739 12 3.5V5"
        stroke="#7E00FD"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 11L2.88604 5.34189C2.91922 5.24233 2.98289 5.15574 3.06802 5.09438C3.15316 5.03302 3.25544 5 3.36038 5H13.3063C13.3855 5 13.4636 5.01883 13.5341 5.05493C13.6046 5.09103 13.6656 5.14337 13.7119 5.20764C13.7582 5.27192 13.7886 5.34628 13.8006 5.4246C13.8125 5.50292 13.8057 5.58295 13.7806 5.65811L12 11H1Z"
        stroke="#7E00FD"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

