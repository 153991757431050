import axios from "api/axios";
import {
    ICreateEventQuestionsReq,
    IGetAllEventQuestionsReq,
    IUpdateEventQuestionReq
} from "./types";

const END_POINT = {
  CREATE_EVENT_QUESTIONS: "/event-questions",
  GET_ALL_EVENT_QUESTIONS: "/event-questions/alls",
  GET_EVENT_QUESTIONS_BY_ID: "/event-questions/:id",
  GET_EVENT_QUESTIONS_ANSWER_BY_ID: "event-questions/answers/:id",
  UPDATE_EVENT_QUESTIONS_BY_ID: "/event-questions/:id",
  DELETE_EVENT_QUESTIONS_BY_ID: "/event-questions/:id",
};

export const createEventQuestionsAPI = async (
  params: ICreateEventQuestionsReq
) => {
  const { data } = await axios({
    url: END_POINT.CREATE_EVENT_QUESTIONS,
    method: "POST",
    data: params,
  });
  return data;
};

export const getAllEventQuestionsAPI = async (
  params: IGetAllEventQuestionsReq
) => {
  const { data } = await axios({
    url: `${END_POINT.GET_ALL_EVENT_QUESTIONS}`,
    method: "GET",
    params: params,
  });
  return data;
};

export const getEventQuestionByIdAPI = async (id: string) => {
  const { data } = await axios({
    url: `${END_POINT.GET_EVENT_QUESTIONS_BY_ID.replace(":id", id)}`,
    method: "GET",
  });
  return data;
};

export const updateEventQuestionByIdAPI = async (
  params: IUpdateEventQuestionReq
) => {
  const data = await axios({
    url: `${END_POINT.UPDATE_EVENT_QUESTIONS_BY_ID.replace(":id", params.id)}`,
    method: "PUT",
    data: params.updatedEventQuestion,
  });
  return data;
};

export const getEventQuestionAnswerByIdAPI = async (id: string) => {
  const { data } = await axios({
    url: `${END_POINT.GET_EVENT_QUESTIONS_ANSWER_BY_ID.replace(":id", id)}`,
    method: "GET",
  });
  return data;
};

export const deleteEventQuestionByIdAPI = async (id: string) => {
  const data = await axios({
    url: `${END_POINT.DELETE_EVENT_QUESTIONS_BY_ID.replace(":id", id)}`,
    method: "DELETE",
  });
  return data;
};
