export const TwitterIcon = (props: { style?: React.CSSProperties }) => (
  <svg
    style={props.style}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="#C4C6CD"
    viewBox="0 0 24 24"
  >
    <path d="M21.75 5.96c-.718.31-1.498.532-2.302.619.834-.496 1.46-1.28 1.758-2.203-.784.466-1.642.792-2.536.965-.374-.4-.827-.718-1.329-.935-.502-.218-1.044-.33-1.592-.328-2.214 0-3.996 1.795-3.996 3.999 0 .309.038.618.099.916-3.317-.173-6.274-1.758-8.24-4.184-.36.612-.547 1.31-.545 2.018 0 1.388.706 2.611 1.782 3.33-.634-.024-1.253-.198-1.807-.508v.05c0 1.942 1.373 3.553 3.204 3.923-.344.09-.698.135-1.053.136-.26 0-.506-.026-.755-.061.507 1.584 1.98 2.735 3.736 2.773-1.373 1.075-3.093 1.708-4.961 1.708-.335 0-.645-.012-.966-.05 1.772 1.138 3.874 1.794 6.138 1.794 7.35 0 11.372-6.09 11.372-11.374 0-.174 0-.347-.012-.52.779-.57 1.46-1.276 2.004-2.068z"></path>
  </svg>
);
