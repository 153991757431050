import { FC, useRef } from "react";
import { ILabelGradientProps } from "./labelGradient.props";
import { Label } from "./labelGradient.style";

const LabelGradient: FC<ILabelGradientProps> = ({
  children,
  showUnderline,
  ...props
}: ILabelGradientProps) => {
  const label = useRef<any>();

  return (
      <Label showUnderline={showUnderline} ref={label} {...props}>
        {children}
      </Label>
  );
};

export default LabelGradient;
