import { useMutation } from "@tanstack/react-query";
import { Button, Checkbox, Collapse, message, Row } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { getDefaultAvatarsAPI } from "api/users/defaultAvatar";
import { AxiosError } from "axios";
import { FC, Fragment, memo, useRef, useState } from "react";
import ReactJson from "react-json-view";
import { IDefaultAvatarsTbl } from "types";
import { METHOD_API } from "../apiTest";
import { CollapseShowJson } from "../commonUi.style";
import { getMessageContent } from "utils/message";
const { Panel } = Collapse;

const DefaultAvatarAPI: FC<any> = () => {
  const [isShow, setIsShow] = useState<boolean>(true);
  const [defaultAvatars, setDefaultAvatars] = useState<IDefaultAvatarsTbl[]>(
    []
  );

  const checkBox = useRef<any>(null);

  const { mutate: getDefaultAvatars, isLoading: isLoadingDefaultAvatars } =
    useMutation(getDefaultAvatarsAPI, {
      onSuccess: async (rs: IDefaultAvatarsTbl[]) => {
        setDefaultAvatars(rs);
      },
      onError: (err: AxiosError) => {
        const data: any = err.response?.data;
        message.error(getMessageContent(data?.message));
      },
    });

  const onTestDefaltAvatars = () => {
    getDefaultAvatars();
  };

  return (
    <>
      <Row style={{ marginTop: "10px" }}>
        <h1>
          <b>Default Avatar</b>
        </h1>
        <Checkbox
          ref={checkBox}
          onChange={(e: CheckboxChangeEvent) => {
            setIsShow(e.target.checked);
          }}
          style={{
            marginLeft: "10px",
          }}
          checked={isShow}
        />
      </Row>
      {isShow && (
        <Collapse accordion>
          <Panel header="/default-avatars" key="1" extra={METHOD_API.POST}>
            <Button
              loading={isLoadingDefaultAvatars}
              disabled={isLoadingDefaultAvatars}
              onClick={onTestDefaltAvatars}
            >
              Test
            </Button>
            {defaultAvatars.length > 0 && (
              <Fragment>
                <h1>Result</h1>
                <CollapseShowJson ghost>
                  {defaultAvatars?.map(
                    (item: IDefaultAvatarsTbl, key: number) => (
                      <Panel header={key} key={key}>
                        <ReactJson src={item} />
                      </Panel>
                    )
                  )}
                </CollapseShowJson>
              </Fragment>
            )}
          </Panel>
        </Collapse>
      )}
    </>
  );
};

export default memo(DefaultAvatarAPI);

