import { Button } from "antd";
import styled from "styled-components";

export const ButtonColorContainer = styled(Button)<{ color: string }>`
  display: flex;
  align-items: center;
  column-gap: 5px;
  border: none;
  height: 40px;
  border-radius: 6px;
  font-weight: 500;
  font-size: 16px;
  ${({ color }: any) => {
    const bgCol = color.replace(", 1)", ", 0.16)");
    return `background: ${bgCol} !important; color: ${color}`;
  }};

  &:hover,
  &:focus {
    ${({ color }: any) => {
      const bgCol = color.replace(", 1)", ", 0.16)");
      return `background: ${bgCol} !important; color: ${color}`;
    }};
  }
`;
