import { Button } from "antd";
import styled from "styled-components";

export const ButtonColorContainer = styled(Button)`
  height: 40px;
  background: #7e00fd;
  border-radius: 6px;
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;

  &:hover,
  &:focus {
    background: #7e00fd;
    color: #efecec;
  }
`;
