import { Moment } from "moment";
import {
  IBountyTbl,
  ICollaborationTbl,
  // IDeliverableTbl,
  IExperienceLevelTbl,
  IIssueTbl,
  IPaymentMethodTbl,
  IProjectTbl,
  ITagTbl,
  ITypeTbl,
  IUserTbl,
} from "types";

export enum TimeCommitmentEnum {
  HOURS = "Hours",
  DAYS = "Days",
  WEEKS = "Weeks",
  MONTHS = "Months",
}

export enum BountyActivityType {
  CREATED,
  NEW_APPLIED,
  ACCEPTED_AS_COLLABORATOR,
  SUBMITTED_WORK,
  AWARDED,
}

export enum DeliverablesStatusType {
  REVIEWING,
  ACCEPTED,
  DECLINED,
  DELIVERED,
  PENDING,
  PROCESSING,
  COMPLETE_TASK,
  REJECTED,
}

export type bountyTabType = "GITHUB" | "CUSTOM";

export interface IGetDataMasterReq {
  projectId: number;
  step: number | undefined;
}

export interface IDeliverableFile {
  link: string;
  link_name: string;
  file_url: string;
  file_name: string;
}

export interface IGetDataMasterRes extends IBountyData {
  default_data?: {
    types: ITypeTbl[];
    tags: ITagTbl[];
    experience_levels: IExperienceLevelTbl[];
    collaborations: ICollaborationTbl[];
    available_issues: IIssueTbl[];
    project: IProjectTbl;
    user: IUserTbl;
    payment_methods: IPaymentMethodTbl[];
    available_organizers: IUserTbl[];
    bounty_tab: bountyTabType;
  };
}

export interface IBountyData {
  content?: IBountyContentData;
  done_step?: number;
  current_step?: number;
}

export interface IBountyContentData {
  bounty_id?: number;
  // step bounty type
  bounty_type?: {
    bounty_type_id: number;
    bounty_type_other: string;
    bounty_experience_level_id: number;
    bounty_collaboration_id: number;
    time_commitment: string;
    time_commitment_value: string;
    time_number: string;
    tags: string[];
    maximum_collaborations: number;
    start_time: string;
    end_time: string;
  };
  // step detail
  bounty_details?: {
    title: string;
    description: string;
    acceptance_criteria: string;
    resources: string;
    issue_id?: number;
    bounty_contacts: IBountyContact[];
    bounty_tab: "GITHUB" | "CUSTOM";
    project_type?: string;
    permission?: string;
    is_never_expire?: boolean;
    is_require_approval: boolean;
    is_auto_approve?: boolean;
    is_reserve_bounty: boolean;
    payout_date: Moment | string | undefined;
  };
  // step payment information
  payment_information?: {
    bounty_payment_method_id: string;
    bounty_payment_token_id: string;
    additional_bounty_owner: string;
    amount: number;
    expected_value_usd: number;
    maximum_collaborations?: number;
  };
  // step additional information
  additional_information?: {
    project_type?: string;
    permission?: string;
    is_never_expire?: boolean;
    is_require_approval: boolean;
    is_auto_approve?: boolean;
    is_reserve_bounty: boolean;
    cutoff_date: Moment | string | undefined;
    payout_date: Moment | string | undefined;
    notification_email?: string;
  };
  // step review bounty
  review_bounty?: {
    is_community_publish: boolean;
    finance_transaction_id?: string;
    transaction_hash?: string;
  };
}

export interface IBountyContact {
  contact_detail?: string;
  contact_url?: string;
}

export interface IGetBountyByIdReq {
  id: number;
}

export interface IUpdateBountyRes {}

export interface IListBountiesReq {
  page: number;
  limit: number;
  sort_by: number;
}

export interface IListBountiesRes {
  page: number;
  limit: number;
  result: IBountyTbl[];
}

export interface IGetBountyByProjectIdReq {
  project_id: number;
  page: number;
  limit: number;
  sort_by: number;
}

export interface IListBountiesRes {
  page: number;
  page_size: number;
  total_page: number;
  total_item: number;
  result: IBountyTbl[];
}

export interface IFindBountyReq {
  id: number;
}

export interface IDeliverablesAccept extends IDeliverables {
  deliverables_files: [];
  title: string;
  description: string;
  is_read: boolean;
}

export interface IFindBountyRes extends IBountyTbl {
  is_joined_community?: boolean;
  is_owner_bounty?: boolean;
  is_user_express_interest?: boolean;
  is_maximum_collaborations?: boolean;
  deliverables: IDeliverablesAccept[];
  user_available_deliverable_id: string;
  time_commitment_value: string;
  bounty_tab: string;
  worker_tab?: {
    id: string;
    status: number;
    user: {
      id: string;
      user_name: string;
      full_name: string;
      avatar: string;
    };
  }[];
}

export interface IDeliverablesRequestJoinReq {
  bounty_id: number;
  project_id: number;
  wallet: string;
}

export interface IDeliverablesRequestJoinAllReq {
  bounty_id?: number;
  project_id?: number;
  page: number;
  limit: number;
}

export interface IDeliverables {
  id: string;
  status: number;
  user: {
    id: number;
    user_name: string;
    full_name: string;
    avatar: string;
  };
  isLoading: boolean;
}

export interface IDeliverablesRequestJoinAllRes {
  page: number;
  page_size: number;
  total_page: number;
  total_item: number;
  is_maximum_collaborations: boolean;
  result: {
    bounty: {
      id: string;
      amount: number;
      token: {
        name: string;
        symbol: string;
      };
    };
    deliverables: IDeliverables[];
  };
}

export interface IDeliverablesActionRequestReq {
  id: string;
  bounty_id: number;
  project_id: number;
  status: DeliverablesStatusType;
}

export interface IDeliverablesSubmissionReq {
  id: string;
  workers: string[];
  description: string;
  files: IDeliverableFile[];
  bounty_id: number;
  project_id: number;
}

export interface IDeliverableDetailReq {
  id: string;
  bounty_id: number;
  project_id: number;
}

export interface IDeliverableDetailRes {
  action: boolean;
  deliverables: {
    bounty: IBountyTbl;
    id: string;
    status: DeliverablesStatusType;
    description: string;
    updated_at: string;
    deliverables_files: IDeliverableFile[];
    wallet: string;
    user: {
      user_name: string;
      full_name: string;
      avatar: string;
      wallet: string;
    };
    workers: string[];
  };
}

export interface IDeliverablesActionTaskReq {
  id: string;
  bounty_id: number;
  project_id: number;
  is_community_approved: boolean;
  finance_transaction_id?: string;
  tx_hash?: string;
  status: DeliverablesStatusType;
}

export interface ICloseBountyReq {
  bounty_id: string;
  tx_hash?: string;
  finance_transaction_id?: string;
}

