import { Footer } from "antd/lib/layout/layout";
import styled from "styled-components";

export const Container = styled.div``;

export const SpinContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const AppFooter = styled(Footer)`
  background: white;
  padding: 80px 0px;
  max-width: 1200px;
  margin: 0 auto;
`;
