export enum ProjectStatusEnum {
  DRAFT,
  LOADING,
  PROCESSING,
  ADMIN_REVIEW,
  ACTIVE,
  CANCELED,
  INACTIVE,
}

export interface IProjectsAd {
  id: number;
  category: {
    name: string;
  };
  primary_repo_id: number;
  github_name: string;
  github_login: string;
  github_link: string;
  email_support: string;
  primary_repo_url: string;
  avatar_url: string;
  is_new_project: boolean;
  status: ProjectStatusEnum;
  description: string;
  about_project: string;
  website: string;
  documentation: string;
  social_twitter: string;
  social_linkedin: string;
  social_medium: string;
  social_devto: string;
  social_hashnode: string;
  social_facebook: string;
  social_workspace: string;
  social_discord: string;
  created_at?: Date;
  updated_at?: Date;
  deleted_at?: Date;
}
export interface IProjectsAdParams {
  limit: number;
  page: number;
  search?: string;
  status?: ProjectStatusEnum[];
}
export interface IProjectsAdRes {
  page: number;
  page_size: number;
  total_item: number;
  total_page: number;
  result: IProjectsAd[];
}
export interface IUpdateStatusProjectParams {
  id: number;
  status: ProjectStatusEnum;
}

export interface IRejectProjectParams {
  id: number;
  reject_reason: string;
}

