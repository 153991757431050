import styled from "styled-components";

export const Delete = styled.div<{ top: number; right: number }>`
  position: absolute;
  width: 13px;
  height: 13px;
  background-color: #fff;
  border-radius: 50%;
  top: ${({ top }) => top + "px;"};
  right: ${({ right }) => right + "px;"};
  color: #fff;
  overflow: hidden;
  cursor: pointer;
  div {
    width: 100%;
    height: 100%;
    background-color: rgba(120, 120, 120);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.3;
    transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    span {
      font-size: xx-small;
    }
    :hover {
      opacity: 0.8;
    }
  }
`;
