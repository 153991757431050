import { useFontObserver } from "hooks/useFontObserver";
import { FC } from "react";
import { Container } from "./loadingFullpage.style";

const LoadingFullpage: FC = () => {
  const fontLoaded = useFontObserver();

  return <Container show={!fontLoaded}></Container>;
};

export default LoadingFullpage;
