const ADMIN_PATH = "admin";

export const ROUTER_CONSTANTS = {
  HOME: "/",
  USER: {
    COMMON_UI: "/user/common-ui",
    COMMUNITY: "/communities",
    ABOUT_US: "/about-us",
    SIGNUP: "/user/sign-up",
    LOGIN: "/user/log-in",
    VERIFY_EMAIL: "/user/verify-email/:token",
    FORGOT_PASSWORD: "/user/forgot-password",
    CHANGE_PASSWORD: "/user/change-password/:token",
    PROFILE: "/profile",
    PROFILE_PUBLIC: "/profile/:id",
    PROFILE_USER_INNER: "/profile/user-inner",
    PROFILE_ADD_PROJECT: "/add-project",
    PROFILE_PORTFOLIO: "/profile/portfolio/:id",
    ISSUE_EXPLORER: "/issue-explorer",
    ISSUE_EXPLORER_DETAIL: "/issue-explorer/:projectId/:bountyId",
    ISSUE_REVIEW_DELIVERABLES:
      "/issue-review-deliverables/:id/:bountyId/:projectId",
    PROFILE_PREVIEW: "/profile-preview",
    BECOME_SPONSOR: "/become-sponsor/:id",
    PROJECTS: {
      ABOUT: "/projects/about/:id",
      REWARDS: "/projects/rewards/:id",
      COMMUNITY: "/projects/community/:id",
      ACTIVITY: "/projects/activity/:id",
      RESOURCES: "/projects/resources/:id",
      EVENTS: "/projects/events/:id",
      CREATE_EVENTS: "/projects/events/create/:id",
      ACTIVITIES: "/projects/:id/activities",
      GITHUB_ISSUES: "/projects/github-issues/:id",
      BOUNTIES: "projects/bounties/:id",
      DISCUSSION_DETAIL: "/projects/resources/discussion/:id",
      QUESTION_DETAIL: "/projects/resources/question/:id",
      SPONSOR: "/projects/sponsorships/:id",
      GOVERNANCE: "/projects/governance/:id",
      FINANCE: "/projects/finance/:id",
      GOVERNANCE_DETAIL: "/projects/governance/detail/:id/:idProposal",
    },
    ADD_PORTFOLIO: "/portfolio/create",
    UPDATE_PORTFOLIO: "/portfolio/update/:id",
    ADD_PROJECT_EMPTY: "/add-project-empty",
    BOUNTY_CREATE: "/projects/:id/bounties/create",
    BOUNTY_UPDATE: "/projects/:id/bounties/update",
    DATA_SETUP: "/data-setup/:id",
    CLAIM_NFT_POAPS: "/claim-all-nft-and-poaps",
    SUPPORT_TICKET: "/support-ticket",
    TRANSACTION_REWARD: "/transaction-and-reward",
    BLOGS: "/blogs",
    BLOG_DETAIL: "/blogs/:id",
    ADVERT_DETAIL: "/advert/:advertId/:sponsorTierId/:projectId",
  },
  ADMIN_PAGES: {
    HOME: `/${ADMIN_PATH}`,
    LOGIN: `/${ADMIN_PATH}/login`,
    SIGIN: `/${ADMIN_PATH}/sigin`,
    DASHBOARD: `/${ADMIN_PATH}/dashboard`,
    PROJECTS: `/${ADMIN_PATH}/projects`,
    USERS: `/${ADMIN_PATH}/users`,
    DATA_SETUPS: `/${ADMIN_PATH}/data-setup`,
    DEFAULT_AVATARS: `/${ADMIN_PATH}/default-avatars`,
    LICENSES: `/${ADMIN_PATH}/licenses`,
    BLOGS: `/${ADMIN_PATH}/blogs`,
  },
  ERROR_404: "/error/404",
  ERROR_CODE: "/error/:code/:message",
};

export const KEY_COOKIE = {
  ACCESS_TOKEN: "access_token",
  ACCESS_TOKEN_ADMIN: "access_token_admin",
  LOGIN_INFO: "login_info",
  BOUNTY: "bounty",
  BOUNTY_STEP: "bounty_step",
  DATA_SETUP: "data-setup",
  DATA_STEP: "data-step",
  ADD_PORTFOLIO_STEP: "add-portfolio-step",
  PROFILE: "profile",
};

export const SHARE_URL = {
  TWITTER: "https://twitter.com/intent/tweet?url=",
  LINKEDLN: "https://www.linkedin.com/shareArticle?mini=true&url=",
  FACEBOOK: "https://www.facebook.com/sharer/sharer.php?u=",
};

export const INFURA_ID = process.env.REACT_APP_INFURA_ID || "";

export const IPFS_CONFIG = {
  API_ENDPOINT: process.env.REACT_APP_IPFS_API_ENDPOINT,
  API_VIEW: process.env.REACT_APP_IPFS_API_VIEW,
  PROTOCOL: process.env.REACT_APP_IPFS_PROTOCOL,
};

export const GOVERNANCE_IPFS_PATH = "/";

export const BLOG_PATH = process.env.REACT_APP_BLOG_URL;
