import { FC } from "react";
import { Breadcrumb as AntdBreadcrumb } from "antd";
import {
  BreadcrumbSkeleton,
  Container,
  Separator,
  Text,
} from "./breadcrumb.style";
import { IBreadcrumbProps } from "./breadcrumb.props";

const Breadcrumb: FC<IBreadcrumbProps> = ({ items, loading }) => {
  return (
    <div>
      <Container>
        {loading ? (
          <BreadcrumbSkeleton active paragraph={false} />
        ) : (
          <AntdBreadcrumb separator={<Separator />}>
            {items.map((item, pos) => (
              <AntdBreadcrumb.Item key={pos} href={item.to}>
                <Text>{item.text}</Text>
              </AntdBreadcrumb.Item>
            ))}
          </AntdBreadcrumb>
        )}
      </Container>
    </div>
  );
};

export default Breadcrumb;
