import {
  AppstoreOutlined,
  AudioOutlined,
  FileOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import { Col, List, Row, Tag, Typography } from "antd";
import { FileType, IFile } from "api/users/portfolio";
import { GlobeIcon } from "assets/users/projects/add_project/Globe.icon";
import { FC, memo } from "react";
import { IPortfolioProps } from "./portfolio.props";
import {
  Description,
  GradientCard,
  Heading,
  Heading2,
  ImageBlock,
  LinkBLock,
  ProjectListItem,
  SkillListItem,
  WrapBlock,
  WrapContent,
} from "./portfolio.style";

const { Text, Link } = Typography;

const Portfolio: FC<IPortfolioProps> = ({ userPortfolio }: IPortfolioProps) => {
  const renderProjectFileThumbnail = (file: IFile) => {
    switch (file.type) {
      case FileType.VIDEO:
        return <VideoCameraOutlined className="thumbnail" />;
      case FileType.IMAGE:
        return <img src={file.url} className="thumbnail" alt="Thumbnail" />;
      case FileType.DOCUMENT:
        return <AppstoreOutlined className="thumbnail" />;
      case FileType.AUDIO:
        return <AudioOutlined className="thumbnail" />;
      default:
        return <FileOutlined className="thumbnail" />;
    }
  };

  return (
    <WrapBlock>
      <Row gutter={[24, 20]}>
        <Col span={24}>
          <ImageBlock>
            <img src={userPortfolio?.cover_img_url} alt="Cover" />
          </ImageBlock>
        </Col>
        <Col span={24}>
          <Heading>{userPortfolio?.title}</Heading>
        </Col>
        <Col span={24}>
          <GradientCard>
            <Heading2>Description</Heading2>
            <Description>{userPortfolio?.description}</Description>
          </GradientCard>
        </Col>
        <Col span={24}>
          <WrapContent>
            {userPortfolio?.skills && userPortfolio?.skills.length > 0 && (
              <Row gutter={[24, 16]}>
                <Col span={24}>
                  <Heading2>Skills and deliverables</Heading2>
                </Col>
                <Col span={24}>
                  <List
                    grid={{ gutter: 8 }}
                    dataSource={userPortfolio.skills.map((skill) => ({
                      name: skill,
                      color: "purple",
                    }))}
                    renderItem={({ name, color }) => (
                      <SkillListItem>
                        <Tag color={color}>{name}</Tag>
                      </SkillListItem>
                    )}
                  />
                </Col>
              </Row>
            )}

            {userPortfolio?.files && userPortfolio?.files.length > 0 && (
              <Row gutter={[24, 16]}>
                <Col span={24}>
                  <Heading2>Project Files</Heading2>
                </Col>
                <Col span={24}>
                  <List
                    grid={{ gutter: 8 }}
                    dataSource={userPortfolio.files}
                    renderItem={(file) => (
                      <ProjectListItem>
                        <a download href={file.url}>
                          <div>{renderProjectFileThumbnail(file)}</div>
                          <Text ellipsis>{file.name}</Text>
                        </a>
                      </ProjectListItem>
                    )}
                  />
                </Col>
              </Row>
            )}

            {userPortfolio?.embedded_video_urls &&
              userPortfolio?.embedded_video_urls.length > 0 && (
                <Row gutter={[24, 16]}>
                  <Col span={24}>
                    <Heading2>Video Link</Heading2>
                  </Col>
                  <Col>
                    {userPortfolio?.embedded_video_urls.map(
                      (link: string, key: number) => (
                        <LinkBLock key={key}>
                          <GlobeIcon />
                          <Link href={link} ellipsis>
                            {link}
                          </Link>
                        </LinkBLock>
                      )
                    )}
                  </Col>
                </Row>
              )}

            {userPortfolio?.website && (
              <Row gutter={[24, 16]}>
                <Col span={24}>
                  <Heading2>Project URL</Heading2>
                </Col>
                <Col>
                  <LinkBLock>
                    <GlobeIcon />
                    <Link href={userPortfolio.website}>
                      {userPortfolio.website}
                    </Link>
                  </LinkBLock>
                </Col>
              </Row>
            )}
          </WrapContent>
        </Col>
      </Row>
    </WrapBlock>
  );
};

export default memo(Portfolio);
