import { JsonRpcProvider, JsonRpcSigner } from "@ethersproject/providers";
import { useConnector, useEthers } from "@usedapp/core";
import { BigNumber, ethers } from "ethers";
import { useEffect, useMemo, useState, useCallback } from "react";

type TConnectionName =
  | "Metamask"
  | "CoinbaseWallet"
  | "WalletConnect"
  | undefined;

export function useWallet(expectedChainId: number = 1) {
  const { account, isLoading, library, activateBrowserWallet, switchNetwork } =
    useEthers();
  const [balance, setBalance] = useState<BigNumber>(BigNumber.from(0));
  const [signer, setSigner] = useState<JsonRpcSigner>();

  const [chainId, setChainId] = useState<number>(1);
  const [isLoadingChainId, setIsLoadingChainId] = useState<boolean>(false);

  const { connector: activeConnector } = useConnector();

  const connectorName = useMemo((): TConnectionName => {
    if (!account) {
      return undefined;
    }
    const connectionNames = ["Metamask", "CoinbaseWallet", "WalletConnect"];
    if (connectionNames.includes(activeConnector?.connector?.name || "")) {
      return activeConnector?.connector?.name as TConnectionName;
    } else if (activeConnector?.connector?.name === "Injected") {
      return "WalletConnect";
    }
    return undefined;
  }, [account, activeConnector?.connector]);

  useEffect(() => {
    if (!account) return;

    (async () => {
      const provider = library as JsonRpcProvider;
      const balance = await provider.getBalance(account);
      setBalance(balance);

      const signer = provider.getSigner();
      setSigner(signer);

      const network = await provider.getNetwork();
      setChainId(network.chainId);
    })();
  }, [account, library]);

  const connectToWallet = useCallback(async () => {
    setIsLoadingChainId(true);

    try {
      await activateBrowserWallet();
      await switchNetwork(expectedChainId);

      const provider = library as ethers.providers.JsonRpcProvider;
      const network = await provider.getNetwork();
      setChainId(network.chainId);
    } catch (error) {
      console.error(error);
    }

    setIsLoadingChainId(false);
  }, [activateBrowserWallet, library, switchNetwork, expectedChainId]);

  useEffect(() => {
    const ethereum = (window as any).ethereum;

    const handleChainChanged = (newChainId: number) => {
      setChainId(newChainId);
    };

    setChainId(ethereum?.network?.chainId || 1);

    return () => ethereum?.removeListener("chainChanged", handleChainChanged);
  }, [setChainId, expectedChainId]);

  return {
    account,
    balance,
    signer,
    isLoading: isLoading || isLoadingChainId,
    connectorName,
    provider: library as JsonRpcProvider,
    chainId,
    isConnected: account && chainId === expectedChainId,
    connectToWallet,
  };
}

