import { Card } from "antd";
import styled from "styled-components";
import { THEME } from "utils/theme";

export const CardContainer = styled(Card)`
  border-radius: ${THEME.USER.BORDER_RADIUS_CARD};
  .ant-card-head {
    border: none;
    padding: 0px;

    .ant-card-head-title {
      font-weight: 600;
      font-size: 20px;
      line-height: 120%;
      color: rgba(8, 10, 37, 0.8);
    }

    .ant-card-extra {
      text-decoration-line: underline;
      a {
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
        color: #343538;
      }
    }
  }

  .ant-card-body {
    padding: 24px;
    border: ${THEME.USER.BORDER_CARD};
    background: ${THEME.USER.BACKGROUND_ITEM};
    border-radius: ${THEME.USER.BORDER_RADIUS_CARD};
  }
`;
