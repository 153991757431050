import { FC } from "react";
import { ISelectCheckboxProps } from "./selectCheckbox.props";
import { StyledTreeSelect } from "./selectCheckbox.style";

const SelectCheckbox: FC<ISelectCheckboxProps> = (
  props: ISelectCheckboxProps
) => {
  return (<StyledTreeSelect {...props}>
  </StyledTreeSelect>);
};

export default SelectCheckbox;
