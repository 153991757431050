import { Card } from 'antd';
import styled from "styled-components";

export const CardInfo = styled(Card)`
  border: unset;

  .ant-card-head {
    border: none;
    padding: 0px;

    .ant-card-head-title {
      font-weight: 600;
      font-size: 20px;
      line-height: 120%;
      color: rgba(8, 10, 37, 0.8);
    }

    .ant-card-extra {
      text-decoration-line: underline;
      a {
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
        color: #343538;
      }
    }
  }

  .ant-card-body {
    padding: unset;
  }
`;