import { Select } from "antd";
import { ArrowDown } from "assets/common/svg/ArrowDown.icon";
import { forwardRef } from "react";
import { ISelectArrowDownIconProps } from "./selectArrowDownIcon.props";
import { AntdSelect } from "./selectArrowDownIcon.style";

const { Option } = Select;

const SelectArrowDownIcon: React.ForwardRefRenderFunction<
  any,
  ISelectArrowDownIconProps
> = ({ defaultValue, icon, onChange, ...props }, ref) => {
  return (
    <AntdSelect
      defaultValue={defaultValue}
      suffixIcon={icon ? icon : <ArrowDown />}
      onChange={onChange}
      filterOption={(inputValue, option: any) => {
        return option?.label.toLowerCase().includes(inputValue.toLowerCase());
      }}
      ref={ref}
      {...props}
    >
      {props.options?.map((item: any, key: number) => (
        <Option key={key} value={item.value}>
          {item?.label}
        </Option>
      ))}
    </AntdSelect>
  );
};

export default forwardRef(SelectArrowDownIcon);
