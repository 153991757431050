import { Layout } from "antd";
import styled from "styled-components";
import { THEME } from "utils/theme";

const { Header } = Layout;

export const HeaderAd = styled(Header)`
  background: ${THEME.USER.BACKGROUND_GRADIENT};
  padding-left: 10px;
  height: 65px;
  display: flex;
  align-items: center;
`;

export const IconToggle = styled.div`
  span {
    font-size: 18px;
    cursor: pointer;
    color: white;
  }
`;

export const RightHeader = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  justify-content: space-around;

  .ant-space-item {
    :last-child {
      line-height: 0px;
    }
  }

  span {
    cursor: pointer;
  }

  .ant-space-item:not(:last-child) {
    margin-top: 10px;
  }

  svg {
    color: white;
    font-size: 20px;
  }
`;

export const DropdownButton = styled.div`
  cursor: pointer;
  color: white;
  font-weight: 500;
`;


