export const ReportFlag = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="#676B7E"
    viewBox="0 0 20 20"
  >
    <path
      fillRule="evenodd"
      d="M2.533 2.5c0-.443.358-.8.8-.8h11.554c.31 0 .615.088.877.254.262.167.47.405.602.686.132.28.18.594.141.901-.04.308-.166.598-.365.837l-2.6 3.121 2.6 3.121c.199.239.325.53.365.837.04.308-.01.62-.14.902-.132.28-.341.518-.603.685-.262.167-.566.255-.876.255H4.133v4.2c0 .442-.358.8-.8.8-.442 0-.8-.358-.8-.8v-15zm1.6 9.2h10.754c.006 0 .013-.003.018-.006.005-.003.01-.008.012-.014.003-.006.004-.012.003-.018 0-.007-.003-.013-.007-.017L11.885 8.01c-.247-.296-.247-.727 0-1.024l3.028-3.633c.004-.005.006-.01.007-.017 0-.006 0-.013-.003-.019-.002-.005-.007-.01-.012-.014-.005-.003-.012-.005-.018-.005H4.133v8.4z"
      clipRule="evenodd"
    ></path>
  </svg>
);
