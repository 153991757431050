import { IProjectTbl, IQuestionTbl, ITagTbl, IUserTbl } from "types";
export enum QuestionSortByEnum {
  UPVOTES,
  LATEST,
}

export interface ICreateQuestionsReq {
  project_id: number;
  title: string;
  content: string;
  tag_names: string[];
}

export interface IGetAllQuestionsReq {
  project_id: number;
  search: string;
  limit: number;
  page: number;
  tag_names: string[];
  sort_by: number;
}

export interface IQuestionResult extends IQuestionTbl {
  tags?: ITagTbl[];
  user?: IUserTbl;
  project?: IProjectTbl;
}

export interface IGetAllQuestionsRes {
  page?: number;
  page_size?: number;
  total_page?: number;
  total_item?: number;
  result?: IQuestionResult[];
}

export interface IUpdateQuestion {
  title: string;
  content: string;
  tag_names: string[];
}

export interface IUpdateQuestionReq {
  id: string;
  updatedQuestion: IUpdateQuestion;
}

export interface IQuestionTag {
  title: string | undefined;
  value: string | undefined;
  key: number | undefined;
}
