import { FC, memo } from "react";
import { ITagWithIconProps } from "./tagWithIcon.props";
import { Container } from "./tagWithIcon.style";

const TagWithIcon: FC<ITagWithIconProps> = ({
  children,
  color,
  ...props
}: ITagWithIconProps) => {
  return (
    <Container color={color} {...props}>
      {children}
    </Container>
  );
};

export default memo(TagWithIcon);
