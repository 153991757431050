import styled from "styled-components";
import { THEME } from "utils/theme";

export const Container = styled.div<{ show: boolean }>`
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  ${({ show }: any) => {
    return show
      ? `
      opacity: 1;
      z-index: 100;
    `
      : `
      opacity: 0;
      z-index: -1;
    `;
  }}

  background: ${THEME.USER.BACKGROUND_GRADIENT};
  transition: all 2s;
`;