import { Rule } from "antd/lib/form";
import { ethers } from "ethers";
import { VALIDATE_MESSAGES } from "./message";

export const validAddressRules: Rule = {
  required: true,
  validator: (_: any, value: string) => {
    if (!value) return Promise.reject("This is required field");
    if (!ethers.utils.isAddress(value))
      return Promise.reject("Invalid address format");

    return Promise.resolve();
  },
};

export const requiredRules: Rule = {
  required: true,
  message: VALIDATE_MESSAGES.REQUIRED,
};

export const requiredRulesShortMes: Rule = {
  required: true,
  message: "Required",
};

export const stringRequired: Rule = {
  required: true,
  whitespace: true,
  message: VALIDATE_MESSAGES.REQUIRED,
};

export const selectRequiredRules: Rule = {
  required: true,
  message: "Please select at least 1",
};

export const maxLength_30: Rule = {
  max: 30,
  message: "is too long (maximum 30 characters)",
};

export const maxLength_40: Rule = {
  max: 40,
  message: "is too long (maximum 40 characters)",
};

export const maxLength_50: Rule = {
  max: 50,
  message: "is too long (maximum 50 characters)",
};

export const maxLength_60: Rule = {
  max: 60,
  message: "is too long (maximum 60 characters)",
};

export const maxLength_80: Rule = {
  max: 80,
  message: "is too long (maximum 80 characters)",
};

export const maxLength_128: Rule = {
  max: 128,
  message: "is too long (maximum 128 characters)",
};

export const maxLength_140: Rule = {
  max: 140,
  message: "is too long (maximum 140 characters)",
};

export const maxLength_180: Rule = {
  max: 180,
  message: "is too long (maximum 180 characters)",
};

export const maxLength_200: Rule = {
  max: 200,
  message: "is too long (maximum 200 characters)",
};
export const maxLength_255: Rule = {
  max: 255,
  message: "is too long (maximum 255 characters)",
};

export const minLength_100: Rule = {
  min: 100,
  message: "is too short (minimum 100 characters)",
};

export const numberTextAndUnderscore: Rule = {
  validator: (_: any, value: string) => {
    if (!value) {
      return Promise.resolve();
    }
    if (!/^[A-Za-z_][A-Za-z0-9_]*$/.test(value)) {
      return Promise.reject(
        new Error("Only letters, numbers, and underscores are allowed.")
      );
    }
    return Promise.resolve();
  },
};

export const urlRules: Rule = { type: "url", message: "Must be a valid URL " };

export const emailRules: Rule = {
  type: "email",
  message: "The input is not valid E-mail!",
};

export const numberRules: Rule = {
  pattern: new RegExp(/^\d+(?:\.\d{1,6})?$/),
  message: "Must be a number",
};

export const integerRules: Rule = {
  pattern: new RegExp(/^[0-9]+$/),
  message: "Positive number!",
};

export const positiveDecimalRules: Rule = {
  pattern: new RegExp(/^[0-9.]+$/),
  message: "Positive number!",
};

export const greatThan0Rules: Rule = {
  validator: (_: any, value: string) => {
    if (!(Number(value) > 0))
      return Promise.reject("The value must be greater than 0");
    return Promise.resolve();
  },
};

export const datePickerRules: Rule = {
  type: "object" as const,
  required: true,
  message: "Please select time!",
};

export const scrollToFirstErrorSmooth: boolean | any | undefined = {
  behavior: "smooth",
  block: "center",
  inline: "center",
};

// formatter and parser input number
export const formatterNumber = (val: any) => {
  return `${val}`
    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    .replace(/\.(?=\d{0,2}$)/g, ",");
};

export const parserNumber = (val: any) => {
  if (!val) return 0;
  return Number.parseFloat(
    val.replace(/\$\s?|(\.*)/g, "").replace(/(,{1})/g, ".")
  ).toFixed(2);
};

export const formatByMonths = "MM/YYYY";
export const formatByFullDate = "MM/DD/YYYY";
export const formatByFullDateTime = "DD/MM/YYYY HH:mm";
export const formatTime = "h:mm a";

export const facebookUrlRules: Rule = {
  validator: (_: any, value: string) => {
    if (
      value &&
      !/^http(s)?:\/\/(?:www\.)?facebook\.com(\/)?([a-zA-Z0-9_\/]*)/.test(value)
    ) {
      return Promise.reject(new Error("Must be a Facebook URL"));
    }
    return Promise.resolve();
  },
};

export const twitterUrlRules: Rule = {
  validator: (_: any, value: string) => {
    if (
      value &&
      !/^http(s)?:\/\/(?:www\.)?twitter\.com(\/)?([a-zA-Z0-9_\/]*)/.test(value)
    ) {
      return Promise.reject(new Error("Must be a Twitter URL"));
    }
    return Promise.resolve();
  },
};

export const linkedinUrlRules: Rule = {
  validator: (_: any, value: string) => {
    if (
      value &&
      !/^http(s)?:\/\/(?:www\.)?linkedin\.com(\/)?([a-zA-Z0-9_\/]*)/.test(value)
    ) {
      return Promise.reject(new Error("Must be a LinkedIn URL"));
    }
    return Promise.resolve();
  },
};

export const githubUrlRules: Rule = {
  validator: (_: any, value: string) => {
    if (
      value &&
      !/http(?:s)?:\/\/(?:www\.)?github\.com\/([a-zA-Z0-9_]+)/.test(value)
    ) {
      return Promise.reject(new Error("Must be a Github URL"));
    }
    return Promise.resolve();
  },
};
