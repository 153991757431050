import { useMutation } from "@tanstack/react-query";
import { Button, Checkbox, Collapse, Input, Row } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import TextArea from "antd/lib/input/TextArea";
import {
  createEventPollsAPI,
  deleteEventPollByIdAPI,
  getAllEventPollsAPI,
  getEventPollAnswerByIdAPI,
  getEventPollByIdAPI,
  IGetAllEventPollsReq,
  IGetAllEventPollsRes,
  updateEventPollByIdAPI
} from "api/users/eventPolls";
import { AxiosError } from "axios";
import { FC, Fragment, memo, useRef, useState } from "react";
import ReactJson from "react-json-view";
import { IEventPollTbl } from "types";
import { METHOD_API } from "../apiTest";
import { CollapseShowJson } from "../commonUi.style";
const { Panel } = Collapse;

const EventPollsAPI: FC<any> = () => {
  const checkBox = useRef<any>(null);
  const [isShow, setIsShow] = useState<boolean>(true);
  const [createdResult, setCreateResult] = useState<IEventPollTbl>({});
  const [updateResult, setUpdateResult] = useState<any>({});
  const [deleteResult, setDeleteResult] = useState<any>({});
  const [eventPoll, setEventPoll] = useState<IEventPollTbl>({});
  const [eventPollId, setEventPollId] = useState<string>("");
  const [eventPollAnswerId, setEventPollAnswerId] = useState<string>("");
  const [eventPollAnswer, setEventPollAnswer] = useState<IEventPollTbl>({});
  const [updateEventPollId, setUpdateEventPollId] = useState<string>("");
  const [deleteEventPollId, setDeleteEventPollId] = useState<string>("");

  const [allEventPoll, setAllEventPoll] = useState<IGetAllEventPollsRes>({});
  const [textInputForUpdate, setTextInputForUpdate] = useState<string>(`
    {
      "content": "string",
      "status": 0
    }
  `);
  const [textInputForGetAll, setTextInputForGetAll] = useState<string>(`
  {
    "page": 1,
    "limit": 10
  }
`);
  const [textInputForCreate, setTextInputForCreate] = useState<string>(`{
  "author_id": 1,
  "event_id": 1,
  "multiple_vote": 1,
  "poll_items": ["Option_5"]
}`);

  const { mutate: createEventPolls } = useMutation(createEventPollsAPI, {
    onSuccess: async (rs: IEventPollTbl) => {
      setCreateResult(rs);
    },
    onError: (err: AxiosError) => {
      setCreateResult(JSON.parse(JSON.stringify(err)));
    },
  });

  const { mutate: getAllEventPolls } = useMutation(getAllEventPollsAPI, {
    onSuccess: async (rs: IGetAllEventPollsReq) => {
      setAllEventPoll(rs);
    },
    onError: (err: any) => {
      setAllEventPoll(JSON.parse(JSON.stringify(err)));
    },
  });

  const { mutate: getEventPollById } = useMutation(getEventPollByIdAPI, {
    onSuccess: async (rs: IEventPollTbl) => {
      setEventPoll(rs);
    },
    onError: (err: any) => {
      setEventPoll(JSON.parse(JSON.stringify(err)));
    },
  });

  const { mutate: updateEventPollById } = useMutation(updateEventPollByIdAPI, {
    onSuccess: async (rs: any) => {
      setUpdateResult(rs);
    },
    onError: (err: any) => {
      setUpdateResult(JSON.parse(JSON.stringify(err)));
    },
  });

  const { mutate: deleteEventPollById } = useMutation(deleteEventPollByIdAPI, {
    onSuccess: async (rs: any) => {
      setDeleteResult(rs);
    },
    onError: (err: any) => {
      setDeleteResult(JSON.parse(JSON.stringify(err)));
    },
  });

  const { mutate: getEventPollAnswerById } = useMutation(
    getEventPollAnswerByIdAPI,
    {
      onSuccess: async (rs: IEventPollTbl) => {
        setEventPollAnswer(rs);
      },
      onError: (err: any) => {
        setEventPollAnswer(JSON.parse(JSON.stringify(err)));
      },
    }
  );

  const handleChageTextInputForCreate = (event: any) => {
    setTextInputForCreate(event.target.value);
  };

  const handleChangeTextInputForGetAll = (event: any) => {
    setTextInputForGetAll(event.target.value);
  };

  const handleChangeEventPollId = (event: any) => {
    setEventPollId(event.target.value);
  };

  const handleChangeUpdateEventPollId = (event: any) => {
    setUpdateEventPollId(event.target.value);
  };

  const handleChangeTextInputForUpdate = (event: any) => {
    setTextInputForUpdate(event.target.value);
  };

  const handleChangeDeleteEventPollId = (event: any) => {
    setDeleteEventPollId(event.target.value);
  };

  const handleChangeEventPollAnswerId = (event: any) => {
    setEventPollAnswerId(event.target.value);
  };

  const createEventPollsClick = () => {
    createEventPolls(JSON.parse(textInputForCreate));
  };

  const getAllEventPollsAPIClick = () => {
    getAllEventPolls(JSON.parse(textInputForGetAll));
  };

  const getEventByIdClick = () => {
    getEventPollById(eventPollId);
  };

  const updateEventPollByIdClick = () => {
    updateEventPollById({
      id: updateEventPollId,
      updatedEventPoll: JSON.parse(textInputForUpdate),
    });
  };

  const deleteEventPollByIdClick = () => {
    deleteEventPollById(deleteEventPollId);
  };

  const getEventPollAnswerByIdClick = () => {
    getEventPollAnswerById(eventPollAnswerId);
  };

  return (
    <>
      <Row style={{ marginTop: "10px" }}>
        <h1>
          <b>Event polls</b>
        </h1>
        <Checkbox
          ref={checkBox}
          onChange={(e: CheckboxChangeEvent) => {
            setIsShow(e.target.checked);
          }}
          style={{
            marginLeft: "10px",
          }}
          checked={isShow}
        />
      </Row>
      {isShow && (
        <Collapse accordion>
          <Panel header="/event-polls" key="1" extra={METHOD_API.POST}>
            <Button
              type="primary"
              onClick={createEventPollsClick}
              style={{
                marginBottom: "10px",
              }}
            >
              Try it out
            </Button>
            <h1>Request body</h1>
            <TextArea
              style={{
                marginBottom: "10px",
              }}
              value={textInputForCreate}
              defaultValue={textInputForCreate}
              onChange={handleChageTextInputForCreate}
              placeholder=""
              rows={10}
            />
            <Fragment>
              <h1>Result</h1>
              <CollapseShowJson ghost>
                <ReactJson src={JSON.parse(JSON.stringify(createdResult))} />
              </CollapseShowJson>
            </Fragment>
          </Panel>
          <Panel header="/event-polls/alls" key="2" extra={METHOD_API.GET}>
            <Button
              type="primary"
              onClick={getAllEventPollsAPIClick}
              style={{
                marginBottom: "10px",
              }}
            >
              Try it out
            </Button>
            <h1>Request body</h1>
            <TextArea
              style={{
                marginBottom: "10px",
              }}
              value={textInputForGetAll}
              defaultValue={textInputForGetAll}
              onChange={handleChangeTextInputForGetAll}
              placeholder=""
              rows={10}
            />
            <Fragment>
              <h1>Result</h1>
              <CollapseShowJson ghost>
                <ReactJson src={JSON.parse(JSON.stringify(allEventPoll))} />
              </CollapseShowJson>
            </Fragment>
          </Panel>
          <Panel header="/event-polls/{id}" key="3" extra={METHOD_API.GET}>
            <Button
              type="primary"
              onClick={getEventByIdClick}
              style={{
                marginBottom: "10px",
              }}
            >
              Try it out
            </Button>
            <h1>Request body</h1>
            <Input
              placeholder="Enter event id"
              allowClear
              onChange={handleChangeEventPollId}
              value={eventPollId}
              style={{
                marginBottom: "10px",
              }}
            />
            <Fragment>
              <h1>Result</h1>
              <CollapseShowJson ghost>
                <ReactJson src={JSON.parse(JSON.stringify(eventPoll))} />
              </CollapseShowJson>
            </Fragment>
          </Panel>
          <Panel header="/event-polls/{id}" key="4" extra={METHOD_API.PUT}>
            <Button
              type="primary"
              onClick={updateEventPollByIdClick}
              style={{
                marginBottom: "10px",
              }}
            >
              Try it out
            </Button>
            <Input
              placeholder="Enter event id"
              allowClear
              onChange={handleChangeUpdateEventPollId}
              value={updateEventPollId}
              style={{
                marginBottom: "10px",
              }}
            />
            <h1>Request body</h1>
            <TextArea
              style={{
                marginBottom: "10px",
              }}
              value={textInputForUpdate}
              defaultValue={textInputForUpdate}
              onChange={handleChangeTextInputForUpdate}
              placeholder=""
              rows={10}
            />
            <Fragment>
              <h1>Result</h1>
              <CollapseShowJson ghost>
                <ReactJson src={JSON.parse(JSON.stringify(updateResult))} />
              </CollapseShowJson>
            </Fragment>
          </Panel>
          <Panel header="/event-polls/{id}" key="5" extra={METHOD_API.DELETE}>
            <Button
              type="primary"
              onClick={deleteEventPollByIdClick}
              style={{
                marginBottom: "10px",
              }}
            >
              Try it out
            </Button>
            <h1>Request body</h1>
            <Input
              placeholder="Enter event id"
              allowClear
              onChange={handleChangeDeleteEventPollId}
              value={deleteEventPollId}
              style={{
                marginBottom: "10px",
              }}
            />
            <Fragment>
              <h1>Result</h1>
              <CollapseShowJson ghost>
                <ReactJson src={JSON.parse(JSON.stringify(deleteResult))} />
              </CollapseShowJson>
            </Fragment>
          </Panel>
          <Panel
            header="/event-polls/answers/{id}"
            key="6"
            extra={METHOD_API.GET}
          >
            <Button
              type="primary"
              onClick={getEventPollAnswerByIdClick}
              style={{
                marginBottom: "10px",
              }}
            >
              Try it out
            </Button>
            <h1>Request body</h1>
            <Input
              placeholder="Enter event id"
              allowClear
              onChange={handleChangeEventPollAnswerId}
              value={eventPollAnswerId}
              style={{
                marginBottom: "10px",
              }}
            />
            <Fragment>
              <h1>Result</h1>
              <CollapseShowJson ghost>
                <ReactJson src={JSON.parse(JSON.stringify(eventPollAnswer))} />
              </CollapseShowJson>
            </Fragment>
          </Panel>
        </Collapse>
      )}
    </>
  );
};

export default memo(EventPollsAPI);
