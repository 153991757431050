import { Layout, Spin } from "antd";
import { IssueDetailHeader, ProjectsHeader } from "components/module";
import SponsorHeader from "components/module/sponsorHeader";
import Seo from "pages/seo";
import { FC, memo } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTER_CONSTANTS } from "utils/constant";
import Breadcrumb from "../breadcrumb";
import FooterUser from "../footer";
import HeaderUser from "../header";
import SidebarProjects from "../sidebarProjects";
import { IMasterLayoutProps } from "./masterLayout.props";
import { AppFooter, Container, SpinContainer } from "./masterLayout.style";

const { Sider, Content } = Layout;

const MasterLayout: FC<IMasterLayoutProps> = ({
  children,
  showSidebarProjects,
  showHeaderProjects,
  showHeaderIssueDetail,
  isFluid,
  isLoading,
  breadcrumb,
  loadingBreadcrumb,
  setRefetchAboutPage,
  showHeaderSponsor,
  customHeader,
  ...props
}: IMasterLayoutProps) => {
  const navigate = useNavigate();

  const bgBody = "#FFFFFF";

  const showFluid = isFluid ? "0px 136px 50px 136px" : "unset";

  return (
    <Layout style={{ background: bgBody }} {...props}>
      <Seo />
      <HeaderUser
        onClickSignUp={() => navigate(ROUTER_CONSTANTS.USER.SIGNUP)}
        onClickLogIn={() => navigate(ROUTER_CONSTANTS.USER.LOGIN)}
      />
      {breadcrumb && breadcrumb.length > 0 && (
        <Breadcrumb items={breadcrumb} loading={loadingBreadcrumb} />
      )}

      {showHeaderIssueDetail && <IssueDetailHeader />}

      {showHeaderProjects && (
        <ProjectsHeader setRefetchAboutPage={setRefetchAboutPage} />
      )}

      {showHeaderSponsor && <SponsorHeader />}

      {customHeader}

      <Container style={{ margin: showFluid }}>
        {isLoading ? (
          <SpinContainer>
            <Spin />
          </SpinContainer>
        ) : (
          <Layout>
            {showSidebarProjects && (
              <Sider width={300} style={{ background: bgBody }}>
                <SidebarProjects />
              </Sider>
            )}
            <Content style={{ background: bgBody }}>{children}</Content>
          </Layout>
        )}
      </Container>
      <AppFooter>
        <FooterUser />
      </AppFooter>
    </Layout>
  );
};

export default memo(MasterLayout);
