import { Switch } from "antd";
import styled from "styled-components";

export const Container = styled.div`
  span {
    margin-left: 2px;
  }
`;

export const SwitchCustom = styled(Switch)`
`;
