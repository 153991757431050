import { FC, memo } from "react";
import { IButtonGradientTextProps } from "./buttonGradientText.props";
import {
  ButtonGradientAntd,
  TextContainer,
  TextContent,
} from "./buttonGradientText.style";

const ButtonGradientText: FC<IButtonGradientTextProps> = ({
  children,
  ...props
}: IButtonGradientTextProps) => {
  return (
    <ButtonGradientAntd {...props}>
      <TextContainer>
        <TextContent>{children}</TextContent>
      </TextContainer>
    </ButtonGradientAntd>
  );
};

export default memo(ButtonGradientText);
