export enum FileType {
  VIDEO = "Video",
  IMAGE = "Image",
  DOCUMENT = "Document",
  AUDIO = "Audio",
}

export interface IFile {
  name: string;
  url: string;
  type: FileType;
}

export interface ISuggestedSkill {
  id: number;
  value: string;
}

export interface IFileType {
  id: number;
  value: string;
}

export interface IProject {
  id: number;
  github_name: string;
}

export interface IPortfolioTbl {
  id?: number;
  title?: string;
  thumbnail_url?: string;
  cover_img_url?: string;
  description?: string;
  files?: IFile[];
  skills?: string[];
  website?: string;
  embedded_video_urls?: string[];
}

export interface IGetPortfolioAddRes {
  suggested_skills: ISuggestedSkill[];
  file_types: IFileType[];
}

export interface IPortfolioReq {
  title?: string;
  thumbnail_url?: string;
  cover_img_url?: string;
  description?: string;
  files?: IFile[];
  skills?: string[];
  website?: string | null;
  embedded_video_urls?: string[];
}

export interface ICreatePortfolioReq {
  title?: string;
  thumbnail_url?: string;
  cover_img_url?: string;
  description?: string;
  files?: IFile[];
  skills?: string[];
  website?: string;
  embedded_video_urls?: string[];
}

export interface ICreatePortfolioRes extends IPortfolioTbl {}

export interface IUpdatePortfolioReq extends IPortfolioReq {
  id: number;
  body: IPortfolioReq;
}

export interface IUpdatePortfolioRes extends IPortfolioTbl {}

export interface IGetPortfolioByIdReq {
  id?: string;
}

export interface IGetPortfolioByIdRes extends IPortfolioTbl {}
