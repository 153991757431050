import { Col } from "antd";
import SkeletonCustom from "components/base/skeletonCustom";
import styled from "styled-components";
import { THEME } from "utils/theme";
import { PROJECT_STATUS } from "./projectCard.props";

export const Container = styled(Col)`
  text-align: center;
  background: #ffffff;
  width: 100%;
  overflow: visible;
`;

export const ProjectCardContainer = styled(Col)`
  cursor: pointer;
  border: 1px solid rgba(8, 10, 37, 0.1);
  border-radius: 8px;
  height: 95%;
  transition: all 0.4s;
  :hover {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4);
  }
`;

export const HeaderCard = styled.div`
  display: flex;
  border: 1px solid rgba(8, 10, 37, 0.1);
  border-radius: 32px;
  margin-top: 10px;
  margin-bottom: 10px;
  height: 60px;
  align-items: center;
`;

export const LeftHeaderCard = styled.div`
  display: flex;
  align-items: center;
`;

export const Avatar = styled.img`
  width: 40px;
  height: 40px;
  background: ${THEME.USER.BACKGROUND_ITEM};
  border-radius: 42px;
  cursor: pointer;
  margin-left: 10px;
  transform: scale(1);
`;

export const LeftHeaderTitle = styled.div`
  margin-left: 5px;
  text-align: start;
  display: flex;
  flex-direction: column;
  row-gap: 6px;

  .ant-typography {
    margin-bottom: unset;
    line-height: 100%;
  }
  max-width: 180px;
`;

export const LeftHeaderTitleName = styled.div`
  font-weight: 700;
  font-size: 18px;
  color: #080a25;
`;

export const LeftHeaderTitleDesc = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: rgba(8, 10, 37, 0.6);
  width: 100px;
`;

export const RightBlock = styled.div`
  display: flex;
  align-items: center;
`;

export const CardStatus = styled.div<{ status?: number }>`
  ${(status) => {
    switch (`${status.status}`) {
      case `${PROJECT_STATUS.DRAFT}`:
        return `
        color: rgba(8, 151, 156, 1);
          background: rgba(8, 151, 156, 0.08);
        `;
      case `${PROJECT_STATUS.LOADING}`:
        return `
        color: rgba(156, 151, 8, 1);
          background: rgba(156, 151, 8, 0.08);
        `;
      case `${PROJECT_STATUS.PROCESSING}`:
        return `
        color: rgba(255, 122, 0, 1);;
          background: rgba(255, 122, 0, 0.08);
        `;
      case `${PROJECT_STATUS.ADMIN_REVIEW}`:
        return `
          color: rgba(209, 175, 11, 1);
          background: rgba(209, 175, 11,0.08);
        `;
      case `${PROJECT_STATUS.ACTIVE}`:
        return ``;
      case `${PROJECT_STATUS.CANCELED}`:
        return `
        color: rgba(255, 0, 0, 1);
          background: rgba(255, 0, 0, 0.08);
        `;
      case `${PROJECT_STATUS.INACTIVE}`:
        return `
        color: rgba(0, 0, 0, 1);
          background: rgba(0, 0, 0, 0.08);
        `;
      default:
        return ``;
    }
  }}
  border-radius: 32px;
  padding: 0px 12px;
  height: 30px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    margin-left: 2px;
    margin-top: 2px;
  }
  width: fit-content;
  margin-bottom: 5px;
`;

export const StarContainer = styled.div`
  background: rgba(126, 0, 253, 0.08);
  border-radius: 32px;
  width: 75px;
  height: 30px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    margin-left: 2px;
    margin-top: 2px;
  }
`;

export const RightHeaderCard = styled.div`
  margin-left: auto;
`;

export const BodyCard = styled.div`
  text-align: left;
  min-height: 120px;
`;

export const Content = styled.div``;

export const BountyExplorer = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  color: #f47820;
  cursor: pointer;
`;

export const FooterCard = styled.div`
  margin-left: -10px;
  margin-right: -10px;
  border-top: 1px solid rgba(8, 10, 37, 0.08);
  margin-bottom: 25px;
`;

export const TagsLanguage = styled.div`
  margin: 10px 10px 10px 10px;
  min-height: 20px;
  flex-wrap: wrap;
  display: flex;
  gap: 5px;
`;

export const TagLanguage = styled.span<{ color?: string }>`
  ${({ color }: any) => {
    const bdCol = color ? color.replace(", 1)", ", 0.5)") : "";
    const bgCol = color ? color.replace(", 1)", ", 0.08)") : "";
    return `color: ${color};
    border: 1px solid ${bdCol};
      background: ${bgCol};
      `;
  }}
  border-radius: 15px;
  padding: 2px 5px 2px 5px;
  margin-right: 5px;
`;

export const AvatarSkeleton = styled(SkeletonCustom)`
  margin-left: 10px;
  .ant-skeleton-header {
    vertical-align: middle;
    padding-right: 0px !important;
  }
`;

export const TitleSkeleton = styled(SkeletonCustom)`
  .ant-skeleton-paragraph {
    margin-bottom: 2px;
    li {
      height: 14px;
    }
  }
`;

export const CategorySkeleton = styled(SkeletonCustom)`
  .ant-skeleton-paragraph {
    margin-bottom: 0px;
    li {
      height: 8px;
    }
  }
`;

export const StartInfoSkeleton = styled(SkeletonCustom)`
  margin-right: 4px;
  .ant-skeleton-button {
    width: 75px !important;
    height: 30px !important;
    border-radius: 32px !important;
  }
`;

export const CardInfoSkeleton = styled(SkeletonCustom)`
  .ant-skeleton-paragraph {
    margin-bottom: 2px;
    li {
      height: 8px;
    }
  }
`;

export const TagLanguagesSkeleton = styled(SkeletonCustom)`
  .ant-skeleton-button {
    height: 26px;
    border-radius: 32px !important;
  }
`;

export const LeftButtonSkeleton = styled(SkeletonCustom)`
  .ant-skeleton-button {
    width: 110px !important;
    height: 26px !important;
    border-radius: 8px;
  }
`;

export const RightButtonSkeleton = styled(SkeletonCustom)`
  .ant-skeleton-button {
    width: 22px !important;
    min-width: 22px !important;
    height: 26px !important;
    border-radius: 8px;
  }
`;
