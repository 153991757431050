import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { Col, Form, Row } from "antd";
import Portfolio from "pages/users/profile/portfolioDetailPage/components/portfolio";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GradientNextButton, OutlinedButton } from "../addProjectPage.style";
import TitleStep from "../titleStep";
import { IPreviewProjectStepProps } from "./previewProjectStep.props";
import {
  Container,
  ContentBlock,
  WrapBlock,
  WrapButtons,
} from "./previewProjectStep.style";

export const getBase64 = async (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
};

const PreviewProjectStep: FC<IPreviewProjectStepProps> = ({
  projectFormData,
  onFinishStep,
  onPrevStep,
}: IPreviewProjectStepProps) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const onFinish = async () => {
    setIsLoading(true);
    await onFinishStep(projectFormData);
    setIsLoading(false);
  };

  return (
    <Container form={form} onFinish={onFinish}>
      <ContentBlock>
        <TitleStep step={2} />
        <WrapBlock>
          <Portfolio userPortfolio={projectFormData} isLoading={false} />
        </WrapBlock>

        <WrapButtons>
          <Row gutter={[24, 0]} justify="space-between">
            <Col>
              <OutlinedButton icon={<ArrowLeftOutlined />} onClick={onPrevStep}>
                Back
              </OutlinedButton>
            </Col>
            <Col>
              <Row gutter={[16, 0]}>
                <Col>
                  <OutlinedButton
                    onClick={() => navigate(-1)}
                    disabled={isLoading}
                  >
                    Cancel
                  </OutlinedButton>
                </Col>
                <Col>
                  <GradientNextButton
                    icon={<ArrowRightOutlined />}
                    onClick={() => form.submit()}
                    loading={isLoading}
                  >
                    Publish the project
                  </GradientNextButton>
                </Col>
              </Row>
            </Col>
          </Row>
        </WrapButtons>
      </ContentBlock>
    </Container>
  );
};

export default PreviewProjectStep;
