import styled from "styled-components";

import { THEME } from "utils/theme";
import { Button } from "antd";

export const StepContainer = styled.div`
  min-height: 50vh;
`;

export const StepBlock = styled.div``;

export const StepBody = styled.div`
  margin: 0px ${THEME.USER.CONTAINER_FLUID} 0px ${THEME.USER.CONTAINER_FLUID};
  border-bottom: 1px solid #73899533;
  padding-bottom: 60px;
`;

export const OutlinedButton = styled(Button)`
  border-radius: 6px;
  padding: 16px 24px 16px 24px;
  cursor: pointer;
  min-width: 142px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  font: normal 400 16px/115% "Rubik";
  color: rgba(41, 46, 115, 0.42);

  &:hover,
  &:active,
  &.ant-btn:hover,
  &.ant-btn:focus,
  &.ant-btn:active {
    background-color: rgba(156, 138, 193, 0.16);
    border-color: #7e00fd;
    color: #7e00fd;
  }
`;

export const NextButton = styled(OutlinedButton)`
  gap: 8px;

  .anticon {
    order: 1;
  }

  span {
    margin: 0;
  }
`;

export const GradientNextButton = styled(NextButton)`
  background: linear-gradient(
    90deg,
    #c58aff 0%,
    #00adfb 50.61%,
    #00c1ce 78.78%,
    #00de8c 100%
  );
  color: white;

  &:hover,
  &:active,
  &.ant-btn:hover,
  &.ant-btn:focus,
  &.ant-btn:active {
    background: linear-gradient(
      90deg,
      #c58aff 0%,
      #00adfb 50.61%,
      #00c1ce 78.78%,
      #00de8c 100%
    );
    border-color: #7e00fd;
    color: white;
  }
`;
