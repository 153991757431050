import Cookies from "js-cookie";
import { memo } from "react";
import { Outlet, Navigate } from "react-router-dom";
import { KEY_COOKIE, ROUTER_CONSTANTS } from "utils/constant";

const RouteAuth = () => {
  const isLoggedIn = Cookies.get(KEY_COOKIE.ACCESS_TOKEN);

  return isLoggedIn ? (
    <Outlet />
  ) : (
    <Navigate to={ROUTER_CONSTANTS.USER.LOGIN} />
  );
};

export default memo(RouteAuth);
