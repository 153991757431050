import { GithubOutlined, WarningOutlined } from "@ant-design/icons";
import { useMutation } from "@tanstack/react-query";
import { Form, message, Radio, RadioChangeEvent, Space, Spin } from "antd";
import { createProjectsAPI, IAddProjectReq } from "api/users/home";
import { ISuggestProject, useGetPublicOrganizations } from "api/users/projects";
import { AxiosError } from "axios";
import { CommonModal } from "components/base";
import Cookies from "js-cookie";
import { FC, memo, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { homeActions } from "redux/slices/homeSlice";
import { ILoginInfo } from "types";
import { KEY_COOKIE, ROUTER_CONSTANTS } from "utils/constant";
import { getLocalStorage } from "utils/storage";
import { urlRules } from "utils/validatorRules";
import { IModalAddProjectProps } from "./modalAddProject.props";
import {
  ButtonConnect,
  ContainerLoading,
  FooteChooseOrganization,
  InputLinkGithub,
  ListOrganizations,
  ModalTitle,
  RadioGroup,
  TextDecs,
  TitleSuggest,
  WarningIcon,
} from "./modalAddProject.style";
import ModalConnectGithub from "./modalConnectGithub";
import { getMessageContent } from "utils/message";

const ModalAddProject: FC<IModalAddProjectProps> = ({
  show,
  onSuccess,
  ...props
}: IModalAddProjectProps) => {
  const GITHUB_URL = "https://github.com/";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isConnectOK, setIsConnectOK] = useState<boolean>(false);
  const [isShowSuggestModal, setIsShowSuggestModal] = useState<boolean>(false);
  const [repoVal, setRepoVal] = useState<string>("");
  const { github_login: isLoggedGithub, basic_info }: ILoginInfo =
    getLocalStorage(KEY_COOKIE.PROFILE) ?? "";
  const isLoggedLocalSt = Cookies.get(KEY_COOKIE.ACCESS_TOKEN);

  const { mutateAsync: createProject, isLoading } = useMutation(
    createProjectsAPI,
    {
      onSuccess: async () => {
        setIsConnectOK(true);
        if (onSuccess) {
          onSuccess();
        }
      },
      onError: (err: AxiosError) => {
        const data: any = err.response?.data;
        message.error(getMessageContent(data?.message));
      },
    }
  );

  const { data: dataPublic, isLoading: isLoadingDataPublic } =
    useGetPublicOrganizations({
      enabled: isLoggedLocalSt ? true : false,
      onError: (err: any) => {
        getMessageContent(err?.response?.data?.message || err?.message);
      },
    });

  const onConnectGithubLink = async (values: any) => {
    const gitHub: IAddProjectReq = {
      github_url: values.url,
    };

    const data = await createProject(gitHub);

    if (data.id) {
      window.open(process.env.REACT_APP_CONNECT_PROJECT_GITHUB_URI, "_blank");
      navigate(
        ROUTER_CONSTANTS.USER.DATA_SETUP.replace(":id", data.id?.toString())
      );
    }
  };

  const onChangeRepo = (e: RadioChangeEvent) => {
    setRepoVal(e.target.value);
  };

  const onAddProjectByRepo = async () => {
    const data = await createProject({ github_url: `${GITHUB_URL}${repoVal}` });

    if (data.id) {
      window.open(process.env.REACT_APP_CONNECT_PROJECT_GITHUB_URI, "_blank");
      navigate(
        ROUTER_CONSTANTS.USER.DATA_SETUP.replace(":id", data.id?.toString())
      );
    }
  };

  if (!isLoggedGithub) return <ModalConnectGithub show={show} />;

  return (
    <CommonModal
      footer={null}
      visible={show}
      centered
      closable={true}
      onCancel={() => {
        dispatch(homeActions.hideAddProjectModalOut());
        setIsShowSuggestModal(false);
        setRepoVal("");
      }}
      destroyOnClose={true}
      {...props}
    >
      {dataPublic &&
        dataPublic.length > 0 &&
        !isShowSuggestModal &&
        !isConnectOK && (
          <>
            <ModalTitle>Suggest a Communities</ModalTitle>
            <TextDecs>
              Please find the organisations, which are marked as public under
              your GitHub account - <b>@{basic_info.user_name}</b>
            </TextDecs>
            <ListOrganizations>Suggest a Communities</ListOrganizations>

            <RadioGroup onChange={onChangeRepo}>
              <Space direction="vertical">
                {dataPublic.map((repo: ISuggestProject, key: number) => (
                  <Radio key={key} value={repo?.login}>
                    {repo?.login}
                  </Radio>
                ))}
              </Space>
            </RadioGroup>

            <TextDecs>
              by continuing, we will create a page and community for
              Test-SoulBound-2 project
            </TextDecs>
            <FooteChooseOrganization>
              <ButtonConnect
                disabled={repoVal === "" || isLoading}
                loading={isLoading}
                onClick={onAddProjectByRepo}
              >
                Connect
              </ButtonConnect>
              <ButtonConnect
                onClick={() => {
                  setIsShowSuggestModal(true);
                }}
              >
                Suggest a Communities?
              </ButtonConnect>
            </FooteChooseOrganization>
          </>
        )}
      {(!dataPublic || isShowSuggestModal) &&
        !isConnectOK &&
        (isLoadingDataPublic ? (
          <ContainerLoading>
            <Spin></Spin>
          </ContainerLoading>
        ) : (
          <Form onFinish={onConnectGithubLink}>
            <ModalTitle>Add Project</ModalTitle>
            <WarningIcon>
              <WarningOutlined />
            </WarningIcon>
            <TextDecs>
              Looks like you don't have any public organizations under your
              github account - <b>@{basic_info.user_name}</b>
            </TextDecs>
            <TitleSuggest>Suggest a Communities</TitleSuggest>
            <Form.Item
              name="url"
              rules={[
                {
                  required: true,
                  message: "Please Enter Github Repo URL",
                },
                urlRules,
              ]}
            >
              <InputLinkGithub
                size="large"
                placeholder="Enter Github Repo URL"
                prefix={<GithubOutlined />}
              />
            </Form.Item>
            <ButtonConnect
              loading={isLoading}
              disabled={isLoading}
              htmlType="submit"
            >
              Connect
            </ButtonConnect>
          </Form>
        ))}

      {isConnectOK && (
        <>
          <ModalTitle>Thanks for suggesting!</ModalTitle>
          <TextDecs>
            You'll be the first to know once the project is onboard
          </TextDecs>
          <ButtonConnect
            onClick={() => {
              dispatch(homeActions.hideAddProjectModalOut());
              setIsShowSuggestModal(false);
              setRepoVal("");
              setIsConnectOK(false);
            }}
          >
            Got It
          </ButtonConnect>
        </>
      )}
    </CommonModal>
  );
};

export default memo(ModalAddProject);
