import { useMutation } from "@tanstack/react-query";
import { Button, Checkbox, Collapse, Input, Row } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import TextArea from "antd/lib/input/TextArea";
import {
  createEventQuestionsAPI,
  deleteEventQuestionByIdAPI,
  getAllEventQuestionsAPI,
  getEventQuestionAnswerByIdAPI,
  getEventQuestionByIdAPI,
  IGetAllEventQuestionsReq,
  IGetAllEventQuestionsRes,
  updateEventQuestionByIdAPI
} from "api/users/eventQuestions";
import { AxiosError } from "axios";
import { FC, Fragment, memo, useRef, useState } from "react";
import ReactJson from "react-json-view";
import { IEventQuestionTbl } from "types";
import { METHOD_API } from "../apiTest";
import { CollapseShowJson } from "../commonUi.style";
const { Panel } = Collapse;

const EventQuestionsAPI: FC<any> = () => {
  const checkBox = useRef<any>(null);
  const [isShow, setIsShow] = useState<boolean>(true);
  const [createdResult, setCreateResult] = useState<IEventQuestionTbl>({});
  const [updateResult, setUpdateResult] = useState<any>({});
  const [deleteResult, setDeleteResult] = useState<any>({});
  const [eventQuestion, setEventQuestion] = useState<IEventQuestionTbl>({});
  const [eventQuestionId, setEventQuestionId] = useState<string>("");
  const [eventQuestionAnswerId, setEventQuestionAnswerId] =
    useState<string>("");
  const [eventQuestionAnswer, setEventQuestionAnswer] =
    useState<IEventQuestionTbl>({});
  const [updateEventQuestionId, setUpdateEventQuestionId] =
    useState<string>("");
  const [deleteEventQuestionId, setDeleteEventQuestionId] =
    useState<string>("");

  const [allEventQuestion, setAllEventQuestion] =
    useState<IGetAllEventQuestionsRes>({});
  const [textInputForUpdate, setTextInputForUpdate] = useState<string>(`
    {
      "content": "string",
      "status": 0
    }
  `);
  const [textInputForGetAll, setTextInputForGetAll] = useState<string>(`
  {
    "page": 1,
    "limit": 10
  }
`);
  const [textInputForCreate, setTextInputForCreate] = useState<string>(`{
  "author_id": 5,
  "event_id": 1,
  "content": "dawdwdwwww",
  "event_question_id": 6
}`);

  const { mutate: createEventQuestions } = useMutation(
    createEventQuestionsAPI,
    {
      onSuccess: async (rs: IEventQuestionTbl) => {
        setCreateResult(rs);
      },
      onError: (err: AxiosError) => {
        setCreateResult(JSON.parse(JSON.stringify(err)));
      },
    }
  );

  const { mutate: getAllEventQuestions } = useMutation(
    getAllEventQuestionsAPI,
    {
      onSuccess: async (rs: IGetAllEventQuestionsReq) => {
        setAllEventQuestion(rs);
      },
      onError: (err: any) => {
        setAllEventQuestion(JSON.parse(JSON.stringify(err)));
      },
    }
  );

  const { mutate: getEventQuestionById } = useMutation(
    getEventQuestionByIdAPI,
    {
      onSuccess: async (rs: IEventQuestionTbl) => {
        setEventQuestion(rs);
      },
      onError: (err: any) => {
        setEventQuestion(JSON.parse(JSON.stringify(err)));
      },
    }
  );

  const { mutate: updateEventQuestionById } = useMutation(
    updateEventQuestionByIdAPI,
    {
      onSuccess: async (rs: any) => {
        setUpdateResult(rs);
      },
      onError: (err: any) => {
        setUpdateResult(JSON.parse(JSON.stringify(err)));
      },
    }
  );

  const { mutate: deleteEventQuestionById } = useMutation(
    deleteEventQuestionByIdAPI,
    {
      onSuccess: async (rs: any) => {
        setDeleteResult(rs);
      },
      onError: (err: any) => {
        setDeleteResult(JSON.parse(JSON.stringify(err)));
      },
    }
  );

  const { mutate: getEventQuestionAnswerById } = useMutation(
    getEventQuestionAnswerByIdAPI,
    {
      onSuccess: async (rs: IEventQuestionTbl) => {
        setEventQuestionAnswer(rs);
      },
      onError: (err: any) => {
        setEventQuestionAnswer(JSON.parse(JSON.stringify(err)));
      },
    }
  );

  const handleChageTextInputForCreate = (event: any) => {
    setTextInputForCreate(event.target.value);
  };

  const handleChangeTextInputForGetAll = (event: any) => {
    setTextInputForGetAll(event.target.value);
  };

  const handleChangeEventQuestionId = (event: any) => {
    setEventQuestionId(event.target.value);
  };

  const handleChangeUpdateEventQuestionId = (event: any) => {
    setUpdateEventQuestionId(event.target.value);
  };

  const handleChangeTextInputForUpdate = (event: any) => {
    setTextInputForUpdate(event.target.value);
  };

  const handleChangeDeleteEventQuestionId = (event: any) => {
    setDeleteEventQuestionId(event.target.value);
  };

  const handleChangeEventQuestionAnswerId = (event: any) => {
    setEventQuestionAnswerId(event.target.value);
  };

  const createEventQuestionsClick = () => {
    createEventQuestions(JSON.parse(textInputForCreate));
  };

  const getAllEventQuestionsAPIClick = () => {
    getAllEventQuestions(JSON.parse(textInputForGetAll));
  };

  const getEventByIdClick = () => {
    getEventQuestionById(eventQuestionId);
  };

  const updateEventQuestionByIdClick = () => {
    updateEventQuestionById({
      id: updateEventQuestionId,
      updatedEventQuestion: JSON.parse(textInputForUpdate),
    });
  };

  const deleteEventQuestionByIdClick = () => {
    deleteEventQuestionById(deleteEventQuestionId);
  };

  const getEventQuestionAnswerByIdClick = () => {
    getEventQuestionAnswerById(eventQuestionAnswerId);
  };

  return (
    <>
      <Row style={{ marginTop: "10px" }}>
        <h1>
          <b>Event questions</b>
        </h1>
        <Checkbox
          ref={checkBox}
          onChange={(e: CheckboxChangeEvent) => {
            setIsShow(e.target.checked);
          }}
          style={{
            marginLeft: "10px",
          }}
          checked={isShow}
        />
      </Row>
      {isShow && (
        <Collapse accordion>
          <Panel header="/event-questions" key="1" extra={METHOD_API.POST}>
            <Button
              type="primary"
              onClick={createEventQuestionsClick}
              style={{
                marginBottom: "10px",
              }}
            >
              Try it out
            </Button>
            <h1>Request body</h1>
            <TextArea
              style={{
                marginBottom: "10px",
              }}
              value={textInputForCreate}
              defaultValue={textInputForCreate}
              onChange={handleChageTextInputForCreate}
              placeholder=""
              rows={10}
            />
            <Fragment>
              <h1>Result</h1>
              <CollapseShowJson ghost>
                <ReactJson src={JSON.parse(JSON.stringify(createdResult))} />
              </CollapseShowJson>
            </Fragment>
          </Panel>
          <Panel header="/event-questions/alls" key="2" extra={METHOD_API.GET}>
            <Button
              type="primary"
              onClick={getAllEventQuestionsAPIClick}
              style={{
                marginBottom: "10px",
              }}
            >
              Try it out
            </Button>
            <h1>Request body</h1>
            <TextArea
              style={{
                marginBottom: "10px",
              }}
              value={textInputForGetAll}
              defaultValue={textInputForGetAll}
              onChange={handleChangeTextInputForGetAll}
              placeholder=""
              rows={10}
            />
            <Fragment>
              <h1>Result</h1>
              <CollapseShowJson ghost>
                <ReactJson src={JSON.parse(JSON.stringify(allEventQuestion))} />
              </CollapseShowJson>
            </Fragment>
          </Panel>
          <Panel header="/event-questions/{id}" key="3" extra={METHOD_API.GET}>
            <Button
              type="primary"
              onClick={getEventByIdClick}
              style={{
                marginBottom: "10px",
              }}
            >
              Try it out
            </Button>
            <h1>Request body</h1>
            <Input
              placeholder="Enter event id"
              allowClear
              onChange={handleChangeEventQuestionId}
              value={eventQuestionId}
              style={{
                marginBottom: "10px",
              }}
            />
            <Fragment>
              <h1>Result</h1>
              <CollapseShowJson ghost>
                <ReactJson src={JSON.parse(JSON.stringify(eventQuestion))} />
              </CollapseShowJson>
            </Fragment>
          </Panel>
          <Panel header="/event-questions/{id}" key="4" extra={METHOD_API.PUT}>
            <Button
              type="primary"
              onClick={updateEventQuestionByIdClick}
              style={{
                marginBottom: "10px",
              }}
            >
              Try it out
            </Button>
            <Input
              placeholder="Enter event id"
              allowClear
              onChange={handleChangeUpdateEventQuestionId}
              value={updateEventQuestionId}
              style={{
                marginBottom: "10px",
              }}
            />
            <h1>Request body</h1>
            <TextArea
              style={{
                marginBottom: "10px",
              }}
              value={textInputForUpdate}
              defaultValue={textInputForUpdate}
              onChange={handleChangeTextInputForUpdate}
              placeholder=""
              rows={10}
            />
            <Fragment>
              <h1>Result</h1>
              <CollapseShowJson ghost>
                <ReactJson src={JSON.parse(JSON.stringify(updateResult))} />
              </CollapseShowJson>
            </Fragment>
          </Panel>
          <Panel
            header="/event-questions/{id}"
            key="5"
            extra={METHOD_API.DELETE}
          >
            <Button
              type="primary"
              onClick={deleteEventQuestionByIdClick}
              style={{
                marginBottom: "10px",
              }}
            >
              Try it out
            </Button>
            <h1>Request body</h1>
            <Input
              placeholder="Enter event id"
              allowClear
              onChange={handleChangeDeleteEventQuestionId}
              value={deleteEventQuestionId}
              style={{
                marginBottom: "10px",
              }}
            />
            <Fragment>
              <h1>Result</h1>
              <CollapseShowJson ghost>
                <ReactJson src={JSON.parse(JSON.stringify(deleteResult))} />
              </CollapseShowJson>
            </Fragment>
          </Panel>
          <Panel
            header="/event-questions/answers/{id}"
            key="6"
            extra={METHOD_API.GET}
          >
            <Button
              type="primary"
              onClick={getEventQuestionAnswerByIdClick}
              style={{
                marginBottom: "10px",
              }}
            >
              Try it out
            </Button>
            <h1>Request body</h1>
            <Input
              placeholder="Enter event id"
              allowClear
              onChange={handleChangeEventQuestionAnswerId}
              value={eventQuestionAnswerId}
              style={{
                marginBottom: "10px",
              }}
            />
            <Fragment>
              <h1>Result</h1>
              <CollapseShowJson ghost>
                <ReactJson
                  src={JSON.parse(JSON.stringify(eventQuestionAnswer))}
                />
              </CollapseShowJson>
            </Fragment>
          </Panel>
        </Collapse>
      )}
    </>
  );
};

export default memo(EventQuestionsAPI);
