import { Input, Radio } from "antd";
import { ButtonGradient } from "components/base";
import styled from "styled-components";

export const ModalTitle = styled.p`
  font-size: 24px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: bold;
  line-height: 1.5715;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const WarningIcon = styled.div`
  svg {
    color: orange;
    font-size: 20px;
  }
`;

export const ListOrganizations = styled.div`
  font-weight: 600;
`;

export const TextDecs = styled.div`
  margin-bottom: 20px;
`;

export const ButtonConnect = styled(ButtonGradient)`
  margin-top: 20px;
  height: 40px;
`;

export const InputLinkGithub = styled(Input)`
  border-radius: 8px;
`;

export const TitleSuggest = styled.p`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
`;

export const FooteChooseOrganization = styled.p`
  display: flex;
  justify-content: space-between;
`;

export const RadioGroup = styled(Radio.Group)`
  margin: 10px 10px;
`;

export const ContainerLoading = styled.div`
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
