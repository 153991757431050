export const ConnectionIcon = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M42.8 14.9996C44.4 16.5996 44.4 19.1996 42.8 20.5996L37.2 26.1996L21.6 10.5996L27.2 4.99961C28.8 3.39961 31.4 3.39961 32.8 4.99961L36.4 8.59961L42.4 2.59961L45.2 5.39961L39.2 11.3996L42.8 14.9996ZM31.2 26.5996L28.4 23.7996L22.8 29.3996L18.6 25.1996L24.2 19.5996L21.4 16.7996L15.8 22.3996L12.8 19.5996L7.19999 25.1996C5.59999 26.7996 5.59999 29.3996 7.19999 30.7996L10.8 34.3996L2.79999 42.3996L5.59999 45.1996L13.6 37.1996L17.2 40.7996C18.8 42.3996 21.4 42.3996 22.8 40.7996L28.4 35.1996L25.6 32.3996L31.2 26.5996Z"
        fill="#7E00FD"
      />
      <rect
        x="15"
        y="3.75781"
        width="6"
        height="6"
        transform="rotate(45 15 3.75781)"
        fill="#292E73"
        fillOpacity="0.42"
      />
      <rect
        x="32.2427"
        y="37"
        width="6"
        height="6"
        rx="3"
        transform="rotate(45 32.2427 37)"
        fill="#292E73"
        fillOpacity="0.42"
      />
    </svg>
  );
};

