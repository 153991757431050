import { Badge } from "antd";
import Logo from "assets/users/svg/bg_header_projects.svg";
import { ButtonGradient } from "components/base";
import styled from "styled-components";

export const BackgroundContainer = styled.div`
  background-image: url(${Logo});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 120px;
  position: relative;
  font-family: "Rubik";
  font-style: normal;
  margin-bottom: 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: 1s;
    background-image: linear-gradient(transparent, #fff);
  }

  @media (max-width: 992px) {
    height: auto;
  }
`;

export const BalanceButton = styled(ButtonGradient)`
  height: 38px;
  width: auto;
`;

export const StatusBadge = styled(Badge)`
  > sup {
    background: #7e00fd;
  }
`;

export const TechnologyBadge = styled(Badge)`
  > sup {
    background: rgba(8, 10, 37, 0.08);
    border: 1px solid rgba(8, 10, 37, 0.16);
    border-radius: 20px;
    color: #080a25;
  }
`;

export const Unit = styled.span`
  font-weight: 600;
  color: #7e00fd;
`;

export const Container = styled.div`
  margin: 0px 136px 0px 136px;
  padding: 16px 0;
`;

export const Heading = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
  p {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 100%;
    color: #080a25;
  }
  span {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 110%;
    color: rgba(8, 10, 37, 0.6);
  }
`;

export const SpaceLeft = styled.div`
  display: grid;
  gap: 8px;
  h1 {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #080a25;
    margin-bottom: unset;
  }
  span {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: rgba(8, 10, 37, 0.6);
  }
`;

export const StatusBar = styled.div<{ percent: number }>`
  width: 389px;
  height: 8px;
  background: linear-gradient(0deg, rgba(8, 10, 37, 0.1), rgba(8, 10, 37, 0.1)),
    #ffffff;
  border-radius: 20px;
  position: relative;
  ::after {
    position: absolute;
    width: ${({ percent }) => `${percent ?? 0}%`};
    height: inherit;
    background: linear-gradient(
      90deg,
      #c58aff 0%,
      #00adfb 50.61%,
      #00c1ce 78.78%,
      #00de8c 100%
    );
    border-radius: inherit;
    content: "";
  }
`;
