import { FC, memo } from "react";
import { IFooterProps } from "./footer.props";
import { FooterAd } from "./footer.style";

const FooterAdmin: FC<IFooterProps> = (props: IFooterProps) => {
  return (
    <FooterAd style={{ textAlign: "center" }}>
      Nikos SoulBound ©{new Date().getFullYear()}
    </FooterAd>
  );
};

export default memo(FooterAdmin);
