import { IBountyTbl } from "types";

export const BOUNTY_STEP = {
  BOUNTY_TYPE: 1,
  BOUNTY_DETAILS: 2,
  PAYMENT_INFORMATION: 3,
  ADDITIONAL_INFORMATION: 4,
  REVIEW_BOUNTY: 5,
  COMPLETED: 6,
};

export enum BountyStatus {
  DRAFT,
  PENDING,
  PROCESSING,
  OPEN,
  STARTED,
  SUBMITTED,
  FINISHED,
  EXPIRED,
  CANCELED,
  CLOSING,
  PENDING_CLOSE,
}

export interface IBountyTypePageProps {}

export interface ICreateBountyRes extends IBountyTbl {}

