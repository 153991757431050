import { Button } from "antd";
import styled from "styled-components";

export const ButtonWhiteAntd = styled(Button)`
  background: #ffffff;
  box-shadow: 0px 8px 16px rgba(17, 17, 17, 0.06);
  border-radius: 8px;
  color: #080a25;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  width: auto;
  height: 44px;
  &:hover,
  &:focus {
    color: #35374c;
  }
`;
