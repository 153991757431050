import axios from "api/axios";
import { IUserPortfolioTbl } from "types";
import {
  ICreatePortfolioReq,
  ICreatePortfolioRes,
  IGetPortfolioByIdReq,
  IUpdatePortfolioReq,
  IUpdatePortfolioRes,
} from "./types";

const END_POINT = {
  GET_PORTFOLIO_ADD: "/users/profile/portfolio/add",
  POST_PORTFOLIO_ADD: "/users/profile/portfolio/add",
  GET_PORTFOLIO_BY_ID: "/users/profile/portfolio/:id",
  UPDATE_PORTFOLIO_BY_ID: "users/profile/portfolio/:id",
};

export const getPortfolioAdd = async () => {
  const { data } = await axios({
    url: `${END_POINT.GET_PORTFOLIO_ADD}`,
    method: "GET",
  });
  return data;
};

export const createPortfolio = async (
  params: ICreatePortfolioReq
): Promise<ICreatePortfolioRes> => {
  const { data } = await axios({
    url: END_POINT.POST_PORTFOLIO_ADD,
    method: "POST",
    data: params,
  });
  return data;
};

export const getPortfolioById = async (
  params: IGetPortfolioByIdReq
): Promise<IUserPortfolioTbl> => {
  if (!params.id) {
    Promise.resolve(null);
  }
  const { data } = await axios({
    url: END_POINT.GET_PORTFOLIO_BY_ID.replace(":id", `${params.id}`),
    method: "GET",
  });
  return data;
};

export const updatePortfolioById = async (
  request: IUpdatePortfolioReq
): Promise<IUpdatePortfolioRes> => {
  const { data } = await axios({
    url: `${END_POINT.UPDATE_PORTFOLIO_BY_ID}`.replace(":id", `${request.id}`),
    method: "PUT",
    data: request.body,
  });
  return data;
};
