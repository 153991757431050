import {
  FacebookOutlined,
  GithubOutlined,
  InstagramOutlined,
  SkypeOutlined,
  TwitterOutlined,
  WindowsOutlined,
} from "@ant-design/icons";
import { Menu, MenuProps } from "antd";
import { ProjectStatusEnum } from "api/admin/projects";
import { DragIcon } from "assets/common/svg/Drag.icon";
import AboutIcon from "assets/users/menu/About.png";
import ActivityIcon from "assets/users/menu/Activity.png";
import BountiesIcon from "assets/users/menu/Bounties.png";
import CommunityIcon from "assets/users/menu/Community.png";
import ConductIcon from "assets/users/menu/Conduct.png";
import EventsIcon from "assets/users/menu/Events.png";
import GitHub_IssuesIcon from "assets/users/menu/GitHub_Issues.png";
import GovernanceIcon from "assets/users/menu/Governance.png";
import ResourcesIcon from "assets/users/menu/Resources.png";
import SponsorshipsIcon from "assets/users/menu/Sponsorships.png";
import TokenEconomyIcon from "assets/users/menu/TokenEconomy.png";
import VotingRightsIcon from "assets/users/menu/VotingRights.png";
import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { generatePath, useLocation, useNavigate } from "react-router-dom";
import { RootState } from "redux/store";
import { ROUTER_CONSTANTS } from "utils/constant";
import { NOTIFICATION } from "utils/message";
import { ISlidbarProjectsProps } from "./sidebarProjects.props";
import {
  AdvertMenu,
  ContainerContentProject,
  Copywriter,
  DragIconBlock,
  MenuText,
  QuickPages,
  QuickPagesItem,
  Social,
  SocialItem,
} from "./sidebarProjects.style";

type MenuItem = Required<MenuProps>["items"][number];

const SidebarProjects: FC<ISlidbarProjectsProps> = (
  props: ISlidbarProjectsProps
) => {
  const location = useLocation();
  let navigate = useNavigate();
  const [keyMenu] = useState<string>(location?.pathname?.split("/")[2]);
  // Get id project
  const [idProject] = useState<string>(location?.pathname?.split("/")[3]);
  const [isShowFinance, setIsShowFinance] = useState<boolean>(true);
  const [isShowSponsorship, setIsShowSponsorship] = useState<boolean>(true);

  const {
    reward: rewardInfo,
    finance: financeInfo,
    sponsorship: sponsorshipInfo,
    advert: advertInfo,
    about: projectInfo,
  } = useSelector((state: RootState) => state.projectsUser);

  const isPublishProject = projectInfo.status === ProjectStatusEnum.ACTIVE;

  useEffect(() => {
    if (projectInfo.status === undefined) {
      return;
    } else if (
      projectInfo.status !== ProjectStatusEnum.ACTIVE &&
      !projectInfo.is_owner &&
      !projectInfo.is_admin
    ) {
      navigate(
        generatePath(ROUTER_CONSTANTS.ERROR_CODE, {
          code: "404",
          message: NOTIFICATION.PAGE_NOT_FOUND,
        })
      );
    }
  }, [isPublishProject, projectInfo, navigate]);

  useEffect(() => {
    setIsShowFinance(projectInfo.is_owner || financeInfo.is_active);
    setIsShowSponsorship(financeInfo.is_active && sponsorshipInfo.is_active);
  }, [
    projectInfo,
    financeInfo,
    sponsorshipInfo,
    setIsShowSponsorship,
    setIsShowFinance,
  ]);

  function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    disabled?: boolean
  ): MenuItem {
    return {
      key,
      icon,
      label,
      disabled,
    } as MenuItem;
  }

  const items: MenuProps["items"] = [
    getItem(
      <MenuText>About</MenuText>,
      "projects-menu-about",
      <img src={AboutIcon} alt="menu-icon" />
    ),
    getItem(
      <MenuText>Rewards</MenuText>,
      "projects-menu-rewards",
      <DragIconBlock>
        <DragIcon />
      </DragIconBlock>,
      !rewardInfo.is_active
    ),
    getItem(
      <MenuText>Community</MenuText>,
      "projects-menu-community",
      <img src={CommunityIcon} alt="menu-icon" />
    ),
    getItem(
      <MenuText>Activity</MenuText>,
      "projects-menu-activity",
      <img src={ActivityIcon} alt="menu-icon" />
    ),
    getItem(
      <MenuText>Resources</MenuText>,
      "projects-menu-resources",
      <img src={ResourcesIcon} alt="menu-icon" />
    ),
    getItem(
      <MenuText>Events</MenuText>,
      "projects-menu-events",
      <img src={EventsIcon} alt="menu-icon" />
    ),
    getItem(
      <MenuText>GitHub Issues</MenuText>,
      "projects-menu-github-issues",
      <img src={GitHub_IssuesIcon} alt="menu-icon" />
    ),
    getItem(
      <MenuText>Bounties</MenuText>,
      "projects-menu-bounties",
      <img src={BountiesIcon} alt="menu-icon" />,
      !isPublishProject
    ),
    getItem(
      <MenuText>Sponsorships</MenuText>,
      "projects-menu-sponsorships",
      <img src={SponsorshipsIcon} alt="menu-icon" />,
      !isPublishProject || !isShowSponsorship
    ),
    getItem(
      <MenuText>Governance</MenuText>,
      "projects-menu-governance",
      <img src={GovernanceIcon} alt="menu-icon" />
    ),
    getItem(
      <MenuText>Finance</MenuText>,
      "projects-menu-finance",
      <img src={VotingRightsIcon} alt="menu-icon" />,
      !isShowFinance || !isPublishProject
    ),
    // Don't have any ui
    getItem(
      <MenuText>Code of Conduct</MenuText>,
      "projects-menu-conduct",
      <img src={ConductIcon} alt="menu-icon" />,
      true
    ),
    getItem(
      <MenuText>Token Economy</MenuText>,
      "projects-menu-token-economy",
      <img src={TokenEconomyIcon} alt="menu-icon" />,
      true
    ),
    getItem(
      <MenuText>Voting Rights</MenuText>,
      "projects-menu-voting-rights",
      <img src={VotingRightsIcon} alt="menu-icon" />,
      true
    ),

    getItem(
      <QuickPages>
        <QuickPagesItem to={ROUTER_CONSTANTS.HOME}>About</QuickPagesItem>
        <QuickPagesItem to={ROUTER_CONSTANTS.HOME}>Credits</QuickPagesItem>
        <QuickPagesItem to={ROUTER_CONSTANTS.HOME}>Tems</QuickPagesItem>
        <QuickPagesItem to={ROUTER_CONSTANTS.HOME}>Privacy</QuickPagesItem>
      </QuickPages>,
      "projects-quick-pages"
    ),
    getItem(
      <>
        <Social>
          <SocialItem to={ROUTER_CONSTANTS.HOME}>
            <TwitterOutlined />
          </SocialItem>
          <SocialItem to={ROUTER_CONSTANTS.HOME}>
            <GithubOutlined />
          </SocialItem>
          <SocialItem to={ROUTER_CONSTANTS.HOME}>
            <InstagramOutlined />
          </SocialItem>
          <SocialItem to={ROUTER_CONSTANTS.HOME}>
            <SkypeOutlined />
          </SocialItem>
          <SocialItem to={ROUTER_CONSTANTS.HOME}>
            <FacebookOutlined />
          </SocialItem>
          <SocialItem to={ROUTER_CONSTANTS.HOME}>
            <WindowsOutlined />
          </SocialItem>
        </Social>
      </>,
      "projects-social"
    ),
    getItem(
      advertInfo?.left_column_image ? (
        <AdvertMenu src={advertInfo?.left_column_image} />
      ) : (
        <Copywriter>Space for Ad</Copywriter>
      ),
      "projects-ad"
    ),
  ];

  return (
    <ContainerContentProject {...props}>
      <Menu
        onClick={(e) => {
          let path = e?.keyPath[0];
          if (path.indexOf("projects-menu-") >= 0 && idProject) {
            path = path.replace("projects-menu-", "");
            navigate(`/projects/${path}/${idProject}`);
          }
        }}
        style={{ width: 293 }}
        defaultSelectedKeys={["projects-menu-" + keyMenu]}
        mode="inline"
        items={items}
      />
    </ContainerContentProject>
  );
};

export default SidebarProjects;
