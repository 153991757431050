import { Checkbox, Collapse, Row } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { FC, memo, useRef, useState } from "react";
import { METHOD_API } from "../apiTest";
const { Panel } = Collapse;

const AnswersAPI: FC<any> = () => {
  const [isShow, setIsShow] = useState<boolean>(true);

  const checkBox = useRef<any>(null);

  return (
    <>
      <Row style={{ marginTop: "10px" }}>
        <h1>
          <b>Answers</b>
        </h1>
        <Checkbox
          ref={checkBox}
          onChange={(e: CheckboxChangeEvent) => {
            setIsShow(e.target.checked);
          }}
          style={{
            marginLeft: "10px",
          }}
          checked={isShow}
        />
      </Row>
      {isShow && (
        <Collapse accordion>
          <Panel header="/answers" key="1" extra={METHOD_API.POST}>
            1
          </Panel>
          <Panel header="/answers" key="2" extra={METHOD_API.GET}>
            2
          </Panel>
          <Panel header="/answers/{id}" key="3" extra={METHOD_API.GET}>
            3
          </Panel>
          <Panel header="/answers/{id}" key="4" extra={METHOD_API.PUT}>
            4
          </Panel>
          <Panel header="/answers/{id}" key="5" extra={METHOD_API.DELETE}>
            5
          </Panel>
          <Panel header="/answers/questions/{id}" key="6" extra={METHOD_API.GET}>
            6
          </Panel>
        </Collapse>
      )}
    </>
  );
};

export default memo(AnswersAPI);
