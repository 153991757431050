export const DragIcon = (props: { style?: React.CSSProperties }) => (
  <svg
    style={props.style}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill=""
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1336_2888)">
      <path
        d="M3.33398 6L1.33398 8L3.33398 10"
        stroke=""
        strokeOpacity="0.6"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 3.33337L8 1.33337L10 3.33337"
        stroke=""
        strokeOpacity="0.6"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 12.6666L8 14.6666L6 12.6666"
        stroke=""
        strokeOpacity="0.6"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.666 6L14.666 8L12.666 10"
        stroke=""
        strokeOpacity="0.6"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.33398 8H14.6673"
        stroke=""
        strokeOpacity="0.6"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 1.33337V14.6667"
        stroke=""
        strokeOpacity="0.6"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1336_2888">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
