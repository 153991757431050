import { ArrowRightOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { Col, Form, Row } from "antd";
import { IFile } from "api/users/portfolio";
import { UploadIcon } from "assets/common/svg/Upload.icon";
import { CameraIcon } from "assets/users/projects/add_project/Camera.icon";
import UploadImage from "components/module/uploadImage";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { requiredRules, stringRequired, urlRules } from "utils/validatorRules";
import { ISkill } from "../addProjectPage.props";
import { NextButton, OutlinedButton } from "../addProjectPage.style";
import TitleStep from "../titleStep";
import { IAddProjectStepProps } from "./addProjectStep.props";
import {
  AddVideoLinkButton,
  AntdTextArea,
  BaseFormItem,
  Container,
  ContentBlock,
  FormTextInput,
  Note,
  SubInputBlock,
  VideoUrlInputBlock,
  WrapBlock,
} from "./addProjectStep.style";
import ProjectFile from "./projectFile";
import SkillTagSystem from "./skillTagSystem";

export const getBase64 = async (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
};

const AddProjectStep: FC<IAddProjectStepProps> = ({
  projectFormData,
  onFinishStep,
  suggestedSkills,
  setSuggestedSkills,
}: IAddProjectStepProps) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [thumbnailFile, setThumbnailFile] = useState<File>();
  const [thumbnailPreview, setThumbnailPreview] = useState(
    projectFormData.thumbnail_url || ""
  );
  const [thumbnailUrl, setThumbnailUrl] = useState("");

  const [coverImgFile, setCoverImgFile] = useState<File>();
  const [coverImgPreview, setCoverImgPreview] = useState(
    projectFormData.cover_img_url || ""
  );
  const [coverImgUrl, setCoverImgUrl] = useState("");

  const [projectFiles, setProjectFiles] = useState<IFile[]>([]);

  const [tags, setTags] = useState<ISkill[]>([]);

  useEffect(() => {
    const skills = (projectFormData.skills || []).map((item) => ({
      value: item,
      isSelected: true,
    }));

    form.setFieldsValue({
      ...projectFormData,
      title: projectFormData.title,
      thumbnail: projectFormData.thumbnail_url,
      cover_img: projectFormData.cover_img_url,
      description: projectFormData.description,
      skills,
      website: projectFormData.website,
      embedUrls: projectFormData.embedded_video_urls,
      files: projectFormData.files,
    });

    setThumbnailPreview(projectFormData.thumbnail_url || "");
    setCoverImgPreview(projectFormData.cover_img_url || "");
    setProjectFiles(projectFormData.files || []);
    setTags(skills);

    // eslint-disable-next-line
  }, [projectFormData, form]);

  const onFinish = (values: any) => {
    onFinishStep({
      ...projectFormData,
      title: values.title,
      thumbnail_url: thumbnailUrl || projectFormData.thumbnail_url,
      cover_img_url: coverImgUrl || projectFormData.cover_img_url,
      description: values.description,
      skills: tags.map((tag) => tag.value),
      website: values.website,
      embedded_video_urls: values.embedUrls,
      files: projectFiles,
    });
  };

  const onChangeFiles = (files: IFile[]) => {
    setProjectFiles(files);
    form.setFieldsValue({ files });
  };

  useEffect(() => {
    const skillTags = tags.map((tag) => tag.value);
    const suggestedSelectionSkills = suggestedSkills.map((skill) => {
      if (skillTags.includes(skill.value)) {
        skill.isSelected = true;
      }
      return skill;
    });
    setSuggestedSkills(suggestedSelectionSkills);
    // eslint-disable-next-line
  }, [tags]);

  return (
    <Container form={form} onFinish={onFinish}>
      <ContentBlock>
        <TitleStep step={1} />
        <WrapBlock>
          <Row gutter={[24, 0]}>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <BaseFormItem
                label="Project Title"
                name="title"
                labelAlign="left"
                rules={[stringRequired]}
                initialValue={projectFormData.title || ""}
              >
                <FormTextInput placeholder="Enter a brief but descriptive title" />
              </BaseFormItem>
            </Col>
          </Row>
          <Row gutter={[24, 0]}>
            <Col xs={12} sm={24} md={12} lg={12} xl={12}>
              <BaseFormItem
                label="Upload Thumbnail"
                name="thumbnail"
                labelAlign="left"
                rules={[requiredRules]}
              >
                <UploadImage
                  title={
                    <p>
                      <strong>Click to upload</strong> or Drag & Drop
                    </p>
                  }
                  imageFile={thumbnailFile}
                  setImageFile={setThumbnailFile}
                  imagePreview={thumbnailPreview}
                  setImagePreview={setThumbnailPreview}
                  setUploadUrl={setThumbnailUrl}
                  icon={<UploadIcon />}
                />
              </BaseFormItem>
            </Col>
            <Col xs={12} sm={24} md={12} lg={12} xl={12}>
              <BaseFormItem
                label="Upload a Cover"
                name="cover_img"
                labelAlign="left"
                rules={[requiredRules]}
              >
                <UploadImage
                  title={
                    <p>
                      <strong>Click to upload</strong> or Drag & Drop
                    </p>
                  }
                  imageFile={coverImgFile}
                  setImageFile={setCoverImgFile}
                  imagePreview={coverImgPreview}
                  setImagePreview={setCoverImgPreview}
                  setUploadUrl={setCoverImgUrl}
                  icon={<UploadIcon />}
                />
              </BaseFormItem>
            </Col>
          </Row>
          <Row gutter={[24, 0]}>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <BaseFormItem
                label="Project Files"
                name="files"
                labelAlign="left"
                rules={[requiredRules]}
              >
                <ProjectFile
                  onChangeFiles={onChangeFiles}
                  files={projectFiles}
                />
              </BaseFormItem>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <BaseFormItem
                label="Or, embed a video"
                name="videoUrl"
                labelAlign="left"
              >
                <Form.List
                  name="embedUrls"
                  initialValue={projectFormData.embedded_video_urls}
                >
                  {(fields, { add, remove }, { errors }) => (
                    <>
                      <Form.Item>
                        <AddVideoLinkButton
                          onClick={() => {
                            add(
                              "",
                              (projectFormData.embedded_video_urls || []).length
                            );
                          }}
                        >
                          <CameraIcon />
                          <span>Add Video Link</span>
                        </AddVideoLinkButton>
                        <Form.ErrorList errors={errors} />
                      </Form.Item>
                      {fields.map((field) => (
                        <BaseFormItem required={false} {...field}>
                          <VideoUrlInputBlock>
                            <BaseFormItem
                              {...field}
                              validateTrigger={["onChange", "onBlur"]}
                              noStyle
                              rules={[urlRules, requiredRules]}
                              initialValue={field.name}
                            >
                              <FormTextInput placeholder="Add video link" />
                            </BaseFormItem>

                            <SubInputBlock>
                              <MinusCircleOutlined
                                onClick={() => remove(field.name)}
                              />
                            </SubInputBlock>
                          </VideoUrlInputBlock>
                        </BaseFormItem>
                      ))}
                    </>
                  )}
                </Form.List>
              </BaseFormItem>
            </Col>
          </Row>

          <Row gutter={[24, 0]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <BaseFormItem
                label="Short Description"
                name="description"
                labelAlign="left"
                rules={[stringRequired]}
                initialValue={projectFormData.description}
              >
                <AntdTextArea rows={4} placeholder="Write description" />
              </BaseFormItem>
            </Col>
          </Row>

          <Row gutter={[24, 0]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <BaseFormItem
                label={<div>Skills and deliverables</div>}
                name="skills"
                labelAlign="left"
              >
                <SkillTagSystem
                  tags={tags}
                  setTags={setTags}
                  suggestedSkills={suggestedSkills}
                  setSuggestedSkills={setSuggestedSkills}
                />
              </BaseFormItem>
            </Col>
          </Row>

          <Row gutter={[24, 0]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <BaseFormItem
                label={<div>Project URL</div>}
                name="website"
                labelAlign="left"
                initialValue={projectFormData.website}
                rules={[
                  { type: "url", message: "This field must be a valid url." },
                ]}
              >
                <FormTextInput placeholder="Project URL" />
              </BaseFormItem>
              <Note>
                Make sure you have approval from your clients to display the
                work you’ve done for them publicly.
              </Note>
            </Col>
          </Row>

          <Row gutter={[24, 0]} justify="space-between">
            <Col></Col>
            <Col>
              <Row gutter={[16, 0]}>
                <Col>
                  <OutlinedButton onClick={() => navigate(-1)}>
                    Cancel
                  </OutlinedButton>
                </Col>
                <Col>
                  <NextButton
                    icon={<ArrowRightOutlined />}
                    onClick={() => form.submit()}
                  >
                    Next
                  </NextButton>
                </Col>
              </Row>
            </Col>
          </Row>
        </WrapBlock>
      </ContentBlock>
    </Container>
  );
};

export default AddProjectStep;
