export const CameraIcon = (props: { style?: React.CSSProperties }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_5455_3956)">
      <path
        d="M15.3332 4.66699L10.6665 8.00033L15.3332 11.3337V4.66699Z"
        fill="#7E00FD"
        stroke="#7E00FD"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.33317 3.33301H1.99984C1.26346 3.33301 0.666504 3.92996 0.666504 4.66634V11.333C0.666504 12.0694 1.26346 12.6663 1.99984 12.6663H9.33317C10.0696 12.6663 10.6665 12.0694 10.6665 11.333V4.66634C10.6665 3.92996 10.0696 3.33301 9.33317 3.33301Z"
        fill="#7E00FD"
        stroke="#7E00FD"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_5455_3956">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
