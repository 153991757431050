import { comment as commentIconSvg } from "assets/common/svg/Comment.icon";
import { FC, memo } from "react";
import { IButtonCommentProps } from "./buttonComment.props";
import { ButtonCommentAntd, ButtonIconAntd } from "./buttonComment.style";

const ButtonComment: FC<IButtonCommentProps> = ({
  isCommented = false,
  commentQuantity,
  children,
  ...props
}: IButtonCommentProps) => {
  return (
    <ButtonCommentAntd {...props} $is_done={isCommented}>
      <ButtonIconAntd component={commentIconSvg}></ButtonIconAntd>
      Comment
      {commentQuantity ? `(${commentQuantity})` : ``}
    </ButtonCommentAntd>
  );
};

export default memo(ButtonComment);
