import { FC, memo } from "react";
import { IButtonConnectInfoProps } from "./buttonConnectInfo.props";
import { ButtonConnectInfoCustom } from "./buttonConnectInfo.style";

const ButtonConnectInfo: FC<IButtonConnectInfoProps> = ({
  children,
  ...props
}: IButtonConnectInfoProps) => {
  return (
    <ButtonConnectInfoCustom {...props}>{children}</ButtonConnectInfoCustom>
  );
};

export default memo(ButtonConnectInfo);
