import axios from "api/axios";
import {
  IProjectsAdParams,
  IProjectsAdRes,
  IRejectProjectParams,
  IUpdateStatusProjectParams,
} from "./types";

const END_POINT = {
  PROJECTS: "/admin/projects/alls",
  UPDATE_STATUS: "/admin/projects/update/:id",
  REJECT_PROJECTS: "/admin/projects/reject/:id",
};

export const getProjectsAPI = async (
  params: IProjectsAdParams
): Promise<IProjectsAdRes> => {
  const { data } = await axios({
    url: END_POINT.PROJECTS,
    method: "POST",
    data: {
      limit: params.limit,
      page: params.page,
      search: params.search ?? "",
      status: params.status ?? [],
    },
  });
  return data;
};

export const updateStatusProjectAPI = async (
  params: IUpdateStatusProjectParams
): Promise<any> => {
  const { data } = await axios({
    url: `${END_POINT.UPDATE_STATUS.replace(":id", params.id.toString())}`,
    method: "PUT",
    data: {
      status: params.status,
    },
  });
  return data;
};

export const rejectProjectAPI = async (
  params: IRejectProjectParams
): Promise<any> => {
  const { data } = await axios({
    url: `${END_POINT.REJECT_PROJECTS.replace(":id", params.id.toString())}`,
    method: "PUT",
    data: {
      reject_reason: params.reject_reason,
    },
  });
  return data;
};
