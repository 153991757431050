import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { IPaymentMethodTbl } from "types";
import { optionUseQuery } from "utils/optionUseQuery";
import {
  getBountyByIdAPI,
  getPaymentsAPI,
  postDeliverableDetailAPI,
} from "./request";
import {
  IDeliverableDetailReq,
  IDeliverableDetailRes,
  IFindBountyReq,
  IFindBountyRes,
} from "./types";

export const useGetBountyById = (
  params: IFindBountyReq,
  option?: UseQueryOptions<IFindBountyRes, Error>
) => {
  return useQuery<IFindBountyRes, Error>(
    ["FindBounty", params],
    () => getBountyByIdAPI(params),
    {
      ...optionUseQuery,
      ...option,
    }
  );
};

export const useGetDeliverableDetailById = (
  params: IDeliverableDetailReq,
  option?: UseQueryOptions<IDeliverableDetailRes, Error>
) => {
  return useQuery<IDeliverableDetailRes, Error>(
    ["GetDeliverablesDetailByID", params],
    () => postDeliverableDetailAPI(params),
    {
      ...optionUseQuery,
      ...option,
    }
  );
};

export const useGetPayments = (
  option?: UseQueryOptions<undefined, AxiosError<{ message: string }>>
) => {
  return useQuery<IPaymentMethodTbl[], AxiosError<{ message: string }>>(
    ["GetPayments"],
    () => getPaymentsAPI(),
    {
      ...optionUseQuery,
      ...option,
    }
  );
};

