import Cookies from "js-cookie";
import { KEY_COOKIE } from "./constant";

export const saveLocalStorage = (name: string, payload: any) => {
  localStorage.setItem(name, payload);
};

export const getLocalStorage = (key: string) => {
  if (typeof window !== "undefined") {
    try {
      JSON.parse(localStorage.getItem(key) || "null");
      return JSON.parse(localStorage.getItem(key) || "null");
    } catch (error) {
      return localStorage.getItem(key);
    }
  }
};

export const removeLocalStorage = (name: string) => {
  localStorage.removeItem(name);
};

export const deleteItem = (key: string | Array<string>) => {
  if (typeof window !== "undefined") {
    if (Array.isArray(key)) {
      key.forEach((item) => localStorage.removeItem(item));
    } else {
      localStorage.removeItem(key);
    }
  }
};

export const clearStorageAndGlobalState = (isAdmin: boolean = false) => {
  if (isAdmin)
    Cookies.remove(KEY_COOKIE.ACCESS_TOKEN_ADMIN, {
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
    });
  else
    Cookies.remove(KEY_COOKIE.ACCESS_TOKEN, {
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
    });

  const isEmptyCookies =
    !Cookies.get(KEY_COOKIE.ACCESS_TOKEN_ADMIN) &&
    !Cookies.get(KEY_COOKIE.ACCESS_TOKEN);

  if (isEmptyCookies) {
    removeLocalStorage(KEY_COOKIE.DATA_SETUP);
    removeLocalStorage(KEY_COOKIE.DATA_STEP);
    removeLocalStorage(KEY_COOKIE.BOUNTY);
    removeLocalStorage(KEY_COOKIE.BOUNTY_STEP);
    removeLocalStorage(KEY_COOKIE.LOGIN_INFO);
    removeLocalStorage(KEY_COOKIE.PROFILE);
  }
};
