import { Button, Col, Input, Row } from "antd";
import styled from "styled-components";

export const ContactContainer = styled(Row)``;

export const LogoContainer = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    height: 80px;
  }
`;

export const EmailContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 16px;

  img {
    width: 18px;
    height: 18px;
  }

  a {
    font: normal 400 14px/150% "DM Sans";
    color: #080a25;
  }
`;

export const LinksContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
  margin-top: 32px;

  img {
    width: 24px;
    height: 24px;

    &:hover {
      opacity: 0.8;
    }
  }
`;

export const RightReserved = styled.p`
  font: normal 400 14px/150% "DM Sans";
  color: #080a25;
  margin-bottom: 0;
  opacity: 0.4;
  margin-top: 64px;
`;

export const PageListContainer = styled(Col)``;

export const PageList = styled.ul`
  list-style-type: none;
`;

export const PageListLinks = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 16px;
`;

export const PageListItem = styled.li`
  font: normal 400 14px/150% "DM Sans";
  color: #080a25;
  opacity: 0.7;
  margin-bottom: 0;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`;

export const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 12px;
  margin-top: 44px;
`;

export const SearchEmail = styled(Input)`
  background: transparent;
  height: 48px;
  border: 1px solid #11131515;
  color: #080a2540;
  font: normal 400 16px/156% "DM Sans";
  border-radius: 8px;
  letter-spacing: -0.02em;
  padding: 10px 16px;
`;

export const SubButton = styled(Button)`
  font: normal 700 16px/156% "DM Sans";
  line-height: 0;
  align-self: flex-start;
  border-radius: 8px;
  padding: 12px 16px 12px 16px;
  cursor: pointer;
  width: 100%;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  color: #080a25;
  border-color: transparent;
  border-width: 2px;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    ),
    linear-gradient(90deg, #f47820, #b007ff, #423efd, #33cbec, #0fcb87);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px white inset;

  &:hover,
  &:active,
  &.ant-btn:hover,
  &.ant-btn:focus,
  &.ant-btn:active {
    border-color: transparent;
    color: #080a25;
    background-image: linear-gradient(
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0)
      ),
      linear-gradient(90deg, #f47820, #b007ff, #423efd, #33cbec, #0fcb87);
    box-shadow: 2px 1000px 1px white inset;
    background-origin: border-box;
    background-clip: content-box, border-box;
  }

  &:disabled,
  &:disabled:hover,
  &:disabled:active,
  &:disabled:focus,
  &.ant-btn:disabled,
  &.ant-btn:disabled:hover,
  &.ant-btn:disabled:focus,
  &.ant-btn:disabled:active {
    background-color: rgba(156, 138, 193, 0);
    border-color: transparent;
    color: white;
  }

  img {
    order: 2;
  }

  span {
    order: 1;
  }
`;

export const TopLabel = styled.label`
  font: normal 500 16px/150% "DM Sans";
  color: #080a25;
  margin-bottom: 0;
`;

export const SubscribeContainer = styled.div`
  padding-left: 40px;
`;

export const SubscribeDescription = styled.p`
  font: normal 400 14px/150% "DM Sans";
  color: #080a25;
  margin-bottom: 0;
  opacity: 0.7;
  margin-top: 16px;
`;

export const PrivacyPolicy = styled.p`
  font: normal 400 11px/150% "DM Sans";
  color: #080a25cc;
  margin-bottom: 0;
  opacity: 0.7;
`;
