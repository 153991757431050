export const comment = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill=""
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 4a1 1 0 00-1 1v13.586l2.293-2.293A1 1 0 017 16h12a1 1 0 001-1V5a1 1 0 00-1-1H5zM2.879 2.879A3 3 0 015 2h14a3 3 0 013 3v10a3 3 0 01-3 3H7.414l-3.707 3.707A1 1 0 012 21V5a3 3 0 01.879-2.121z"
      ></path>
    </svg>
  );
};
