import { StarFilled, UserOutlined } from "@ant-design/icons";
import { Card, Row, Select, Table, Tag, Typography } from "antd";
import { SkeletonCustom } from "components/base";
import { ResultsNotFound } from "components/module";
import styled from "styled-components";

export const IssueExplorerContainer = styled(Row)``;

export const PageTitleBlock = styled.div`
  width: 100%;
  padding: 60px 180px;
  background: linear-gradient(180deg, #ffffff 0%, #ffffff00 100%),
    linear-gradient(
      90deg,
      #ecf6ff 0%,
      #f3eaf8 20%,
      #feecf0 25%,
      #faeeec 30%,
      #e7f5f6 35%,
      #e1f2ff 45%,
      #e7f7fa 60%,
      #e6f5fc 80%,
      #e6f4fd 100%
    );
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const PageTitle = styled.span`
  font: normal 600 32px/140% "DM Sans";
  color: #080a25;
`;

export const ContentBlock = styled.div`
  width: 100%;
  padding: 0px 180px;
  min-height: 50vh;
`;

export const BountiesHeaderBlock = styled.div`
  width: 100%;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    background: linear-gradient(
      94.38deg,
      #c58aff 7.87%,
      #00adfb 50.31%,
      #00d5c9 82.37%,
      #00de8c 107.9%
    );
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    height: 95%;
    opacity: 0.6;
    filter: blur(22px);
    border-radius: 50%;
  }
`;

export const BountiesHeaderContent = styled.div`
  height: 60px;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  background-color: white;
  position: relative;
`;

export const BountiesTitleBlock = styled.div`
  display: flex;
  gap: 32px;
  position: relative;
`;

export const BountyTitleTab = styled.div<{ isActive?: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  transition: 0.2s ease-out;

  &:hover {
    background-color: #7e00fd07;
  }

  ${({ isActive }) =>
    isActive &&
    `
      &:before {
        content: "";
        position: absolute;
        background-color: #7E00FD;
        width: 100%;
        height: 2px;
        top: 100%;
      }
  `}

  .ant-typography {
    font: normal 600 20px/100% "Rubik" !important;
    color: ${({ isActive }) =>
      isActive ? "#7E00FD" : "rgba(41, 46, 115, 0.82)"};
  }
`;

export const BountiesTitle = styled.span`
  font: normal 700 18px/100% "DM Sans";
  color: #080a25;
  position: relative;
  justify-self: center;
`;

export const ToolBlock = styled.div``;

export const ToolTitle = styled.span`
  font: normal 400 16px/100% "Rubik" !important;
  color: rgba(8, 10, 37, 0.6);
`;

export const SortBlock = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  column-gap: 12px;
`;

export const SortSelect = styled(Select)`
  .ant-select-selector {
    width: 156px !important;
    padding: 0px 8px;
    border-radius: 20px !important;
    border: 1px solid rgba(8, 10, 37, 0.1) !important;
    font: normal 400 16px/100% "Rubik" !important;
    color: #080a25;
  }
  .ant-select-arrow {
    color: #000000;
  }
`;

export const IssueTable = styled(Table)`
  margin: 20px 0px 0px 0px;
  thead {
    tr {
      th {
        padding: 16px 0px;
        background-color: #ffffff;
      }
      .ant-table-cell:before {
        content: none !important;
      }
    }
  }
  tbody {
    tr {
      cursor: pointer;
      td {
        vertical-align: middle;
        padding: 0px 0px;
        height: 84px;
        border-left: 0px solid #7e00fd;
        transition: border-left 200ms ease, background 1000s ease;
        &:first-child {
          min-width: 280px;
          max-width: 280px;
        }
      }
      &:hover {
        box-shadow: 0px 12px 17px -9px rgba(156, 138, 193, 0.18) !important;
        background: linear-gradient(
          270deg,
          #ffffff 78.12%,
          #f3e8ff 100%
        ) !important;
        td:first-child {
          border-left: 14px solid #7e00fd;
          & > img {
            margin-left: 10px;
          }
        }
      }
    }
  }
  .ant-table-placeholder {
    &:hover {
      border: none !important;
      background: none !important;
    }
    td {
      &:first-child {
        border-left: none !important;
      }
    }
  }
  .ant-table-empty {
    .ant-table-tbody {
      tr {
        box-shadow: none !important;
        td {
          border-bottom: none;
        }
      }
    }
  }
`;

export const TableColumn = styled(Table.Column)``;

export const IssueBlock = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;
  height: 100%;
`;
export const ProjectAvatar = styled.img`
  width: 56px;
  height: 56px;
  border-radius: 100%;
`;
export const IssueInfo = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 6px;
  justify-content: space-between;
`;
export const IssueName = styled.span`
  font: normal 700 18px/100% "DM Sans" !important;
`;
export const IssueStatusBlock = styled.div`
  display: flex;
  align-items: center;
  column-gap: 6px;
`;
export const ProjectName = styled.div`
  font: normal 400 14px/100% "DM Sans" !important;
`;
export const IssueStatus = styled.div<{ color: string }>`
  color: ${({ color }) => color};
  border: 1px solid ${({ color }) => color};
  padding: 4px 6px;
  font: normal 400 12px/100% "DM Sans" !important;
  border-radius: 50px;
`;

export const TypeBlock = styled.div<{ color: string }>`
  ${({ color }: any) => {
    const bgCol = color.replace(", 1)", ", 0.08)");
    return `background: ${bgCol} !important;`;
  }}
  display: inline-flex;
  column-gap: 4px;
  align-items: center;
  padding: 4px 8px;
  border-radius: 4px;
`;

export const TypeText = styled.span<{ color: string }>`
  color: ${(props) => props.color};
  padding-top: 2px;
  font: normal 400 14px/14px "Rubik";
`;

export const TypeIcon = styled(StarFilled)<{ color: string }>`
  color: ${(props) => props.color};
`;

export const LevelBlock = styled.div<{ color: string }>`
  ${({ color }: any) => {
    const bgCol = color.replace(", 1)", ", 0.08)");
    return `background: ${bgCol} !important;`;
  }}
  display: inline-flex;
  column-gap: 4px;
  align-items: center;
  padding: 4px 8px;
  border-radius: 4px;
`;

export const LevelText = styled.span<{ color: string }>`
  color: ${(props) => props.color};
  padding-top: 2px;
  font: normal 400 14px/14px "Rubik";
`;

export const LevelIcon = styled(StarFilled)<{ color: string }>`
  color: ${(props) => props.color};
`;

export const StatusText = styled.span`
  font: normal 400 14px/100% "Rubik";
  color: #000000;
`;

export const ApplicantsBlock = styled.div`
  display: flex;
  column-gap: 6px;
  align-items: center;
`;

export const ApplicantsIcon = styled(UserOutlined)`
  background: rgba(8, 10, 37, 0.1);
  border-radius: 100%;
  padding: 4px;
`;

export const ApplicantsText = styled.span`
  font: normal 400 14px/140% "Rubik";
  color: #000000;
  padding-top: 2px;
`;

export const LoadingBlock = styled.div`
  height: 40px;
  text-align: center;
`;

export const AvatarSkeleton = styled(SkeletonCustom)`
  margin-left: 10px;
  .ant-skeleton-avatar {
    width: 56px;
    height: 56px;
  }

  .ant-skeleton-header {
    vertical-align: middle;
    padding-right: 0px !important;
  }
`;

export const NameSkeleton = styled(SkeletonCustom)``;

export const BountyBlock = styled(Row)`
  margin-top: 34px;
`;

export const BountyCard = styled(Card)`
  border: 1px solid rgba(8, 10, 37, 0.1);
  border-radius: 12px;
  cursor: pointer;

  &:hover {
    background-color: #00000006;
  }

  .ant-card-body {
    padding: 0;
  }
`;

export const BountyCardBody = styled.div`
  padding: 20px;
`;

export const BountyCardInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  .time-left {
    font: normal 600 14px/100% "DM Sans";
    color: #7e00fd;
  }

  .square {
    width: 4px;
    height: 4px;
    background: #080a25;
  }

  .status {
    font: normal 400 14px/100% "DM Sans";
    color: #080f6c;
  }

  .refer a {
    font: italic 600 14px/100% "DM Sans";
    color: #7e00fd;
  }
`;

export const BountyTitle = styled(Typography.Text)`
  font: normal 700 18px/100% "DM Sans";
  color: #080a25;
  margin-top: 12px;
  display: block !important;
`;

export const BountyContent = styled.div`
  font: normal 400 16px/156% "DM Sans";
  color: rgba(57, 59, 81, 0.7);
  margin-top: 38px;
  display: block !important;
  height: 100px;
  white-space: break-spaces;
  overflow: hidden;
`;

export const BountyFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid rgba(8, 10, 37, 0.08);
  padding: 20px;

  .participants {
    display: flex;
    align-items: center;
    gap: 4px;
    color: #000000;
  }
`;

export const CollaborationModeTag = styled(Tag)`
  padding: 4px 8px;
  background: #00de8c;
  border-radius: 999px;
  color: #080f6c;
  border: none;
  font: normal 400 14px/100% "Rubik";
  letter-spacing: -0.01em;
  margin-right: 12px;
`;

export const ExperienceLevelTag = styled(Tag)`
  padding: 4px 8px;
  background: #7783a5;
  border-radius: 999px;
  color: white;
  border: none;
  font: normal 400 14px/100% "Rubik";
  letter-spacing: -0.01em;
`;

export const ResultsNotFoundRepo = styled(ResultsNotFound)`
  margin: 64px 0;

  img {
    width: 100px;
    height: 100px;
  }
  span {
    font-size: 14px;
  }
  p {
    margin-top: 16px;
  }
`;

