import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  MessageOutlined,
  NotificationOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Avatar, Badge, Dropdown, Menu, Space, message } from "antd";
import { logoutAPIAd } from "api/admin/auth";
import { AvatarLogged } from "layout/users/header/header.style";
import { FC, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { authActions } from "redux/slices/authSlice";
import { RootState } from "redux/store";
import { ROUTER_CONSTANTS } from "utils/constant";
import { getMessageContent } from "utils/message";
import { clearStorageAndGlobalState } from "utils/storage";
import { IHeaderProps } from "./header.props";
import {
  DropdownButton,
  HeaderAd,
  IconToggle,
  RightHeader,
} from "./header.style";

const HeaderAdmin: FC<IHeaderProps> = ({
  collapsed,
  setCollapsed,
  ...props
}: IHeaderProps) => {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const { basicInfo } = useSelector((state: RootState) => state.profileUser);

  const menuItems = (
    <Menu
      items={[
        {
          key: "1",
          label: <Link to={ROUTER_CONSTANTS.ADMIN_PAGES.HOME}>Profile</Link>,
        },
        {
          key: "2",
          label: (
            <p
              onClick={async () => {
                try {
                  await logoutAPIAd();
                  clearStorageAndGlobalState(true);
                  dispatch(authActions.logOut());
                  navigate(ROUTER_CONSTANTS.ADMIN_PAGES.LOGIN);
                } catch (error: any) {
                  message.error(getMessageContent(error?.message));
                }
              }}
            >
              Logout
            </p>
          ),
        },
      ]}
    />
  );

  const renderHeader = () => {
    return (
      <>
        <IconToggle onClick={() => setCollapsed(!collapsed)}>
          {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </IconToggle>
        <RightHeader>
          <Space size={"middle"}>
            <Badge dot>
              <NotificationOutlined />
            </Badge>
            <Badge dot>
              <MessageOutlined />
            </Badge>
            <Dropdown overlay={menuItems} placement="bottom" arrow>
              <DropdownButton>
                <Avatar
                  src={
                    !basicInfo?.avatar && (
                      <AvatarLogged icon={<UserOutlined />} />
                    )
                  }
                />
                {"Admin logged"}
              </DropdownButton>
            </Dropdown>
          </Space>
        </RightHeader>
      </>
    );
  };

  return <HeaderAd {...props}>{renderHeader()}</HeaderAd>;
};

export default memo(HeaderAdmin);

