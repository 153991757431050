import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { IUserPortfolioTbl } from "types";
import { optionUseQuery } from "utils/optionUseQuery";
import { getPortfolioAdd, getPortfolioById } from "./request";
import { IGetPortfolioAddRes, IGetPortfolioByIdReq } from "./types";

export const useGetPortfolioAddRes = (
  option?: UseQueryOptions<IGetPortfolioAddRes, AxiosError<{ message: string }>>
) => {
  return useQuery<IGetPortfolioAddRes, AxiosError<{ message: string }>>(
    ["get-portfolio-add"],
    () => getPortfolioAdd(),
    {
      ...optionUseQuery,
      ...option,
    }
  );
};

export const useGetPortfolioById = (
  params: IGetPortfolioByIdReq,
  option?: UseQueryOptions<IUserPortfolioTbl, AxiosError<{ message: string }>>
) => {
  return useQuery<IUserPortfolioTbl, AxiosError<{ message: string }>>(
    ["get-portfolio-by-id", params.id],
    () => getPortfolioById(params),
    { ...optionUseQuery, ...option }
  );
};
