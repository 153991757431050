import Logo from "assets/users/svg/logo_footer.svg";

import { Col } from "antd";
import LinkedinSvg from "assets/users/footer/linkedin.svg";
import MailSvg from "assets/users/footer/mail.svg";
import RightArrowSvg from "assets/users/footer/right-arrow.svg";
import TelegramSvg from "assets/users/footer/telegram.svg";
import TwitterSvg from "assets/users/footer/twitter.svg";
import { ChangeEvent, FC, memo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTER_CONSTANTS } from "utils/constant";
import { IFooterProps } from "./footer.props";
import {
  ContactContainer,
  EmailContainer,
  LinksContainer,
  LogoContainer,
  PageList,
  PageListContainer,
  PageListItem,
  PageListLinks,
  PrivacyPolicy,
  RightReserved,
  SearchEmail,
  SubButton,
  SubContainer,
  SubscribeContainer,
  SubscribeDescription,
  TopLabel,
} from "./footer.style";
import NewsletterForm from "./newsletterForm";

const FooterUser: FC<IFooterProps> = (props: IFooterProps) => {
  const navigate = useNavigate();

  const [isShowNewsletter, setIsShowNewsletter] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");

  return (
    <ContactContainer {...props}>
      <LogoContainer span={6}>
        <img src={Logo} alt="Logo" />
        <EmailContainer>
          <img src={MailSvg} alt="Mail" />
          <a href="mailto:team@opensoul.io">team@opensoul.io</a>
        </EmailContainer>
        <LinksContainer>
          <a
            href="https://twitter.com/"
            target="_blank"
            rel="noreferrer noopener"
          >
            <img src={TwitterSvg} alt="Twitter" />
          </a>
          <a
            href="https://linkedin.com/"
            target="_blank"
            rel="noreferrer noopener"
          >
            <img src={LinkedinSvg} alt="Linkedin" />
          </a>
          <a
            href="https://telegram.org/"
            target="_blank"
            rel="noreferrer noopener"
          >
            <img src={TelegramSvg} alt="Telegram" />
          </a>
        </LinksContainer>
        <RightReserved>©All Rights Reserved.</RightReserved>
      </LogoContainer>
      <PageListContainer span={6}>
        <PageList>
          <TopLabel>OpenSoul</TopLabel>
          <PageListLinks>
            <PageListItem
              onClick={() => navigate(ROUTER_CONSTANTS.USER.ABOUT_US)}
            >
              About Us
            </PageListItem>
            <PageListItem>Documentation</PageListItem>
            <PageListItem
              onClick={() =>
                navigate(ROUTER_CONSTANTS.HOME, {
                  state: {
                    isSponsor: true,
                  },
                })
              }
            >
              Sponsor Open Source
            </PageListItem>
            <PageListItem
              onClick={() =>
                navigate(ROUTER_CONSTANTS.HOME, {
                  state: {
                    isExplore: true,
                  },
                })
              }
            >
              Launch your OSS project on OpenSoul
            </PageListItem>
            <PageListItem
              onClick={() => window.open("mailto:team@opensoul.io", "_blank")}
            >
              Contact
            </PageListItem>
            <PageListItem onClick={() => navigate(ROUTER_CONSTANTS.USER.BLOGS)}>
              Blog
            </PageListItem>
          </PageListLinks>
        </PageList>
      </PageListContainer>
      <PageListContainer span={6}>
        <PageList>
          <TopLabel>Our friends</TopLabel>
          <PageListLinks>
            <PageListItem
              onClick={() =>
                window.open("https://www.rebaked.com/", "_blank", "noopener")
              }
            >
              reBaked DAO
            </PageListItem>
            <PageListItem
              onClick={() =>
                window.open("https://buidlfi.io/", "_blank", "noopener")
              }
            >
              BuildFi
            </PageListItem>
            <PageListItem
              onClick={() =>
                window.open("https://www.datasource.ai/", "_blank", "noopener")
              }
            >
              DataSource
            </PageListItem>
            <PageListItem
              onClick={() =>
                window.open("https://crowdhack.io/", "_blank", "noopener")
              }
            >
              CrowdHack
            </PageListItem>
          </PageListLinks>
        </PageList>
      </PageListContainer>
      <Col span={6}>
        <SubscribeContainer>
          <TopLabel>Subscribe</TopLabel>
          <SubscribeDescription>
            Get the latest updates for the OSS creator economy straight to your
            inbox!
          </SubscribeDescription>

          <SubContainer>
            <SearchEmail
              placeholder="Your email"
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setEmail(e.target.value);
              }}
              value={email}
            />
            <SubButton
              icon={<img src={RightArrowSvg} alt="right arrow" />}
              onClick={() => {
                setIsShowNewsletter(true);
              }}
            >
              Subscribe
            </SubButton>
            <PrivacyPolicy>
              We’ll never share your details. Check{" "}
              <strong>Privacy Policy</strong>
            </PrivacyPolicy>
          </SubContainer>
        </SubscribeContainer>
      </Col>

      {isShowNewsletter && (
        <NewsletterForm
          isShowNewsletter={isShowNewsletter}
          setIsShowNewsletter={setIsShowNewsletter}
          email={email}
          resetEmail={() => {
            setEmail("");
          }}
        />
      )}
    </ContactContainer>
  );
};

export default memo(FooterUser);
