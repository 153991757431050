import { ConnectionIcon } from "assets/common/svg/Connection.icon";
import { CommonModal } from "components/base";
import styled from "styled-components";

export const Modal = styled(CommonModal)`
  .ant-modal-content {
    border-radius: 8px;
  }
  .ant-modal-body {
    padding: 16px !important;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #080f6c;
`;

export const Logo = styled(ConnectionIcon)`
  display: block;
  margin: auto;
`;

export const Title = styled.h2`
  font-size: 20px;
  font-weight: 600;
  color: inherit;
  margin-top: 8px;
`;

export const WalletsContainer = styled.div`
  margin: 16px 0;
  width: 100%;
`;
