import { Avatar, Col, Row } from "antd";
import { FC, memo } from "react";
import {
  BackgroundContainer,
  Container,
  Heading,
  SpaceLeft,
  StatusBar,
} from "./sponsorHeader.style";
import AvatarImg from "assets/common/png/avatar.png";

const SponsorHeader: FC = () => {
  return (
    <BackgroundContainer>
      <Container>
        <Row>
          <Col flex="auto">
            <Row gutter={[16, 16]}>
              <Col>
                <Avatar size={72} src={AvatarImg} />
              </Col>
              <Col>
                <Heading>
                  <p>Preact</p>
                  <span>preactjs</span>
                </Heading>
              </Col>
            </Row>
          </Col>
          <Col flex="none">
            <SpaceLeft>
              <h1>38% towards 50 sponsors goal</h1>
              <StatusBar percent={38}></StatusBar>
              <span>ilovept and 18 others sponsor this goal</span>
            </SpaceLeft>
          </Col>
        </Row>
      </Container>
    </BackgroundContainer>
  );
};

export default memo(SponsorHeader);
