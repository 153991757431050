import { message } from "antd";
import axios from "axios";
import Cookies from "js-cookie";
import { KEY_COOKIE, ROUTER_CONSTANTS } from "utils/constant";
import { getMessageContent } from "utils/message";
import { debounce } from "lodash";
import { clearStorageAndGlobalState } from "utils/storage";

const baseURL = process.env.REACT_APP_API_URI;
const timeout = +(process.env.REACT_APP_API_TIME_OUT || 20000);

const handleUnauthorized = debounce((msg: string) => {
  clearStorageAndGlobalState(msg === "UNAUTHORIZED_ADMIN");
  message.error(getMessageContent("Unauthorized"));

  setTimeout(() => {
    const isUserCall = msg === "Unauthorized";
    window.location.href = isUserCall
      ? ROUTER_CONSTANTS.USER.LOGIN
      : ROUTER_CONSTANTS.ADMIN_PAGES.LOGIN;
  }, 500);
}, 500);

const axiosInstance = axios.create({
  baseURL,
  timeout,
});

axiosInstance.interceptors.request.use(
  function (config: any) {
    config.headers["Content-Type"] = "application/json";
    // Set authorization header for user login
    if (Cookies.get(KEY_COOKIE.ACCESS_TOKEN)) {
      config.headers["Authorization"] = `Bearer ${Cookies.get(
        KEY_COOKIE.ACCESS_TOKEN
      )}`;
    }
    // Set authorization header for admin login
    if (Cookies.get(KEY_COOKIE.ACCESS_TOKEN_ADMIN)) {
      config.headers["Authorization"] = `Bearer ${Cookies.get(
        KEY_COOKIE.ACCESS_TOKEN_ADMIN
      )}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Response interceptor for API calls
axiosInstance.interceptors.response.use(
  (response) => {
    if (response?.data) {
      return response?.data;
    }
    return response;
  },
  async function (error) {
    if (
      error?.response?.status === 401 ||
      error?.response?.data?.message === "Unauthorized" ||
      error?.response?.data?.message === "UNAUTHORIZED_ADMIN"
    ) {
      const msg = error?.response?.data?.message;
      handleUnauthorized(msg);
    }

    if (error?.response?.status === 404) {
      const msg = error?.response?.data?.message;
      const url = ROUTER_CONSTANTS.ERROR_CODE.replace(":code", "404").replace(
        ":message",
        getMessageContent(msg)
      );
      window.location.href = url;
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;

