import styled from "styled-components";

export const HeaderTabsContainer = styled.div`
  display: grid;
  grid-template-columns: 100px auto;
`;

export const HeaderTitleContainer = styled.div`
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 120%;
  color: rgba(8, 10, 37, 0.8);
`;

export const HeaderTagsContainer = styled.div<{
  showTitle?: boolean;
}>`
  display: flex;
  ${({ showTitle } : any) =>
    showTitle ? `justify-content: end;` : `justify-content: left;`}
`;

export const Timeline = styled.p`
  margin-top: 13px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: #080a25;
  min-width: fit-content;
`;

export const Menu = styled.ul`
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  padding-left: 0px;
  margin-left: 20px;
  padding: 5px;
  background: #ffffff;
  border: 1px solid rgba(8, 10, 37, 0.1);
  border-radius: 8px;
  min-width: max-content;
`;

export const MenuItem = styled.li<{
  active?: boolean;
}>`
  padding: 5px;
  cursor: pointer;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: #343538;
  ${({active}: any) =>
    active &&
    `
    background: linear-gradient(
        96.27deg,
        rgba(197, 138, 255, 0.15) 1.94%,
        rgba(125, 151, 253, 0.15) 19.04%,
        rgba(0, 173, 251, 0.15) 41.09%,
        rgba(0, 213, 201, 0.15) 68.76%,
        rgba(0, 222, 140, 0.15) 90.53%
      );
      border-radius: 8px;
      color: #343538;
    `}
`;
