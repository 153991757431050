import styled from "styled-components";
import { THEME } from "utils/theme";
import projectBackground from "assets/users/projects/add_project/add_project_background.png";

const STEP_THEME = {
  BG_ACTIVE_COLOR: "#7e00fd",
  BG_MISS_COLOR: "#C3C6CF",
  BG_PICKED_COLOR: "#00DE8C",
};

export const Wrapper = styled.div`
  position: relative;
  /* height: 149px; */
  /* margin-bottom: 16px; */
  background-repeat: repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-image: url(${projectBackground});
  /* background: linear-gradient(to bottom, rgba(1, 184, 226, 0.3), transparent); */
`;

export const Content = styled.div`
  display: flex;
  margin: 0px ${THEME.USER.CONTAINER_FLUID} 0px ${THEME.USER.CONTAINER_FLUID};
  width: 100%;
  border-radius: 24px 24px 0px 0px;
  background: #ffffff;
  justify-content: center;
  padding: 21px 0px;
  margin-top: 20px;
`;

export const Step = styled.div<{ picked: boolean; isDisabled: boolean }>`
  text-align: center;
  width: 100px;
  ${({ picked, isDisabled }: any) =>
    picked && !isDisabled ? `cursor: pointer;` : `cursor: not-allowed;`}
`;

export const Icon = styled.div<{
  active: boolean;
  picked: boolean;
  hasNext: boolean;
  line: string;
}>`
  ${({ active, picked }) => {
    let color = STEP_THEME.BG_MISS_COLOR;
    if (active) {
      color = STEP_THEME.BG_ACTIVE_COLOR;
    } else if (picked) {
      color = STEP_THEME.BG_PICKED_COLOR;
    }
    return `background: ${color};`;
  }}

  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 10px;
  margin-left: 25%;

  ${({ hasNext, picked, line }) =>
    hasNext
      ? `
    ::after {
      position: absolute;
      top: 50%;
      left: 100%;
      display: block;
      height: 2px;
      content: "";
      width: 110%;
      background: linear-gradient(to right, ${line});
    }  
  `
      : ``}
`;

export const Text = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 115%;
  text-align: center;
  color: #080a25;
  width: 100px;
  word-break: break-word;
`;

