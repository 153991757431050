import { useMutation } from "@tanstack/react-query";
import { Form as AntdForm, message, Spin } from "antd";
import { IUploadsReq, IUploadsRes, postUploadsAPI } from "api/users/uploads";
import axios, { AxiosError } from "axios";
import React, { memo, useEffect, useState } from "react";
import { IUploadImageProps } from "./uploadImage.props";
import {
  ImgPDF,
  PreviewCustom,
  UploadBlock,
  UploadInfoWrapper,
  UploadTextDesc,
} from "./uploadImage.style";
import { getMessageContent } from "utils/message";

export const getBase64 = async (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
};

const UploadImage: React.ForwardRefRenderFunction<any, IUploadImageProps> = (
  {
    icon,
    title,
    accept,
    subTitle,
    imageFile,
    setImageFile,
    imagePreview,
    setImagePreview,
    setUploadUrl,
    disabled,
    ...props
  },
  ref
) => {
  const [form] = AntdForm.useForm();
  const [initUploads, setInitUploads] = useState<IUploadsReq>();
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const THUMB_PREVIEW_PDF: string =
    "https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1667px-PDF_file_icon.svg.png";
  const beforeUpload = async (file: any) => {
    if (accept === ".pdf") {
      if (!file.type.includes("pdf")) {
        message.error("You can only upload pdf!");
        return false;
      }
    } else {
      if (!file.type.includes("image")) {
        message.error("You can only upload Image!");
        return false;
      }
    }

    if (!file) {
      message.error("You can only upload Image!");
      return false;
    } else {
      setInitUploads({
        file_name: file.name.replace(" ", "_"),
        file_type: file.type,
      });
      setImageFile(file);
      return false;
    }
  };

  const handleChangeImageFile = async (info: any) => {
    const file = info.file;
    if (!file) return;
    setImageFile(file);
    setImagePreview(await getBase64(file));
    form.setFieldsValue({ cover: file });
  };

  const { mutate: postUploads } = useMutation(postUploadsAPI, {
    onSuccess: async (rsUpload: IUploadsRes) => {
      axios
        .put(rsUpload.signedRequest, imageFile)
        .then(() => {
          setImagePreview(rsUpload.url);
          setUploadUrl(rsUpload.url);
        })
        .catch((err: AxiosError) => {
          message.error(getMessageContent(err?.message));
        });
    },
    onError: (err: AxiosError) => {
      message.error(getMessageContent(err?.message));
    },
  });

  useEffect(() => {
    if (initUploads) {
      setIsUploading(true);
      postUploads(initUploads);
    }
  }, [initUploads, postUploads]);

  useEffect(() => {
    setIsUploading(false);
  }, [imagePreview]);

  return (
    <UploadBlock
      multiple={false}
      listType="picture-card"
      className="avatar-uploader"
      onRemove={() => {
        setImageFile(undefined);
      }}
      showUploadList={false}
      beforeUpload={beforeUpload}
      fileList={imageFile ? [imageFile as any] : []}
      onChange={handleChangeImageFile}
      accept={accept ?? ".png,.jpg,.gif,.jpeg"}
      iconRender={() => {
        return <Spin></Spin>;
      }}
      disabled={disabled}
      ref={ref}
      {...props}
    >
      <PreviewCustom preview={imagePreview}>
        {!isUploading || <Spin size="large" />}
        {!isUploading && imagePreview && (
          <>
            {imagePreview.split(".").pop() === "pdf" ? (
              <ImgPDF src={THUMB_PREVIEW_PDF} alt="" />
            ) : (
              <img src={imagePreview} alt="" />
            )}
          </>
        )}
        {!isUploading && !imagePreview && (
          <UploadInfoWrapper>
            {icon}
            <UploadTextDesc>
              {title}
              <div>{subTitle}</div>
            </UploadTextDesc>
          </UploadInfoWrapper>
        )}
      </PreviewCustom>
    </UploadBlock>
  );
};

export default memo(React.forwardRef(UploadImage));
