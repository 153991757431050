import { Form } from "antd";
import styled from "styled-components";

export const Container = styled(Form)`
  @keyframes visibleAnimation {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  animation: visibleAnimation 2s;
  .ant-form-item-control {
    flex: auto;
  }
`;

export const ContentBlock = styled.div``;

export const WrapBlock = styled.div`
  margin-top: 36px;
  width: 800px;
  margin-left: auto;
  margin-right: auto;
  gap: 26px;
`;

export const WrapButtons = styled.div`
  margin-top: 36px;
  margin-left: 26px;
  display: grid;
  gap: 26px;
`;

export const Title = styled.div`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  color: rgba(8, 10, 37, 0.8);
`;

export const SubTitle = styled.div`
  display: grid;
  gap: 8px;
  span {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 145%;
    color: rgba(8, 10, 37, 0.4);
  }
  margin-bottom: 8px;
`;

export const WrapItem = styled.div<{ active: boolean }>`
  display: flex;
  padding: 18px 16px;
  width: 342px;
  height: fit-content;
  background: #faf8ff;
  border: 1px solid rgba(115, 137, 149, 0.2);
  border-radius: 4px;
  cursor: pointer;
  :hover {
    border: 1px solid #7e00fd;
  }
  justify-content: center;
  align-items: center;
  gap: 16px;

  // active
  ${({ active }: any) =>
    active
      ? `background: rgba(126, 0, 253, 0.1); 
         border: 1px solid #7E00FD;
         box-shadow: #7e00fd68 0px 0px 6px;
        `
      : `border: 1px solid rgba(115, 137, 149, 0.2);`}
  transition: background-color 0.3s ease-in-out, border 0.3s ease-in-out,
  box-shadow 0.3s ease-in-out;
`;

export const Left = styled.div`
  display: grid;
  gap: 6px;
  div {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    align-items: center;
    color: #080a25;
  }
  span {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 134%;
    color: rgba(8, 10, 37, 0.5);
  }
`;
export const Right = styled.div`
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #7e00fd;
  border-radius: 50%;
  svg {
    width: inherit;
  }
`;

export const RightText = styled.div`
  margin-top: 4px;
  text-align: right;
  font: normal 400 14px/100% "DM Sans";
  letter-spacing: -0.01em;
  color: rgba(8, 15, 108, 0.5);
`;

export const SuggestedTagBlock = styled.div`
  display: flex;
  gap: 4px 4px;
  flex-wrap: wrap;
`;
