import { FC, useEffect, useState, memo } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation, useParams } from "react-router-dom";

const Seo: FC = () => {
  const [pathname, setPathname] = useState<string>("");
  const location = useLocation();
  const params = useParams();

  const usPageName = "Soulbound |";

  const data: any = {
    "/": {
      title: `${usPageName} Home`,
    },
    "/projects/about/": {
      title: `${usPageName} Projects | About`,
    },
    "/projects/community/": {
      title: `${usPageName} Projects | Community`,
    },
    "/projects/activity/": {
      title: `${usPageName} Projects | Activity`,
    },
  };

  useEffect(() => {
    if (JSON.stringify(params) === "{}") {
      setPathname(location.pathname);
    } else {
      setPathname(
        location.pathname.substring(0, location.pathname.lastIndexOf("/") + 1)
      );
    }
  }, [location, params]);

  return (
    <Helmet>
      <title>{data[pathname]?.title}</title>
    </Helmet>
  );
};

export default memo(Seo);
