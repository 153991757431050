import { Button, Dropdown } from "antd";
import styled from "styled-components";

export const DropdownCustom = styled(Dropdown)`
  height: 40px;
`;

export const ButtonFilter = styled(Button)`
  background: #ffffff !important;
  border: 1px solid rgba(8, 10, 37, 0.1) !important;
  border-radius: 4px !important;
  width: 130px;
`;