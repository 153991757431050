import { Button } from "antd";
import styled from "styled-components";

export const ButtonUpvoteAntd = styled(Button)<{
  $is_done?: boolean;
}>`
  display: flex;
  align-items: center;
  padding: 4px 6px 2px 6px;
  border-radius: 8px;
  border: none;
  background: #ffffff;
  ${({ $is_done }: any) => {
    const doneColor = "rgba(126, 0, 253, 1)";
    const doneBgCol = doneColor.replace(", 1)", ", 0.08)");
    const doneBgColActive = doneColor.replace(", 1)", ", 0.12)");
    const unDoneColor = "rgba(117, 117, 117, 1)";
    return `
        span {
          font: normal 400 14px/100% "Rubik";
          color: ${$is_done ? doneColor : unDoneColor} !important;
        }
        svg {
          font-size: 20px;
          fill: ${$is_done ? doneColor : unDoneColor} !important;
        }
        &:hover {
          background: ${doneBgCol} !important;
          span {
            color: ${doneColor} !important;
          }
          svg {
            fill: ${doneColor} !important;
          }
        }
        &:active {
          background: ${doneBgColActive} !important;
        }
          `;
  }}
  cursor: pointer;
`;
