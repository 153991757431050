import { FC, useEffect, useState } from "react";
import { ITabsMenuTimeline } from "types/Models/tabsModel";
import { ITabsInfoProps } from "./tabsInfo.props";
import {
  HeaderTitleContainer,
  HeaderTagsContainer,
  HeaderTabsContainer,
  Timeline,
  Menu,
  MenuItem,
} from "./tabsInfo.style";

const TabsInfo: FC<ITabsInfoProps> = ({
  children,
  titleHeader,
  tabsTimeline,
  onClickTabs,
  ...props
}: ITabsInfoProps) => {
  const onChangeTabs = (key: any) => {
    let newtabs = tabs?.map((item: ITabsMenuTimeline) => {
      item.active = item?.key === key;
      return item;
    });
    setTabs(newtabs);
  };

  const [tabs, setTabs] = useState<ITabsMenuTimeline[] | undefined>([]);

  useEffect(() => {
    setTabs(tabsTimeline);
  }, [tabsTimeline]);

  return (
    <>
      <HeaderTabsContainer>
        {titleHeader && (
          <HeaderTitleContainer>{titleHeader}</HeaderTitleContainer>
        )}
        {tabs && (
          <HeaderTagsContainer showTitle={!!titleHeader}>
            <Timeline>In last:</Timeline>
            <Menu>
              {tabs?.map((item: ITabsMenuTimeline, key: number) => (
                <MenuItem
                  key={key}
                  onClick={() => {
                    if (onClickTabs) {
                      onClickTabs(item?.key);
                      onChangeTabs(item?.key);
                    }
                  }}
                  active={item?.active}
                >
                  {item?.name}
                </MenuItem>
              ))}
            </Menu>
          </HeaderTagsContainer>
        )}
      </HeaderTabsContainer>
      {children}
    </>
  );
};

export default TabsInfo;
