import { Select } from "antd";
import styled from "styled-components";

export const Wrapper = styled(Select)<{ focus: boolean }>`
  width: 100%;
  border-bottom: 1px solid #e2dfe9;
  position: relative;
  box-sizing: border-box;
  font-weight: 400 !important;
  font-size: 20px !important;
  color: #080a25 !important;
  height: 38px;

  ::after {
    position: absolute;
    content: "";
    display: block;
    left: 0px;
    bottom: -1px;
    width: ${({ focus }) => (focus ? `100%` : `0%`)};
    border-bottom: 1px solid #7e00fd;
    transition: all 0.4s;
  }

  .ant-select-selector {
    border: none !important;
    padding: 0 !important;
    background: none !important;
    .ant-select-selection-search {
      left: 0px;
    }
  }
  .ant-select-arrow > svg {
    width: 12px !important;
  }

  .ant-select-item {
    font-size: 20px !important;
  }
`;
