import { SkeletonProps } from "antd";

export enum SkeletonType {
  BUTTON = "button",
  AVATAR = "avatar",
  INPUT = "input",
  IMAGE = "image",
  NODE = "node",
}

interface ISkeletonProps extends SkeletonProps {
  type?: string;
}

export default ISkeletonProps;
