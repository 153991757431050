import {
  ArrowRightOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Card,
  Form,
  Layout,
  Pagination,
  Select,
  Spin,
  Tabs,
  Upload,
  UploadProps,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import { ClockInTagIcon } from "assets/common/svg/ClockInTag.icon";
import { CompletedIcon } from "assets/common/svg/Complete.icon";
import { FolderIcon } from "assets/common/svg/Folder.Icon";
import {
  ButtonBorderGradient,
  ButtonColor,
  ButtonConnectInfo,
  ButtonTransparent,
  ButtonWhite,
  FormItemWithHl,
  TagWithIcon,
} from "components/base";
import ButtonComment from "components/base/buttonComment";
import ButtonGradient from "components/base/buttonGradient";
import ButtonGradientText from "components/base/buttonGradientText";
import ButtonShare from "components/base/buttonShare";
import ButtonUpvote from "components/base/buttonUpvote";
import CommonModal from "components/base/commonModal";
import { IFormItemWithHlMethods } from "components/base/formItemWithHl/formItemWithHl.props";
import { InputLink } from "components/base/formItemWithHl/formItemWithHl.style";
import SelectArrowDownIcon from "components/base/selectArrowDownIcon";
import {
  CardInfo,
  PaginationCustom,
  TabsInfo,
  TabsMenu,
} from "components/module";
import ButtonOptions from "components/module/buttonOptions";
import useModal from "hooks/useModal";
import MasterLayout from "layout/users/masterLayout";
import TestAPI from "pages/users/commonUi/apiTest";
import { FC, memo, ReactNode, useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from "react-router-dom";
import { ITabsMenuTimeline } from "types/Models/tabsModel";
import CollectGuestInfoDialog from "../collectGuestInfoDialog";
import DropdownList from "../dataSetupPage/components/dropdownList";
import { ICommonUiProps } from "./commonUi.props";
import { WrapComponent } from "./commonUi.style";

const { Content, Footer } = Layout;
const { TabPane } = Tabs;

const CommonUi: FC<ICommonUiProps> = (props: ICommonUiProps) => {
  const [tabsTimelineTitle] = useState<ITabsMenuTimeline[] | undefined>([
    { name: "30 Days", key: 0, active: true },
    { name: "90 Days", key: 1 },
    { name: "Lifetime", key: 2 },
  ]);
  const [tabKey, settabKey] = useState<number | undefined>(0);
  const [tabsContent, setTabsContent] = useState<ReactNode | undefined>();

  const [tabsTimeline] = useState<ITabsMenuTimeline[] | undefined>([
    { name: "30 Days", key: 0 },
    { name: "90 Days", key: 1, active: true },
    { name: "Lifetime", key: 2 },
  ]);
  const [tabKeyTitle, settabKeyTitle] = useState<string | undefined>("30days");
  const [tabsContentTitle, setTabsContentTitle] = useState<
    ReactNode | undefined
  >();
  const { isShowing, toggle } = useModal();

  useEffect(() => {
    const tabElement = () => {
      return <h1>Tab key {tabKey}</h1>;
    };
    setTabsContent(tabElement);
  }, [tabKey]);

  useEffect(() => {
    const tabElementTitle = () => {
      return <h1>Tab key title {tabKeyTitle}</h1>;
    };
    setTabsContentTitle(tabElementTitle);
  }, [tabKeyTitle]);

  const handleformSubmitted = (result: boolean) => console.log({ result });

  const [hasMore, setHasMore] = useState<boolean>(true);
  const [items, setItems] = useState<any>(Array.from({ length: 20 }));

  const fetchMoreDataFC = () => {
    if (items.length >= 200) {
      setHasMore(false);
      return;
    }
    setTimeout(() => {
      setItems(items.concat(Array.from({ length: 20 })));
    }, 500);
  };

  const inputDaoName = useRef<IFormItemWithHlMethods>(null);
  const inputPurpose = useRef<IFormItemWithHlMethods>(null);

  const uploadXlsxProps: UploadProps = {
    name: "file",
    onChange(info) {},
  };

  return (
    <MasterLayout>
      <Content style={{ padding: "0 50px" }}>
        <Breadcrumb style={{ margin: "16px 0" }}>
          <Breadcrumb.Item>Common UI</Breadcrumb.Item>
        </Breadcrumb>
        <Layout>
          <Card>
            <TabsMenu>
              <TabPane tab="Tab 1" key="1">
                <h1>Tabs 1</h1>
              </TabPane>
              <TabPane tab="Tab 2" key="2">
                <h1>Tabs 2</h1>
              </TabPane>
            </TabsMenu>
          </Card>
        </Layout>
        <Layout>
          <Card>
            <h2>Button</h2>
            <WrapComponent>
              <ButtonGradient>
                <PlusOutlined />
                Primary Button
              </ButtonGradient>
              <ButtonGradient onClick={toggle}>JOIN COMMUNITY</ButtonGradient>
              <ButtonComment></ButtonComment>
              <ButtonConnectInfo>Connect Info</ButtonConnectInfo>
              <ButtonBorderGradient>Connect Info</ButtonBorderGradient>
              <ButtonOptions></ButtonOptions>
              <ButtonShare></ButtonShare>
              <ButtonTransparent>Transparent</ButtonTransparent>
              <ButtonUpvote></ButtonUpvote>
              <ButtonWhite>ButtonWhite</ButtonWhite>
              <ButtonColor>
                ButtonColor <ArrowRightOutlined />
              </ButtonColor>
              <ButtonGradientText>Announcement</ButtonGradientText>
            </WrapComponent>
            <h2>Paginate</h2>
            <WrapComponent>
              <PaginationCustom isLoading={false}>
                <Pagination
                  defaultCurrent={100}
                  pageSize={10}
                  total={100}
                  showSizeChanger={false}
                  onChange={(page: number) => {
                    console.log(page);
                  }}
                />
              </PaginationCustom>
            </WrapComponent>
          </Card>
          <Card>
            <h2>Tag</h2>
            <WrapComponent>
              <TagWithIcon color="#1CC98A">
                <CompletedIcon></CompletedIcon>
                <span>Completed</span>
              </TagWithIcon>
              <TagWithIcon color="#FF5722">
                <ClockInTagIcon></ClockInTagIcon>
                <span>In progress</span>
              </TagWithIcon>
              <TagWithIcon color="#7E00FD">
                <FolderIcon></FolderIcon>
                <span>Admin</span>
              </TagWithIcon>
            </WrapComponent>
          </Card>
          <Card>
            <h2>Select Box</h2>
            <WrapComponent>
              <DropdownList>
                <Select.Option key={1} value={1}>
                  Value 1
                </Select.Option>
                <Select.Option key={2} value={2}>
                  Value 2
                </Select.Option>
              </DropdownList>
              <SelectArrowDownIcon
                options={[
                  { value: 1, label: "Value 1" },
                  { value: 2, label: "Value 2" },
                ]}
              />
            </WrapComponent>
          </Card>

          <Card>
            <h2>Input</h2>
            <WrapComponent>
              <FormItemWithHl
                label="Dao Name"
                ref={inputDaoName}
                colorBefore={"rgb(8, 10, 37,0.7)"}
              >
                <Form.Item
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please input DAO Name",
                    },
                  ]}
                >
                  <InputLink
                    onFocus={() => inputDaoName.current?.handleFocus()}
                    onBlur={() => inputDaoName.current?.handleBlur()}
                  ></InputLink>
                </Form.Item>
              </FormItemWithHl>
              <FormItemWithHl
                label="Purpose"
                ref={inputPurpose}
                colorBefore={"rgb(8, 10, 37, 0.7)"}
              >
                <Form.Item name="description">
                  <TextArea
                    rows={6}
                    onFocus={() => inputPurpose.current?.handleFocus()}
                    onBlur={() => inputPurpose.current?.handleBlur()}
                  ></TextArea>
                </Form.Item>
              </FormItemWithHl>
            </WrapComponent>
          </Card>
        </Layout>
        <Layout>
          <CardInfo title="Get message object">
            <Upload {...uploadXlsxProps}>
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
          </CardInfo>
        </Layout>
        <Layout>
          <CardInfo title="API-TEST">
            <TestAPI />
          </CardInfo>
          <CardInfo
            title="Activity"
            extra={
              <Link to="#">
                View All Activities <ArrowRightOutlined />
              </Link>
            }
          >
            Card content
          </CardInfo>
        </Layout>
        <Layout>
          <Card>
            <TabsInfo
              tabsTimeline={tabsTimeline}
              onClickTabs={(key?: number) => {
                settabKey(key);
              }}
            >
              {tabsContent}
            </TabsInfo>
          </Card>
          <Card>
            <TabsInfo
              titleHeader="Activity"
              tabsTimeline={tabsTimelineTitle}
              onClickTabs={(key?: string) => {
                settabKeyTitle(key);
              }}
            >
              {tabsContentTitle}
            </TabsInfo>
          </Card>
          <CardInfo title="Infinite scroll">
            <InfiniteScroll
              dataLength={items.length}
              next={fetchMoreDataFC}
              hasMore={hasMore}
              loader={<Spin />}
            >
              {items.map((item: any, key: number) => (
                <div key={key}>div - #{key}</div>
              ))}
            </InfiniteScroll>
          </CardInfo>
        </Layout>
      </Content>

      <Footer style={{ textAlign: "center" }}>UI COMMON</Footer>
      <CommonModal
        footer={null}
        visible={isShowing}
        centered
        closable={true}
        onCancel={toggle}
        destroyOnClose={true}
      >
        <CollectGuestInfoDialog onSubmitInfo={handleformSubmitted} />
      </CommonModal>
    </MasterLayout>
  );
};

export default memo(CommonUi);
