import { CopyOutlined, ShareAltOutlined } from "@ant-design/icons";
import { FacebookIcon } from "assets/common/svg/Facebook.icon";
import { LinkedlnIcon } from "assets/common/svg/Linkedln.icon";
import { TwitterIcon } from "assets/common/svg/Twitter.icon";
import { FC, memo, useRef, useState } from "react";
import { SHARE_URL } from "utils/constant";
import { IButtonShareProps } from "./buttonShare.props";
import {
  ButtonBlock,
  ButtonFacebook,
  ButtonLinkedln,
  ButtonShareAntd,
  ButtonShareBlock,
  ButtonTwitter,
  CopyButton,
  CopyNotification,
  InputLink,
  InputLinkBlock,
  PopoverAntd,
  ShareBlock,
  ShareBlockTitle,
  TextTitle,
} from "./buttonShare.style";

const ButtonShare: FC<IButtonShareProps> = ({
  showText,
  children,
  objectId,
  url,
  ...props
}: IButtonShareProps) => {
  const shareRef = useRef(null);
  const [showCopyNotification, setShowCopyNotification] =
    useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);

  const fnCopy = (text: string) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    try {
      document.execCommand("copy");
    } catch (err) {
      console.error("Unable to copy to clipboard", err);
    }
    document.body.removeChild(textArea);
  };

  const copyToClipboard = (text: string) => {
    fnCopy(text);
    setShowCopyNotification(true);
    setTimeout(() => {
      setShowCopyNotification(false);
    }, 2000);
  };
  const handleVisibleChange = (visible: boolean) => {
    setVisible(visible);
  };
  const sharePost = (platform: string) => {
    switch (platform) {
      case "Twitter":
        window.open(
          `${SHARE_URL.TWITTER}${url || window.location.href}`,
          "_blank",
          "noopener,noreferrer"
        );
        break;
      case "Facebook":
        window.open(
          `${SHARE_URL.FACEBOOK}${url || window.location.href}`,
          "_blank",
          "noopener,noreferrer"
        );
        break;
      case "Linkedln":
        window.open(
          `${SHARE_URL.LINKEDLN}${url || window.location.href}`,
          "_blank",
          "noopener,noreferrer"
        );
        break;
      default:
    }
  };
  return (
    <ButtonShareBlock ref={shareRef}>
      <PopoverAntd
        placement="bottom"
        getPopupContainer={(node) => shareRef.current || node}
        content={
          <ShareBlock>
            <ShareBlockTitle>Copy Link to the post</ShareBlockTitle>
            <InputLinkBlock>
              <InputLink readOnly value={url} />
              <CopyButton
                onClick={(e: any) => {
                  copyToClipboard(url!);
                  e.stopPropagation();
                }}
                icon={<CopyOutlined />}
              >
                Copy
              </CopyButton>
            </InputLinkBlock>
            <TextTitle>or share to:</TextTitle>
            <ButtonBlock>
              <ButtonTwitter
                onClick={(e: any) => {
                  sharePost("Twitter");
                  e.stopPropagation();
                }}
              >
                <TwitterIcon style={{ fill: "white" }} />
              </ButtonTwitter>
              <ButtonLinkedln
                onClick={(e: any) => {
                  sharePost("Linkedln");
                  e.stopPropagation();
                }}
              >
                <LinkedlnIcon />
              </ButtonLinkedln>
              <ButtonFacebook
                onClick={(e: any) => {
                  sharePost("Facebook");
                  e.stopPropagation();
                }}
              >
                <FacebookIcon />
              </ButtonFacebook>
            </ButtonBlock>
            <CopyNotification isShow={showCopyNotification}>
              Copied to clipboard
            </CopyNotification>
          </ShareBlock>
        }
        trigger="click"
        visible={visible}
        onVisibleChange={handleVisibleChange}
      >
        <ButtonShareAntd
          onClick={() => {
            setVisible(true);
          }}
          icon={<ShareAltOutlined />}
          $is_done={false}
          {...props}
        >
          {showText ? "Share" : ""}
        </ButtonShareAntd>
      </PopoverAntd>
    </ButtonShareBlock>
  );
};

export default memo(ButtonShare);
