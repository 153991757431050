import { FC, memo } from "react";
import { IProjectSkeletonCardProps } from "./projectSkeletonCard.props";
import {
  AvaAndButtonSkeletonContainer,
  AvaAndButtonSkeletonLeft,
  AvatarSkeleton,
  ButtonSkeletonLeft,
  ButtonSkeletonRight,
  SkeletonContainer,
  TitleAndParagraphSkeleton,
} from "./projectSkeletonCard.style";

const ProjectSkeletonCard: FC<IProjectSkeletonCardProps> = ({
  rowsNumber,
  ...props
}: IProjectSkeletonCardProps) => {
  return (
    <SkeletonContainer {...props}>
      <AvaAndButtonSkeletonContainer>
        <AvaAndButtonSkeletonLeft>
          <AvatarSkeleton type="avatar" active />
          <ButtonSkeletonLeft type="button" active />
        </AvaAndButtonSkeletonLeft>
        <ButtonSkeletonRight type="button" active />
      </AvaAndButtonSkeletonContainer>
      <TitleAndParagraphSkeleton active paragraph={{ rows: rowsNumber }} />
    </SkeletonContainer>
  );
};

export default memo(ProjectSkeletonCard);
