import { Modal, Radio } from "antd";
import styled from "styled-components";

export const AntdModal = styled(Modal)``;

export const ModalHeader = styled.div`
  display: flex;
  column-gap: 8px;
  align-items: center;
`;

export const ReportIconBlock = styled.div``;

export const ReportTitle = styled.div``;

export const ModalBody = styled.div``;

export const Content = styled.div``;

export const RadioGroup = styled(Radio.Group)`
  margin: 10px 10px;
`;
