// Output string of form xxx b or yyy k

import moment from "moment";
import { IPFS_CONFIG } from "./constant";
import { formatByFullDate } from "./validatorRules";
import { getMessageContent } from "./message";

// with only {digits} decimals
export function nFormatter(num: number, digits: number) {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
    : "0";
}

export function subDescription(desc: string) {
  if (desc.length > 350) {
    let i = 350;
    while (i >= 0 && desc[i] !== "\n" && desc[i] !== ".") {
      i--;
      if (i === 300) break;
    }
    return desc.slice(0, i);
  }
  return desc;
}

export function getDuration(milli: number) {
  let minutes = Math.floor(milli / 60000);
  let hours = Math.round(minutes / 60);
  let days = Math.round(hours / 24);

  return (
    (days && { value: days, unit: "days" }) ||
    (hours && { value: hours, unit: "hours" }) || {
      value: minutes,
      unit: "minutes",
    }
  );
}

export function getDurationByFullDateTime(start: string, end: string) {
  return getDurationMoment(start, end);
}

export function getDurationByDateTime(
  startDate: string,
  endDate: string,
  startTime: string,
  endTime: string
) {
  const start =
    moment(startDate).format(`${formatByFullDate} `) +
    moment.unix(+startTime).format("h:mm");
  const end =
    moment(endDate).format(`${formatByFullDate} `) +
    moment.unix(+endTime).format("h:mm");

  return getDurationMoment(start, end);
}

export const getDurationByRangeDate = (start: string, end: string) => {
  const diff = moment(end).diff(start);
  const duration = moment.duration(diff, "milliseconds");

  const years = duration.asYears();
  const remainingYears = years - parseInt(years.toString(), 10);
  const remainingYearsDuration = remainingYears * 365 * 24 * 60 * 60 * 1000;

  const months = moment
    .duration(remainingYearsDuration, "milliseconds")
    .asMonths();

  const remainingDate =
    parseInt(months.toString(), 10) + parseInt(years.toString(), 10) * 12;
  const durationDays2 = moment(end).diff(
    moment(start).add(remainingDate, "months")
  );

  const days = moment.duration(durationDays2).asDays();

  const h = (days - parseInt(days.toString(), 10)) * 24;
  const m = (h - parseInt(h.toString(), 10)) * 60;
  const s = (m - parseInt(m.toString(), 10)) * 60;

  return {
    years: parseInt(years.toString()),
    months: parseInt(months.toString()),
    days: parseInt(days.toString()),
    h: parseInt(h.toString()),
    m: parseInt(m.toString()),
    s: parseInt(s.toString()),
  };
};

const getDurationMoment = (start: string, end: string) => {
  let rs = "";

  const { years, months, days, h, m } = getDurationByRangeDate(start, end);

  if (years > 0) {
    rs = `${years} y `;
  }

  if (months > 0) {
    rs += `${months} m `;
  }

  if (days > 0) {
    rs += `${days} d `;
  }

  if (h > 0) {
    rs += `${h} hr `;
  }

  if (m > 0) {
    rs += `${m} m`;
  }

  return rs.trim();
};

export const sortArray = (arr: any, key: string, inc: boolean) => {
  const newArr = inc
    ? arr.sort(
        (a: any, b: any) => moment(a[key]).valueOf() - moment(b[key]).valueOf()
      )
    : arr.sort(
        (a: any, b: any) => moment(b[key]).valueOf() - moment(a[key]).valueOf()
      );

  return newArr;
};

export function AddCommas(num: number) {
  return num.toLocaleString("en-US", {
    maximumFractionDigits: 3,
  });
}

export function getPathByIpfs(path: string) {
  return `${IPFS_CONFIG.API_VIEW}/${path}`;
}

export function formatCurrency(path: number) {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(path);
}

export function abbreviateNumber(number) {
  var SI_SYMBOL = ["", "K", "M", "G", "T", "P", "E"];

  var tier = (Math.log10(Math.abs(number)) / 3) | 0;

  if (tier === 0) return number;

  var suffix = SI_SYMBOL[tier];
  var scale = Math.pow(10, tier * 3);

  var scaled = number / scale;

  return scaled.toFixed(1) + suffix;
}

export function getErrorBlockchain(error: any) {
  const messageBc = error.response?.data?.message.replaceAll("\\", "");

  const startSlice = messageBc.indexOf(`execution reverted: `) + 20;
  const endSlice = messageBc.indexOf(`","`);

  if (startSlice > 0 && endSlice > startSlice) {
    const rs = messageBc.substring(startSlice, endSlice);

    return rs;
  }

  return getMessageContent(error.response?.data?.message || error.message);
}

export function getOtherLink(link: string) {
  return link.startsWith("http://") || link.startsWith("https://")
    ? link
    : `//${link}`;
}

export const formatDecimals = (
  value: string = "",
  expectedDecimals: number = 6
) => {
  const decimals = value.split(".")[1];
  if (!decimals || decimals.length <= expectedDecimals) return value;

  return Number(value).toFixed(expectedDecimals);
};

export const analyzeDecimals = (value: string | number) => {
  const [integer, decimals] = value.toString().split(".");
  return {
    numInteger: integer.length,
    numDecimals: decimals?.length || 0,
    integer: Number(integer),
    decimals: Number(decimals),
  };
};

