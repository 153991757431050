import {
  ContactsOutlined,
  DashboardOutlined,
  KeyOutlined,
  SettingOutlined,
  ShopOutlined,
  UserOutlined,
} from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Menu } from "antd";
import { LogoAdmin } from "assets/common/svg/admin/LogoAdmin.icon";
import { LogoAdminFull } from "assets/common/svg/admin/LogoAdminFull.icon";
import { FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTER_CONSTANTS } from "utils/constant";
import { ISlidbarProps } from "./sidebar.props";
import { LogoMenuAd, SiderAdmin } from "./sidebar.style";

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[]
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

const Sidebar: FC<ISlidbarProps> = ({ collapsed, ...props }: ISlidbarProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const siderbarMenu = [
    ROUTER_CONSTANTS.ADMIN_PAGES.DASHBOARD,
    ROUTER_CONSTANTS.ADMIN_PAGES.PROJECTS,
    ROUTER_CONSTANTS.ADMIN_PAGES.USERS,
    ROUTER_CONSTANTS.ADMIN_PAGES.DATA_SETUPS,
    ROUTER_CONSTANTS.ADMIN_PAGES.DEFAULT_AVATARS,
    ROUTER_CONSTANTS.ADMIN_PAGES.LICENSES,
    ROUTER_CONSTANTS.ADMIN_PAGES.BLOGS,
  ];

  const items: MenuItem[] = [
    getItem(
      "Dashboard",
      ROUTER_CONSTANTS.ADMIN_PAGES.HOME,
      <DashboardOutlined />
    ),
    getItem(
      "Projects",
      ROUTER_CONSTANTS.ADMIN_PAGES.PROJECTS,
      <ShopOutlined />
    ),
    getItem("Users", ROUTER_CONSTANTS.ADMIN_PAGES.USERS, <UserOutlined />),
    getItem(
      "Data Setups",
      ROUTER_CONSTANTS.ADMIN_PAGES.DATA_SETUPS,
      <SettingOutlined />
    ),
    getItem(
      "Default Avatars",
      ROUTER_CONSTANTS.ADMIN_PAGES.DEFAULT_AVATARS,
      <ContactsOutlined />
    ),
    getItem("License", ROUTER_CONSTANTS.ADMIN_PAGES.LICENSES, <KeyOutlined />),
    getItem("Blog", ROUTER_CONSTANTS.ADMIN_PAGES.BLOGS, <KeyOutlined />),
  ];

  return (
    <SiderAdmin trigger={null} collapsible collapsed={collapsed} {...props}>
      <LogoMenuAd
        onClick={() => {
          navigate(ROUTER_CONSTANTS.HOME);
        }}
      >
        {collapsed ? <LogoAdmin /> : <LogoAdminFull />}
      </LogoMenuAd>
      <Menu
        theme="dark"
        mode="inline"
        defaultSelectedKeys={
          siderbarMenu.includes(location.pathname)
            ? [location.pathname]
            : [ROUTER_CONSTANTS.ADMIN_PAGES.HOME]
        }
        items={items}
        onClick={(item: any) => {
          navigate(item.key);
        }}
      />
    </SiderAdmin>
  );
};

export default Sidebar;

