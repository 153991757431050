import { ButtonGradient } from "components/base";
import ContainerFluidCustom from "components/base/containerFluidCustom";
import styled from "styled-components";

export const ErrorContainer = styled(ContainerFluidCustom)<{ margin?: string }>`
  text-align: center;
  ${({ margin }: any) => {
    return margin ? `margin: ${margin};` : `margin: 150px 0px 150px;`;
  }}
`;

export const Title = styled.p`
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 156%;
  text-align: center;
  color: #080a25;
  margin-bottom: 0px;
  margin-top: 40px;
`;

export const Content = styled.p`
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 156%;
  text-align: center;
  color: #080a25;
  opacity: 0.6;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

export const GoHomeButton = styled(ButtonGradient)`
  margin-top: 10px;
`;
