import { Layout } from "antd";
import { FC, memo, useState } from "react";
import FooterAdmin from "../footer";
import HeaderAdmin from "../header";
import Sidebar from "../sidebar";
import { IMasterLayoutAdminProps } from "./masterLayoutAdmin.props";
import { ContentBody, LayoutMaster } from "./masterLayoutAdmin.style";

const MasterLayoutAdmin: FC<IMasterLayoutAdminProps> = ({
  children,
  ...props
}: IMasterLayoutAdminProps) => {
  const [collapsed, setCollapsed] = useState(false);
  const isLoggedInAdmin = true;

  // privatea pages
  if (isLoggedInAdmin)
    return (
      <LayoutMaster {...props}>
        <Sidebar collapsed={collapsed} />
        <Layout className="site-layout">
          <HeaderAdmin collapsed={collapsed} setCollapsed={setCollapsed} />
          <ContentBody>{children}</ContentBody>
          <FooterAdmin />
        </Layout>
      </LayoutMaster>
    );

  // Public pages
  return <>{children}</>;
};

export default memo(MasterLayoutAdmin);
