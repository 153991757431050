import { configureStore } from "@reduxjs/toolkit";
import {
  authReducer,
  homeReducer,
  projectsReducer,
  bountyTypeReducer,
  profileReducer,
} from "./slices";

const store = configureStore({
  reducer: {
    authUser: authReducer,
    homeUser: homeReducer,
    projectsUser: projectsReducer,
    bountyTypeUser: bountyTypeReducer,
    profileUser: profileReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export default store;
