import { LikeOutlined } from "@ant-design/icons";
import { message } from "antd";
import { FC, memo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { VALIDATE_MESSAGES } from "utils/message";
import { IButtonUpvoteProps } from "./buttonUpvote.props";
import { ButtonUpvoteAntd } from "./buttonUpvote.style";

const ButtonUpvote: FC<IButtonUpvoteProps> = ({
  isVoted = false,
  upvotedQuantity,
  children,
  onClick,
  ...props
}: IButtonUpvoteProps) => {
  const { isLoggedIn } = useSelector((state: RootState) => state.authUser);
  const handleClick = (e: any) => {
    if (isLoggedIn && onClick) onClick(e);
    else message.warning(VALIDATE_MESSAGES.NOT_LOGIN);
  };
  return (
    <ButtonUpvoteAntd
      icon={<LikeOutlined />}
      $is_done={isVoted}
      onClick={handleClick}
      {...props}
    >
      {isVoted ? "Upvoted" : "Upvote"}
      {upvotedQuantity ? `(${upvotedQuantity})` : ``}
    </ButtonUpvoteAntd>
  );
};

export default memo(ButtonUpvote);
