import { SkeletonCustom } from "components/base";
import styled from "styled-components";

export const IssueSkeletonWrapper = styled.div`
  height: 83px;
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  column-gap: 20px;
  justify-content: space-between;
  align-items: center;
`;
export const LeftBlock = styled.div``;

export const RightBlock = styled.div`
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  row-gap: 6px;
`;

export const AvatarSkeleton = styled(SkeletonCustom)`
  .ant-skeleton-avatar {
    width: 56px;
    height: 56px;
  }
`;

export const IssueNameSkeleton = styled(SkeletonCustom)`
  .ant-skeleton-paragraph {
    margin-bottom: 2px;
    li {
      width: 100% !important;
      height: 18px;
    }
  }
`;

export const GithubNameSkeleton = styled(SkeletonCustom)`
  .ant-skeleton-paragraph {
    margin-bottom: 2px;
    li {
      width: 100% !important;
      height: 12px;
    }
  }
`;
