import { lazy } from "react";

const overrideLazy = (str: string) => {
  return lazy(() => {
    return new Promise((resolve) => {
      setTimeout(() => resolve(import(`${str}`)), 200);
    });
  });
};
/* PLOP EXPORT */
export const AboutUsPage = overrideLazy('./aboutUsPage');
export const LandingPage = overrideLazy('./landingPage');
export const PortfolioDetailPage = overrideLazy(
  "./profile/portfolioDetailPage"
);
export const AdvertDetailPage = overrideLazy("./advertDetailPage");
export const BlogDetailPage = overrideLazy("./blogDetailPage");
export const BlogsPage = overrideLazy("./blogsPage");
export const ReviewDeliverablesPage = overrideLazy(
  "./issues/reviewDeliverablesPage"
);
export const ProjectFinancePage = overrideLazy("./projects/projectFinance");
export const GovernanceDetailPage = overrideLazy(
  "./projects/projectGovernance/governanceDetail"
);
export const ProjectGovernancePage = overrideLazy(
  "./projects/projectGovernance"
);
export const BecomeSponsor = overrideLazy("./becomeSponsor");
export const EventDetailPage = overrideLazy(
  "./projects/projectEvent/eventDetailPage"
);
export const CreateEventPage = overrideLazy(
  "./projects/projectEvent/createEventPage"
);
export const ProfileUserInnerPage = overrideLazy("./profileUserInnerPage");
export const SupportTicket = overrideLazy("./supportTicket");
export const TransactionAndReward = overrideLazy("./transactionAndReward");
export const ClaimAllNftAndPoap = overrideLazy("./claimAllNftAndPoap");
export const DataSetupPage = overrideLazy("./dataSetupPage");
export const BountyTypePage = overrideLazy("./bountyTypePage");
export const ErrorCode = overrideLazy("./errorCode");
export const ChangePasswordPage = overrideLazy("./changePasswordPage");
export const ForgotPasswordPage = overrideLazy("./forgotPasswordPage");
export const VerifyEmailPage = overrideLazy("./verifyEmailPage");
export const CollectGuestInfoDialog = overrideLazy("./collectGuestInfoDialog");
export const Test = overrideLazy("./commonUi");
export const HomePage = overrideLazy("./homePage");
export const LoginPage = overrideLazy("./loginPage");
export const ProfilePage = overrideLazy("./profilePage");
export const ProjectGithubIssues = overrideLazy("./projects/githubIssues");
export const IssueExplorer = overrideLazy("./issues/issueExplorer");
export const IssueDetail = overrideLazy("./issues/issueDetail");
export const ProjectActivity = overrideLazy("./projects/projectActivity");
export const ProjectCommunity = overrideLazy("./projects/projectCommunity");
export const ProjectEvent = overrideLazy("./projects/projectEvent");
export const ProjectResources = overrideLazy("./projects/projectResources");
export const DiscussionDetail = overrideLazy(
  "./projects/projectResources/discussionDetail"
);
export const QuestionDetail = overrideLazy(
  "./projects/projectResources/questionDetail"
);
export const ProjectRewards = overrideLazy("./projects/projectRewards");
export const ProjectsAbout = overrideLazy("./projects/projectsAbout");
export const SignUpPage = overrideLazy("./signUpPage");
export const UserSignUpDialog = overrideLazy("./userSignUpDialog");
export const CardInfoIssue = overrideLazy("./projects/githubIssues");
export const Sponsorship = overrideLazy("./projects/sponsorship");
