import FontFaceObserver from "fontfaceobserver";
import { useLayoutEffect, useState } from "react";

const azoSansObserver = new FontFaceObserver("Poppins", {});
const avenirObserver = new FontFaceObserver("Rubik", {});

const timeout = process.env.REACT_APP_OVERLAY_TIME_OUT || 5000;

export function useFontObserver() {
  const [loaded, setLoaded] = useState(false);

  useLayoutEffect(() => {
    Promise.all([
      azoSansObserver.load(null, +timeout),
      avenirObserver.load(null, +timeout),
    ])
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setLoaded(true);
      });
  }, []);

  return loaded;
}

