export const LinkedlnIcon = (props: { style?: React.CSSProperties }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="#fff"
    viewBox="0 0 24 24"
  >
    <path d="M4.983 7.197c1.208 0 2.188-.98 2.188-2.188 0-1.208-.98-2.188-2.188-2.188-1.208 0-2.188.98-2.188 2.188 0 1.209.98 2.188 2.188 2.188zM9.238 8.855v12.139h3.769V14.99c0-1.584.298-3.118 2.262-3.118 1.937 0 1.96 1.81 1.96 3.218v5.904h3.772v-6.657c0-3.27-.704-5.783-4.526-5.783-1.835 0-3.065 1.007-3.568 1.96h-.051v-1.66H9.238zm-6.142 0H6.87v12.139H3.096V8.854z"></path>
  </svg>
);
