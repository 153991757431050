import { Button } from "antd";
import styled from "styled-components";

export const ButtonGradientAntd = styled(Button)`
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;

  background: linear-gradient(
    112.3deg,
    #a24df5 9.99%,
    #05a4ec 54.53%,
    #00de8c 100%
  );
  box-shadow: 0px 20px 32px rgba(97, 156, 253, 0.2);
  border-radius: 6px;
  color: white;
  width: 210px;
  height: 52px;

  &:hover,
  &:focus {
    background: linear-gradient(
        90.18deg,
        #c58aff 1.63%,
        #00adfb 64.37%,
        #00d5c9 93.96%,
        #00de8c 125.59%
      ),
      linear-gradient(
        96.1deg,
        #f9ec80 -46.81%,
        #f9ea7c -38.1%,
        #fae66e -27.85%,
        #fbde54 -16.82%,
        #fdd42c -5.32%,
        #ffce07 0.69%,
        #f57f20 68.1%
      );
    color: #efecec;
  }
`;
