export const THEME = {
  USER: {
    BACKGROUND_BODY_WHITE: "#FFFFFF",
    BACKGROUND_ITEM: "transparent",
    BORDER_RADIUS_CARD: "12px",
    BORDER_CARD: "1px solid rgba(8, 10, 37, 0.1)",
    CONTAINER_FLUID: "136px",
    CONTAINER_FLUID_NUM: 136,
    CONTAINER_WIDTH: "1170px",
    BACKGROUND_GRADIENT: `linear-gradient(90deg, #F47820 0%, #B007FF 24.48%, #423EFD 50%, #33CBEC 73.96%, #0FCB87 100%)`,
    WIDTH_FLUID: "840px",
    CUSTOM_SCROLL: `
    ::-webkit-scrollbar {
      width: 7px;
    }
    ::-webkit-scrollbar-track {
      background: rgba(8, 15, 108, 0.05);
    }
    ::-webkit-scrollbar-thumb {
      background: rgba(8, 15, 108, 0.08);
    }
    ::-webkit-scrollbar-thumb:hover {
      background: rgba(8, 15, 108, 0.12);
    }`,
  },
};
