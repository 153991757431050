import { WarningOutlined } from "@ant-design/icons";
import { Popconfirm, Space } from "antd";
import { EditIcon } from "assets/common/svg/users/profile-preview";
import { RoleIcon } from "assets/common/svg/users/profile-user-inner";
import { FC, memo } from "react";
import { IWorkExperienceProfileProps } from "./workExperienceProfile.props";
import {
  ButtonDelete,
  ButtonEdit,
  Desc,
  DescRole,
  ImageWorkExperiences,
  InfoContainer,
  InfoContainerName,
  InfoItem,
  LeftContainer,
  Name,
  NameCompany,
  RightContainer,
  WorkExperience,
} from "./workExperienceProfile.style";

const WorkExperienceProfile: FC<IWorkExperienceProfileProps> = ({
  data,
  onEdit,
  onDelete,
  ...props
}: IWorkExperienceProfileProps) => {
  return (
    <WorkExperience {...props}>
      <LeftContainer>
        <InfoItem>
          <ImageWorkExperiences>{<RoleIcon />}</ImageWorkExperiences>
          <InfoContainerName>
            <NameCompany
              style={{ maxWidth: 250 }}
              ellipsis={{ tooltip: data.name }}
            >
              {data.name}
            </NameCompany>
            <DescRole
              style={{ maxWidth: 250 }}
              ellipsis={{ tooltip: data.name }}
            >
              {data.desc}
            </DescRole>
          </InfoContainerName>
        </InfoItem>
        {data.from && (
          <>
            <InfoItem>
              <InfoContainer>
                <Name>From</Name>
                <Desc>{data.from}</Desc>
              </InfoContainer>
            </InfoItem>
            <InfoItem>
              <InfoContainer>
                <Name>To</Name>
                <Desc>{data.to}</Desc>
              </InfoContainer>
            </InfoItem>
          </>
        )}
      </LeftContainer>
      <RightContainer>
        <Space>
          <ButtonEdit
            icon={<EditIcon />}
            onClick={() => {
              if (onEdit) {
                onEdit(data.id);
              }
            }}
          >
            Edit
          </ButtonEdit>
          <Popconfirm
            title="Data cannot recover. Do you want to delete it?"
            onConfirm={() => {
              if (onDelete) {
                onDelete(data.id);
              }
            }}
            icon={<WarningOutlined />}
          >
            <ButtonDelete>Delete</ButtonDelete>
          </Popconfirm>
        </Space>
      </RightContainer>
    </WorkExperience>
  );
};

export default memo(WorkExperienceProfile);
