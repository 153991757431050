import {
  AppstoreOutlined,
  AudioOutlined,
  FileImageOutlined,
  FileOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import { useMutation } from "@tanstack/react-query";
import { Col, Modal, Row, Upload, UploadProps, message } from "antd";
import { RcFile } from "antd/es/upload";
import { UploadFile } from "antd/es/upload/interface";
import { FileType, IFile } from "api/users/portfolio";
import { postUploadsAPI } from "api/users/uploads";
import picturesImage from "assets/users/projects/add_project/pictures.png";
import axios from "axios";
import { FC, memo, useEffect, useRef, useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack5";
import { fileValidator, getBase64 } from "utils/fileValidator";
import { IUploadFileProps } from "./projectFile.props";
import {
  AntdDragger,
  DocumentBlock,
  DraggerBlock,
  ImageBlock,
  ImageUpload,
  MainTitle,
  PrimaryText,
  SubTitleIcon,
  SubTitleText,
  UploadContainer,
  UploadWrapper,
} from "./projectFile.style";

const ProjectFile: FC<IUploadFileProps> = ({
  onChangeFiles,
  files,
  ...props
}: IUploadFileProps) => {
  const [previewOpen, setPreviewOpen] = useState<boolean>(false);
  const [preview, setPreview] = useState("");
  const [previewTitle, setPreviewTitle] = useState<string>("");
  const [previewType, setPreviewType] = useState<string>("");
  const [numPages, setNumPages] = useState(null);
  const [pageNumber] = useState(1);
  const messageCount = useRef(0);

  const MAX_QUANTITY = {
    VIDEO: 2,
    AUDIO: 20,
  };
  const validator = fileValidator({
    strict: true,
    image: {
      acceptableFormat: ["jpg", "jpeg", "gif", "png"],
      limitDimension: 4000,
      limitSizeMB: 10,
    },
    video: {
      acceptableFormat: [
        "mp4",
        "webm",
        "video/mov",
        "ogm",
        "ogv",
        "video/quicktime",
        "quicktime",
        "mov",
      ],
      limitSizeMB: 100,
      limitDuration: 60,
    },
    audio: {
      acceptableFormat: ["mp3", "wav", "mpeg"],
      limitSizeMB: 10,
    },
    document: {
      acceptableFormat: ["pdf"],
      limitSizeMB: 10,
    },
  });

  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages);
  }

  const [fileList, setFileList] = useState<UploadFile[]>([]);

  useEffect(() => {
    const importedFileList: UploadFile[] = files?.map(
      (file: IFile, index: number) => {
        let fileType = "";

        switch (file.type) {
          case FileType.VIDEO:
            fileType = "video";
            break;
          case FileType.IMAGE:
            fileType = "image";
            break;
          case FileType.DOCUMENT:
            fileType = "application";
            break;
          case FileType.AUDIO:
            fileType = "audio";
            break;
          default:
            fileType = "file";
            break;
        }

        return {
          name: file.name,
          type: fileType,
          uid: `-${index}`,
          xhr: { url: file.url },
        };
      }
    );

    const newFiles: UploadFile[] = importedFileList.reduce((result, file) => {
      const existedFile = fileList.find((item) => item.name === file.name);
      if (!existedFile) result.push(file);

      return result;
    }, [] as UploadFile[]);

    setFileList([...fileList, ...newFiles]);

    // eslint-disable-next-line
  }, [files]);

  const { mutateAsync: postUploads } = useMutation(postUploadsAPI);

  const getQuantity = (fileList: any, fileType: string) => {
    return fileList.reduce((acc: number, file: any) => {
      if (file.type.includes(fileType)) {
        return acc + 1;
      }
      return acc;
    }, 0);
  };

  const handleCancel = () => {
    setPreview("");
    setPreviewType("");
    setPreviewOpen(false);
  };

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    if (
      file.type === "image/png" ||
      file.type === "image/gif" ||
      file.type === "image/jpeg"
    ) {
      setPreview(file?.xhr?.url || "");
      setPreviewType("image");
    }

    if (
      file.type === "audio/mp3" ||
      file.type === "audio/wav" ||
      file.type === "audio/mpeg"
    ) {
      setPreview(file?.xhr?.url || "");
      setPreviewType("audio");
    }
    if (
      file.type === "video/mp4" ||
      file.type === "video/x-ms-wmv" ||
      file.type === "video/quicktime"
    ) {
      setPreview(file?.xhr?.url || "");
      setPreviewType("video");
    }

    if (file.type === "application/pdf") {
      setPreview(file?.xhr?.url || "");
      setPreviewType("pdf");
    }
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };

  const customRequest: UploadProps["customRequest"] = async ({
    onProgress,
    file,
    onSuccess,
    onError,
  }: any) => {
    const rs = await postUploads({
      file_name: file.name.replace(" ", "_"),
      file_type: file.type,
    });

    await axios
      .put(rs.signedRequest, file, {
        onUploadProgress: (e) => {
          onProgress!({ percent: (e.loaded / e.total) * 100 }, file);
        },
      })
      .then((res) => {
        onSuccess(res.data, { url: rs.url });
      })
      .catch((error) => {
        onError(error);
      });
  };

  const exportFileList = (fileList: UploadFile[]) => {
    return fileList.map((file) => {
      const result: IFile = {
        name: file.name,
        url: file.xhr.url,
        type: FileType.DOCUMENT,
      };

      switch (file.type?.split("/")[0]) {
        case "video":
          result.type = FileType.VIDEO;
          break;
        case "image":
          result.type = FileType.IMAGE;
          break;
        case "application":
          result.type = FileType.DOCUMENT;
          break;
        case "audio":
          result.type = FileType.AUDIO;
          break;
        default:
          result.type = FileType.DOCUMENT;
          break;
      }

      return result;
    });
  };

  const handleChanged: UploadProps["onChange"] = ({
    fileList: newFileList,
  }) => {
    messageCount.current = 0;

    const uploadedFiles = newFileList
      .filter((fileItem) => {
        if (fileItem.status !== "done") return false;

        const existedFile = fileList.find(
          (item) => item.name === fileItem.name
        );
        if (existedFile?.status !== "done") return true;
        return false;
      })
      .map((fileItem) => {
        fileItem.name = `${Date.now()}_${fileItem.name.replace(" ", "_")}`;
        return fileItem;
      });

    const remainedFiles = files.filter((file) => {
      const existedFile = newFileList.find((item) => item.name === file.name);
      if (existedFile) return true;
      return false;
    });

    setFileList(newFileList);
    if (onChangeFiles)
      onChangeFiles([...remainedFiles, ...exportFileList(uploadedFiles)]);
  };

  const beforeUpload: UploadProps["beforeUpload"] = async (file, newList) => {
    const videoQuantity =
      getQuantity(fileList, "video") + getQuantity(newList, "video");
    const audioQuantity =
      getQuantity(fileList, "audio") + getQuantity(newList, "audio");

    if (videoQuantity > MAX_QUANTITY.VIDEO) {
      if (messageCount.current !== videoQuantity - 1) messageCount.current++;
      else messageCount.current = 0;
      if (messageCount.current === 1) {
        message.error(`You can only upload ${MAX_QUANTITY.VIDEO} video`);
      }
      return false;
    }

    if (audioQuantity > MAX_QUANTITY.AUDIO) {
      if (messageCount.current !== audioQuantity - 1) messageCount.current++;
      else messageCount.current = 0;
      if (messageCount.current === 1)
        message.error(`You can only upload ${MAX_QUANTITY.AUDIO} audio`);

      return false;
    }

    const validatorResult = await validator(file);

    if (!validatorResult.isValid) {
      message.error(validatorResult.error);
      return Upload.LIST_IGNORE;
    }

    return true;
  };

  return (
    <>
      <UploadContainer {...props}>
        <DraggerBlock>
          <AntdDragger
            isHaveFile={fileList.length > 0}
            multiple={true}
            listType="picture"
            fileList={fileList}
            beforeUpload={beforeUpload}
            customRequest={customRequest}
            onChange={handleChanged}
            onPreview={handlePreview}
            iconRender={(file) => {
              switch (file.type?.split("/")[0]) {
                case "video": // video
                case FileType.VIDEO:
                  return <VideoCameraOutlined />;
                case "image": // image
                case FileType.IMAGE:
                  return file.xhr ? (
                    <img src={file.xhr.url} alt="icon" />
                  ) : (
                    <FileImageOutlined />
                  );
                case "application": // application
                case FileType.DOCUMENT:
                  return <AppstoreOutlined />;
                case "audio": // audio
                case FileType.AUDIO:
                  return <AudioOutlined />;
                default:
                  return <FileOutlined />;
              }
            }}
          >
            <UploadWrapper isHaveFile={fileList.length > 0}>
              <ImageBlock isHaveFile={fileList.length > 0}>
                <ImageUpload src={picturesImage} />
              </ImageBlock>
              <MainTitle isHaveFile={fileList.length > 0}>
                Drag and drop or <PrimaryText> browse </PrimaryText>files
              </MainTitle>
              <Row gutter={[16, 8]}>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <SubTitleIcon>
                    <SubTitleText>
                      Images (.jpg, .gif, .png, up to 10 MB, no more than 4000px
                      in any dimension)
                    </SubTitleText>
                  </SubTitleIcon>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <SubTitleIcon>
                    <SubTitleText>
                      Videos (.mp4, up to 100 MB, 2 maximum, 60 seconds)
                    </SubTitleText>
                  </SubTitleIcon>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <SubTitleIcon>
                    <SubTitleText>
                      Audio (.mp3, .wav, up to 10 MB, 20 maximum)
                    </SubTitleText>
                  </SubTitleIcon>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <SubTitleIcon>
                    <SubTitleText>Document (.pdf, up to 10 MB)</SubTitleText>
                  </SubTitleIcon>
                </Col>
              </Row>
            </UploadWrapper>
          </AntdDragger>
        </DraggerBlock>

        <Modal
          visible={previewOpen}
          title={previewTitle}
          footer={false}
          onCancel={handleCancel}
          width={previewType === "pdf" ? "660px" : "400px"}
        >
          {preview && previewType === "image" && (
            <img alt="example" style={{ width: "100%" }} src={preview} />
          )}
          {preview && previewType === "audio" && (
            <audio controls>
              <source src={preview} type="audio/mpeg" />
            </audio>
          )}
          {preview && previewType === "video" && (
            <video width="100%" height="100%" controls>
              <source src={preview} type="video/mp4" />
              <object data={preview} width="470" height="255">
                <embed src={preview} width="470" height="255" />
              </object>
            </video>
          )}
          {preview && previewType === "pdf" && (
            <>
              <DocumentBlock>
                <Document file={preview} onLoadSuccess={onDocumentLoadSuccess}>
                  {Array.from(new Array(numPages), (el, index) => (
                    <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                  ))}
                  <Page pageNumber={pageNumber} />
                </Document>
              </DocumentBlock>
            </>
          )}
        </Modal>
      </UploadContainer>
    </>
  );
};

export default memo(ProjectFile);
