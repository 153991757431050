import axios from "api/axios";
import { ITagTbl } from "types";
import {
  ICreateQuestionsReq,
  IGetAllQuestionsReq,
  IQuestionResult,
  IUpdateQuestionReq,
} from "./types";

const END_POINT = {
  CREATE_QUESTIONS: "/questions",
  GET_ALL_QUESTIONS: "/questions/alls",
  GET_QUESTIONS_BY_ID: "/questions/:id",
  UPDATE_QUESTIONS_BY_ID: "/questions/:id",
  DELETE_QUESTIONS_BY_ID: "/questions/:id",
  GET_QUESTION_TAGS: "/questions/tags/:projectId",
};

export const createQuestionsAPI = async (params: ICreateQuestionsReq) => {
  const { data } = await axios({
    url: END_POINT.CREATE_QUESTIONS,
    method: "POST",
    data: params,
  });
  return data;
};

export const getAllQuestionsAPI = async (params: IGetAllQuestionsReq) => {
  const { data } = await axios({
    url: `${END_POINT.GET_ALL_QUESTIONS}`,
    method: "POST",
    data: params,
  });
  return data;
};

export const getQuestionByIdAPI = async (id: string) : Promise<IQuestionResult> => {
  const { data } = await axios({
    url: `${END_POINT.GET_QUESTIONS_BY_ID.replace(":id", id)}`,
    method: "GET",
  });
  return data;
};

export const updateQuestionByIdAPI = async (params: IUpdateQuestionReq) => {
  const data = await axios({
    url: `${END_POINT.UPDATE_QUESTIONS_BY_ID.replace(":id", params.id)}`,
    method: "PUT",
    data: params.updatedQuestion,
  });
  return data;
};

export const deleteQuestionByIdAPI = async (id: string) => {
  const data = await axios({
    url: `${END_POINT.DELETE_QUESTIONS_BY_ID.replace(":id", id)}`,
    method: "DELETE",
  });
  return data;
};

export const getQuestionTagsAPI = async (
  projectId: string
): Promise<ITagTbl[]> => {
  const { data } = await axios({
    url: `${END_POINT.GET_QUESTION_TAGS.replace(":projectId", projectId)}`,
    method: "GET",
  });
  return data;
};
