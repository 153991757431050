import { ButtonGradient } from "components/base";
import styled from "styled-components";

export const Footer = styled.div`
  margin-top: 20px;
  display: flex;
`;

export const TitleFooter = styled.div`
  color: #21202d;
  font-weight: 600;
`;

export const DecsFooter = styled.div``;

export const LeftFooter = styled.div`
  flex: 1 1 80%;
`;

export const RightFooter = styled.div`
  flex: 1 1 20%;
  display: flex;
  justify-content: end;
  align-items: center;
  margin-top: 20px;
`;

export const BtnGetInTouch = styled(ButtonGradient)`
  width: max-content;
  height: fit-content;
`;
