import axios from "api/axios";
import { IUploadsReq, IUploadsRes } from "./types";

const END_POINT = {
  UPLOADS: "/uploads/single",
};
export const postUploadsAPI = async (params: IUploadsReq): Promise<IUploadsRes> => {
  const { data } = await axios({
    url: END_POINT.UPLOADS,
    method: "POST",
    data: params,
  });
  return data;
};
