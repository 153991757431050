import styled from "styled-components";
import bg_datasetup from "assets/common/png/bg_datasetup.png";
export const Container = styled.div`
  background-image: url(${bg_datasetup});
  background-size: cover;
  border-radius: 24px 24px 0px 0px;
  padding-left: 26px;
  width: 100%;
  height: 80px;
  padding: 28px 32px 28px 26px;
  display: flex;
  align-items: flex-end;
  p {
    letter-spacing: -0.02em;
    color: #080a25;
    margin-right: 10px;
    font: normal 600 24px/100% "DM Sans";
  }
  justify-content: space-between;
  align-items: center;
`;

export const CurrentStep = styled.span`
  color: #7e00fd;
  margin-right: 2px;
  font: normal 600 16px/100% "DM Sans";
`;

export const SumStep = styled.span`
  font: normal 600 16px/100% "DM Sans";
  color: #080a2580;
  margin-right: 2px;
`;

export const WrapLeft = styled.div`
  display: flex;
  align-items: end;
`;

export const WrapRight = styled.div`
  display: flex;
  gap: 20px;
  span {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    display: flex;
    align-items: center;
    text-align: center;
  }
`;

export const AddCustomLink = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 194px;
  height: 40px;
  background: rgba(120, 120, 120, 0.3);
  border-radius: 6px;
  cursor: pointer;
  color: #080a25;
`;

