import { Button, Popover } from "antd";
import styled from "styled-components";
import { ButtonUpvoteAntd } from "../../base/buttonUpvote/buttonUpvote.style";

export const OptionsWrapper = styled.div`
  .ant-popover-inner-content {
    padding: unset;
  }
  .ant-popover-arrow {
    display: none !important;
  }
  .ant-popover-placement-bottom {
    padding: 0 !important;
  }
`;

export const ButtonOptionBlock = styled.div``;
export const ButtonOptionsAntd = styled(ButtonUpvoteAntd)``;

export const OptionsBlock = styled.div``;

export const PopoverAntd = styled(Popover)`
  border-radius: 4px;
`;

export const ButtonReportFlag = styled(Button)`
  width: 100%;
  padding: 20px 16px;
  box-shadow: none;
  border: none;
  color: rgba(117, 117, 117, 1);
  &:hover {
    color: rgba(117, 117, 117, 1);
    background: #f2f4fd;
  }
  &.ant-btn {
    display: flex;
    align-items: center;
    column-gap: 6px;
  }
  &.ant-btn {
    color: rgba(117, 117, 117, 1);
    &:hover,
    &:focus,
    &:active {
      color: rgba(117, 117, 117, 1);
    }
  }
  & > svg:first-child,
  span:first-child {
    font-size: 20px;
  }
`;
