import { Form, Radio, Space } from "antd";
import { ReportFlag } from "assets/common/svg/ReportFlag.icon";
import { FC, memo } from "react";
import { IModalReportProps } from "./modalReport.props";
import {
  AntdModal,
  Content,
  ModalBody,
  ModalHeader,
  RadioGroup,
  ReportIconBlock,
  ReportTitle,
} from "./modalReport.style";

const ModalReport: FC<IModalReportProps> = ({
  form,
  handleFinish,
  ...props
}: IModalReportProps) => {
  return (
    <AntdModal
      {...props}
      title={
        <ModalHeader>
          <ReportIconBlock>
            <ReportFlag />
          </ReportIconBlock>
          <ReportTitle>Report Post</ReportTitle>
        </ModalHeader>
      }
    >
      <ModalBody>
        <Content></Content>
        <Form form={form} onFinish={handleFinish}>
          <Form.Item name="report_content">
            <RadioGroup>
              <Space direction="vertical">
                <Radio value="1">Its propagating the wrong information.</Radio>
                <Radio value="2">Its abusive or harmful</Radio>
              </Space>
            </RadioGroup>
          </Form.Item>
        </Form>
      </ModalBody>
    </AntdModal>
  );
};

export default memo(ModalReport);
