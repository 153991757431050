import { Checkbox, Form, Radio, Space } from "antd";
import { GradientCheckmark } from "assets/common/svg/GradientCheckmark.icon";
import { ButtonGradient } from "components/base";
import {
  ModalFormItem,
  ModalTitle,
  StyledTextInput,
} from "components/base/commonModal/commonModal.style";
import useModal from "hooks/useModal";
import { FC } from "react";
import { ICollectGuestInfoDialogProps } from "./collectGuestInfoDialog.props";
import { Container, ContainerFlex } from "./collectGuestInfoDialog.style";

const CollectGuestInfoDialog: FC<ICollectGuestInfoDialogProps> = (
  props: ICollectGuestInfoDialogProps
) => {
  const { isShowing: submitted, toggle: toggleThankYou } = useModal();

  const onGuestSubmitted = () => {
    props.onSubmitInfo(true);
    toggleThankYou();
  };

  const formView = (
    <Container>
      <ModalTitle title="Please give us a bit more details" />
      <h4 style={{ marginBottom: "36px" }}>
        We take privacy seriously and will never spam you.
      </h4>
      <Form
        name="collectInfo"
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 28 }}
        layout="vertical"
        initialValues={{ remember: true }}
        onFinish={() => onGuestSubmitted()}
        onFinishFailed={() => onGuestSubmitted()}
        autoComplete="off"
      >
        <ModalFormItem
          label="Email Address"
          name="email"
          labelAlign="left"
          rules={[{ required: true, message: "Please input your email!" }]}
        >
          <StyledTextInput />
        </ModalFormItem>

        <ModalFormItem
          label="Full Name"
          name="fullname"
          labelAlign="left"
          style={{ marginBottom: "24px" }}
        >
          <StyledTextInput placeholder="Ali Ashraf Tuhin" />
        </ModalFormItem>

        <ModalFormItem
          label="You identify yourself as :)"
          name="guestType"
          valuePropName="guestType"
        >
          <Radio.Group>
            <Space direction="vertical">
              <Radio value="creator">Open Source Creator</Radio>
              <Radio value="contributor">Open Source Contributor</Radio>
              <Radio value="dev_business">Developer / Business</Radio>
            </Space>
          </Radio.Group>
        </ModalFormItem>

        <ModalFormItem name="newsletter" valuePropName="newsletter">
          <Checkbox>Sign me up for newsletter</Checkbox>
        </ModalFormItem>

        <ButtonGradient
          style={{ display: "block", width: "100%" }}
          type="primary"
          htmlType="submit"
        >
          Submit
        </ButtonGradient>
      </Form>
    </Container>
  );
  const thankYouView = (
    <ContainerFlex>
      <Container style={{ marginBottom: "32px" }}>
        <GradientCheckmark />
      </Container>
      <ModalTitle title="Thanks" style={{ textAlign: "center" }} />
      <h2 style={{ fontSize: "20px", textAlign: "center" }}>
        We are excited to get you onboard the early beta program.
      </h2>
      <h3>We shall soon send an invite your way.</h3>
    </ContainerFlex>
  );
  return submitted ? thankYouView : formView;
};

export default CollectGuestInfoDialog;
