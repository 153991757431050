import { FC, memo } from "react";
import { IAdsProjectsProps } from "./adsProjects.props";
import { Container, ContainerImage } from "./adsProjects.style";

const AdsProjects: FC<IAdsProjectsProps> = ({
  imgUrl,
  ...props
}: IAdsProjectsProps) => {
  return (
    <>
      {imgUrl ? (
        <ContainerImage src={imgUrl} {...props} />
      ) : (
        <Container {...props}>
          <span>Space for Ad</span>
        </Container>
      )}
    </>
  );
};

export default memo(AdsProjects);
