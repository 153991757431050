export const PreviewStepIcon = (props: { style?: React.CSSProperties }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.5 17L18.5 22L15 18.5L16.5 17L18.5 19L22 15.5L23.5 17ZM12 9C12.7956 9 13.5587 9.31607 14.1213 9.87868C14.6839 10.4413 15 11.2044 15 12C15 12.7956 14.6839 13.5587 14.1213 14.1213C13.5587 14.6839 12.7956 15 12 15C11.2044 15 10.4413 14.6839 9.87868 14.1213C9.31607 13.5587 9 12.7956 9 12C9 11.2044 9.31607 10.4413 9.87868 9.87868C10.4413 9.31607 11.2044 9 12 9ZM12 17C12.5 17 12.97 16.93 13.42 16.79C13.15 17.5 13 18.22 13 19V19.45L12 19.5C7 19.5 2.73 16.39 1 12C2.73 7.61 7 4.5 12 4.5C17 4.5 21.27 7.61 23 12C22.75 12.64 22.44 13.26 22.08 13.85C21.18 13.31 20.12 13 19 13C18.22 13 17.5 13.15 16.79 13.42C16.93 12.97 17 12.5 17 12C17 10.6739 16.4732 9.40215 15.5355 8.46447C14.5979 7.52678 13.3261 7 12 7C10.6739 7 9.40215 7.52678 8.46447 8.46447C7.52678 9.40215 7 10.6739 7 12C7 13.3261 7.52678 14.5979 8.46447 15.5355C9.40215 16.4732 10.6739 17 12 17Z"
      fill="white"
    />
  </svg>
);
