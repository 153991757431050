import { FC, memo } from "react";
import { IPaginationCustomProps } from "./paginationCustom.props";
import {
  PaginationContainer,
  PaginationSkeleton,
} from "./paginationCustom.style";

const PaginationCustom: FC<IPaginationCustomProps> = ({
  children,
  isLoading,
  ...props
}: IPaginationCustomProps) => {
  return (
    <PaginationContainer {...props}>
      {isLoading ? <PaginationSkeleton active /> : children}
    </PaginationContainer>
  );
};

export default memo(PaginationCustom);
