import { IPostCommentsTbl, IUserTbl } from "types";

enum SortBy {
  POPULAR = 0,
  LATEST = 1,
}

export interface IPostsCommentsReq {
  post_id?: number;
  comment_id?: number;
  status?: number;
  content?: string;
}

export interface IPostsCommentsAllReq {
  limit: number;
  page: number;
  sort_by: SortBy;
  post_id: number;
}

export interface IPostsCommentGetReq {
  id: number;
}

export interface IPostsCommentsChilReq extends IPostsCommentsAllReq {
  id: number;
}

export interface IPostsCommentsIdReq {
  id: number;
}
export interface IPutCommentsReq extends IPostsCommentsIdReq {
  content: string;
  status: number;
}

export interface IPostsCommentsRes {
  author_id?: number;
  post_id?: number;
  comment_id?: number;
  status?: number;
  content?: string;
}

export interface IPostCommentRes extends IPostCommentsTbl {
  user?: IUserTbl;
  count_replies?: string;
  count_upvotes?: string;
}

export interface IPostsCommentsAllsRes {
  page?: number;
  page_size?: number;
  total_page?: number;
  total_item?: number;
  result?: IPostCommentRes[];
  childComments?: IPostCommentRes[];
}

export interface IGetPostsCommentsByIdRes extends IPostCommentsTbl {
  upvotes?: [];
}
