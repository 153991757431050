import { SkeletonCustom } from "components/base";
import styled from "styled-components";

export const TitleSkeleton = styled(SkeletonCustom)`
  .ant-skeleton-paragraph {
    margin-bottom: 2px;
    li {
      height: 14px;
    }
  }
  padding: 0px 32px 0px 32px;
  margin-bottom: 30px;
`;

export const ButtonSkeletonLeft = styled(SkeletonCustom)`
  display: flex;
  .ant-skeleton-button {
    height: 16px;
    width: 100px;
    border-radius: 0px;
  }
`;

export const ButtonSkeletonRight = styled(SkeletonCustom)`
  display: flex;
  .ant-skeleton-button {
    height: 16px;
    width: 200px;
    border-radius: 0px;
  }
`;

export const AvatarSkeleton = styled(SkeletonCustom)`
  .ant-skeleton-avatar {
    width: 24px;
    height: 24px;
  }
`;

export const TitleAndParagraphSkeleton = styled(SkeletonCustom)`
  .ant-skeleton-paragraph {
    margin-bottom: 2px;
    li {
      height: 19.2px;
    }
  }
`;

export const TagLanguagesSkeleton = styled(SkeletonCustom)`
  .ant-skeleton-button {
    height: 26px;
    border-radius: 32px !important;
  }
`;

export const AvaAndButtonSkeletonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

export const AvaAndButtonSkeletonLeft = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 10px;
`;

export const SkeletonContainer = styled.div`
  padding: 24px;
  background: #ffffff;
  margin: 0px 0px 40px 0px;
  border: 1px solid rgba(8, 10, 37, 0.1);
  border-radius: 12px;
`;
