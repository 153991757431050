import { useMutation } from "@tanstack/react-query";
import { Button, Checkbox, Collapse, Row } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { getUserAPI } from "api/users/auth";
import { AxiosError } from "axios";
import { FC, memo, useRef, useState } from "react";
import { METHOD_API } from "../apiTest";
import ReactJson from "react-json-view";

const { Panel } = Collapse;

const UserAPI: FC<any> = () => {
  const [isShow, setIsShow] = useState<boolean>(true);
  const [userSuccess, setUserSuccess] = useState<any>();
  const [userErr, setUserErr] = useState<any>();

  const checkBox = useRef<any>(null);

  // Get user
  const { mutate: getUser, isLoading: isLoadingGetUser } = useMutation(
    getUserAPI,
    {
      onSuccess: async (rs: any) => {
        setUserSuccess(rs);
      },
      onError: (err: AxiosError) => {
        const data: any = err.response?.data;
        setUserErr(data);
      },
    }
  );

  return (
    <>
      <Row>
        <h1>
          <b>Users</b>
        </h1>
        <Checkbox
          ref={checkBox}
          onChange={(e: CheckboxChangeEvent) => {
            setIsShow(e.target.checked);
          }}
          style={{
            marginLeft: "10px",
          }}
          checked={isShow}
        />
      </Row>
      {isShow && (
        <Collapse accordion>
          <Panel header="/users/set-up-account" key="2" extra={METHOD_API.POST}>
            2
          </Panel>
          <Panel header="/users" key="3" extra={METHOD_API.PUT}>
            3
          </Panel>
          <Panel header="/users/profile" key="4" extra={METHOD_API.GET}>
            <Button
              loading={isLoadingGetUser}
              disabled={isLoadingGetUser}
              onClick={() => {
                setUserSuccess(undefined);
                setUserErr(undefined);
                getUser();
              }}
            >
              Try it out
            </Button>
            {userSuccess && <ReactJson src={userSuccess} />}
            {userErr && (
              <ReactJson
                theme={"greenscreen"}
                enableClipboard={false}
                displayDataTypes={false}
                src={userErr}
              />
            )}
          </Panel>
          <Panel
            header="/users/resend-verify-email"
            key="5"
            extra={METHOD_API.POST}
          >
            5
          </Panel>
        </Collapse>
      )}
    </>
  );
};

export default memo(UserAPI);
