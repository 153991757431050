import axios from "api/axios";
import { IResMetadata, ISuggestProject } from "./types";

const END_POINT = {
  GET_PROJECTS_METADATA: "/projects/:id/metadata",
  PUBLIC_ORGANIZATIONS: "/projects/public-organizations",
  ADD_NEW_PROJECT: "/projects/organization/public-repos",
};

export const getProjectsMetadataByIdAPI = async (
  id: string
): Promise<IResMetadata> => {
  const { data } = await axios({
    url: `${END_POINT.GET_PROJECTS_METADATA.replace(":id", id)}`,
    method: "GET",
  });
  return data;
};

export const getPublicOrganizationsAPI = async (): Promise<
  ISuggestProject[]
> => {
  const { data } = await axios({
    url: `${END_POINT.PUBLIC_ORGANIZATIONS}`,
    method: "GET",
  });
  return data;
};
