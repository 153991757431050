import { IProject } from "api/users/home";

export interface IProjectCardProps {
  cardInfo?: IProject;
  loading?: boolean | undefined;
}

export enum PROJECT_STATUS {
  DRAFT,
  LOADING,
  PROCESSING,
  ADMIN_REVIEW,
  ACTIVE,
  CANCELED,
  INACTIVE,
}
