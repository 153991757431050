// import { useMutation } from "@tanstack/react-query";
// import { message } from "antd";
// import { getPortfolioAdd } from "api/users/portfolio";
// import { IGetPortfolioAddRes } from "api/users/portfolio/types";
// import { AxiosError } from "axios";
import MasterLayout from "layout/users/masterLayout";
// import { useEffect, useState } from "react";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { IAddProjectEmptyProps } from "./addProjectEmpty.props";
import {
  BaseButtonGradient,
  ButtonBlock,
  Heading,
} from "./addProjectEmpty.style";
// import { getMessageContent } from "utils/message";
import { PlusOutlined } from "@ant-design/icons";
import errorProjectImage from "assets/common/png/error-project.png";
import { ResultsNotFound } from "components/module";
import { ROUTER_CONSTANTS } from "utils/constant";

const AddProjectEmpty: FC<IAddProjectEmptyProps> = () => {
  let navigate = useNavigate();

  // eslint-disable-next-line
  // const [portfolioAddRes, setPortfolioAddRes] = useState<IGetPortfolioAddRes>({
  //   suggested_skills: [],
  //   file_types: [],
  // });

  // const { mutate: fetchGetPortfolioAdd } = useMutation(getPortfolioAdd, {
  //   onSuccess: async (res: IGetPortfolioAddRes) => {
  //     setPortfolioAddRes(res);
  //   },
  //   onError: (err: AxiosError) => {
  //     message.error(getMessageContent(err?.message));
  //   },
  // });
  // useEffect(() => {
  //   fetchGetPortfolioAdd();
  //   // eslint-disable-next-line
  // }, []);

  return (
    <MasterLayout>
      <Heading>
        <div>Portfolio</div>
      </Heading>
      <ResultsNotFound
        img={errorProjectImage}
        title="Talent who add portfolios to their profile are more likely to win
        work. (20%)"
      />
      <ButtonBlock>
        <BaseButtonGradient
          onClick={() => {
            navigate(ROUTER_CONSTANTS.USER.ADD_PORTFOLIO);
          }}
        >
          <PlusOutlined />
          <span>Add a portfolio</span>
        </BaseButtonGradient>
      </ButtonBlock>
      {/* {portfolioAddRes?.opensoul_projects.length > 0 ? (
        <>
          <ResultsNotFound
            img={errorProjectImage}
            title="Talent who add portfolios to their profile are more likely to win
        work. (20%)"
          />
          <ButtonBlock>
            <BaseButtonGradient
              onClick={() => {
                navigate("/add-project");
              }}
            >
              <PlusOutlined />
              <span>Add a portfolio</span>
            </BaseButtonGradient>
          </ButtonBlock>
        </>
      ) : (
        <>
          <ResultsNotFound
            img={errorProjectImage}
            title="You have never participated in any project. Get involved in the project to have a basis for creating a portfolio."
          />
          <ButtonBlock>
            <BaseButtonGradient
              onClick={() => {
                navigate("/");
              }}
            >
              <span>Go to home page</span>
              <RightOutlined />
            </BaseButtonGradient>
          </ButtonBlock>
        </>
      )} */}
    </MasterLayout>
  );
};

export default AddProjectEmpty;
