import LoadingFullpage from "components/base/loadingFullpage";
import ScrollToTop from "components/base/scrollToTop";
import { FC, memo, ReactNode, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { ROUTER_CONSTANTS } from "utils/constant";
import {
  BlogAd,
  DataSetupAd,
  DefaultAvatarAd,
  HomeAd,
  LicenseAd,
  ProjectAd,
  SiginAd,
  UserAd,
} from "./admin";
import LoginAd from "./admin/auth/loginAd";
import BrowserRouter from "./browserRouter";
import RouteAdminAuth from "./routeAdminAuth";
import RouteAuth from "./routeAuth";
import {
  AdvertDetailPage,
  BecomeSponsor,
  BlogDetailPage,
  BlogsPage,
  BountyTypePage,
  ChangePasswordPage,
  ClaimAllNftAndPoap,
  CreateEventPage,
  DataSetupPage,
  DiscussionDetail,
  ErrorCode,
  ForgotPasswordPage,
  GovernanceDetailPage,
  HomePage,
  IssueDetail,
  IssueExplorer,
  LoginPage,
  ProfilePage,
  ProfileUserInnerPage,
  ProjectActivity,
  ProjectCommunity,
  ProjectEvent,
  ProjectFinancePage,
  ProjectGithubIssues,
  ProjectGovernancePage,
  ProjectResources,
  ProjectRewards,
  ProjectsAbout,
  QuestionDetail,
  ReviewDeliverablesPage,
  SignUpPage,
  Sponsorship,
  SupportTicket,
  Test,
  TransactionAndReward,
  VerifyEmailPage,
  PortfolioDetailPage,
  LandingPage,
  AboutUsPage,
} from "./users";
import AddProjectPage from "./users/addProjectPage";
import AddProjectEmpty from "./users/addProjectPage/addProjectEmpty";
import ProjectBounties from "./users/projects/projectBounties";
import CommonUi from "./users/commonUi";

interface IRoute {
  path: string;
  element: ReactNode;
}

const Router: FC = () => {
  const userRoutes: {
    publicRoutes: IRoute[];
    privateRoutes: IRoute[];
    publicRoutesAdmin: IRoute[];
    privateRoutesAdmin: IRoute[];
  } = {
    publicRoutes: [
      {
        path: "test",
        element: <Test />,
      },
      {
        path: "*",
        element: <h1>404 Not Found!</h1>,
      },
      {
        path: ROUTER_CONSTANTS.ERROR_CODE,
        element: <ErrorCode />,
      },
      {
        path: ROUTER_CONSTANTS.USER.COMMUNITY,
        element: <HomePage />,
      },
      {
        path: ROUTER_CONSTANTS.HOME,
        element: <LandingPage />,
      },
      {
        path: ROUTER_CONSTANTS.USER.ABOUT_US,
        element: <AboutUsPage />,
      },
      {
        path: ROUTER_CONSTANTS.USER.COMMON_UI,
        element: <CommonUi />,
      },
      {
        path: ROUTER_CONSTANTS.USER.SIGNUP,
        element: <SignUpPage />,
      },
      {
        path: ROUTER_CONSTANTS.USER.LOGIN,
        element: <LoginPage />,
      },
      {
        path: ROUTER_CONSTANTS.USER.ISSUE_EXPLORER,
        element: <IssueExplorer />,
      },
      {
        path: ROUTER_CONSTANTS.USER.PROJECTS.ABOUT,
        element: <ProjectsAbout />,
      },
      {
        path: ROUTER_CONSTANTS.USER.PROJECTS.REWARDS,
        element: <ProjectRewards />,
      },
      {
        path: ROUTER_CONSTANTS.USER.PROJECTS.GITHUB_ISSUES,
        element: <ProjectGithubIssues />,
      },
      {
        path: ROUTER_CONSTANTS.USER.PROJECTS.BOUNTIES,
        element: <ProjectBounties />,
      },
      {
        path: ROUTER_CONSTANTS.USER.PROJECTS.COMMUNITY,
        element: <ProjectCommunity />,
      },
      {
        path: ROUTER_CONSTANTS.USER.PROJECTS.ACTIVITY,
        element: <ProjectActivity />,
      },
      {
        path: ROUTER_CONSTANTS.USER.PROJECTS.RESOURCES,
        element: <ProjectResources />,
      },
      {
        path: ROUTER_CONSTANTS.USER.PROJECTS.EVENTS,
        element: <ProjectEvent />,
      },
      {
        path: ROUTER_CONSTANTS.USER.PROJECTS.SPONSOR,
        element: <Sponsorship />,
      },
      {
        path: ROUTER_CONSTANTS.USER.PROJECTS.DISCUSSION_DETAIL,
        element: <DiscussionDetail />,
      },
      {
        path: ROUTER_CONSTANTS.USER.PROJECTS.QUESTION_DETAIL,
        element: <QuestionDetail />,
      },
      {
        path: ROUTER_CONSTANTS.USER.VERIFY_EMAIL,
        element: <VerifyEmailPage />,
      },
      {
        path: ROUTER_CONSTANTS.USER.FORGOT_PASSWORD,
        element: <ForgotPasswordPage />,
      },
      {
        path: ROUTER_CONSTANTS.USER.CHANGE_PASSWORD,
        element: <ChangePasswordPage />,
      },
      {
        path: ROUTER_CONSTANTS.USER.PROFILE_PUBLIC,
        element: <ProfilePage />,
      },
      {
        path: ROUTER_CONSTANTS.USER.PROJECTS.GOVERNANCE,
        element: <ProjectGovernancePage />,
      },
      {
        path: ROUTER_CONSTANTS.USER.PROJECTS.FINANCE,
        element: <ProjectFinancePage />,
      },
      {
        path: ROUTER_CONSTANTS.USER.PROJECTS.GOVERNANCE_DETAIL,
        element: <GovernanceDetailPage />,
      },
      {
        path: ROUTER_CONSTANTS.USER.ISSUE_REVIEW_DELIVERABLES,
        element: <ReviewDeliverablesPage />,
      },
      {
        path: ROUTER_CONSTANTS.USER.BLOGS,
        element: <BlogsPage />,
      },
      {
        path: ROUTER_CONSTANTS.USER.BLOG_DETAIL,
        element: <BlogDetailPage />,
      },
      {
        path: ROUTER_CONSTANTS.USER.PROFILE,
        element: <ProfilePage />,
      },
      {
        path: ROUTER_CONSTANTS.USER.PROFILE_PORTFOLIO,
        element: <PortfolioDetailPage />,
      },
      {
        path: ROUTER_CONSTANTS.USER.ISSUE_EXPLORER_DETAIL,
        element: <IssueDetail />,
      },
    ],

    privateRoutes: [
      {
        path: ROUTER_CONSTANTS.USER.BECOME_SPONSOR,
        element: <BecomeSponsor />,
      },
      {
        path: ROUTER_CONSTANTS.USER.PROFILE_USER_INNER,
        element: <ProfileUserInnerPage />,
      },
      {
        path: ROUTER_CONSTANTS.USER.BOUNTY_UPDATE,
        element: <BountyTypePage />,
      },
      {
        path: ROUTER_CONSTANTS.USER.BOUNTY_CREATE,
        element: <BountyTypePage />,
      },

      {
        path: ROUTER_CONSTANTS.USER.DATA_SETUP,
        element: <DataSetupPage />,
      },
      {
        path: ROUTER_CONSTANTS.USER.SUPPORT_TICKET,
        element: <SupportTicket />,
      },
      {
        path: ROUTER_CONSTANTS.USER.TRANSACTION_REWARD,
        element: <TransactionAndReward />,
      },
      {
        path: ROUTER_CONSTANTS.USER.CLAIM_NFT_POAPS,
        element: <ClaimAllNftAndPoap />,
      },
      {
        path: ROUTER_CONSTANTS.USER.ADD_PORTFOLIO,
        element: <AddProjectPage />,
      },
      {
        path: ROUTER_CONSTANTS.USER.UPDATE_PORTFOLIO,
        element: <AddProjectPage />,
      },
      {
        path: ROUTER_CONSTANTS.USER.ADD_PROJECT_EMPTY,
        element: <AddProjectEmpty />,
      },
      {
        path: ROUTER_CONSTANTS.USER.PROJECTS.CREATE_EVENTS,
        element: <CreateEventPage />,
      },
      {
        path: ROUTER_CONSTANTS.USER.ADVERT_DETAIL,
        element: <AdvertDetailPage />,
      },
    ],

    privateRoutesAdmin: [
      {
        path: ROUTER_CONSTANTS.ADMIN_PAGES.HOME,
        element: <HomeAd />,
      },
      {
        path: ROUTER_CONSTANTS.ADMIN_PAGES.PROJECTS,
        element: <ProjectAd />,
      },
      {
        path: ROUTER_CONSTANTS.ADMIN_PAGES.USERS,
        element: <UserAd />,
      },
      {
        path: ROUTER_CONSTANTS.ADMIN_PAGES.DATA_SETUPS,
        element: <DataSetupAd />,
      },
      {
        path: ROUTER_CONSTANTS.ADMIN_PAGES.DEFAULT_AVATARS,
        element: <DefaultAvatarAd />,
      },
      {
        path: ROUTER_CONSTANTS.ADMIN_PAGES.LICENSES,
        element: <LicenseAd />,
      },
      {
        path: ROUTER_CONSTANTS.ADMIN_PAGES.BLOGS,
        element: <BlogAd />,
      },
    ],

    publicRoutesAdmin: [
      {
        path: ROUTER_CONSTANTS.ADMIN_PAGES.LOGIN,
        element: <LoginAd />,
      },
      {
        path: ROUTER_CONSTANTS.ADMIN_PAGES.SIGIN,
        element: <SiginAd />,
      },
    ],
  };

  const renderLazy = (elm: ReactNode) => {
    return <Suspense fallback={<LoadingFullpage />}>{elm}</Suspense>;
  };

  const renderRoutes = (userRoutes: {
    publicRoutes: IRoute[];
    privateRoutes: IRoute[];
    publicRoutesAdmin: IRoute[];
    privateRoutesAdmin: IRoute[];
  }) => {
    let routesElm: ReactNode[] = [];

    for (const key in userRoutes) {
      switch (key) {
        case "publicRoutes":
        case "publicRoutesAdmin":
          routesElm.push(
            userRoutes.publicRoutes.map((item: IRoute, key: number) => (
              <Route
                path={item.path}
                element={renderLazy(item.element)}
                key={`publicRoutes-${key}`}
              />
            ))
          );
          routesElm.push(
            userRoutes.publicRoutesAdmin.map((item: IRoute, key: number) => (
              <Route
                path={item.path}
                element={renderLazy(item.element)}
                key={`publicRoutesAdmin-${key}`}
              />
            ))
          );
          break;
        case "privateRoutes":
          routesElm.push(
            <Route element={<RouteAuth />} key={key}>
              {userRoutes.privateRoutes.map((item: IRoute, key: number) => (
                <Route
                  path={item.path}
                  element={renderLazy(item.element)}
                  key={`privateRoutes-${key}`}
                />
              ))}
            </Route>
          );
          break;
        case "privateRoutesAdmin":
          routesElm.push(
            <Route element={<RouteAdminAuth />} key={key}>
              {userRoutes.privateRoutesAdmin.map(
                (item: IRoute, key: number) => (
                  <Route
                    path={item.path}
                    element={renderLazy(item.element)}
                    key={`privateRoutesAdmin-${key}`}
                  />
                )
              )}
            </Route>
          );
          break;
        default:
          break;
      }
    }
    return routesElm;
  };

  return (
    <>
      <LoadingFullpage />
      <BrowserRouter>
        <ScrollToTop />
        <Routes>{renderRoutes(userRoutes)}</Routes>
      </BrowserRouter>
    </>
  );
};

export default memo(Router);
