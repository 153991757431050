import { useMutation } from "@tanstack/react-query";
import {
  IListBountiesRes,
  getAllBountiesByProjectIdAPI,
} from "api/users/bounties";
import { PaginationCustom, ResultsNotFound } from "components/module";
import MasterLayout from "layout/users/masterLayout";
import {
  ApplicantsBlock,
  ApplicantsIcon,
  ApplicantsText,
  IssueBlock,
  IssueInfo,
  IssueName,
  IssueStatus,
  IssueStatusBlock,
  IssueTable,
  LevelBlock,
  LevelIcon,
  LevelText,
  ProjectAvatar,
  ProjectName,
  StatusText,
  TableColumn,
  TypeBlock,
  TypeIcon,
  TypeText,
} from "pages/users/issues/issueExplorer/issueExplorer.style";
import IssueSkeleton from "pages/users/issues/issueExplorer/issueSkeleton";
import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { RootState } from "redux/store";
import { IBountyTbl } from "types";
import { ROUTER_CONSTANTS } from "utils/constant";
import { IProjectBountiesProps } from "./projectBounties.props";
import {
  BodyBounty,
  ButtonCreateBounty,
  HeaderBounty,
  HeaderBountyInfo,
  HeaderBountyInfoDesc,
  HeaderBountyInfoTitle,
} from "./projectBounties.style";
import errorResourcesIcon from "assets/users/svg/error_my_resource.svg";
import timeUtils from "utils/time";
import { BountyStatus } from "pages/users/bountyTypePage/bountyTypePage.props";
import { Pagination } from "antd";

const ProjectBounties: FC<IProjectBountiesProps> = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [bounties, setBounties] = useState<IListBountiesRes>();
  const initParams = {
    page: 1,
    limit: 10,
    sort_by: 0,
    project_id: +id!,
  };
  const [params, setParams] = useState(initParams);

  const TYPE_COLOR = "rgba(189, 0, 255, 1)";

  const getLevelColor = (name: string) => {
    let color = "rgba(107, 137, 255, 1)";
    switch (name) {
      case "Beginner":
        color = "rgba(115, 137, 149, 1)";
        break;
      case "Intermediate":
        color = "rgba(125, 151, 253, 1)";
        break;
      case "Advanced":
        color = "rgba(245, 127, 32, 1)";
        break;
      default:
        break;
    }
    return color;
  };

  const { about: projectInfo } = useSelector(
    (state: RootState) => state.projectsUser
  );

  const { mutate: fetchGetAllBounties, isLoading } = useMutation(
    getAllBountiesByProjectIdAPI,
    {
      onSuccess: (res) => {
        setBounties(res);
      },
    }
  );

  const onBountyClicked = (bounty: IBountyTbl) => {
    navigate(
      generatePath(ROUTER_CONSTANTS.USER.ISSUE_EXPLORER_DETAIL, {
        projectId: id,
        bountyId: bounty.id.toString(),
      })
    );
    // eslint-disable-next-line
  };

  useEffect(() => {
    fetchGetAllBounties(params);
    // eslint-disable-next-line
  }, [params]);

  const bountyStatusMap: Record<BountyStatus, any> = {
    [BountyStatus.DRAFT]: {
      text: "Draft",
      color: "#080a25",
    },
    [BountyStatus.PENDING]: {
      text: "Pending",
      color: "#F47820",
    },
    [BountyStatus.PROCESSING]: {
      text: "Processing",
      color: "#7e00fd",
    },
    [BountyStatus.OPEN]: {
      text: "Open",
      color: "#00DE8C",
    },
    [BountyStatus.STARTED]: {
      text: "Started",
      color: "#7e00fd",
    },
    [BountyStatus.SUBMITTED]: {
      text: "Submitted",
      color: "#F47820",
    },
    [BountyStatus.FINISHED]: {
      text: "Finished",
      color: "#00DE8C",
    },
    [BountyStatus.EXPIRED]: {
      text: "Expired",
      color: "#FC2929",
    },
    [BountyStatus.CLOSING]: {
      text: "Closing",
      color: "#F47820",
    },
    [BountyStatus.PENDING_CLOSE]: {
      text: "Pending Close",
      color: "#F47820",
    },
    [BountyStatus.CANCELED]: {
      text: "Canceled",
      color: "#FC2929",
    },
  };

  return (
    <MasterLayout showHeaderProjects showSidebarProjects isFluid>
      <HeaderBounty>
        <HeaderBountyInfo>
          <HeaderBountyInfoTitle>All Bounties</HeaderBountyInfoTitle>
          <HeaderBountyInfoDesc>
            Bounties for project issues!
          </HeaderBountyInfoDesc>
        </HeaderBountyInfo>
        {projectInfo.is_joined_community && (
          <ButtonCreateBounty
            onClick={() => {
              navigate(
                ROUTER_CONSTANTS.USER.BOUNTY_CREATE.replace(":id", `${id}`)
              );
            }}
          >
            Create Bounty
          </ButtonCreateBounty>
        )}
      </HeaderBounty>

      <BodyBounty>
        <IssueTable
          dataSource={bounties?.result}
          pagination={false}
          rowKey="id"
          locale={{
            emptyText: isLoading ? (
              [...Array(10)].map((_, index: number) => (
                <IssueSkeleton isLoading={true} key={index}></IssueSkeleton>
              ))
            ) : (
              <ResultsNotFound
                img={errorResourcesIcon}
                title="No results found"
              />
            ),
          }}
          onRow={(bounty: any) => {
            return {
              onClick: () => {
                onBountyClicked(bounty);
              },
            };
          }}
        >
          <TableColumn
            title="Project"
            render={(record: IBountyTbl) => (
              <IssueBlock>
                <ProjectAvatar
                  src={record?.project?.avatar_url}
                ></ProjectAvatar>
                <IssueInfo>
                  <IssueStatusBlock>
                    <ProjectName>{record?.project?.github_name}</ProjectName>
                    <IssueStatus
                      color={
                        Number.isInteger(record.status) &&
                        bountyStatusMap[record.status!].color
                      }
                    >
                      {Number.isInteger(record.status) &&
                        bountyStatusMap[record.status!].text}
                    </IssueStatus>
                  </IssueStatusBlock>
                  <IssueName>{record.title}</IssueName>
                </IssueInfo>
              </IssueBlock>
            )}
          />
          <TableColumn
            title="Type"
            render={(record: IBountyTbl) => {
              return record.bounty_type_other || record.type?.name ? (
                <TypeBlock color={TYPE_COLOR}>
                  <TypeIcon color={TYPE_COLOR}></TypeIcon>
                  <TypeText color={TYPE_COLOR}>
                    {record.bounty_type_other || record.type?.name || ""}
                  </TypeText>
                </TypeBlock>
              ) : (
                <> - </>
              );
            }}
          />
          <TableColumn
            title="Level"
            render={(record: any) => {
              return record.bounty_experience_level_id ? (
                <LevelBlock
                  color={getLevelColor(record.bounty_experience_level?.name)}
                >
                  <LevelIcon
                    color={getLevelColor(record.bounty_experience_level?.name)}
                  ></LevelIcon>
                  <LevelText
                    color={getLevelColor(record.bounty_experience_level?.name)}
                  >
                    {record.bounty_experience_level?.name || ""}
                  </LevelText>
                </LevelBlock>
              ) : (
                <> - </>
              );
            }}
          />
          <TableColumn
            title="Status"
            render={(record: IBountyTbl) => (
              <StatusText>
                {"Opened " +
                  timeUtils.getTimeSince(new Date(record.created_at || 0))}
              </StatusText>
            )}
          />
          <TableColumn
            title="Applicants"
            render={(record: IBountyTbl) => (
              <ApplicantsBlock>
                <ApplicantsText>
                  {record.deliverables?.length || 0}
                </ApplicantsText>
                <ApplicantsIcon />
              </ApplicantsBlock>
            )}
          />
        </IssueTable>

        {bounties && bounties.total_item > bounties.page_size && (
          <PaginationCustom>
            <Pagination
              defaultCurrent={bounties?.page}
              total={bounties?.total_item}
              pageSize={bounties.page_size}
              showSizeChanger={false}
              onChange={(page: number) => {
                setParams({
                  ...params,
                  page: page,
                });
              }}
            />
          </PaginationCustom>
        )}
      </BodyBounty>
    </MasterLayout>
  );
};

export default ProjectBounties;
