import { Badge } from "antd";
import Logo from "assets/users/svg/bg_header_projects.svg";
import { ButtonGradient, SkeletonCustom } from "components/base";
import styled from "styled-components";

export const BackgroundContainer = styled.div`
  background-image: url(${Logo});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 120px;
  position: relative;
  font-family: "Rubik";
  font-style: normal;
  margin-bottom: 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: 1s;
    background-image: linear-gradient(transparent, #fff);
  }

  @media (max-width: 992px) {
    height: auto;
  }
`;

export const BalanceButton = styled(ButtonGradient)`
  height: 38px;
  width: auto;
`;

export const StatusBadge = styled(Badge)`
  > sup {
    background: #7e00fd;
  }
`;

export const TechnologyBadge = styled(Badge)`
  > sup {
    background: rgba(8, 10, 37, 0.08);
    border: 1px solid rgba(8, 10, 37, 0.16);
    border-radius: 20px;
    color: #080a25;
  }
`;

export const Unit = styled.span`
  font-weight: 600;
  color: #7e00fd;
`;

export const Container = styled.div`
  margin: 0px 136px 0px 136px;
  padding: 16px 0;
  & > .ant-row {
    flex-flow: unset;
    align-items: center;
    justify-content: space-between;
  }
`;

export const Heading = styled.h1`
  font-size: 32px;
  font-weight: 500;
  margin: 0;
`;

export const SkeletonThumbnail = styled(SkeletonCustom)`
  .ant-skeleton-image {
    width: 64px;
    height: 64px;
  }
`;

export const SkeletonTitle = styled(SkeletonCustom)`
  .ant-skeleton-title {
    height: 26px;
    max-width: 256px;
    margin-bottom: 10px;
  }
`;
