import { FC } from "react";
import { ICardSocialProps } from "./cardSocial.props";
import { CardSocialContainer, Header, Icon, Title } from "./cardSocial.style";

const CardSocial: FC<ICardSocialProps> = ({
  children,
  title,
  icon,
  ...props
}: ICardSocialProps) => {
  return (
    <CardSocialContainer {...props}>
      <Header>
        <Title>{title}</Title>
        <Icon>{icon}</Icon>
      </Header>
      {children}
    </CardSocialContainer>
  );
};

export default CardSocial;
