import { useMutation } from "@tanstack/react-query";
import { Spin, message } from "antd";
import {
  useGetPortfolioAddRes,
  useGetPortfolioById,
} from "api/users/portfolio/queries";
import {
  createPortfolio,
  updatePortfolioById,
} from "api/users/portfolio/request";
import {
  ICreatePortfolioReq,
  IGetPortfolioByIdRes,
} from "api/users/portfolio/types";
import { AddProjectStepIcon } from "assets/users/projects/add_project/AddProjectStep.icon";
import MasterLayout from "layout/users/masterLayout";
import { FC, memo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IAddProjectPageProps, ISkill } from "./addProjectPage.props";
import { StepBody, StepContainer } from "./addProjectPage.style";
import AddProjectStep from "./addProjectStep";
import Steps from "./steps";
import { getMessageContent, NOTIFICATION } from "utils/message";
import { PreviewStepIcon } from "assets/users/projects/add_project/PreviewStep.icon";
import { CompletedStepIcon } from "assets/users/projects/add_project/CompleteStep.icon";
import PreviewProjectStep from "./previewProjectStep";
import CompletedProjectStep from "./addProjectStep/completedProjectStep";

export interface IStepConfig {
  id: number;
  text: string;
  icon: any;
  isShownProgress?: boolean;
}

export const STEPS_CONFIG: IStepConfig[] = [
  {
    id: 1,
    text: "Add project details",
    icon: <AddProjectStepIcon />,
    isShownProgress: true,
  },
  {
    id: 2,
    text: "Preview",
    icon: <PreviewStepIcon />,
    isShownProgress: true,
  },
  {
    id: 3,
    text: "Completed",
    icon: <CompletedStepIcon />,
    isShownProgress: false,
  },
];

const AddProjectPage: FC<IAddProjectPageProps> = (
  props: IAddProjectPageProps
) => {
  let navigate = useNavigate();
  const { id } = useParams();
  const [projectFormData, setProjectFormData] = useState<ICreatePortfolioReq>({
    title: "",
    thumbnail_url: "",
    description: "",
    files: [],
    skills: [],
    website: "",
    embedded_video_urls: [],
  });
  const [suggestedSkills, setSuggestedSkills] = useState<ISkill[]>([]);
  const [doneStep, setDoneStep] = useState<number>(0);
  const [currentStep, setCurrentStep] = useState<number>(1);
  const { isFetching: isFetchingPortfolio } = useGetPortfolioAddRes({
    onSuccess: (res) => {
      const suggestedSkillsRes = res.suggested_skills.map((skill) => ({
        value: skill.value,
        isSelected: false,
      }));
      setSuggestedSkills(suggestedSkillsRes);
    },
    onError: (error: any) => {
      message.error(getMessageContent(error?.message));
    },
  });
  const { isFetching: isFetchPortfolioById } = useGetPortfolioById(
    { id: `${id}` },
    {
      onSuccess: (res: IGetPortfolioByIdRes) => {
        setProjectFormData({
          title: res?.title,
          thumbnail_url: res?.thumbnail_url,
          cover_img_url: res?.cover_img_url,
          description: res?.description,
          files: res?.files || [],
          skills: res?.skills || [],
          website: res?.website,
          embedded_video_urls: res?.embedded_video_urls,
        });
      },
      onError: (error: any) => {
        message.error(getMessageContent(error?.message));
      },
      enabled: !!id,
    }
  );

  const setNextStep = () => {
    setDoneStep(currentStep! < doneStep ? doneStep : currentStep!);
    setCurrentStep(currentStep! + 1);
  };
  const jumpStep = (id: number) => {
    if (id < doneStep + 1) {
      setCurrentStep(id);
    }
  };
  const { mutateAsync: fetchCreatePortfolio } = useMutation(createPortfolio, {
    onSuccess: () => {
      message.success(NOTIFICATION.CREATE_SUCCESS);
      setCurrentStep(3);
    },
    onError: (error: any) => {
      message.error(getMessageContent(error?.message));
    },
  });
  const { mutateAsync: fetchUpdatePortfolioById, isLoading: isUpdating } =
    useMutation(updatePortfolioById, {
      onSuccess: () => {
        message.success(NOTIFICATION.UPDATE_SUCCESS);
        navigate("/profile");
      },
      onError: (error: any) => {
        message.error(getMessageContent(error?.message));
      },
    });

  const onPrevStep = () => {
    setCurrentStep(currentStep! - 1);
  };

  const onFinishStep = async (newPortfolio: ICreatePortfolioReq) => {
    switch (currentStep) {
      case 2:
        if (window.location.href.includes("create")) {
          await fetchCreatePortfolio(newPortfolio);
        }
        if (window.location.href.includes("update")) {
          await fetchUpdatePortfolioById({
            id: +id!,
            body: { ...newPortfolio },
          });
        }
        break;

      case 3:
        navigate("/profile");
        return;

      default:
        break;
    }

    setProjectFormData(newPortfolio);
    setNextStep();
  };

  return (
    <MasterLayout>
      <StepContainer {...props}>
        <Steps
          configs={STEPS_CONFIG}
          currentStep={currentStep}
          doneStep={doneStep}
          onClick={jumpStep}
        />
        <StepBody>
          <Spin
            spinning={isFetchingPortfolio || isFetchPortfolioById || isUpdating}
          >
            {(() => {
              switch (currentStep) {
                case 1:
                  return (
                    <AddProjectStep
                      projectFormData={projectFormData}
                      onFinishStep={onFinishStep}
                      suggestedSkills={suggestedSkills}
                      setSuggestedSkills={setSuggestedSkills}
                    />
                  );

                case 2:
                  return (
                    <PreviewProjectStep
                      projectFormData={projectFormData}
                      onFinishStep={onFinishStep}
                      onPrevStep={onPrevStep}
                    />
                  );

                case 3:
                  return (
                    <CompletedProjectStep
                      projectFormData={projectFormData}
                      onFinishStep={onFinishStep}
                    />
                  );

                default:
                  return <></>;
              }
            })()}
          </Spin>
        </StepBody>
      </StepContainer>
    </MasterLayout>
  );
};

export default memo(AddProjectPage);
