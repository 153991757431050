import { FC } from "react";
import { IIssueSkeletonProps } from "./issueSkeleton.props";
import {
  AvatarSkeleton,
  GithubNameSkeleton,
  IssueNameSkeleton,
  IssueSkeletonWrapper,
  LeftBlock,
  RightBlock,
} from "./issueSkeleton.style";

const IssueSkeleton: FC<IIssueSkeletonProps> = ({
  isLoading,
  ...props
}: IIssueSkeletonProps) => {
  return (
    <IssueSkeletonWrapper>
      <LeftBlock>
        <AvatarSkeleton active type="avatar" />
      </LeftBlock>
      <RightBlock>
        <GithubNameSkeleton
          active={true}
          paragraph={{ rows: 1 }}
          title={false}
        />
        <IssueNameSkeleton
          active={true}
          paragraph={{ rows: 1 }}
          title={false}
        />
      </RightBlock>
    </IssueSkeletonWrapper>
  );
};

export default IssueSkeleton;
