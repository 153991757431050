import styled from "styled-components";

import { Form, Input, Modal } from "antd";
import { CSSProperties } from "styled-components";
import { Descriptions } from "antd";

export const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 16px;
    button.ant-modal-close {
      border-radius: 50%;
      top: 15px;
      right: 15px;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      & > span {
        width: inherit;
        height: inherit;
        display: flex;
      }
    }

    .ant-modal-body {
      padding: 40px;
    }
  }
`;

export const ModalTitle = styled(Descriptions)`
  .ant-descriptions-header {
    margin-bottom: 12px;

    .ant-descriptions-title {
      font-size: 24px;
    }
  }
`;

export const ModalFormItem = styled(Form.Item)`
  margin-bottom: 20px;

  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
  }

  .ant-form-item-label > label.ant-form-item-required::after {
    display: inline-block;
    margin-left: 4px;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    line-height: 1;
    content: "*";
  }
`;

export const StyledTextInput = styled(Input)`
  border-radius: 8px;
  padding: 8px 12px;
`;

export const StyledPasswordInput = styled(Input.Password)`
  border-radius: 8px;
  padding: 8px 12px;
`;

export const ModalMaskStyle = {
  backgroundColor: "rgba(8, 10, 37, 0.8)",
} as CSSProperties;
