import { Col, Row } from "antd";
import Logo from "assets/users/svg/bg_header_projects.svg";
import { ButtonGradient, ContainerFluidCustom } from "components/base";
import styled from "styled-components";

export const BackgroundContainer = styled.div`
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%),
    url(${Logo});
  height: 150px;
  position: relative;
  font-family: "Rubik";
  font-style: normal;
  margin-bottom: 20px;
  background-repeat: repeat;
  background-size: cover;
`;

export const SubHeaderProjectsImg = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 24px;
`;

export const SubHeader = styled(Row)`
  position: relative;
`;

export const ContainerInfoProject = styled(ContainerFluidCustom)`
  top: 34%;
  position: absolute;
  width: calc(100% - 272px);
  border-radius: 24px;
`;

export const Avatar = styled.img`
  width: 68px;
  height: 68px;
  background: #e6e6e6;
  border-radius: 50%;
  margin: 16px 16px;
`;

export const InfoProject = styled(Col)`
  display: flex;
  align-items: center;
`;

export const InfoNameProject = styled.div`
  height: 60%;
  display: grid;
  align-items: center;
`;

export const NameProject = styled.div`
  display: flex;
`;

export const Name = styled.p`
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
  letter-spacing: -0.01em;
  color: rgba(255, 255, 255, 0.8);
  margin: unset;
`;

export const Role = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 156%;
  color: rgba(255, 255, 255, 0.6);
`;

export const TagLabel = styled.p`
  color: #ffffff;
  background: linear-gradient(93.82deg, #ffce07 -1.69%, #f57f20 104.17%);
  border-radius: 4px;
  padding: 4px;
  margin-left: 12px;
`;

export const CommunityProject = styled(Col)`
  display: flex;
  justify-content: end;
  align-items: center;
`;

export const TotalMember = styled.p`
  margin: 20px 24px;
  color: rgba(255, 255, 255, 0.8);
`;

export const ButtonJoinCommunity = styled(ButtonGradient)`
  margin-right: 24px;
  border: none;
`;
