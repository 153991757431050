import styled from "styled-components";

export const Container = styled.div<{ color: string | undefined }>`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 10px 5px 7.5008px;
  gap: 6.5008px;
  width: fit-content;
  height: 28px;
  ${({ color }: any) => {
    return `border: 1px solid ${color}`;
  }};
  border-radius: 20px;
  span {
    ${({ color }: any) => {
      return `color: ${color}`;
    }};
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 110%;
    letter-spacing: -0.01em;
  }
`;
