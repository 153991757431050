import { Input } from "antd";
import { ButtonGradient } from "components/base";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { THEME } from "utils/theme";

export const ContainerAuth = styled.div`
  background: ${THEME.USER.BACKGROUND_GRADIENT};
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AuthContainer = styled.div`
  margin-top: 20px;
  width: 500px;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  max-height: calc(100vh - 10%);
  padding: 55px;
`;

export const TitleAuth = styled.div`
  display: block;
  font-size: 39px;
  color: #333333;
  line-height: 1.2;
  text-align: center;
  font-weight: bold;
`;

export const InputAuth = styled(Input)`
  height: 55px;
  font-size: 16px;
`;

export const InputAuthPass = styled(Input.Password)`
  height: 55px;
  font-size: 16px;
`;

export const ButtonAuth = styled(ButtonGradient)`
  width: 100% !important;
`;

export const SiginText = styled.div`
  text-align: center;
`;

export const SiginLink = styled(Link)`
  
`;

