import { FC, memo } from "react";
import { STEPS_CONFIG } from "..";
import { ITitleStepProps } from "./titleStep.props";
import { Container, CurrentStep, SumStep, WrapLeft } from "./titleStep.style";

const TitleStep: FC<ITitleStepProps> = ({
  step,
  setShowModalAdd,
  leftPrefix,
  rightPrefix,
  title,
  ...props
}: ITitleStepProps) => {
  const stepConfig = STEPS_CONFIG.find((config) => config.id === step);
  return (
    <Container {...props}>
      <WrapLeft>
        {leftPrefix}
        <p>{title || stepConfig?.text}</p>
        {stepConfig?.isShownProgress && (
          <>
            <CurrentStep>Step {stepConfig?.id} </CurrentStep>
            <SumStep>of {STEPS_CONFIG.length - 1}</SumStep>
          </>
        )}
      </WrapLeft>
    </Container>
  );
};

export default memo(TitleStep);

