import styled from "styled-components";
import headingBG from "assets/common/png/heading-bg.png";
import { ButtonGradient } from "components/base";
import { ResultsNotFound } from "components/module";
export const Heading = styled.div`
  width: 100%;
  height: 128px;
  background-image: url(${headingBG});
  div {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 100%;
    letter-spacing: -0.04em;
    color: #080f6c;
    width: 1170px;
    margin: auto 160px;
    height: 100%;
    display: flex;
    align-items: center;
  }
`;

export const ButtonBlock = styled.div`
  display: flex;
  justify-content: center;
`;

export const BaseButtonGradient = styled(ButtonGradient)`
  height: 40px;
  width: unset;
  display: flex;
  align-items: center;
  box-shadow: none;
  border: none;
  font: normal 400 16px/100% "Rubik";
`;

export const BassResultsNotFound = styled(ResultsNotFound)``;
