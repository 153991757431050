import { FC, memo } from "react";
import { ICardInfoWhiteBgProps } from "./cardInfoWhiteBg.props";
import { CardInfo } from "./cardInfoWhiteBg.style";

const CardInfoWhiteBg: FC<ICardInfoWhiteBgProps> = ({
  title,
  children,
  ...props
}: ICardInfoWhiteBgProps) => {
  return (
    <CardInfo {...props} title={title}>
      {children}
    </CardInfo>
  );
};

export default memo(CardInfoWhiteBg);
