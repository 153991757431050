import { FC, memo } from "react";
import { ITabsMenuProps } from "./tabsMenu.props";
import { ContainerTabs } from "./tabsMenu.style";

const TabsMenu: FC<ITabsMenuProps> = ({
  children,
  ...props
}: ITabsMenuProps) => {
  return <ContainerTabs {...props}>{children}</ContainerTabs>;
};

export default memo(TabsMenu);
