import { Typography } from "antd";
import starIcon from "assets/users/svg/star.svg";
import { FC, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { ILanguageTbl } from "types";
import { ROUTER_CONSTANTS } from "utils/constant";
import { nFormatter } from "utils/format";
import { IProjectCardProps, PROJECT_STATUS } from "./projectCard.props";
import {
  Avatar,
  AvatarSkeleton,
  BodyCard,
  BountyExplorer,
  CardInfoSkeleton,
  CardStatus,
  CategorySkeleton,
  Container,
  Content,
  FooterCard,
  HeaderCard,
  LeftHeaderCard,
  LeftHeaderTitle,
  LeftHeaderTitleDesc,
  LeftHeaderTitleName,
  ProjectCardContainer,
  RightBlock,
  RightHeaderCard,
  StarContainer,
  StartInfoSkeleton,
  TagLanguage,
  TagLanguagesSkeleton,
  TagsLanguage,
  TitleSkeleton,
} from "./projectCard.style";

const { Paragraph, Text } = Typography;

const ProjectCard: FC<IProjectCardProps> = ({
  cardInfo,
  loading,
  ...props
}: IProjectCardProps) => {
  let navigate = useNavigate();

  const getStatusText = (status?: PROJECT_STATUS) => {
    switch (status) {
      case PROJECT_STATUS.DRAFT:
        return "Draft";
      case PROJECT_STATUS.LOADING:
        return "Loading";
      case PROJECT_STATUS.PROCESSING:
        return "Processing";
      case PROJECT_STATUS.ADMIN_REVIEW:
        return "Reviewing";
      case PROJECT_STATUS.ACTIVE:
        break;
      case PROJECT_STATUS.CANCELED:
        return "Cancel";
      case PROJECT_STATUS.INACTIVE:
        return "Inactive";
      default:
        break;
    }
  };

  const languageTags = useMemo(() => {
    return cardInfo?.languages?.map((item: ILanguageTbl, key: number) => (
      <TagLanguage color={item?.color!} key={key}>
        {item?.name}
      </TagLanguage>
    ));
  }, [cardInfo]);

  return (
    <Container {...props} span={8}>
      <ProjectCardContainer
        onClick={() => {
          if (!loading) {
            navigate(
              `${ROUTER_CONSTANTS.USER.PROJECTS.ABOUT.replace(
                ":id",
                String(cardInfo?.id)
              )}`
            );
          }
        }}
      >
        <HeaderCard>
          <LeftHeaderCard>
            {!loading ? (
              <Avatar src={cardInfo?.avatar_url} alt="Avatar" />
            ) : (
              <AvatarSkeleton active={true} avatar />
            )}
            <LeftHeaderTitle>
              <LeftHeaderTitleName>
                {!loading ? (
                  <Text ellipsis={true} style={{ width: 500 }}>
                    {cardInfo?.dao_name || cardInfo?.github_name}
                  </Text>
                ) : (
                  <TitleSkeleton
                    active={true}
                    paragraph={{ rows: 1, width: 160 }}
                    title={false}
                  />
                )}
              </LeftHeaderTitleName>
              <LeftHeaderTitleDesc>
                {!loading ? (
                  <Paragraph ellipsis={{ rows: 1 }} style={{ width: 180 }}>
                    {cardInfo?.category?.name}
                  </Paragraph>
                ) : (
                  <CategorySkeleton
                    active={true}
                    paragraph={{ rows: 1, width: 80 }}
                    title={false}
                  />
                )}
              </LeftHeaderTitleDesc>
            </LeftHeaderTitle>
          </LeftHeaderCard>
          <RightHeaderCard>
            {!loading ? (
              <RightBlock>
                <StarContainer>
                  <img src={starIcon} alt="icon-star" />
                  <span>
                    {cardInfo?.repo_primary
                      ? nFormatter(cardInfo?.repo_primary.stars ?? 0, 1)
                      : 0}
                  </span>
                </StarContainer>
              </RightBlock>
            ) : (
              <>
                <StartInfoSkeleton type="button" active={true} />
              </>
            )}
          </RightHeaderCard>
        </HeaderCard>
        <BodyCard>
          <Content>
            {cardInfo?.status !== undefined && (
              <CardStatus status={cardInfo?.status}>
                <span>{getStatusText(cardInfo?.status)}</span>
              </CardStatus>
            )}
            {!loading ? (
              <Paragraph ellipsis={{ rows: 2 }}>
                {cardInfo?.repo_primary
                  ? cardInfo?.repo_primary?.description
                  : ""}
              </Paragraph>
            ) : (
              <CardInfoSkeleton
                active={true}
                paragraph={{ rows: 4 }}
                title={false}
              />
            )}
          </Content>
          {loading || (
            <BountyExplorer
              onClick={(e: any) => {
                e.stopPropagation();
                navigate(
                  `${ROUTER_CONSTANTS.USER.PROJECTS.BOUNTIES.replace(
                    ":id",
                    cardInfo?.id?.toString()!
                  )}`
                );
              }}
            >
              Bounty Explorer {">"}
            </BountyExplorer>
          )}
        </BodyCard>
        <FooterCard>
          <TagsLanguage>
            {!loading ? (
              languageTags
            ) : (
              <TagLanguagesSkeleton active={true} type="button" />
            )}
          </TagsLanguage>
        </FooterCard>
      </ProjectCardContainer>
    </Container>
  );
};

export default ProjectCard;
