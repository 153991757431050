import { Layout } from "antd";
import styled from "styled-components";

const { Sider } = Layout;

export const SiderAdmin = styled(Sider)`
  min-height: 100vh;
  .ant-layout-sider-children,
  .ant-menu {
    background: #fff;
  }
  .ant-menu-item {
    color: #54667a;
    border-left: 3px solid transparent;
    margin: unset !important;
    height: 50px;
    gap: 20px;
    :hover {
      color: #009efb;
      border-left: 3px solid #009efb;
    }
    
    span {
      font-size: 18px;
    }
  }

  .ant-menu-item-selected {
    background: #f2f7f8 !important;
    border-left: 3px solid #009efb;

    .ant-menu-item-icon,
    .ant-menu-title-content {
      color: #009efb !important;
    }
  }
`;

export const LogoMenuAd = styled.div`
  text-align: center;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 2px solid #f9f9f9;
  box-sizing: border-box;
  cursor: pointer;

  svg {
    /* scale: 1.7; */
  }
`;
