import { Select } from "antd";
import { FC } from "react";
import { ISelectSearchFilterProps } from "./selectSearchFilter.props";
import { FilterByTag } from "./selectSearchFilter.style";

const { Option } = Select;

const SelectSearchFilter: FC<ISelectSearchFilterProps> = ({
  options,
  style,
  placeholder,
  ...props
}: ISelectSearchFilterProps) => {
  return (
    <FilterByTag
      showSearch
      optionFilterProp="children"
      filterOption={(input: string, option: any) =>
        (option!.children as unknown as string).includes(input)
      }
      filterSort={(optionA: any, optionB: any) =>
        (optionA!.children as unknown as string)
          .toLowerCase()
          .localeCompare((optionB!.children as unknown as string).toLowerCase())
      }
      style={style}
      placeholder={placeholder}
      {...props}
    >
      {options?.map((item: any, key: number) => (
        <Option key={key} value={item?.key}>{item?.value}</Option>
      ))}
    </FilterByTag>
  );
};

export default SelectSearchFilter;
