import { UserOutlined } from "@ant-design/icons";
import { useMutation } from "@tanstack/react-query";
import { useEthers } from "@usedapp/core";
import { Col, Dropdown, Menu, Row } from "antd";
import { IProfile, getUserBasicInfoAPI, logoutAPI } from "api/users/auth";
import { putProfileWalletAPI } from "api/users/profile";
import { ArrowDown } from "assets/common/svg/ArrowDown.icon";
import { NewLogo } from "assets/common/svg/admin/LogoAdminFull.icon";
import { ConnectWallet } from "assets/common/svg/users/profile-preview/ConnectWallet.icon";
import ConnectWalletModal from "components/module/connectWalletModal";
import { ethers } from "ethers";
import { useWallet } from "hooks/useWallet";
import Cookies from "js-cookie";
import { FC, memo, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { authActions } from "redux/slices/authSlice";
import { profileActions } from "redux/slices/profileSlice";
import { RootState } from "redux/store";
import { IMenuItems } from "types";
import { KEY_COOKIE, ROUTER_CONSTANTS } from "utils/constant";
import { clearStorageAndGlobalState, saveLocalStorage } from "utils/storage";
import { IHeaderProps } from "./header.props";
import {
  AvatarLogged,
  BtnConnectWallet,
  ButtonLogin,
  ButtonSignup,
  ConnectWalletContainer,
  Container,
  ItemMenuProfile,
  ItemMenuText,
  ItemMenuTextLogout,
  LoggedContainer,
  LoginContainer,
  LogoContainer,
  MenuItem,
  NavItems,
  ProfileDropdown,
  ProfileText,
  ProfileTextContainer,
} from "./header.style";

const HeaderUser: FC<IHeaderProps> = ({
  onClickSignUp,
  onClickLogIn,
  ...props
}: IHeaderProps) => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [visibleWallet, setVisibleWallet] = useState<boolean>(false);
  const { balance, account } = useWallet();
  const { deactivate } = useEthers();
  const { isLoggedIn } = useSelector((state: RootState) => state.authUser);
  const { basicInfo } = useSelector((state: RootState) => state.profileUser);
  const location = useLocation();
  const isActive = useCallback(
    (expectedPathName: string) => {
      if (location) {
        const pathName = location.pathname;

        if (expectedPathName === ROUTER_CONSTANTS.HOME)
          return pathName === ROUTER_CONSTANTS.HOME;

        return pathName.includes(expectedPathName);
      } else return false;
    },
    [location]
  );

  const { mutateAsync: updateWalletMutate } = useMutation(putProfileWalletAPI);

  useEffect(() => {
    const urlSearchParam = window.location.search
      ? new URLSearchParams(window.location.search.slice(1))
      : null;

    let hasAccessTokenParam = false;
    if (urlSearchParam) {
      const access_token = urlSearchParam.get(KEY_COOKIE.ACCESS_TOKEN);
      if (access_token) {
        clearStorageAndGlobalState(true);
        Cookies.set(KEY_COOKIE.ACCESS_TOKEN, access_token, {
          domain: process.env.REACT_APP_COOKIE_DOMAIN,
        });
        urlSearchParam.delete(KEY_COOKIE.ACCESS_TOKEN);
        hasAccessTokenParam = true;
      }
    }

    if (Cookies.get(KEY_COOKIE.ACCESS_TOKEN)) {
      getUserBasicInfoAPI()
        .then((user: IProfile) => {
          if (!isLoggedIn) {
            saveLocalStorage(
              KEY_COOKIE.LOGIN_INFO,
              JSON.stringify({
                isLoggedIn: true,
              })
            );
            dispatch(
              authActions.logIn({
                isLoggedIn: true,
              })
            );
          }
          saveLocalStorage(
            KEY_COOKIE.PROFILE,
            JSON.stringify({
              basic_info: user.basic_info,
              github_login: user.github_login,
            })
          );
          dispatch(
            profileActions.saveProfile({
              basicInfo: user.basic_info,
              githubLogin: user.github_login,
            })
          );
          if (urlSearchParam && hasAccessTokenParam) {
            window.location.href = `${window.location.origin}${
              window.location.pathname
            }${
              urlSearchParam.toString() ? `?${urlSearchParam.toString()}` : ""
            }`;
          }
        })
        .catch((error: any) => {
          if (error.response.data.message === "Unauthorized") {
            dispatch(authActions.logOut());
            dispatch(profileActions.removeProfile());
          }
        });
    } else if (isLoggedIn) {
      dispatch(authActions.logOut());
      dispatch(profileActions.removeProfile());
      return;
    }
  }, [dispatch, isLoggedIn, navigate]);

  const menus: IMenuItems[] = [
    {
      name: "Blog",
      path: ROUTER_CONSTANTS.USER.BLOGS,
    },
    {
      name: "About",
      path: ROUTER_CONSTANTS.USER.ABOUT_US,
    },
  ];
  const menuRef = useRef<HTMLDivElement>(null);

  const switchWallet = () => {
    if (account) {
      deactivate();
      window.location.reload();
    } else {
      setVisibleWallet(true);
    }
  };

  useEffect(() => {
    if (isLoggedIn) updateWalletMutate(account);
  }, [account, updateWalletMutate, isLoggedIn]);

  const menuLogged = (
    <Menu
      items={[
        {
          key: "1",
          label: (
            <ConnectWalletContainer>
              <p>
                {account
                  ? `${ethers.utils.formatEther(balance).slice(0, 7)} ETH`
                  : "Connect your wallet"}
              </p>
              <BtnConnectWallet onClick={switchWallet}>
                <ConnectWallet />
                {account ? (
                  <span>Disconnect wallet</span>
                ) : (
                  <span>Connect wallet</span>
                )}
              </BtnConnectWallet>
            </ConnectWalletContainer>
          ),
        },
        {
          key: "2",
          label: (
            <ItemMenuProfile
              onClick={() => {
                navigate(ROUTER_CONSTANTS.USER.PROFILE_USER_INNER);
              }}
            >
              Profile
            </ItemMenuProfile>
          ),
        },
        {
          key: "3",
          label: (
            <ItemMenuText
              onClick={() => {
                navigate(ROUTER_CONSTANTS.USER.TRANSACTION_REWARD);
              }}
            >
              Transactions & Rewards
            </ItemMenuText>
          ),
        },
        // {
        //   key: "4",
        //   label: (
        //     <ItemMenuText
        //       onClick={() => {
        //         navigate(ROUTER_CONSTANTS.USER.SUPPORT_TICKET);
        //       }}
        //     >
        //       Support Ticket
        //     </ItemMenuText>
        //   ),
        // },
        {
          key: "5",
          label: (
            <ItemMenuTextLogout
              onClick={async () => {
                logoutAPI()
                  .then(() => {
                    dispatch(authActions.logOut());
                    dispatch(profileActions.removeProfile());
                    clearStorageAndGlobalState(false);
                    navigate(ROUTER_CONSTANTS.USER.LOGIN);
                  })
                  .catch((error) => {});
              }}
            >
              Logout
            </ItemMenuTextLogout>
          ),
        },
      ]}
    />
  );
  return (
    <Container>
      <Row>
        <LogoContainer
          onClick={() => {
            navigate("/");
          }}
          span={8}
        >
          <NewLogo />
        </LogoContainer>
        <Col span={8}>
          <Row justify="center">
            <Col>
              <MenuItem
                to={ROUTER_CONSTANTS.HOME}
                state={{
                  isExplore: true,
                }}
                $isActive={isActive(ROUTER_CONSTANTS.HOME)}
              >
                Explore Communities
              </MenuItem>
            </Col>
          </Row>
        </Col>
        <LoginContainer span={8}>
          <NavItems>
            {menus.map((item: IMenuItems, key: number) => {
              return (
                <MenuItem
                  to={item?.path}
                  key={key}
                  $isActive={isActive(item?.path)}
                >
                  {item?.name}
                </MenuItem>
              );
            })}
          </NavItems>
          {isLoggedIn && basicInfo ? (
            <LoggedContainer ref={menuRef}>
              <Dropdown
                getPopupContainer={(node) => menuRef.current || node}
                overlay={menuLogged}
                placement="bottomRight"
              >
                <ProfileDropdown>
                  {!basicInfo?.avatar && (
                    <AvatarLogged icon={<UserOutlined />} />
                  )}
                  {basicInfo?.avatar && (
                    <AvatarLogged src={basicInfo?.avatar} />
                  )}
                  <ProfileTextContainer>
                    <ProfileText ellipsis>{basicInfo?.full_name}</ProfileText>
                  </ProfileTextContainer>
                  <ArrowDown />
                </ProfileDropdown>
              </Dropdown>
            </LoggedContainer>
          ) : (
            <>
              <ButtonSignup onClick={onClickSignUp}>Sign up</ButtonSignup>
              <ButtonLogin onClick={onClickLogIn}>Log in</ButtonLogin>
            </>
          )}
        </LoginContainer>
      </Row>
      <ConnectWalletModal
        visible={visibleWallet}
        onCancel={() => {
          setVisibleWallet(false);
        }}
        onSuccess={() => {
          setVisibleWallet(false);
        }}
      />
    </Container>
  );
};

export default memo(HeaderUser);
