import axios from "api/axios";
import { IDefaultAvatarsTbl } from "types";

const END_POINT = {
  DEFAULT_AVATARS: "/default-avatars",
};
export const getDefaultAvatarsAPI = async (): Promise<IDefaultAvatarsTbl[]> => {
  const { data } = await axios({
    url: END_POINT.DEFAULT_AVATARS,
    method: "GET",
  });

  return data;
};
