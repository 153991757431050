import { Col, Form, Row } from "antd";
import PartyPopper from "assets/users/bounty/completed/party-popper.png";
import { FC, memo } from "react";
import { GradientNextButton } from "../../addProjectPage.style";
import TitleStep from "../../titleStep";
import { ICompletedProjectStepProps } from "./completedProjectStep.props";
import {
  ButtonWrap,
  Container,
  Content,
  Heading,
  Image,
  ImageWrap,
} from "./completedProjectStep.style";

const CompletedProjectStep: FC<ICompletedProjectStepProps> = ({
  projectFormData,
  onFinishStep,
}: ICompletedProjectStepProps) => {
  const [form] = Form.useForm();

  const onFinish = () => {
    onFinishStep(projectFormData);
  };

  return (
    <Container form={form} onFinish={onFinish}>
      <TitleStep step={3} />
      <Content>
        <Row justify="space-around" align="middle">
          <Col flex="none">
            <ImageWrap>
              <Image src={PartyPopper} />
            </ImageWrap>
          </Col>
          <Col flex="none">
            <Heading>
              Your portfolio
              <br />
              was successfully created!
            </Heading>
            {/* <Description>
              Anyway, so here's what I'm thinking. -- HoneyPot.Money; A site
              where people can get paid to create sybil resistent identities,
              hardening sybil resistent identity systems by creating a known
              price of forgery.
            </Description> */}
            <ButtonWrap>
              <GradientNextButton onClick={() => form.submit()}>
                See the Project task
              </GradientNextButton>
            </ButtonWrap>
          </Col>
        </Row>
      </Content>
    </Container>
  );
};

export default memo(CompletedProjectStep);
