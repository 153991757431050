import { Button } from "antd";
import styled from "styled-components";

export const ButtonTransparentAntd = styled(Button)`
  background: #ffffff00;
  border: 2px solid #ffffff;
  filter: drop-shadow(0px 8px 16px rgba(17, 17, 17, 0.06));
  border-radius: 8px;
  color: #ffffff;
  width: 107px;
  height: 44px;

  &:hover,
  &:focus {
    background: #ffffff00;
    color: #ffffff;
    border: 1px solid #ffffff;
    font-weight: bold;
  }
`;
