import { FC } from "react";
import { ICardInfoProps } from "./cardInfo.props";
import { CardContainer } from "./cardInfo.style";

const CardInfo: FC<ICardInfoProps> = ({
  children,
  title,
  extra,
  ...props
}: ICardInfoProps) => {
  return (
    <CardContainer title={title} extra={extra} bordered={false} {...props}>
      {children}
    </CardContainer>
  );
};

export default CardInfo;
