import styled from "styled-components";

export const Label = styled.span<{
  showUnderline?: boolean;
}>`
  cursor: pointer;
  position: relative;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: #7E00FD;
  ${({ showUnderline }: any) =>
    showUnderline &&
    `&:before {
        content: "";
        position: absolute;
        top: 100%;
        width: 100%;
        left: 0;
        height: 1px;
        background: #7E00FD;
      }`}
`;
