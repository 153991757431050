import { Tag } from "antd";
import UserAPI from "pages/users/commonUi/apiComponent/user";
// import EventAPI from "pages/users/commonUi/apiComponent/event";
import PostsAPI from "pages/users/commonUi/apiComponent/posts";
import UpvotesAPI from "pages/users/commonUi/apiComponent/upvotes";
import QuestionsAPI from "pages/users/commonUi/apiComponent/questions";
import PostCommentsAPI from "pages/users/commonUi/apiComponent/postComments";
import EventCommentsAPI from "pages/users/commonUi/apiComponent/eventComments";
import BookmarksAPI from "pages/users/commonUi/apiComponent/bookmarks";
import AnswersAPI from "pages/users/commonUi/apiComponent/answers";
import EventQuestionsAPI from "pages/users/commonUi/apiComponent/eventQuestions";
import EventPollsAPI from "pages/users/commonUi/apiComponent/eventPolls";
import EventPollItemsAPI from "pages/users/commonUi/apiComponent/eventPollItems";
import UploadsAPI from "pages/users/commonUi/apiComponent/uploads";
import DefaultAvatarAPI from "pages/users/commonUi/apiComponent/defaultAvatar";
import { FC, memo } from "react";
import { ICommonUiProps } from "./commonUi.props";

const TestAPI: FC<ICommonUiProps> = (props: ICommonUiProps) => {
  return (
    <>
      <UserAPI />
      {/* <EventAPI/> */}
      <PostsAPI />
      <UpvotesAPI />
      <QuestionsAPI />
      <PostCommentsAPI />
      <EventCommentsAPI />
      <BookmarksAPI />
      <AnswersAPI />
      <EventQuestionsAPI />
      <EventPollsAPI />
      <EventPollItemsAPI />
      <UploadsAPI />
      <DefaultAvatarAPI />
    </>
  );
};

export default memo(TestAPI);

export const METHOD_API = {
  GET: <Tag color="blue">GET</Tag>,
  POST: <Tag color="green">POST</Tag>,
  PUT: <Tag color="orange">PUT</Tag>,
  DELETE: <Tag color="red">DELETE</Tag>,
};
