import { Button } from "antd";
import styled from "styled-components";

export const ButtonConnectInfoCustom = styled(Button)`
  background: transparent;
  border: none;
  box-shadow: unset;
  &:hover,
  &:active,
  &:focus {
    background: transparent;
    border: none;
    color: #5a5e89;
  }
  font-weight: 400;
  font-size: 18px;
  line-height: 100%;
  display: flex;
  align-items: center;
  color: #080a25;
  span {
    margin-left: 5px;
  }
`;
