import { Col, InputRef, Typography, message } from "antd";
import { FC, useEffect, useRef, useState } from "react";
import { ISkill } from "../../addProjectPage.props";
import {
  AntdTag,
  BaseFormItem,
  RightText,
  SuggestedTagBlock,
} from "../addProjectStep.style";
import { ISkillTagSystemProps } from "./skillTagSystem.props";
import {
  AntdEditTag,
  AntdPlusTag,
  TagInput,
  TagInputContainer,
} from "./skillTagSystem.style";

const SkillTagSystem: FC<ISkillTagSystemProps> = ({
  tags,
  suggestedSkills,
  setTags,
  setSuggestedSkills,
  ...props
}: ISkillTagSystemProps) => {
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [editInputIndex, setEditInputIndex] = useState(-1);
  const [editInputValue, setEditInputValue] = useState("");
  const inputRef = useRef<InputRef>(null);
  const editInputRef = useRef<InputRef>(null);

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  useEffect(() => {
    editInputRef.current?.focus();
  }, [inputValue]);

  const handleClose = (removedTag: ISkill) => {
    if (removedTag.isSelected) {
      const suggestedIndex = suggestedSkills.findIndex(
        (item) => item.value === removedTag.value
      );
      if (suggestedIndex >= 0) {
        suggestedSkills[suggestedIndex].isSelected = false;
        setSuggestedSkills([...suggestedSkills]);
      }
    }
    const newTags = tags.filter((tag) => tag.value !== removedTag.value);
    setTags([...newTags]);
    return;
  };

  const showInput = () => {
    setInputVisible(true);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = (eventType: string) => {
    if (tags.length === 15 && eventType === "onBlur") {
      message.info("You only choose maximum 15 skills");
      setInputVisible(false);
      setInputValue("");
      return;
    }
    const index = tags.findIndex((item) => item.value === inputValue);
    if (inputValue && index === -1) {
      setTags([...tags, { value: inputValue, isSelected: false }]);
    }
    tags.length < 14 ? setInputVisible(true) : setInputVisible(false);
    setInputValue("");
  };

  const handleEditInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditInputValue(e.target.value);
  };

  const handleEditInputConfirm = () => {
    const newTags = [...tags];
    newTags[editInputIndex].value = editInputValue;
    setTags(newTags);
    setEditInputIndex(-1);
    setInputValue("");
  };

  const toggleChooseTag = (suggestedTag: ISkill, index: number) => {
    if (suggestedTag.isSelected) {
      const choosenTag = tags.find((item) => item.value === suggestedTag.value);
      if (!!choosenTag) {
        suggestedSkills[index].isSelected = false;
        setSuggestedSkills([...suggestedSkills]);
        const newTags = tags.filter((tag) => tag.value !== choosenTag.value);
        setTags([...newTags]);
      }
    } else {
      if (tags.length === 15) {
        message.info("You only choose maximum 15 skills");
      } else {
        const index = tags.findIndex(
          (item) => item.value === suggestedTag.value
        );
        if (suggestedTag.isSelected || index !== -1) {
          return;
        }
        suggestedTag.isSelected = true;
        setTags([...tags, suggestedTag]);
      }
    }
  };

  return (
    <>
      <TagInputContainer inputVisible={inputVisible}>
        {tags.map((tag, index) => {
          if (editInputIndex === index) {
            return (
              <TagInput
                ref={editInputRef}
                key={index}
                size="small"
                value={editInputValue}
                onChange={handleEditInputChange}
                onBlur={handleEditInputConfirm}
                onPressEnter={handleEditInputConfirm}
              />
            );
          }
          const tagElem = (
            <AntdEditTag
              key={index}
              closable={true}
              onClose={(e) => {
                e.preventDefault();
                handleClose(tag);
              }}
            >
              <span
                onDoubleClick={(e) => {
                  setEditInputIndex(index);
                  setEditInputValue(tag.value);
                  e.preventDefault();
                }}
              >
                <Typography.Text
                  style={{ maxWidth: 100 }}
                  ellipsis={{ tooltip: tag.value }}
                >
                  {tag.value}
                </Typography.Text>
              </span>
            </AntdEditTag>
          );
          return tagElem;
        })}
        {inputVisible && (
          <TagInput
            ref={inputRef}
            type="text"
            size="small"
            className="tag-input"
            value={inputValue}
            onChange={handleInputChange}
            onBlur={() => {
              handleInputConfirm("onBlur");
            }}
            onPressEnter={() => {
              handleInputConfirm("onPressEnter");
            }}
          />
        )}
        {!inputVisible && tags.length < 15 && (
          <AntdPlusTag onClick={showInput}>
            Add a skill or deliverable...
          </AntdPlusTag>
        )}
      </TagInputContainer>
      <RightText> {tags.length}/15 skills</RightText>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <BaseFormItem label={<div>Suggested Skills</div>} labelAlign="left">
          <SuggestedTagBlock>
            {suggestedSkills.map((suggestedTag, index) => (
              <AntdTag
                key={index}
                color="default"
                onClick={() => toggleChooseTag(suggestedTag, index)}
                $is_selected={suggestedTag.isSelected}
              >
                {suggestedTag.value}
              </AntdTag>
            ))}
          </SuggestedTagBlock>
        </BaseFormItem>
      </Col>
    </>
  );
};

export default SkillTagSystem;
