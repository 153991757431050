import { RightOutlined } from "@ant-design/icons";
import { SkeletonCustom } from "components/base";
import styled from "styled-components";

export const Container = styled.div`
  margin: 0px 136px 0px 136px;
  background: #fff;
  padding: 16px 0;
  z-index: 1;
`;

export const Text = styled.span`
  font-size: 16px;
  font-weight: 600;
`;

export const Separator = styled(RightOutlined)`
  color: #000;
`;

export const BreadcrumbSkeleton = styled(SkeletonCustom)`
  .ant-skeleton-title {
    max-width: 556px;
    margin-bottom: 0;
    height: 28px;
  }
`;
