import { FC, useLayoutEffect } from "react";
import { useLocation } from "react-router";

const ScrollToTop: FC = (...props) => {
  const location = useLocation();
  useLayoutEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location]);

  return <div></div>;
};

export default ScrollToTop;
