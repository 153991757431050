import axios from "api/axios";
import {
    ICreateEventPollsReq,
    IGetAllEventPollsReq,
    IUpdateEventPollReq
} from "./types";

const END_POINT = {
  CREATE_EVENT_POLLS: "/event-polls",
  GET_ALL_EVENT_POLLS: "/event-polls/alls",
  GET_EVENT_POLLS_BY_ID: "/event-polls/:id",
  GET_EVENT_POLLS_ANSWER_BY_ID: "event-polls/answers/:id",
  UPDATE_EVENT_POLLS_BY_ID: "/event-polls/:id",
  DELETE_EVENT_POLLS_BY_ID: "/event-polls/:id",
};

export const createEventPollsAPI = async (params: ICreateEventPollsReq) => {
  const { data } = await axios({
    url: END_POINT.CREATE_EVENT_POLLS,
    method: "POST",
    data: params,
  });
  return data;
};

export const getAllEventPollsAPI = async (params: IGetAllEventPollsReq) => {
  const { data } = await axios({
    url: `${END_POINT.GET_ALL_EVENT_POLLS}`,
    method: "GET",
    params: params,
  });
  return data;
};

export const getEventPollByIdAPI = async (id: string) => {
  const { data } = await axios({
    url: `${END_POINT.GET_EVENT_POLLS_BY_ID.replace(":id", id)}`,
    method: "GET",
  });
  return data;
};

export const updateEventPollByIdAPI = async (params: IUpdateEventPollReq) => {
  const data = await axios({
    url: `${END_POINT.UPDATE_EVENT_POLLS_BY_ID.replace(":id", params.id)}`,
    method: "PUT",
    data: params.updatedEventPoll,
  });
  return data;
};

export const getEventPollAnswerByIdAPI = async (id: string) => {
  const { data } = await axios({
    url: `${END_POINT.GET_EVENT_POLLS_ANSWER_BY_ID.replace(":id", id)}`,
    method: "GET",
  });
  return data;
};

export const deleteEventPollByIdAPI = async (id: string) => {
  const data = await axios({
    url: `${END_POINT.DELETE_EVENT_POLLS_BY_ID.replace(":id", id)}`,
    method: "DELETE",
  });
  return data;
};
