import { message } from "antd";
import { logoutAPI } from "api/users/auth";
import Cookies from "js-cookie";
import MasterLayoutAdmin from "layout/admin/masterLayoutAdmin";
import { memo } from "react";
import { useDispatch } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { authActions } from "redux/slices/authSlice";
import { KEY_COOKIE, ROUTER_CONSTANTS } from "utils/constant";
import { getMessageContent } from "utils/message";
import { clearStorageAndGlobalState } from "utils/storage";

const RouteAdminAuth = () => {
  const dispatch = useDispatch();
  const isLoggedInUser = Cookies.get(KEY_COOKIE.ACCESS_TOKEN);
  const isLoggedInAdmin = Cookies.get(KEY_COOKIE.ACCESS_TOKEN_ADMIN);

  const logoutUser = async () => {
    try {
      await logoutAPI();
      clearStorageAndGlobalState(true);
      dispatch(authActions.logOut());
    } catch (error: any) {
      message.error(getMessageContent(error?.message));
    }
  };

  // When user logged, remove session login to login with admin
  if (isLoggedInUser) {
    logoutUser();
  }

  return isLoggedInAdmin ? (
    <MasterLayoutAdmin>
      <Outlet />
    </MasterLayoutAdmin>
  ) : (
    <Navigate to={ROUTER_CONSTANTS.ADMIN_PAGES.LOGIN} />
  );
};

export default memo(RouteAdminAuth);

