import { Upload } from "antd";
import styled from "styled-components";

export const UploadBlock = styled(Upload)`
  color: rgba(0, 0, 0, 0.85);
  .ant-upload.ant-upload-select-picture-card {
    aspect-ratio: 1/1;
    margin-bottom: 0;
    width: 100%;
    min-height: 166px;
    border-radius: 4px;
    cursor: url(/assets/images/cursor-white.svg), pointer;
    background: #ffffff;
  }
`;

export const PreviewCustom = styled.div<{ preview?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ preview }) =>
    preview
      ? `
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 6px;
    }
  `
      : `
    padding-inline: 14px;
  `}
`;

export const ImgPDF = styled.img`
  object-fit: contain;
  width: fit-content !important;
  height: 80% !important;
`;

export const ThumbnailImage = styled.img``;

export const UploadTextDesc = styled.div`
  line-height: 22px;
`;

export const UploadInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #bfbfbf;
`;
