import IconNextGradient from "assets/users/svg/icon_next_gradient.svg";
import { FC } from "react";
import { IIconGradientProps } from "./iconGradient.props";
import { Container } from "./iconGradient.style";

const IconGradient: FC<IIconGradientProps> = ({
  children,
  ...props
}: IIconGradientProps) => {
  return <Container {...props} src={IconNextGradient} alt="Icon next" />;
};

export default IconGradient;
