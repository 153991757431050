import { FC } from "react";
import IStyledModalProps from "./commonModal.props";
import { StyledModal } from "./commonModal.style";
import { ModalMaskStyle } from "./commonModal.style";
import { CloseIcon } from "assets/common/svg/CloseIcon.icon";

const CommonModal: FC<IStyledModalProps> = ({
  closeIcon,
  children,
  ...props
}: IStyledModalProps) => {
  return (
    <StyledModal
      {...props}
      closeIcon={closeIcon || <CloseIcon />}
      maskStyle={ModalMaskStyle}
    >
      {children}
    </StyledModal>
  );
};

export default CommonModal;
