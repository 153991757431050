import styled from "styled-components";

export const TagBlock = styled.span<{ textColor?: string }>`
  ${({ textColor }: any) => {
    textColor = textColor ?? "rgba(8, 10, 37, 1)";
    const bdCol = textColor.replace(", 1)", ", 0.2)");
    const bgCol = textColor.replace(", 1)", ", 0.08)");
    const txtCol = textColor.replace(", 1)", ", 0.5)");
    return `color: ${txtCol};
    border: 1px solid ${bdCol};
      background: ${bgCol};
      `;
  }}
  border-radius: 20px;
  padding: 1px 8px 1px 8px;
  margin-right: 8px;
  display: inline-block;
`;
