export const UploadIcon = (props: { style?: React.CSSProperties }) => (
  <svg
    style={props.style}
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.8782 24.187H17.3527H16.134H15.8709V18.1167H17.8562C18.3596 18.1167 18.6571 17.5446 18.3596 17.1326L14.4978 11.789C14.2517 11.4457 13.7425 11.4457 13.4965 11.789L9.63465 17.1326C9.33715 17.5446 9.62893 18.1167 10.1381 18.1167H12.1234V24.187H11.8602H10.6416H5.39518C2.3915 24.0211 0 21.2119 0 18.1682C0 16.0685 1.13854 14.2377 2.82632 13.2479C2.67184 12.8302 2.59174 12.384 2.59174 11.9148C2.59174 9.76934 4.3253 8.03579 6.47078 8.03579C6.93421 8.03579 7.38047 8.11589 7.79812 8.27036C9.03964 5.63857 11.7172 3.81348 14.8296 3.81348C18.8574 3.8192 22.1757 6.90297 22.5533 10.8335C25.6486 11.3656 28 14.2319 28 17.4759C28 20.943 25.2996 23.9467 21.8782 24.187Z"
      fill="#7E00FD"
    />
  </svg>
);

