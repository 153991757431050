import axios from "api/axios";
import {
  ICreateEventPollItemsReq,
  IGetAllEventPollItemsReq,
  IUpdateEventPollItemReq
} from "./types";

const END_POINT = {
  CREATE_EVENT_POLL_ITEMS: "/event-poll-items",
  GET_ALL_EVENT_POLL_ITEMS: "/event-poll-items/alls",
  GET_EVENT_POLL_ITEMS_BY_ID: "/event-poll-items/:id",
  GET_EVENT_POLL_ITEMS_ITEMS_BY_ID: "event-poll-items/items/:id",
  UPDATE_EVENT_POLL_ITEMS_BY_ID: "/event-poll-items/:id",
  DELETE_EVENT_POLL_ITEMS_BY_ID: "/event-poll-items/:id",
};

export const createEventPollItemsAPI = async (
  params: ICreateEventPollItemsReq
) => {
  const { data } = await axios({
    url: END_POINT.CREATE_EVENT_POLL_ITEMS,
    method: "POST",
    data: params,
  });
  return data;
};

export const getAllEventPollItemsAPI = async (
  params: IGetAllEventPollItemsReq
) => {
  const { data } = await axios({
    url: `${END_POINT.GET_ALL_EVENT_POLL_ITEMS}`,
    method: "GET",
    params: params,
  });
  return data;
};

export const getEventPollItemByIdAPI = async (id: string) => {
  const { data } = await axios({
    url: `${END_POINT.GET_EVENT_POLL_ITEMS_BY_ID.replace(":id", id)}`,
    method: "GET",
  });
  return data;
};

export const updateEventPollItemByIdAPI = async (
  params: IUpdateEventPollItemReq
) => {
  const data = await axios({
    url: `${END_POINT.UPDATE_EVENT_POLL_ITEMS_BY_ID.replace(":id", params.id)}`,
    method: "PUT",
    data: params.updatedEventPollItem,
  });
  return data;
};

export const getEventPollItemItemByIdAPI = async (id: string) => {
  const { data } = await axios({
    url: `${END_POINT.GET_EVENT_POLL_ITEMS_ITEMS_BY_ID.replace(":id", id)}`,
    method: "GET",
  });
  return data;
};

export const deleteEventPollItemByIdAPI = async (id: string) => {
  const data = await axios({
    url: `${END_POINT.DELETE_EVENT_POLL_ITEMS_BY_ID.replace(":id", id)}`,
    method: "DELETE",
  });
  return data;
};
