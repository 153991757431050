import { useMutation } from "@tanstack/react-query";
import { Button, Checkbox, Collapse, notification, Row } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import {
  delPostCommentsByIdAPI,
  getAllPostsCommentAPI,
  getPostChildCommentsAPI,
  getPostCommentsByIdAPI,
  IGetPostsCommentsByIdRes,
  IPostsCommentsAllReq,
  IPostsCommentsAllsRes,
  IPostsCommentsChilReq,
  IPostsCommentsIdReq,
  IPostsCommentsReq,
  IPostsCommentsRes,
  IPutCommentsReq,
  postPostsCommentsAPI,
  putPostCommentsByIdAPI,
} from "api/users/postsComments";
import { AxiosError } from "axios";
import { FC, memo, useRef, useState } from "react";
import ReactJson from "react-json-view";
import { METHOD_API } from "../apiTest";

const { Panel } = Collapse;

const PostCommentsAPI: FC<any> = () => {
  const checkBox = useRef<any>(null);
  const [isShow, setIsShow] = useState<boolean>(true);
  const [initPostsCommentsReq] = useState<IPostsCommentsReq>({
    comment_id: undefined,
    post_id: 5,
    status: 1,
    content: "Test",
  });

  const [initPostsCommentsAllReq] = useState<IPostsCommentsAllReq>({
    page: 1,
    limit: 10,
    sort_by: 1,
    post_id: 1,
  });

  const [initGetPostCommentsByIDReq] = useState<IPostsCommentsIdReq>({
    id: 5,
  });

  const [initPutCommentsReq] = useState<IPutCommentsReq>({
    id: 5,
    content: "Test",
    status: 1,
  });

  const [initDelCommentsReq] = useState<IPostsCommentsIdReq>({
    id: 5,
  });

  const [initPostCommentsChilReq] = useState<IPostsCommentsChilReq>({
    id: 5,
    page: 1,
    limit: 10,
    sort_by: 1,
    post_id: 1,
  });

  const [postCommentsSuccess, setPostCommentsSuccess] = useState<any>();
  const [postCommentsErr, setPostCommentsErr] = useState<IPostsCommentsRes>();
  const [postCommentsAllSuccess, setPostCommentsAllSuccess] =
    useState<IPostsCommentsAllsRes>();
  const [postCommentsAllErr, setPostCommentsAllErr] = useState<any>();
  const [dataPostCmtByIdSuccess, setDataPostCmtByIdSuccess] =
    useState<IGetPostsCommentsByIdRes>();
  const [dataPostCmtByIdErr, setDataPostCmtByIdErr] = useState<any>();
  const [dataPostCommentsChil, setPostCommentsChil] = useState<any>();
  const [dataPostCommentsChilErr, setPostCommentsChilErr] = useState<any>();

  // Get Posts Comments
  const { mutate: postPostsComments, isLoading: isLoadingPostPostsComments } =
    useMutation(postPostsCommentsAPI, {
      onSuccess: async (rs: IPostsCommentsRes) => {
        setPostCommentsSuccess(rs);
      },
      onError: (err: AxiosError) => {
        const data: any = err.response?.data;
        setPostCommentsErr(data);
      },
    });

  // Get All Posts Comments
  const {
    mutate: postPostsAllComments,
    isLoading: isLoadingPostPostsAllComments,
  } = useMutation(getAllPostsCommentAPI, {
    onSuccess: async (rs: IPostsCommentsAllsRes) => {
      setPostCommentsAllSuccess(rs);
    },
    onError: (err: AxiosError) => {
      const data: any = err.response?.data;
      setPostCommentsAllErr(data);
    },
  });

  // Get Posts Comment By ID
  const {
    mutate: getPostCommentsById,
    isLoading: isLoadingGetPostCommentsById,
  } = useMutation(getPostCommentsByIdAPI, {
    onSuccess: async (rs: IGetPostsCommentsByIdRes) => {
      setDataPostCmtByIdSuccess(rs);
    },
    onError: (err: AxiosError) => {
      const data: any = err.response?.data;
      setDataPostCmtByIdErr(data);
    },
  });

  // Get Put Comment By ID
  const {
    mutate: putPostCommentsById,
    isLoading: isLoadingPutPostCommentsById,
  } = useMutation(putPostCommentsByIdAPI, {
    onSuccess: async (rs: any) => {
      notification.success({
        message: "Notification",
        description: "Put Success",
      });
    },
    onError: (err: AxiosError) => {
      const data: any = err.response?.data;
      notification.error({
        message: "Notification",
        description: data.message,
      });
    },
  });

  // Delete
  const { mutate: delPostsById, isLoading: isLoadingDelPostsById } =
    useMutation(delPostCommentsByIdAPI, {
      onSuccess: async (rs: any) => {
        notification.success({
          message: "Delete success",
        });
      },
      onError: (err: AxiosError) => {
        const data: any = err.response?.data;
        notification.error({
          message: "Notification",
          description: data?.message,
        });
      },
    });

  // Get Posts Comment Children
  const {
    mutate: getPostCommentsChil,
    isLoading: isLoadingGetPostCommentsChilAPI,
  } = useMutation(getPostChildCommentsAPI, {
    onSuccess: async (rs: any) => {
      setPostCommentsChil(rs);
    },
    onError: (err: AxiosError) => {
      const data: any = err.response?.data;
      setPostCommentsChilErr(data);
    },
  });

  return (
    <>
      <Row style={{ marginTop: "10px" }}>
        <h1>
          <b>Post comments</b>
        </h1>
        <Checkbox
          ref={checkBox}
          onChange={(e: CheckboxChangeEvent) => {
            setIsShow(e.target.checked);
          }}
          style={{
            marginLeft: "10px",
          }}
          checked={isShow}
        />
      </Row>
      {isShow && (
        <Collapse accordion>
          <Panel header="/post-comments" key="1" extra={METHOD_API.POST}>
            <h1>Parameters posts comment</h1>
            <ReactJson
              src={initPostsCommentsReq}
              onEdit={(e: any) => {
                initPostsCommentsReq[e.name as keyof IPostsCommentsReq] =
                  e.new_value;
              }}
              enableClipboard={false}
            />
            <Button
              loading={isLoadingPostPostsComments}
              disabled={isLoadingPostPostsComments}
              onClick={() => {
                setPostCommentsSuccess(undefined);
                setPostCommentsErr(undefined);
                postPostsComments(initPostsCommentsReq);
              }}
            >
              Try it out
            </Button>
            {postCommentsSuccess && <ReactJson src={postCommentsSuccess} />}
            {postCommentsErr && (
              <ReactJson
                theme={"greenscreen"}
                enableClipboard={false}
                displayDataTypes={false}
                src={postCommentsErr}
              />
            )}
          </Panel>
          <Panel header="/post-comments/alls" key="2" extra={METHOD_API.POST}>
            <h1>Parameters posts alls comment</h1>
            <ReactJson
              src={initPostsCommentsAllReq}
              onEdit={(e: any) => {
                initPostsCommentsAllReq[e.name as keyof IPostsCommentsAllReq] =
                  e.new_value;
              }}
              enableClipboard={false}
            />
            <Button
              loading={isLoadingPostPostsAllComments}
              disabled={isLoadingPostPostsAllComments}
              onClick={() => {
                setPostCommentsAllSuccess(undefined);
                setPostCommentsAllErr(undefined);
                postPostsAllComments(initPostsCommentsAllReq);
              }}
            >
              Try it out
            </Button>
            {postCommentsAllSuccess && (
              <ReactJson src={postCommentsAllSuccess} />
            )}
            {postCommentsAllErr && (
              <ReactJson
                theme={"greenscreen"}
                enableClipboard={false}
                displayDataTypes={false}
                src={postCommentsAllErr}
              />
            )}
          </Panel>
          <Panel header="/post-comments/{id}" key="3" extra={METHOD_API.GET}>
            <h1>Parameters posts comment by ID</h1>
            <ReactJson
              src={initGetPostCommentsByIDReq}
              onEdit={(e: any) => {
                initGetPostCommentsByIDReq[
                  e.name as keyof IPostsCommentsIdReq
                ] = e.new_value;
              }}
              enableClipboard={false}
            />
            <Button
              loading={isLoadingGetPostCommentsById}
              disabled={isLoadingGetPostCommentsById}
              onClick={() => {
                setDataPostCmtByIdSuccess(undefined);
                setDataPostCmtByIdErr(undefined);
                getPostCommentsById(initGetPostCommentsByIDReq);
              }}
            >
              Try it out
            </Button>
            {dataPostCmtByIdSuccess && (
              <ReactJson src={dataPostCmtByIdSuccess} />
            )}
            {dataPostCmtByIdErr && (
              <ReactJson
                theme={"greenscreen"}
                enableClipboard={false}
                displayDataTypes={false}
                src={dataPostCmtByIdErr}
              />
            )}
          </Panel>
          <Panel header="/post-comments/{id}" key="4" extra={METHOD_API.PUT}>
            <h1>Parameters put comment by ID</h1>
            <ReactJson
              src={initPutCommentsReq}
              onEdit={(e: any) => {
                initPutCommentsReq[e.name as keyof IPostsCommentsIdReq] =
                  e.new_value;
              }}
              enableClipboard={false}
            />
            <Button
              loading={isLoadingPutPostCommentsById}
              disabled={isLoadingPutPostCommentsById}
              onClick={() => {
                putPostCommentsById(initPutCommentsReq);
              }}
            >
              Try it out
            </Button>
          </Panel>
          <Panel header="/post-comments/{id}" key="5" extra={METHOD_API.DELETE}>
            <h1>Parameters By ID</h1>
            <ReactJson
              src={initDelCommentsReq}
              onEdit={(e: any) => {
                initDelCommentsReq[e.name as keyof IPostsCommentsIdReq] =
                  e.new_value;
              }}
              enableClipboard={false}
            />
            <Button
              loading={isLoadingDelPostsById}
              disabled={isLoadingDelPostsById}
              onClick={() => {
                delPostsById(initDelCommentsReq);
              }}
            >
              Try it out
            </Button>
          </Panel>
          <Panel
            header="/post-comments/children/{id}"
            key="6"
            extra={METHOD_API.GET}
          >
            <h1>Parameters post-comments children</h1>
            <ReactJson
              src={initPostCommentsChilReq}
              onEdit={(e: any) => {
                initPostCommentsChilReq[e.name as keyof IPostsCommentsChilReq] =
                  e.new_value;
              }}
              enableClipboard={false}
            />
            <Button
              loading={isLoadingGetPostCommentsChilAPI}
              disabled={isLoadingGetPostCommentsChilAPI}
              onClick={() => {
                setPostCommentsChil(undefined);
                setPostCommentsChilErr(undefined);
                getPostCommentsChil(initPostCommentsChilReq);
              }}
            >
              Try it out
            </Button>
            {dataPostCommentsChil && <ReactJson src={dataPostCommentsChil} />}
            {dataPostCommentsChilErr && (
              <ReactJson
                theme={"greenscreen"}
                enableClipboard={false}
                displayDataTypes={false}
                src={dataPostCommentsChilErr}
              />
            )}
          </Panel>
        </Collapse>
      )}
    </>
  );
};

export default memo(PostCommentsAPI);
