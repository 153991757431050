import styled from "styled-components";

export const IdentityBlock = styled.div`
  display: flex;
  align-items: center;
  column-gap: 4px;
`;

export const IdentityAvatar = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 100%;
`;

export const IdentityName = styled.span``;
