import { DatePicker, Form, Input, Tag, Upload } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { ButtonGradient } from "components/base";
import formItem from "components/base/formItem";
import SelectArrowDownIcon from "components/base/selectArrowDownIcon";
import UploadImage from "components/module/uploadImage";
import styled from "styled-components";

const Dragger = Upload.Dragger;

export const Container = styled(Form)`
  @keyframes visibleAnimation {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  animation: visibleAnimation 2s;
  .ant-form-item-control {
    flex: auto;
  }
`;

export const ContentBlock = styled.div``;

export const WrapBlock = styled.div`
  margin-top: 36px;
  margin-left: 26px;
  display: grid;
  gap: 26px;
`;

export const Title = styled.div`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  color: rgba(8, 10, 37, 0.8);
`;

export const WrapContent = styled.div`
  display: grid;
  padding: 21px 28px;
  gap: 8px;
  width: 440px;
  height: fit-content;
  background: #faf8ff;
  border-radius: 12px;
`;

export const SubTitle = styled.div`
  display: grid;
  gap: 8px;
  span {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 145%;
    color: rgba(8, 10, 37, 0.4);
  }
  margin-bottom: 8px;
`;

export const Heading = styled.div`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 100%;
  color: #7e00fd;
`;

export const WrapItem = styled.div<{ active: boolean }>`
  display: flex;
  padding: 18px 16px;
  width: 342px;
  height: fit-content;
  background: #faf8ff;
  border: 1px solid rgba(115, 137, 149, 0.2);
  border-radius: 4px;
  cursor: pointer;
  :hover {
    border: 1px solid #7e00fd;
  }
  justify-content: center;
  align-items: center;
  gap: 16px;

  // active
  ${({ active }: any) =>
    active
      ? `background: rgba(126, 0, 253, 0.1); 
         border: 1px solid #7E00FD;
         box-shadow: #7e00fd68 0px 0px 6px;
        `
      : `border: 1px solid rgba(115, 137, 149, 0.2);`}
  transition: background-color 0.3s ease-in-out, border 0.3s ease-in-out,
  box-shadow 0.3s ease-in-out;
`;

export const Left = styled.div`
  display: grid;
  gap: 6px;
  div {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    align-items: center;
    color: #080a25;
  }
  span {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 134%;
    color: rgba(8, 10, 37, 0.5);
  }
`;
export const Right = styled.div`
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #7e00fd;
  border-radius: 50%;
  svg {
    width: inherit;
  }
`;

export const BaseFormItem = styled(formItem)`
  &.ant-row {
    display: block !important;
    margin-bottom: 0;
  }
  .ant-form-item-label > label {
    height: auto;
    font: normal 400 14px/100% "DM Sans";
    letter-spacing: -0.01em;
    color: #080f6c;

    :before,
    :after {
      content: unset;
    }
  }

  p {
    color: #080f6c;
  }

  p > strong {
    font-weight: 600;
  }
`;

export const FormTextInput = styled(Input)`
  background: #ffffff;
  border: 1px solid rgba(41, 46, 115, 0.12);
  border-radius: 4px;
  /* padding: 11px 12px 11px 12px; */
  /* height: 50px; */
  &:after {
    display: none;
  }
`;

export const AntdDatePicker = styled(DatePicker)`
  border-radius: 4px;
  width: 100%;
`;

export const Note = styled.div`
  display: inline-block;
  color: #8387b5;
`;

export const UploadFormItem = styled(BaseFormItem)``;

export const UploadWrapper = styled.div`
  position: relative;
  height: 200px;
  border-radius: 4px;
  .ant-upload-list {
    display: none !important;
  }
`;

export const ImageBlock = styled.div`
  text-align: center;
`;

export const CustomSelect = styled(SelectArrowDownIcon)``;

export const AntdDragger = styled(Dragger)`
  position: absolute;
  top: 0;
  z-index: 2;
  height: 200px;
  width: 100%;
  background-color: transparent !important;
  ${ImageBlock} {
    display: none;
  }
  &:hover {
    background: rgba(255, 255, 255, 0.8) !important;
    ${ImageBlock} {
      display: block;
    }
  }
  .ant-upload-list {
    display: none !important;
  }
  .ant-upload.ant-upload-drag {
    background: rgba(255, 255, 255, 0.5) !important;
  }
`;

export const CustomUpload = styled(UploadImage)``;

export const UploadIcon = styled.img``;

export const UploadTitle = styled.div``;

export const PreviewBlock = styled.div`
  position: absolute;
  top: 0;
  z-index: 1;
  height: 200px;
  width: 100%;
  /* background-color: red; */
`;

export const PreviewImage = styled.img`
  height: 200px;
  width: 100%;
`;

export const AddVideoLinkButton = styled(ButtonGradient)`
  height: 40px;
  width: unset;
  display: flex;
  align-items: center;
  column-gap: 12px;
  background: linear-gradient(
    263.55deg,
    rgba(0, 222, 140, 0.1) 1.97%,
    rgba(197, 138, 255, 0.1) 100%
  ) !important;
  color: rgba(8, 10, 37, 0.6) !important;
  box-shadow: none;
  border: none;
  font: normal 400 16px/100% "Rubik";
`;

export const AntdTextArea = styled(TextArea)`
  border-radius: 4px;
`;

export const SubInputBlock = styled.div`
  margin-left: 8px;
`;

export const VideoUrlInputBlock = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

export const AntdTag = styled(Tag)<{ $is_selected?: boolean }>`
  transition: background-color 0.3s ease-in-out, border 0.3s ease-in-out,
    color 0.3s ease-in-out, box-shadow 0.5s ease-in-out;
  ${({ $is_selected }) => {
    return $is_selected
      ? `
      color: #7e00fd;
      border-color: #7e00fd;
      background-color: #eedfff;`
      : `
      color: rgba(8, 10, 37, 0.5);
      border: 1px solid rgba(41, 46, 115, 0.12);
      background-color: #ffffff;`;
  }}
  border-radius: 40px;
  font-size: 14px;

  &:hover {
    cursor: pointer;
    box-shadow: #7e00fd 0px 0px 5px 0px;
    color: #7e00fd;
    border-color: #7e00fd;
    background-color: #eedfff;
  }
`;

export const RightText = styled.div`
  margin-top: 4px;
  text-align: right;
  font: normal 400 14px/100% "DM Sans";
  letter-spacing: -0.01em;
  color: rgba(8, 15, 108, 0.5);
`;

export const SuggestedTagBlock = styled.div`
  display: flex;
  gap: 4px 4px;
  flex-wrap: wrap;
`;
