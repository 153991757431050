import { FC, memo } from "react";
import { IImageWithPropsProps } from "./imageWithProps.props";
import { Container } from "./imageWithProps.style";

const ImageWithProps: FC<IImageWithPropsProps> = ({
  width,
  height,
  ...props
}: IImageWithPropsProps) => {
  return (
    <Container
      preview={false}
      width={width}
      height={height}
      {...props}
    ></Container>
  );
};

export default memo(ImageWithProps);
