import axios from "api/axios";
import { IBountyTbl, IPaymentMethodTbl } from "types";
import {
  IBountyData,
  ICloseBountyReq,
  IDeliverableDetailReq,
  IDeliverablesActionRequestReq,
  IDeliverablesActionTaskReq,
  IDeliverablesRequestJoinAllReq,
  IDeliverablesRequestJoinReq,
  IDeliverablesSubmissionReq,
  IFindBountyReq,
  IFindBountyRes,
  IGetBountyByProjectIdReq,
  IGetDataMasterReq,
  IGetDataMasterRes,
  IListBountiesReq,
  IListBountiesRes,
  IUpdateBountyRes,
} from "./types";

const END_POINT = {
  GET_PAYMENTS: "/bounties/payments",
  DATA_MASTER: "/bounties/data-master?project_id=projectId&step=:step",
  BOUNTIES: "/bounties",
  GET_ALL: "/bounties/alls",
  GET_ALL_DONE: "/bounties/alls/others",
  FIND: "/bounties/detail/:id",
  CLOSE_BOUNTY: "/bounties/close",
  DELIVERABLES_REQUEST_JOIN: "/deliverables/request-join",
  DELIVERABLES_REQUEST_JOIN_ALL: "/deliverables/request-join/alls",
  DELIVERABLES_ACTION_REQUEST: "/deliverables/:id/action-request",
  DELIVERABLES_ACTION_TASK: "/deliverables/:id/action-task",
  DELIVERABLES_SUBMISSION: "/deliverables/:id/submission",
  DELIVERABLES_DETAIL: "/deliverables/:id",
};

export const getDataMasterAPI = async ({
  projectId,
  step,
}: IGetDataMasterReq): Promise<IGetDataMasterRes> => {
  let url = END_POINT.DATA_MASTER.replace("projectId", `${projectId}`);
  url =
    step === undefined
      ? url.replace("&step=:step", "")
      : url.replace(":step", `${step}`);

  const { data } = await axios({
    url: url,
    method: "GET",
  });

  return data;
};

export const updateBountyAPI = async (
  params: IBountyData
): Promise<IUpdateBountyRes> => {
  const { data } = await axios({
    url: END_POINT.BOUNTIES,
    method: "PUT",
    data: params,
  });
  return data;
};

export const getAllBountiesAPI = async (
  params: IListBountiesReq
): Promise<IListBountiesRes> => {
  const { data } = await axios({
    url: END_POINT.GET_ALL,
    method: "GET",
    params: params,
  });
  return data;
};

export const getAllDoneBountiesAPI = async (
  params: IListBountiesReq
): Promise<IListBountiesRes> => {
  const { data } = await axios({
    url: END_POINT.GET_ALL_DONE,
    method: "GET",
    params: params,
  });
  return data;
};

export const getAllBountiesByProjectIdAPI = async (
  params: IGetBountyByProjectIdReq
): Promise<IListBountiesRes> => {
  const { data } = await axios({
    url: END_POINT.GET_ALL,
    method: "POST",
    data: params,
  });
  return data;
};

export const getBountyByIdAPI = async (
  params: IFindBountyReq
): Promise<IFindBountyRes> => {
  const { data } = await axios({
    url: `${END_POINT.FIND.replace(":id", `${params.id}`)}`,
    method: "GET",
  });
  return data;
};

export const postDeliverablesRequestJoinAPI = async (
  params: IDeliverablesRequestJoinReq
): Promise<any> => {
  const { data } = await axios({
    url: `${END_POINT.DELIVERABLES_REQUEST_JOIN}`,
    method: "POST",
    data: params,
  });

  return data;
};

export const postDeliverablesRequestJoinAllAPI = async (
  params: IDeliverablesRequestJoinAllReq
): Promise<any> => {
  const { data } = await axios({
    url: `${END_POINT.DELIVERABLES_REQUEST_JOIN_ALL}`,
    method: "POST",
    data: params,
  });

  return data;
};

export const putDeliverablesActionRequestAPI = async ({
  id,
  ...params
}: IDeliverablesActionRequestReq): Promise<any> => {
  const { data } = await axios({
    url: `${END_POINT.DELIVERABLES_ACTION_REQUEST.replace(":id", id)}`,
    method: "PUT",
    data: params,
  });

  return data;
};

export const postDeliverablesSubmissionAPI = async ({
  id,
  ...params
}: IDeliverablesSubmissionReq): Promise<any> => {
  const { data } = await axios({
    url: `${END_POINT.DELIVERABLES_SUBMISSION.replace(":id", id)}`,
    method: "POST",
    data: params,
  });

  return data;
};

export const postDeliverableDetailAPI = async ({
  id,
  ...params
}: IDeliverableDetailReq): Promise<any> => {
  const { data } = await axios({
    url: `${END_POINT.DELIVERABLES_DETAIL.replace(":id", id)}`,
    method: "POST",
    data: params,
  });

  return data;
};

export const putDeliverablesActionTaskAPI = async ({
  id,
  ...params
}: IDeliverablesActionTaskReq): Promise<any> => {
  const { data } = await axios({
    url: `${END_POINT.DELIVERABLES_ACTION_TASK.replace(":id", id)}`,
    method: "PUT",
    data: params,
  });

  return data;
};

export const getPaymentsAPI = async (): Promise<IPaymentMethodTbl[]> => {
  const { data } = await axios({
    url: END_POINT.GET_PAYMENTS,
    method: "GET",
  });

  return data;
};

export const closeBountyAPI = async (
  params: ICloseBountyReq
): Promise<IBountyTbl> => {
  const { data } = await axios({
    url: END_POINT.CLOSE_BOUNTY,
    method: "PUT",
    data: params,
  });

  return data;
};

