import { Input, Tag } from "antd";
import styled from "styled-components";

export const TagInputContainer = styled.div<{ inputVisible: boolean }>`
  padding: 8px;
  border-radius: 4px;
  border: 1px solid rgba(41, 46, 115, 0.12);
  display: flex;
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  row-gap: 8px;
  transition: all 0.3s, height 0s;
  ${({ inputVisible }) => {
    return inputVisible
      ? "border-color: #40a9ff; border-right-width: 1px;box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);outline: 0;"
      : "";
  }}
`;
export const AntdPlusTag = styled(Tag)`
  background: #fff;
  border-style: dashed;
  border: none;
  padding-top: 2px;
  border-radius: 40px;
  font: normal 400 14px/100%;
  color: rgba(41, 46, 115, 0.42);
  flex-grow: 1;
`;

export const AntdEditTag = styled(Tag)`
  user-select: none;
  border-radius: 40px;
  display: flex;
  align-items: center;
  font-size: 14px;
`;

export const TagInput = styled(Input)`
  flex-grow: 1;
  box-shadow: none;
  border: none;
  margin-right: 8px;
  vertical-align: top;
  display: inline;
  width: 150px;
  &:focus {
    box-shadow: none;
    border: none;
    display: inline;
  }
`;
