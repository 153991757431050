import { Skeleton } from "antd";
import styled from "styled-components";

const PAGINATE = {
  color: "#7e00fd",
  hover: {
    color: "#6d2caf",
  },
};

export const PaginationContainer = styled.div`
  margin-top: 10px;
  float: right;
  border: 1px solid rgba(8, 10, 37, 0.1);
  border-radius: 4px;
  padding: 10px;
  .ant-pagination-item > a {
    &:hover {
      color: ${PAGINATE.color};
    }
  }

  .ant-pagination-item-link {
    &:hover {
      color: ${PAGINATE.color};
    }
  }

  .ant-pagination-item-link,
  .ant-pagination-item {
    border: none;
    font-weight: 500;
    font-size: 16px;
  }

  .ant-pagination-item-link,
  .ant-pagination-item-active {
    color: ${PAGINATE.color};
  }

  .ant-pagination-item-active {
    background: rgba(126, 0, 253, 0.1);
    border: 1.5px solid ${PAGINATE.color};
    border-radius: 5px;
    a {
      color: ${PAGINATE.color};
    }
  }

  .ant-pagination-item-link-icon {
    color: ${PAGINATE.color} !important;
  }
`;

export const PaginationSkeleton = styled(Skeleton.Button)`
  width: 500px;
  .ant-skeleton-button {
    width: 100%;
  }
`;
