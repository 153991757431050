import { FC, memo } from "react";
import { IButtonColorBackgroundProps } from "./buttonColorMapBackground.props";
import { ButtonColorContainer } from "./buttonColorMapBackground.style";

const ButtonColorMapBackground: FC<IButtonColorBackgroundProps> = ({
  children,
  ...props
}: IButtonColorBackgroundProps) => {
  return <ButtonColorContainer {...props}>{children}</ButtonColorContainer>;
};

export default memo(ButtonColorMapBackground);
