import { forwardRef, memo } from "react";
import { IButtonGradientProps } from "./buttonGradient.props";
import { ButtonGradientAntd } from "./buttonGradient.style";

const ButtonGradient = forwardRef<any, IButtonGradientProps>(
  ({ children, ...props }, ref) => {
    return (
      <ButtonGradientAntd {...props} ref={ref}>
        {children}
      </ButtonGradientAntd>
    );
  }
);

export default memo(ButtonGradient);
