import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const ButtonContainer = styled.div<{ heightVal?: string }>`
  width: 182px;
  border-radius: 8px;
  cursor: pointer;
  background: linear-gradient(
    96.27deg,
    #c58aff 1.94%,
    #7d97fd 19.04%,
    #00adfb 41.09%,
    #00d5c9 68.76%,
    #00de8c 90.53%
  );

  ${({ heightVal }: any) => {
    return heightVal ? `height: ${heightVal}px;` : `height: 48px;`;
  }}
`;

export const ButtonBorder = styled.div<{ heightVal?: string }>`
  margin-top: 1px;
  margin-left: 1px;
  width: 180px;
  ${({ heightVal }: any) => {
    return heightVal
      ? `height: ${(+heightVal - 2).toString()}px;`
      : `height: 46px;`;
  }}
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  color: rgba(8, 10, 37, 0.8);
`;
