import { CommonModal } from "components/base";
import Cookies from "js-cookie";
import { FC, memo } from "react";
import { useDispatch } from "react-redux";
import { homeActions } from "redux/slices/homeSlice";
import { KEY_COOKIE } from "utils/constant";
import { ButtonConnect, ModalTitle, TextDecs } from "../modalAddProject.style";
import { IModalConnectGithubProps } from "./modalConnectGithub.props";
import {
  BtnGetInTouch,
  DecsFooter,
  Footer,
  LeftFooter,
  RightFooter,
  TitleFooter,
} from "./modalConnectGithub.style";

const ModalConnectGithub: FC<IModalConnectGithubProps> = ({
  show,
  ...props
}: IModalConnectGithubProps) => {
  const dispatch = useDispatch();

  const baseUrl = process.env.REACT_APP_API_URI;

  return (
    <CommonModal
      footer={null}
      visible={show}
      centered
      closable={true}
      onCancel={() => {
        dispatch(homeActions.hideAddProjectModalOut());
      }}
      destroyOnClose={true}
      {...props}
    >
      <ModalTitle>Connect Github to add project</ModalTitle>
      <TextDecs>
        Login with Github to select your organisation and add your project.
      </TextDecs>
      <ButtonConnect
        onClick={() => {
          window.location.replace(
            `${baseUrl}/auth/login/social/github?access_token=${Cookies.get(
              KEY_COOKIE.ACCESS_TOKEN
            )}`
          );
        }}
      >
        Connect
      </ButtonConnect>
      <Footer>
        <LeftFooter>
          <TitleFooter>Need help?</TitleFooter>
          <DecsFooter>
            Our team is happy to help you understand adding your project.
          </DecsFooter>
        </LeftFooter>
        <RightFooter>
          <BtnGetInTouch>Get in touch</BtnGetInTouch>
        </RightFooter>
      </Footer>
    </CommonModal>
  );
};

export default memo(ModalConnectGithub);

