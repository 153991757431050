import { FC, memo } from "react";
import { BrowserRouter as Router } from "react-router-dom";

interface BrowserRouterProps {
  children: any;
}

const BrowserRouter: FC<BrowserRouterProps> = (props) => {
  const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
  return <Router basename={baseUrl || ""}>{props.children}</Router>;
};

export default memo(BrowserRouter);
