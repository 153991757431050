import { SkeletonButtonProps } from "antd/lib/skeleton/Button";
import { FC, memo } from "react";
import ISkeletonProps, { SkeletonType } from "./skeletonCustom.props";
import { SkeletonAntd } from "./skeletonCustom.style";

export const SkeletonNode = ({
  height = 30,
  ...props
}: SkeletonButtonProps & { height?: number }) => (
  <SkeletonAntd.Button style={{ height }} {...props} />
);

const SkeletonCustom: FC<ISkeletonProps> = ({
  type,
  ...props
}: ISkeletonProps) => {
  switch (type) {
    case SkeletonType.BUTTON:
      return <SkeletonAntd.Button {...props} />;
    case SkeletonType.AVATAR:
      return <SkeletonAntd.Avatar {...props} />;
    case SkeletonType.INPUT:
      return <SkeletonAntd.Input {...props} />;
    case SkeletonType.IMAGE:
      return <SkeletonAntd.Image {...props} />;
    case SkeletonType.NODE:
      return <SkeletonNode {...props} />;
    default:
      return <SkeletonAntd {...props} />;
  }
};

export default memo(SkeletonCustom);
