import { ArrowLeftOutlined } from "@ant-design/icons";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTER_CONSTANTS } from "utils/constant";
import { IResultsNotFoundProps } from "./resultsNotFound.props";
import {
  Content,
  ErrorContainer,
  GoHomeButton,
  Title,
} from "./resultsNotFound.style";

const ResultsNotFound: FC<IResultsNotFoundProps> = ({
  title,
  content,
  img,
  margin,
  isShowBackHome,
  ...props
}: IResultsNotFoundProps) => {
  let navigate = useNavigate();

  return (
    <ErrorContainer {...props} margin={margin}>
      <img src={img} alt={"empty"} />
      <Title>{title}</Title>
      <Content
        onClick={() => {
          if (content?.onclick) content?.onclick();
        }}
      >
        {content?.text}
      </Content>
      {isShowBackHome && (
        <GoHomeButton
          onClick={() => {
            navigate(ROUTER_CONSTANTS.HOME);
          }}
        >
          <ArrowLeftOutlined />
          Go Back Home
        </GoHomeButton>
      )}
    </ErrorContainer>
  );
};

export default ResultsNotFound;
