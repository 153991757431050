export const ConnectWallet = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.0508 5.62461C15.6508 6.22461 15.6508 7.19961 15.0508 7.72461L12.9508 9.82461L7.10078 3.97461L9.20078 1.87461C9.80078 1.27461 10.7758 1.27461 11.3008 1.87461L12.6508 3.22461L14.9008 0.974609L15.9508 2.02461L13.7008 4.27461L15.0508 5.62461ZM10.7008 9.97461L9.65078 8.92461L7.55078 11.0246L5.97578 9.44961L8.07578 7.34961L7.02578 6.29961L4.92578 8.39961L3.80078 7.34961L1.70078 9.44961C1.10078 10.0496 1.10078 11.0246 1.70078 11.5496L3.05078 12.8996L0.0507812 15.8996L1.10078 16.9496L4.10078 13.9496L5.45078 15.2996C6.05078 15.8996 7.02578 15.8996 7.55078 15.2996L9.65078 13.1996L8.60078 12.1496L10.7008 9.97461Z"
        fill="white"
      />
    </svg>
  );
};

