import axios from "api/axios";
import {
  IActivityReq,
  IJoinCommunityReq,
  IJoinCommunityRes,
  IProjectsAbout,
  IReposReq,
} from "./types";

const END_POINT = {
  GET_PROJECT_BY_ID: "/projects",
  GET_ABOUT_ACTIVITY: "/projects/:id/about/activities",
  GET_ABOUT_COMMUNITY: "/projects/:id/about/communities",
  GET_ABOUT_REPOS: "/projects/:id/about/repositories",
  JOIN_COMMUNITY: "/projects/:id/communities/join",
};

export const getProjectByIDAPI = async (
  id: string | undefined
): Promise<IProjectsAbout> => {
  if (!id) return {};
  const { data } = await axios({
    url: `${END_POINT.GET_PROJECT_BY_ID}/${id}`,
    method: "GET",
  });
  return data;
};

export const getActivityAPI = async (params: IActivityReq) => {
  const { data } = await axios({
    url: `${END_POINT.GET_ABOUT_ACTIVITY.replace(":id", params.id)}`,
    method: "GET",
    params: {
      type: params.type,
    },
  });
  return data;
};

export const getCommunityAPI = async (id: string) => {
  const { data } = await axios({
    url: `${END_POINT.GET_ABOUT_COMMUNITY.replace(":id", id)}`,
    method: "GET",
  });
  return data;
};

export const getReposAPI = async (params: IReposReq) => {
  const { data } = await axios({
    url: `${END_POINT.GET_ABOUT_REPOS.replace(":id", params.id)}`,
    method: "GET",
    params: {
      search: params.search,
      sort: params.sort,
    },
  });
  return data;
};

export const joinCommunityAPI = async (
  params: IJoinCommunityReq
): Promise<IJoinCommunityRes> => {
  const { data } = await axios({
    url: `${END_POINT.JOIN_COMMUNITY.replace(":id", params.id)}`,
    method: "POST",
  });
  return data;
};

