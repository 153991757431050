import axios from "api/axios";
import { ICreateEventCommentReq, IGetAllEventCommentsReq, IUpdateEventCommentReq } from "./types";

const END_POINT = {
  CREATE_EVENT_COMMENTS: "/event-comments",
  GET_EVENT_COMMENT_BY_ID: "/event-comments/:id",
  GET_ALL_EVENT_COMMENTS: "/event-comments",
  GET_CHILD_EVENT_COMMENT: "/event-comments/children/:id",
  UPDATE_EVENT_COMMENT_BY_ID: "/event-comments/:id",
  DELETE_EVENT_COMMENT_BY_ID: "/event-comments/:id",
};

export const createEventCommentAPI = async (params: ICreateEventCommentReq) => {
    const { data } = await axios({
    url: END_POINT.CREATE_EVENT_COMMENTS,
    method: "POST",
    data: params,
  })
  return data;
}

export const getEventCommentByIdAPI = async (id: string) => {
  const { data } = await axios({
    url: `${END_POINT.GET_EVENT_COMMENT_BY_ID.replace(":id", id)}`,
    method: "GET",
  });
  return data;
};

export const getAllEventCommentsAPI = async (params: IGetAllEventCommentsReq) => {
  const { data } = await axios({
    url: `${END_POINT.GET_ALL_EVENT_COMMENTS}`,
    method: "GET",
    params: params,
  });
  return data;
}

export const getChildEventCommentAPI = async (id: string) => {
  const { data } = await axios({
    url: `${END_POINT.GET_CHILD_EVENT_COMMENT.replace(":id", id)}`,
    method: "GET"
  })
  return data;
}

export const updateEventCommentByIdAPI = async (params: IUpdateEventCommentReq) => {
  const data = await axios({
    url: `${END_POINT.UPDATE_EVENT_COMMENT_BY_ID.replace(":id", params.id)}`,
    method: "PUT",
    data: params.updatedEventComment
  })
  return data;
}

export const deleteEventCommentByIdAPI = async (id: string) => {
  const  data  = await axios({
    url: `${END_POINT.DELETE_EVENT_COMMENT_BY_ID.replace(":id", id)}`,
    method: "DELETE"
  })
  return data;
}
