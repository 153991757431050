export interface ICommonValidator {
  acceptableFormat?: string[];
  limitSizeMB?: number;
  minSizeMB?: number;
}

export interface ICommonFileValidator extends ICommonValidator {
  file: File;
  targetType: string;
  targetError: string;
  formatError: string;
  limitSizeError: string;
  minSizeError: string;
}

export interface IImageValidator extends ICommonValidator {
  limitDimension?: number;
}

export interface IVideoValidator extends ICommonValidator {
  limitDuration?: number; // second
}

export interface IAudioValidator extends ICommonValidator {}

export interface IDocumentValidator extends ICommonValidator {}

export interface IFileValidator {
  image?: IImageValidator;
  video?: IVideoValidator;
  audio?: IAudioValidator;
  document?: IDocumentValidator;
  strict?: boolean; // If true, will not accept ether file types
}

export type FileValidated = { isValid: boolean; error?: string };

export const getBase64 = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

export const fileSizeInMB = (file: File) => file.size / 1024 / 1024;

export const getDurationTimeOfVideo = async (file: File): Promise<number> => {
  const duration = await new Promise<number>((resolve, reject) => {
    let reader = new FileReader();

    reader.onload = async () => {
      const duration = await new Promise<number>((resolve, reject) => {
        var audio = new Audio(reader.result?.toString());
        audio.onloadedmetadata = () => {
          resolve(audio.duration);
        };
        reader.onerror = reject;
      });

      resolve(duration);
    };

    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
  return duration;
};

export const commonFileValidator = ({
  file,
  targetType,
  acceptableFormat = ["*"], // Accept all file formats
  minSizeMB = 0,
  limitSizeMB,
  targetError,
  formatError,
  limitSizeError,
  minSizeError,
}: ICommonFileValidator): FileValidated => {
  const [type, format] = file?.type?.split("/");
  if (type !== targetType) return { isValid: false, error: targetError };
  acceptableFormat = acceptableFormat.map((f) => f.toLowerCase());
  if (!acceptableFormat.includes(format) && !acceptableFormat.includes("*"))
    return {
      isValid: false,
      error: formatError,
    };

  const fileSize = fileSizeInMB(file);
  if (fileSize < minSizeMB)
    return {
      isValid: false,
      error: limitSizeError,
    };

  if (limitSizeMB && fileSize > limitSizeMB)
    return {
      isValid: false,
      error: minSizeError,
    };

  return { isValid: true };
};

export const imageFileValidator = async (
  imageFile: File,
  { limitDimension, limitSizeMB, ...other }: IImageValidator
): Promise<FileValidated> => {
  const validateResult = commonFileValidator({
    ...other,
    file: imageFile,
    targetType: "image",
    limitSizeMB,
    targetError: "File is not a image",
    formatError: "Image file is not supported",
    minSizeError: `Image ${imageFile.name} is too large, maximum file size is ${limitSizeMB}MB.`,
    limitSizeError: `Image ${imageFile.name} is too large, maximum file size is ${limitSizeMB}MB.`,
  });

  if (!validateResult.isValid) return validateResult;

  if (limitDimension) {
    const image = new Image();
    image.src = await getBase64(imageFile);
    if (image.width > limitDimension || image.height > limitDimension)
      return {
        isValid: false,
        error: `File ${imageFile.name} has a dimension is more than ${limitDimension}px, maximum dimension is ${limitDimension}px.`,
      };
  }

  return { isValid: true };
};

export const videoFileValidator = async (
  videoFile: File,
  { limitSizeMB, limitDuration, ...other }: IVideoValidator
): Promise<FileValidated> => {
  const validateResult = commonFileValidator({
    ...other,
    file: videoFile,
    targetType: "video",
    limitSizeMB,
    targetError: "File is not a video",
    formatError: "Video file is not supported",
    minSizeError: `Video ${videoFile.name} is too large, maximum file size is ${limitSizeMB}MB.`,
    limitSizeError: `Video ${videoFile.name} is too large, maximum file size is ${limitSizeMB}MB.`,
  });

  if (!validateResult.isValid) return validateResult;

  const videoDuration = await getDurationTimeOfVideo(videoFile);
  if (limitDuration && videoDuration > limitDuration)
    return {
      isValid: false,
      error: `Your video can only maximum ${limitDuration} seconds`,
    };

  return { isValid: true };
};

export const audioFileValidator = async (
  audioFile: File,
  { limitSizeMB, ...other }: IAudioValidator
): Promise<FileValidated> => {
  return commonFileValidator({
    ...other,
    file: audioFile,
    targetType: "audio",
    limitSizeMB,
    targetError: "File is not a audio",
    formatError: "Audio file is not supported",
    minSizeError: `Audio ${audioFile.name} is too large, maximum file size is ${limitSizeMB}MB.`,
    limitSizeError: `Audio ${audioFile.name} is too large, maximum file size is ${limitSizeMB}MB.`,
  });
};

export const documentFileValidator = async (
  documentFile: File,
  { limitSizeMB, ...other }: IDocumentValidator
): Promise<FileValidated> => {
  return commonFileValidator({
    ...other,
    file: documentFile,
    targetType: "application",
    limitSizeMB,
    targetError: "File is not a document",
    formatError: "Document file is not supported",
    minSizeError: `Document ${documentFile.name} is too large, maximum file size is ${limitSizeMB}MB.`,
    limitSizeError: `Document ${documentFile.name} is too large, maximum file size is ${limitSizeMB}MB.`,
  });
};

export const fileValidator = ({
  image,
  audio,
  video,
  document,
  strict = false,
}: IFileValidator) => {
  return async (file: File): Promise<FileValidated> => {
    const [type] = file?.type?.split("/");

    if (type === "image" && image)
      if (image) return imageFileValidator(file, image);

    if (type === "audio" && audio)
      if (audio) return audioFileValidator(file, audio);

    if (type === "video" && video) return videoFileValidator(file, video);

    if (type === "application" && document)
      return documentFileValidator(file, document);

    if (strict) return { isValid: false, error: "File cannot recognized" };

    return { isValid: true };
  };
};

