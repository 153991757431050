export const GradientCheckmark = () => {
  return (
    <svg
      width="105"
      height="106"
      viewBox="0 0 105 106"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M52.3936 105.302C81.243 105.302 104.63 81.8355 104.63 52.8877C104.63 23.94 81.243 0.473145 52.3936 0.473145C23.5443 0.473145 0.157196 23.94 0.157196 52.8877C0.157196 81.8355 23.5443 105.302 52.3936 105.302Z"
        fill="white"
      />
      <path
        d="M52.3936 105.46C45.3195 105.46 38.4624 104.071 32.0021 101.329C25.7664 98.6773 20.1595 94.8916 15.346 90.0617C10.5326 85.2319 6.75975 79.6134 4.11723 73.3488C1.38489 66.874 0 59.986 0 52.8878C0 45.797 1.38489 38.909 4.11723 32.4267C6.75975 26.1697 10.5326 20.5436 15.346 15.7138C20.1595 10.884 25.7589 7.09823 32.0021 4.4467C38.4624 1.70504 45.3195 0.31543 52.3936 0.31543C59.4678 0.31543 66.3249 1.70504 72.7852 4.4467C79.0209 7.09823 84.6278 10.884 89.4412 15.7138C94.2547 20.5436 98.0275 26.1622 100.67 32.4267C103.402 38.909 104.787 45.7895 104.787 52.8878C104.787 59.986 103.402 66.8665 100.67 73.3488C98.0275 79.6058 94.2547 85.2319 89.4412 90.0617C84.6278 94.8916 79.0284 98.6773 72.7852 101.329C66.3249 104.071 59.4678 105.46 52.3936 105.46ZM52.3936 0.630909C45.3644 0.630909 38.5448 2.01301 32.1219 4.73965C25.9236 7.36864 20.3466 11.1394 15.5706 15.9392C10.7797 20.7389 7.02924 26.3274 4.40169 32.5469C1.68432 38.9917 0.314407 45.8346 0.314407 52.8878C0.314407 59.941 1.69181 66.7839 4.40918 73.2287C7.02924 79.4481 10.7871 85.0441 15.5706 89.8364C20.3541 94.6362 25.9236 98.4069 32.1219 101.036C38.5448 103.763 45.3644 105.145 52.3936 105.145C59.4229 105.145 66.2425 103.763 72.6654 101.036C78.8637 98.4069 84.4407 94.6362 89.2167 89.8364C94.0001 85.0366 97.758 79.4481 100.378 73.2287C103.095 66.7839 104.473 59.941 104.473 52.8878C104.473 45.8346 103.095 38.9917 100.378 32.5469C97.758 26.3274 94.0001 20.7314 89.2167 15.9392C84.4407 11.1394 78.8712 7.36864 72.6654 4.73965C66.2425 2.01301 59.4229 0.630909 52.3936 0.630909Z"
        fill="#31313B"
      />
      <path
        d="M52.3936 99.7214C78.1712 99.7214 99.0681 78.7533 99.0681 52.8878C99.0681 27.0223 78.1712 6.0542 52.3936 6.0542C26.6161 6.0542 5.71921 27.0223 5.71921 52.8878C5.71921 78.7533 26.6161 99.7214 52.3936 99.7214Z"
        fill="url(#paint0_linear_2_2464)"
      />
      <path
        d="M71.311 27H68.2639L48.5477 55.6607L35.7991 41.2541H32.752L33.1127 43.6449L25.1816 50.7125L47.5842 79.597H50.5601L82.7591 35.4201L71.311 27Z"
        fill="#31313B"
      />
      <path
        d="M47.5646 79.4446L22.1387 50.7125L32.7514 41.2541L46.5062 56.7942L68.2633 27L79.7161 35.4201L47.5646 79.4446Z"
        fill="white"
      />
      <path
        d="M47.5683 79.7399L47.4877 79.6494L22 50.8458L32.7646 41.2541L32.8311 41.3303L46.5004 56.7751L68.2433 27L79.8527 35.5392L79.7958 35.6201L47.5683 79.7399ZM22.2753 50.8601L47.5541 79.4208L79.5774 35.582L68.286 27.2762L46.5099 57.0894L32.7456 41.5351L22.2753 50.8601Z"
        fill="#31313B"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2_2464"
          x1="9.69714"
          y1="-11.9587"
          x2="100.395"
          y2="-2.02912"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C58AFF" />
          <stop offset="0.193041" stopColor="#7D97FD" />
          <stop offset="0.44193" stopColor="#00ADFB" />
          <stop offset="0.754306" stopColor="#00D5C9" />
          <stop offset="1" stopColor="#00DE8C" />
        </linearGradient>
      </defs>
    </svg>
  );
};
