import { Layout } from "antd";
import styled from "styled-components";

const { Footer } = Layout;

export const ContactContainer = styled.div``;

export const FooterAd = styled(Footer)`
  max-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
