import axios from "api/axios";
import { IPostTbl } from "types";
import {
  IPostsAllReq,
  IPostsAllRes,
  IPostsByIDReq,
  IPostsByProjectIdReq,
  IPostsReq,
  IPostResult,
} from "./types";

const END_POINT = {
  POSTS: "/posts",
  POSTS_ALLS: "/posts/alls",
  POST_BY_PROJECT_ID: "/posts/:projectId/alls",
};

export const postPostAPI = async (params: IPostsReq): Promise<IPostTbl> => {
  const { data } = await axios({
    url: END_POINT.POSTS,
    method: "POST",
    data: params,
  });
  return data;
};

export const postPostsAllAPI = async (
  params: IPostsAllReq
): Promise<IPostsAllRes> => {
  const { data } = await axios({
    url: END_POINT.POSTS_ALLS,
    method: "POST",
    data: params,
  });
  return data;
};

export const getPostByIdAPI = async (
  params: IPostsByIDReq
): Promise<IPostResult> => {
  const { data } = await axios({
    url: `${END_POINT.POSTS}/${params.id}`,
    method: "GET",
  });
  return data;
};

export const getPostsByProjectIdAPI = async (
  params: IPostsByProjectIdReq
): Promise<IPostsAllRes> => {
  const { data } = await axios({
    url: `${END_POINT.POST_BY_PROJECT_ID.replace(
      ":projectId",
      params.project_id ?? ""
    )}`,
    method: "POST",
    data: params.body,
  });
  return data;
};

export const putPostByIdAPI = async ({
  project_id,
  ...bodyData
}: IPostsReq): Promise<any> => {
  const { data } = await axios({
    url: `${END_POINT.POSTS}/${project_id}`,
    method: "PUT",
    data: bodyData,
  });
  return data;
};

export const delPostByIdAPI = async (id: IPostsByIDReq): Promise<any> => {
  const { data } = await axios({
    url: `${END_POINT.POSTS}/${id}`,
    method: "DELETE",
  });
  return data;
};
