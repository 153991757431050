import { Input } from "antd";
import TextArea from "antd/lib/input/TextArea";
import styled from "styled-components";

export const Container = styled.span`
  font: normal 600 18px/100% "DM Sans";
  display: block;
  color: #080a25;
`;
export const InputItem = styled(Input)`
  border: 1px solid rgba(115, 137, 149, 0.2);
  border-radius: 4px;
`;
export const TextAreaItem = styled(TextArea)`
  border: 1px solid rgba(115, 137, 149, 0.2);
  border-radius: 4px;
`;
