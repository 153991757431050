import { FC } from "react";
import { IButtonWhiteProps } from "./buttonWhite.props";
import { ButtonWhiteAntd } from "./buttonWhite.style";

const ButtonWhite: FC<IButtonWhiteProps> = ({
  children,
  ...props
}: IButtonWhiteProps) => {
  return <ButtonWhiteAntd {...props}>{children}</ButtonWhiteAntd>;
};

export default ButtonWhite;
