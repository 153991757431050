import { useMutation } from "@tanstack/react-query";
import { Form, message } from "antd";
import { ILoginParams, loginAPIAd } from "api/admin/auth";
import { AxiosError, AxiosResponse } from "axios";
import Cookies from "js-cookie";
import { FC, memo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { KEY_COOKIE, ROUTER_CONSTANTS } from "utils/constant";
import { emailRules, requiredRules } from "utils/validatorRules";
import { ILoginAdProps } from "./loginAd.props";
import {
  ButtonAuth,
  ContainerAuth,
  InputAuth,
  InputAuthPass,
  AuthContainer,
  SiginLink,
  SiginText,
  TitleAuth,
} from "./loginAd.style";
import { getMessageContent } from "utils/message";
import { clearStorageAndGlobalState } from "utils/storage";

const LoginAd: FC<ILoginAdProps> = (props: ILoginAdProps) => {
  let navigate = useNavigate();
  const isLoggedInAdmin = Cookies.get(KEY_COOKIE.ACCESS_TOKEN_ADMIN);

  const { mutate, isLoading } = useMutation(loginAPIAd, {
    onSuccess: async (rs: AxiosResponse) => {
      if (rs?.data) {
        clearStorageAndGlobalState(false);
        Cookies.set(
          KEY_COOKIE.ACCESS_TOKEN_ADMIN,
          rs?.data?.admin_access_token,
          {
            domain: process.env.REACT_APP_COOKIE_DOMAIN,
          }
        );
        navigate(ROUTER_CONSTANTS.ADMIN_PAGES.HOME);
      }
    },
    onError: (err: AxiosError) => {
      if (err.response?.status === 422) {
        message.error("Invalid email or password");
      } else {
        const data: any = err.response?.data;
        message.error(
          getMessageContent(data?.message) ||
            "An error occurred while logging in"
        );
      }
    },
  });

  const onFinish = (values: ILoginParams) => {
    mutate(values);
  };

  useEffect(() => {
    if (isLoggedInAdmin) {
      navigate(ROUTER_CONSTANTS.ADMIN_PAGES.HOME);
    }
  }, [isLoggedInAdmin, navigate]);

  return (
    <ContainerAuth {...props}>
      <AuthContainer>
        <TitleAuth>Login</TitleAuth>
        <Form
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          autoComplete="off"
          onFinish={onFinish}
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[emailRules, requiredRules]}
          >
            <InputAuth />
          </Form.Item>

          <Form.Item label="Password" name="password" rules={[requiredRules]}>
            <InputAuthPass />
          </Form.Item>

          <Form.Item>
            <ButtonAuth
              loading={isLoading}
              disabled={isLoading}
              type="primary"
              htmlType="submit"
            >
              Login
            </ButtonAuth>
          </Form.Item>

          <SiginText>
            Or Sign Up Using{" "}
            <SiginLink to={ROUTER_CONSTANTS.ADMIN_PAGES.SIGIN}>Sigin</SiginLink>
          </SiginText>
        </Form>
      </AuthContainer>
    </ContainerAuth>
  );
};

export default memo(LoginAd);

