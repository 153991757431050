import { Input } from "antd";
import styled from "styled-components";

export const Label = styled.span<{
  active: boolean;
  colorBefore: string;
  colorAfter: string;
}>`
  ${({ active, colorBefore, colorAfter }) => {
    return active
      ? `color:${colorAfter} !important;`
      : `color:${colorBefore} !important;`;
  }}
`;

export const InputLink = styled(Input)`
  border: none;
  padding-bottom: 10px;
  border-bottom: 1px solid #e2dfe9;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
  padding-left: 0px;
  background: inherit;
  box-shadow: unset;
`;
