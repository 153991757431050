import { forwardRef, memo } from "react";
import { IButtonBorderGradientProps } from "./buttonBorderGradient.props";
import {
  ButtonBorder,
  ButtonContainer,
  Container,
} from "./buttonBorderGradient.style";

const ButtonBorderGradient = forwardRef<any, IButtonBorderGradientProps>(
  ({ children, height, ...props }, ref) => {
    return (
      <Container {...props} ref={ref}>
        <ButtonContainer heightVal={height}>
          <ButtonBorder heightVal={height}>{children}</ButtonBorder>
        </ButtonContainer>
      </Container>
    );
  }
);

export default memo(ButtonBorderGradient);
