import { useMutation } from "@tanstack/react-query";
import { Button, Checkbox, Collapse, Row } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { IUploadsReq, IUploadsRes, postUploadsAPI } from "api/users/uploads";
import { AxiosError } from "axios";
import { FC, memo, useRef, useState } from "react";
import ReactJson from "react-json-view";
import { METHOD_API } from "../apiTest";

const { Panel } = Collapse;

const UploadsAPI: FC<any> = () => {
  const [isShow, setIsShow] = useState<boolean>(true);
  const [initUploads] = useState<IUploadsReq>({
    file_name: "test",
    file_type: ".txt",
  });
  const [uploadsSuccess, setUploadsSuccess] = useState<IUploadsRes>();
  const [uploadsErr, setUploadsErr] = useState<any>();

  const checkBox = useRef<any>(null);

  const { mutate: postUploads, isLoading: isLoadingPostUploads } = useMutation(
    postUploadsAPI,
    {
      onSuccess: async (rs: any) => {
        setUploadsSuccess(rs);
      },
      onError: (err: AxiosError) => {
        const data: any = err.response?.data;
        setUploadsErr(data);
      },
    }
  );

  return (
    <>
      <Row style={{ marginTop: "10px" }}>
        <h1>
          <b>Uploads</b>
        </h1>
        <Checkbox
          ref={checkBox}
          onChange={(e: CheckboxChangeEvent) => {
            setIsShow(e.target.checked);
          }}
          style={{
            marginLeft: "10px",
          }}
          checked={isShow}
        />
      </Row>
      {isShow && (
        <Collapse accordion>
          <Panel header="/uploads/single" key="1" extra={METHOD_API.POST}>
            <h1>Parameters</h1>
            <ReactJson
              src={initUploads}
              onEdit={(e: any) => {
                initUploads[e.name as keyof IUploadsReq] = e.new_value;
              }}
              enableClipboard={false}
            />
            <Button
              loading={isLoadingPostUploads}
              disabled={isLoadingPostUploads}
              onClick={() => {
                setUploadsErr(undefined);
                setUploadsSuccess(undefined);
                postUploads(initUploads);
              }}
            >
              Click to Upload
            </Button>
            {uploadsSuccess && <ReactJson src={uploadsSuccess} />}
            {uploadsErr && (
              <ReactJson
                theme={"greenscreen"}
                enableClipboard={false}
                displayDataTypes={false}
                src={uploadsErr}
              />
            )}
          </Panel>
        </Collapse>
      )}
    </>
  );
};

export default memo(UploadsAPI);
