import { Tabs } from "antd";
import styled from "styled-components";

export const ContainerTabs = styled(Tabs)`
  .ant-tabs-nav {
    border-radius: 4px;
    border-bottom: none !important;
    &:before {
      border-bottom: none !important;
    }
  }
  .ant-tabs-nav-wrap {
    height: 76px;
    padding: 0px 4px;

    justify-content: flex-start !important;
    background: linear-gradient(
      0deg,
      rgba(8, 10, 37, 0.05) 0%,
      rgba(248, 249, 250, 0) 100%
    );
    position: relative;
    .ant-tabs-nav-list {
      align-items: flex-end;
    }
    .ant-tabs-tab-btn {
      font: normal 500 20px/100% "Rubik";
      color: #080a25;
    }
  }
  .ant-tabs-ink-bar {
    background: #7e00fd;
  }
`;
