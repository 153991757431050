import { Select } from "antd";
import styled from "styled-components";

export const FilterByType = styled(Select)`
  min-width: fit-content;
  &:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid rgba(8, 10, 37, 0.1);
    border-radius: 4px;
  }

  .ant-select-selection-item {
    ::after {
      margin-left: 5px;
    }
  }
`;

export const IconFilter = styled.img`
  opacity: 0.3;
`;
