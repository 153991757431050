import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64px;
  background: #080a25;
  border-radius: 12px;
  margin: 30px 0px 30px 0px;
  span {
    color: #ffffff;
    font-weight: 400;
    font-size: 21px;
    line-height: 100%;
    opacity: 0.7;
  }
`;

export const ContainerImage = styled.img`
  width: 100%;
  height: 64px;
  object-fit: cover;
  border-radius: 12px;
  margin: 30px 0px 30px 0px;
`;
