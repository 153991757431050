import axios from "api/axios";
import {
  IPostsCommentGetReq,
  IPostsCommentsAllReq,
  IPostsCommentsChilReq,
  IPostsCommentsIdReq,
  IPostsCommentsReq,
  IPostsCommentsRes,
  IPutCommentsReq,
} from "./types";

const END_POINT = {
  POSTS_COMMENTS: "/post-comments",
  POSTS_COMMENTS_CHILDREN: "/post-comments/children",
  POSTS_COMMENTS_ALL: "/post-comments/alls",
};

export const postPostsCommentsAPI = async (
  params: IPostsCommentsReq
): Promise<IPostsCommentsRes> => {
  const { data } = await axios({
    url: END_POINT.POSTS_COMMENTS,
    method: "POST",
    data: params,
  });
  return data;
};

export const getAllPostsCommentAPI = async (
  params: IPostsCommentsAllReq
): Promise<any> => {
  const { data } = await axios({
    url: END_POINT.POSTS_COMMENTS_ALL,
    method: "POST",
    data: params,
  });
  return data;
};

export const getPostCommentsByIdAPI = async (
  params: IPostsCommentGetReq
): Promise<any> => {
  const { data } = await axios({
    url: `${END_POINT.POSTS_COMMENTS}/${params.id}`,
    method: "POST",
  });
  return data;
};

export const putPostCommentsByIdAPI = async ({
  id: idPost,
  ...bodyData
}: IPutCommentsReq): Promise<any> => {
  const { data } = await axios({
    url: `${END_POINT.POSTS_COMMENTS}/${idPost}`,
    method: "PUT",
    data: bodyData,
  });
  return data;
};

export const delPostCommentsByIdAPI = async (
  params: IPostsCommentsIdReq
): Promise<any> => {
  const { data } = await axios({
    url: `${END_POINT.POSTS_COMMENTS}/${params.id}`,
    method: "DELETE",
  });
  return data;
};

export const getPostChildCommentsAPI = async (
  params: IPostsCommentsChilReq
): Promise<any> => {
  const { data } = await axios({
    url: `${END_POINT.POSTS_COMMENTS_CHILDREN}/${params.id}`,
    method: "POST",
    data: params,
  });
  return data;
};
