import { Card, List } from "antd";
import styled from "styled-components";

export const WrapBlock = styled.div``;

export const ImageBlock = styled.div`
  text-align: center;
  height: 200px;
  width: 100%;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

export const Heading = styled.div`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 100%;
  color: #080a25;
  letter-spacing: -0.04em;
`;

export const Heading2 = styled.div`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 120%;
  color: #080a25;
`;

export const Description = styled.div`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 162%;
  color: rgba(41, 46, 115, 0.82);
  margin-top: 8px;
`;

export const GradientCard = styled(Card)`
  background: linear-gradient(
    90deg,
    #c58aff20 0%,
    #00adfb20 50.61%,
    #00c1ce20 78.78%,
    #00de8c20 100%
  );

  .ant-card-body {
    padding: 32px;
  }
`;

export const WrapContent = styled.div`
  margin-left: 32px;
  margin-right: 32px;
  display: grid;
  gap: 20px;
`;

export const SkillListItem = styled(List.Item)`
  .ant-tag {
    border-radius: 50rem;
    background: transparent;
    font: normal 400 16px/100% "Rubik";
    padding: 4px 12px;
  }
`;

export const ProjectListItem = styled(List.Item)`
  display: flex !important;
  align-items: center;
  justify-content: start;
  gap: 12px;
  padding: 12px !important;
  border: 1px solid rgba(156, 138, 193, 0.16) !important;
  border-radius: 8px;
  width: 240px;
  margin-bottom: 8px !important;
  
  a {
    display: flex;
    gap: 12px;
    align-items: center;
    width: 100%;
  }

  .thumbnail,
  svg {
    width: 32px;
    height: 32px;
    border-radius: 6px;
  }

  .ant-typography {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: #080a25;
  }
`;

export const LinkBLock = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  svg {
    width: 20px;
    height: 20px;

    path {
      fill: #7e00fd;
    }
  }

  .ant-typography {
    width: 700px;
    color: rgba(41, 46, 115, 0.82);
    font: normal 400 16px/100% "Dm Sans";

    &:hover {
      color: #7e00fd;
    }
  }
`;
