import styled from "styled-components";

export const CardSocialContainer = styled.div`
  padding: 20px;
`;

export const Header = styled.div`
  display: grid;
  grid-template-columns: 90% auto;
  margin-bottom: 20px;
`;

export const Title = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 120%;
  color: rgba(8, 10, 37, 0.8);
`;

export const Icon = styled.div`
  text-align: right;
  svg {
    width: 24px;
    height: 24px;
  }
`;
