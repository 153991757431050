import { Avatar, Col, Row, Space } from "antd";
import { SkeletonCustom } from "components/base";
import { SkeletonType } from "components/base/skeletonCustom/skeletonCustom.props";
import { FC, memo } from "react";
import { useParams } from "react-router-dom";
import {
  BackgroundContainer,
  BalanceButton,
  Container,
  Heading,
  SkeletonThumbnail,
  SkeletonTitle,
  StatusBadge,
  TechnologyBadge,
  Unit,
} from "./issueDetailHeader.style";
import AvatarImg from "assets/common/png/avatar.png";
import { useGetBountyById } from "api/users/bounties/queries";

const IssueDetailHeader: FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data, isLoading } = useGetBountyById({ id: +id! });

  return (
    <BackgroundContainer>
      <Container>
        <Row>
          <Col>
            <Row gutter={[16, 16]}>
              <Col flex="none">
                {isLoading ? (
                  <SkeletonThumbnail type={SkeletonType.IMAGE} active />
                ) : (
                  <Avatar
                    size={64}
                    src={data?.project?.avatar_url || AvatarImg}
                  />
                )}
              </Col>
              <Col flex="auto">
                {isLoading ? (
                  <>
                    <Heading>
                      <SkeletonTitle active paragraph={false} />
                    </Heading>
                    <SkeletonTitle active paragraph={false} />
                  </>
                ) : (
                  <>
                    <Heading>
                      {data?.title}{" "}
                      {data?.is_require_approval ? (
                        <StatusBadge count="APPROVAL" />
                      ) : (
                        <StatusBadge count="PERMISSIONLESS" />
                      )}
                    </Heading>
                    <Space direction="horizontal">
                      {(data?.tags || []).map((tag, pos: number) => (
                        <TechnologyBadge key={pos} count={tag.name || ""} />
                      ))}
                    </Space>
                  </>
                )}
              </Col>
            </Row>
          </Col>
          <Col>
            <Space direction="vertical" align="center">
              {isLoading ? (
                <SkeletonCustom type={SkeletonType.BUTTON} active />
              ) : (
                <>
                  <BalanceButton>
                    {data?.amount || 0} {data?.token?.name || ""}
                  </BalanceButton>
                  <div>
                    In <Unit>{data?.payment?.name || ""}</Unit>
                  </div>
                </>
              )}
            </Space>
          </Col>
        </Row>
      </Container>
    </BackgroundContainer>
  );
};

export default memo(IssueDetailHeader);
