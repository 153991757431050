import { Col } from "antd";
import { FC } from "react";
import { IContainerFluidProps } from "./containerFluid.props";
import { Container } from "./containerFluid.style";

const ContainerFluid: FC<IContainerFluidProps> = ({
  children,
  ...props
}: IContainerFluidProps) => {
  return (
    <Container justify="center" {...props}>
      <Col flex="136px"/>
      <Col flex="auto">{children}</Col>
      <Col flex="136px"/>
    </Container>
  );
};

export default ContainerFluid;
