import Icon from "@ant-design/icons";
import { Button, Input, Popover } from "antd";
import styled from "styled-components";
import { ButtonUpvoteAntd } from "../buttonUpvote/buttonUpvote.style";

export const ButtonShareBlock = styled.div`
  .ant-popover-inner-content {
    padding: unset;
  }
  .ant-popover-arrow {
    display: none !important;
  }
  .ant-popover-placement-bottom {
    padding: 0 !important;
  }
`;

export const ButtonShareAntd = styled(ButtonUpvoteAntd)``;

export const ShareBlock = styled.div`
  width: 302px;
  height: 186px;
  padding: 20px 16px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 1px 16px 0 rgb(45 45 67 / 15%);
  transition: opacity 0.3s;
`;

export const ShareBlockTitle = styled.h3`
  font: normal 700 14px/100% "Rubik";
  margin-bottom: 8px;
`;

export const InputLinkBlock = styled.div`
  position: relative;
  height: 36px;
`;

export const InputLink = styled(Input)`
  border-radius: 6px;
  height: 36px !important;
  padding: 8px 16px !important;
`;

export const CopyButton = styled(Button)`
  width: 70px;
  height: 32px;
  background: #f6f6f9 !important;
  border: none;
  position: absolute;
  right: 2px;
  top: 2px;
  border-radius: 6px;
  padding: 4px 8px;
  color: #716f7c;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    margin-left: 2px !important;
    font: normal 700 13px/100% "Rubik";
  }
`;

export const TextTitle = styled.div`
  font: normal 500 16px/100% "Rubik";
  margin: 16px 0px;
`;

export const ButtonBlock = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 8px;
`;

export const ButtonTwitter = styled(Button)`
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1da1f2 !important;
  border-radius: 4px;
`;

export const ButtonLinkedln = styled(Button)`
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0073b1 !important;
  border-radius: 4px;
`;
export const ButtonFacebook = styled(Button)`
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2174ee !important;
  border-radius: 4px;
`;

export const ButtonIconAntd = styled(Icon)`
  padding-top: 4px;
`;

export const CopyNotification = styled.div<{ isShow?: boolean }>`
  ${({ isShow }: any) => {
    return isShow
      ? `visibility: visible;`
      : `visibility: hidden;
    `;
  }}
  position: absolute;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.6);
  font: normal 500 13px/100% "Rubik";
  padding: 10px 16px;
  border-radius: 8px;
  top: 130px;
  left: 77px;
  text-align: center;
`;

export const PopoverAntd = styled(Popover)``;
