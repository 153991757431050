import { CloseOutlined } from "@ant-design/icons";
import { FC, memo } from "react";
import { ISmallDeleteButtonProps } from "./smallDeleteButton.props";
import { Delete } from "./smallDeleteButton.style";

const SmallDeleteButton: FC<ISmallDeleteButtonProps> = (
  props: ISmallDeleteButtonProps
) => {
  const { index, handleDelete, right, top } = props;
  return (
    <Delete
      className="delete-button"
      right={right}
      top={top}
      onClick={(e) => {
        e.stopPropagation();
        handleDelete(index);
      }}
    >
      <div>
        <CloseOutlined />
      </div>
    </Delete>
  );
};

export default memo(SmallDeleteButton);
