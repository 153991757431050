import { Space } from "antd";
import { FC, memo } from "react";
import { IDropdownFilterProps } from "./dropdownFilter.props";
import { ButtonFilter, DropdownCustom } from "./dropdownFilter.style";
import { ArrowDown } from "assets/common/svg/ArrowDown.icon";

const DropdownFilter: FC<IDropdownFilterProps> = ({
  value,
  ...props
}: IDropdownFilterProps) => {
  return (
    <DropdownCustom {...props}>
      <ButtonFilter>
        <Space>
          {value}
          <ArrowDown />
        </Space>
      </ButtonFilter>
    </DropdownCustom>
  );
};

export default memo(DropdownFilter);
