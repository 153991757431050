import { FC, useState } from "react";
import { IConnectWalletModalProps } from "./connectWalletModal.props";
import {
  Logo,
  Modal,
  Title,
  WalletsContainer,
  Wrapper,
} from "./connectWalletModal.style";
import MetamaskImg from "assets/common/png/metamask.png";
import WalletConnectImg from "assets/common/png/wallet-connect.png";
import CoinbaseImg from "assets/common/png/coinbase.png";
import { Spin } from "antd";
import ConnectionItem from "./connectionItem";
import { useWallet } from "hooks/useWallet";

const ConnectWalletModal: FC<IConnectWalletModalProps> = ({
  visible,
  onCancel,
  onSuccess,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { isLoading } = useWallet();

  return (
    <Modal visible={visible} closable={false} footer={null} onCancel={onCancel}>
      <Spin spinning={loading || isLoading}>
        <Wrapper>
          <Logo />
          <Title>Connect Your Wallet</Title>
          <WalletsContainer>
            <ConnectionItem
              connector="metamask"
              name="Metamask"
              img={MetamaskImg}
              onSuccess={onSuccess}
              setLoading={setLoading}
            />
            <ConnectionItem
              connector="walletConnect"
              name="WalletConnect"
              img={WalletConnectImg}
              onSuccess={onSuccess}
              setLoading={setLoading}
            />
            <ConnectionItem
              connector="coinbase"
              name="CoinbaseWallet"
              img={CoinbaseImg}
              onSuccess={onSuccess}
              setLoading={setLoading}
            />
          </WalletsContainer>
        </Wrapper>
      </Spin>
    </Modal>
  );
};

export default ConnectWalletModal;

