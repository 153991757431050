import { FC, memo } from "react";
import { ILabelRequiredFormProps } from "./labelRequiredForm.props";
import { RequiredSymb } from "./labelRequiredForm.style";

const LabelRequiredForm: FC<ILabelRequiredFormProps> = ({
  children,
  ...props
}: ILabelRequiredFormProps) => {
  return (
    <span {...props}>
      {children} <RequiredSymb>*</RequiredSymb>
    </span>
  );
};

export default memo(LabelRequiredForm);
