import { getBlockchainErrorMessageContent } from "smartContract/messages";

export const NOTIFICATION = {
  SESEND_VERIFY_EMAIL:
    "If you entered a valid email, you will receive an email with instructions about how to change your email in a few minutes.",
  NOT_LOGIN: "You must be logged in.",
  CREATE_SUCCESS: "Create successfully",
  UPDATE_SUCCESS: "Update successfully",
  WALLET_ADDRESS_NOT_EXISTS: "Please connect wallet!",
  VOTE_SUCCESS: "Voted successfully",
  NOT_JOIN_COMMUNITY: "Please join :name first",
  PAGE_NOT_FOUND: "Page not found.",
};

export const VALIDATE_MESSAGES = {
  INVALID_TIME_TO_END: "Start date should be less than or equal to End date",
  INVALID_END_TIME: "Start time should be less than End time",
  NOT_LOGIN: "You must be logged in!",
  REQUIRED: "This field is required.",
  VOTED_PROPOSAL: "You have already voted on this proposal.",
  UPLOADING_PROPOSAL_IPFS:
    "We're updating the data on IPFS.  Please wait a minute.",
};

export const MESSAGE_KEY = {
  NOT_FOUND_PROJECT: "Not found this project",
  NOT_FOUND_ADVERT_PLAN: "Not found this advert plan",
  NOT_FOUND_ADVERT: "Not found this advert",
  ADVERTS_IS_PROCESSING: "Adverts is processing",
  ADVERT_IS_RUNNING: "Advert is running",
  INVALID_ADVERT_STATUS: "Invalid advert status",
  USER_NOT_OWNED_PROJECT: "User is not owned this project",
  USER_NOT_OWNED_ADVERT: "User not owned advert",
  UNAUTHORIZED_ADMIN: "Unauthorizied admin",
  COUNTRY_NOT_FOUND: "Not found this country",
  COUNTRY_IS_EXIST: "Country is existed",
  DATA_SETUP_NOT_FOUND: "Not found this data setup",
  DATA_SETUP_IS_EXIST: "Data setup is existed",
  EVENT_NOT_FOUND: "Not found this event",
  EVENT_NOT_BELONGS_USER: "Data setup is existed",
  EVENT_MUST_HAVE_SPEAKER: "Event must have at least one speaker",
  USER_NOT_IS_PARTICIPATED_PROJECT: "User is not participated in this project",
  EVENT_HAVE_ONLY_ONE_HOST: "Event only have one host",
  EVENT_HAVE_DUPLICATE_SPEAKER: "Event has duplicated speakers",
  EVENT_COMMENT_NOT_FOUND: "Not found this event comment",
  USER_NOT_PERMISSION_DELETE_EVENT_COMMENTS:
    "User has no permission to delete this event comments",
  EVENT_POLL_NOT_FOUND: "Not found this event poll",
  USER_NOT_PERMISSION_DELETE_EVENT_POLLS:
    "User has no permission to delete this event polls",
  EVENT_POLL_ITEM_NOT_FOUND: "Not found this event poll item",
  USER_NOT_PERMISSION_DELETE_EVENT_POLL_ITEMS:
    "User has no permission to delete this event poll items",
  EVENT_QUESTION_NOT_FOUND: "Not found this event question",
  USER_NOT_PERMISSION_DELETE_EVENT_QUESTIONS:
    "User has no permission to delete this event questions",
  EMAIL_ALREADY_REGISTERED: "This email has already registered",
  PHONE_NUMBER_ALREADY_REGISTERED: "This phone number has already registered",
  EVENT_SPEAKER_NOT_FOUND: "Not found this event speaker",
  FAQ_NOT_FOUND: "Not found this FAQ",
  FAQ_IS_EXISTS: "FAQ is existed",
  ISSUE_TAG_NOT_FOUND: "Not found this issue tags",
  ISSUE_TAG_IS_EXISTS: "Issue tag is existed",
  LANGUAGE_NOT_FOUND: "Not found this language",
  LANGUAGE_IS_EXIST: "Language is existed",
  LICENSE_NOT_FOUND: "Not found this license",
  LICENSE_IS_EXISTS: "License is existed",
  POST_NOT_FOUND: "Not found this post",
  POST_NOT_BELONGS_USER: "This post is not belongs to this user",
  POST_COMMENT_NOT_FOUND: "Not found this post comment",
  USER_NOT_PERMISSION_DELETE_POST_COMMENTS:
    "User has no permission to delete this post comments",
  WRONG_DATE_FROM_KEY: "Wrong date from key",
  PROJECT_NOT_EXIST: "Project is not existed",
  USER_NOT_OWN_PROJECT: "User not own this project",
  PROJECT_HAS_ALREADY_EXISTED: "Project has already existed",
  PROJECT_HAVE_NO_ORGANIZATION: "Project has no organization",
  ORGANIZATION_HAVE_NO_REPOSITORY: "Organization has no repository",
  PROJECT_NOT_FOUND: "Not found this project",
  EMPTY_ORGANIZERS_LIST: "Empty organizers list",
  MEMBER_NOT_IN_PROJECT: "Member is not in this project",
  PROJECT_NOT_IN_SETUP: "Project is not in setup",
  PROJECT_IS_INACTIVE: "Project is inactive",
  INVALID_TOKEN_POINTS_LENGTH: "Invalid token points length",
  INVALID_GOVERNANCES_LENGTH: "Invalid governances length",
  SEED_NOT_FOUND: "Not found this seed",
  SEED_IS_EXISTS: "This seed is existed",
  THEME_NOT_FOUND: "Not found this theme",
  THEME_IS_EXISTS: "This theme is existed",
  THEME_ITEM_NOT_FOUND: "Not found this theme item",
  THEME_ITEM_IS_EXIST: "This theme item is existed",
  USER_NOT_FOUND: "Not found this user",
  WRONG_PASSWORD: "Wrong password",
  EMAIL_NOT_VERIFIED: "Email is not verified",
  EMAIL_IS_EXISTS: "Email already exists",
  GITHUB_EMAIL_ALREADY_IN_USE: "Github email has already in use",
  GITHUB_EMAIL_ALREADY_EXIST: "Github email has already existed",
  GITHUB_EMAIL_NOT_EXIST: "Github email is not existed",
  TOKEN_IS_NOT_CORRECT: "Token is not correct",
  USER_IS_DISABLED: "This user is disabled",
  USER_VERIFIED: "This user is verified",
  CANT_REGISTER_USER: "Can't register this user",
  EMAIL_ALREADY_IN_USE: "Email has already in use",
  ACCOUNT_BLOCK: "This account is blocked",
  USER_IS_RESTRICTED: "Sorry, you have been blocked",
  COUNTRY_IS_NOT_EXIST: "Country is not existed",
  TWO_PASSWORDS_DO_NOT_MATCH: "Two passwords do not match",
  USER_HKT_NOT_FOUND: "This HKT user is not founds",
  GITHUB_ALREADY_EXISTED: "This github is already existed",
  WORK_MODE_NOT_FOUND: "Work mode is not founded",
  ADMIN_NOT_FOUND: "Not found this admin",
  ADMIN_IS_DISABLED: "This admin is disabled",
  ANSWER_NOT_FOUND: "Not found this answer",
  ANSWER_NOT_BELONGS_USER: "This answer is not belonged to this user",
  BOOKMARK_NOT_FOUND: "Not found this bookmark",
  BOOKMARK_IS_EXITS: "This bookmark is existed",
  SERVICE_NOT_FOUND: "Not found this service",
  NOT_FOUND_BOUNTY_TYPE: "Not found this bounty type",
  NOT_FOUND_TAG: "Not found tags",
  NOT_FOUND_EXPERIENCE_LEVEL: "Not found this experience level",
  NOT_FOUND_ISSUE: "Not found this issue",
  NOT_FOUND_BOUNTY_OWNER: "Not found this bounty owner",
  NOT_FOUND_PAYMENT_METHOD: "Not found this payment method",
  NOT_FOUND_PAYMENT_TOKEN: "Not found this payment token",
  NOT_FOUND_ANY_REPOSITORIES: "Not found any repository",
  INVALID_TIME_COMMITMENT: "Invalid time commitment",
  INVALID_BOUNTY_TYPE_OTHER: "Invalid bounty type other",
  INVALID_BOUNTY_STEP: "Invalid bounty step",
  PAYOUT_DATE_MUST_HIGHER_THAN_CUTOFF_DATE:
    "Payout date must be higher than cutoff date",
  BOUNTY_NOT_FOUND: "Not found this bounty",
  USER_HAS_NO_ORGANIZATION: "User has no organization",
  BOUNTY_NOT_IN_SETUP: "Bounty not in setup",
  BOUNTY_CONTACT_NOT_FOUND: "Not found this bounty contact",
  INVALID_BOUNTY_CONTACTS_LENGTH: "Invalid bounty contacts length",
  CONTRIBUTION_NOT_EXIST: "Not found this contribution",
  USER_HAS_NO_CONTRIBUTION: "User has no contribution",
  DOCUMENTATION_NOT_FOUND: "Not found this document",
  IMAGE_NOT_FOUND: "Not found this image",
  ISSUE_NOT_EXIST: "Not found this issue",
  EMAIL_INVALID: "Invalid email",
  USER_SUBSCRIBED: "User is subscribed",
  WRONG_ACTIVE_MEMBER_DURATION_KEY: "Wrong active member duration key",
  USER_HAS_BEEN_A_MEMBER: "User has been a member",
  LINK_NOT_EXIST: "This link is not existed",
  PULL_REQUEST_NOT_EXIST: "This pull request is not existed",
  QUESTION_NOT_FOUND: "Not found this question",
  USER_NOT_PERMISSION_UPDATE_QUESTION:
    "User has no permission to update question",
  USER_NOT_PERMISSION_DELETE_QUESTION:
    "User has no permission to delete question",
  REPOSITORY_NOT_EXIST: "This repository is not existed",
  REPOSITORY_IS_PRIVATE: "This repository is private",
  TAG_NOT_FOUND: "Not found this tag",
  TAG_IS_EXITS: "This tag is existed",
  SERVICE_TAG_NOT_FOUND: "Not found this service tag",
  TICKET_NOT_FOUND: "Not found this ticket",
  TICKET_COMMENT_NOT_FOUND: "Not found this ticket comment",
  TOKEN_NOT_FOUND: "Not found this token",
  SETUP_LEVEL_FAIL: "Setup level failed",
  NOT_FOUND_PROJECT_TOKEN: "Not found_project this token",
  NOT_FOUND_SEED: "Not found this seed",
  NOT_FOUND_THEME: "Not found this theme",
  NOT_FOUND_SOUL_ID: "Not found soul id",
  PROJECT_HAS_ALREADY_SETUP_REWARD: "Project has already setup reward",
  USER_HAS_NOT_PARTICIPATED: "User has not participated",
  USER_HAS_ALREADY_CLAIM_TOKEN: "User has already claimed this token",
  USER_IS_NOT_ELIGIBLE_TO_CLAIM_TOKEN:
    "User is not eligible to claim this token",
  ALREADY_CLAIMED: "Already claimed",
  UPVOTE_NOT_FOUND: "Not found this upvote",
  UPVOTE_IS_EXITS: "You have upvoted",
  SERVICE_INVALID: "Invalid service",
  FILE_TYPE_NOT_FOUND: "Not found this file type",
  SKILL_NOT_FOUND: "Not found this skill",
  EDUCATION_NOT_FOUND: "This education is not founded",
  PORTFOLIO_NOT_FOUND: "Not found this portfolio",
  WORK_EXPERIENCE_NOT_FOUND: "Not found this work experience",
  WEBHOOK_EVENT_NOT_FOUND: "Not found this webhook event",
  REPOSITORY_ACTION_NOT_FOUND: "Not found this repository action",
  REPOSITORY_ALREADY_EXISTED: "This repository has already existed",
  PULL_REQUEST_ACTION_NOT_FOUND: "Not found this pull request action",
  ISSUE_ACTION_NOT_FOUND: "Not found this issue action",
  ORGANIZATION_ACTION_NOT_FOUND: "Not found this organization action",
  STAR_ACTION_NOT_FOUND: "Not found this star action",
  WATCH_ACTION_NOT_FOUND: "Not found this watch action",
  EXCEEDED_REQUEST_LIMIT: "Exceeded github request limit",
  NOT_THE_NEXT_TIME_TO_CALL: "Not the next time to call Github API",
  FINANCE_NOT_EXISTED: "Finance is not exists",
  FINANCE_TRANSACTION_NOT_EXISTED: "Finance transaction is not exists",
  FINANCE_EXISTED: "Fiannce is existed",
  FINANCE_TRANSACTION_VOTING_EXISTED: "Fianance transaction voting is existed",
  FINANCE_ASSET_NOT_EXISTED: "Finance asset is not exists",
  FINANCE_ASSET_IS_EXISTED: "Finance asset is existed",
  FINANCE_OWNER_IS_NOT_EXISTED: "Finance owner is not exists",
  SAFE_CONTRACT_EXISTED: "Safe contract address already exists",
  SAFE_CONTRACT_NOT_EXISTED: "Safe contract is not exists",
  USER_NOT_OWNED_SAFE_CONTRACT: "User is not owned safe contract",
  OWNER_NOT_IN_SOULBOUND_SYSTEM: "Owner is not in soulbound system",
  FINANCE_TRANSACTION_NOT_ENOUGH_VOTING:
    "Finance transaction is not enough voting",
  NOT_FOUND_SPONSORSHIP: "Not found this sponsorship",
  NOT_FOUND_SPONSORSHIP_TIER: "Not found this sponsorship tier",
  SPONSORSHIP_TIERS_LIMIT_IS_12: "Sponsorship tiers limit is 12",
  DELIVERABLES_NOT_GREATER_THAN_MAXIMUM_COLLABORATIONS:
    "The bounty already reached the max number of collaborators",
  USER_DO_NOT_HAVE_ENOUGH_TOKEN:
    "You are not eligible to post a public voting.",
  DELIVERABLES_NOT_FOUND: "Not found this deliverable",
  USER_NOT_JOIN_THIS_COMMUNITY: "Please join the community",
  PROJECT_HAS_NO_REPO: "This project has no repo",
  START_TIME_IS_NOT_GREATER_THAN_END_TIME: 'Start time is not greater than end time',
	PROPOSAL_NOT_EXIST: 'Proposal is not exist',
	PROPOSAL_HAS_EXPIRED: 'Proposal has expired',
	USER_HAS_VOTING: 'User has voting this proposal',
	POWER_USER_LESS_THAN_OR_EQUAL_TO_ZERO: 'Power user less than or equal to zero',
	USER_NOT_JOIN_GOVERNANCE: 'User not join this governance',
	THE_USER_IS_NOT_IN_THIS_TYPE_GOVERNANCE: 'The user is not in this type of governance',
	PROPOSAL_NOT_HAVE_STATUS_ACTIVE: 'Proposal is not active',
	WALLET_ADDRESS_HAS_VOTING: 'You have voted for this proposal',
  PROJECT_TOKEN_NOT_EXIST: 'Project token is not exist',
	NOT_ENOUGH_TOKEN: 'Not enough token',
	NOTHING_TO_CLAIMED: 'Nothing to claim',
};

export const getMessageContent = (key: string) => {
  const processedGnosisKey = getBlockchainErrorMessageContent(key);
  return MESSAGE_KEY[processedGnosisKey]
    ? MESSAGE_KEY[processedGnosisKey]
    : processedGnosisKey;
};
