import { Checkbox } from "antd";
import { useMemo } from "react";
import { IFilterDrawerProps, IPanelCheckBoxProps } from "./filterDrawer.props";
import {
  ActionButtonWrapper,
  ApplyButton,
  Collapse,
  CollapsePanel,
  CollapseWrapper,
  Container,
  DrawerAnt,
  ResetButton,
} from "./filterDrawer.style";

interface FilterDrawerInterface extends React.FC<IFilterDrawerProps> {
  Panel: typeof CollapsePanel;
  PanelCheckBox: typeof PanelCheckBox;
}

const FilterDrawer: FilterDrawerInterface = ({
  open = false,
  onClose,
  onApply,
  onReset,
  children,
}) => {
  return (
    <Container>
      <DrawerAnt
        title="Project Filter"
        placement="right"
        onClose={onClose}
        visible={open}
      >
        <ActionButtonWrapper>
          <ApplyButton onClick={onApply} size="large">
            Apply
          </ApplyButton>
          <ResetButton onClick={onReset}>Reset</ResetButton>
        </ActionButtonWrapper>

        <CollapseWrapper>
          <Collapse ghost defaultActiveKey={[1, 2, 3, 4, 5, 6, 7, 8, 9]}>
            {children}
          </Collapse>
        </CollapseWrapper>
      </DrawerAnt>
    </Container>
  );
};

const PanelCheckBox: React.FC<IPanelCheckBoxProps> = ({
  value,
  children,
  onChange,
  data = [],
  ...collapsePanelProps
}) => {
  const checkBoxList = useMemo(
    () =>
      data.map((item) => (
        <Checkbox key={item.id} value={item.id}>
          {item.name}
        </Checkbox>
      )),
    [data]
  );

  return (
    <FilterDrawer.Panel {...collapsePanelProps}>
      <Checkbox.Group onChange={onChange} value={value}>
        {checkBoxList}
        {children}
      </Checkbox.Group>
    </FilterDrawer.Panel>
  );
};

// TODO other child panels like: radio, switch, slider...
// To create child panel you must forward all props of the component to FilterDrawer.Panel component
// Take a look on PanelCheckBox component

FilterDrawer.Panel = CollapsePanel;
FilterDrawer.PanelCheckBox = PanelCheckBox;

export default FilterDrawer;
