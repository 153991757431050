import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { optionUseQuery } from "utils/optionUseQuery";
import { IAllFilterParams } from "./interface";
import { getAllProjectAPI, getFilterParamsAPI } from "./request";
import { IProjectReq } from "./types";

export const useAllProject = (
  params: IProjectReq,
  option?: UseQueryOptions<any, Error>
) => {
  return useQuery<any, Error>(
    ["AllProjectHomePageUser", params],
    () => getAllProjectAPI(params),
    {
      ...optionUseQuery,
      ...option,
    }
  );
};

export const useFilterParams = (option?: UseQueryOptions<any, Error>) => {
  return useQuery<IAllFilterParams, Error>(
    ["AllFilterProjectParams"],
    () => getFilterParamsAPI(),
    {
      ...optionUseQuery,
      ...option,
    }
  );
};

