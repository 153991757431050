import { useMutation } from "@tanstack/react-query";
import { Button, Checkbox, Collapse, Input, Row } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import TextArea from "antd/lib/input/TextArea";
import {
  createEventCommentAPI,
  deleteEventCommentByIdAPI,
  getAllEventCommentsAPI,
  getChildEventCommentAPI,
  getEventCommentByIdAPI,
  IGetAllEventCommentsRes,
  updateEventCommentByIdAPI
} from "api/users/eventComments";
import { AxiosError } from "axios";
import { FC, Fragment, memo, useRef, useState } from "react";
import ReactJson from "react-json-view";
import { IEventCommentTbl } from "types";
import { METHOD_API } from "../apiTest";
import { CollapseShowJson } from "../commonUi.style";
const { Panel } = Collapse;

const EventCommentsAPI: FC<any> = () => {
  const [isShow, setIsShow] = useState<boolean>(true);
  const [createdResult, setCreateResult] = useState<IEventCommentTbl>({});
  const [updateResult, setUpdateResult] = useState<any>({});
  const [deleteResult, setDeleteResult] = useState<any>({});
  const [eventComment, setEventComment] = useState<IEventCommentTbl>({});
  const [eventCommentId, setEventCommentId] = useState<string>("");
  const [childEventCommentId, setChildEventCommentId] = useState<string>("");
  const [updateEventCommentId, setUpdateEventCommentId] = useState<string>("");
  const [deleteEventCommentId, setDeleteEventCommentId] = useState<string>("")
  const [childEventComment, setChildEventComment] = useState<IEventCommentTbl>(
    {}
  );
  const [textInputForUpdate, setTextInputForUpdate] = useState<string>(`
    {
      "content": "string",
      "status": 0
    }
  `);
  const [allEventComments, setAllEventComments] =
    useState<IGetAllEventCommentsRes>({});
  const [textInputForGetAll, setTextInputForGetAll] = useState<string>(`
    {
      "page": 1,
      "limit": 10
    }
  `);
  const [textInputForCreate, setTextInputForCreate] = useState<string>(`{
    "comment_id": 2,
    "event_id": 21,
    "content": "rep comment 2"
    }`);
  const checkBox = useRef<any>(null);
  const { mutate: createEventComment } = useMutation(createEventCommentAPI, {
    onSuccess: async (rs: IEventCommentTbl) => {
      setCreateResult(rs);
    },
    onError: (err: AxiosError) => {
      setCreateResult(JSON.parse(JSON.stringify(err)));
    },
  });

  const { mutate: getEventCommentById } = useMutation(getEventCommentByIdAPI, {
    onSuccess: async (rs: IEventCommentTbl) => {
      setEventComment(rs);
    },
    onError: (err: any) => {
      setEventComment(JSON.parse(JSON.stringify(err)));
    },
  });

  const { mutate: getAllEventComments } = useMutation(getAllEventCommentsAPI, {
    onSuccess: async (rs: IGetAllEventCommentsRes) => {
      setAllEventComments(rs);
    },
    onError: (err: any) => {
      setAllEventComments(JSON.parse(JSON.stringify(err)));
    },
  });

  const { mutate: getChildEventComment } = useMutation(
    getChildEventCommentAPI,
    {
      onSuccess: async (rs: IEventCommentTbl) => {
        setChildEventComment(rs);
      },
      onError: (err: any) => {
        setChildEventComment(JSON.parse(JSON.stringify(err)));
      },
    }
  );

  const { mutate: updateEventCommentById } = useMutation(updateEventCommentByIdAPI, {
    onSuccess: async (rs: any) => {
      setUpdateResult(rs);
    },
    onError: (err: any) => {
      setUpdateResult(JSON.parse(JSON.stringify(err)));
    },
  })

  const { mutate: deleteEventCommentById } = useMutation(deleteEventCommentByIdAPI, {
      onSuccess: async (rs: any) => {
        setDeleteResult(rs);
      },
      onError: (err: any) => {
        setDeleteResult(JSON.parse(JSON.stringify(err)));
      },
  })

  const handleChageTextInputForCreate = (event: any) => {
    setTextInputForCreate(event.target.value);
  };

  const handleChangeTextInputForGetAll = (event: any) => {
    setTextInputForGetAll(event.target.value);
  };

  const handleChangeEventCommentId = (event: any) => {
    setEventCommentId(event.target.value);
  };

  const handleChangeUpdateEventCommentId = (event: any) => {
    setUpdateEventCommentId(event.target.value);
  };

  const handleChangeDeleteEventCommentId = (event: any) => {
    setDeleteEventCommentId(event.target.value);
  }

  const handleChangeTextInputForUpdate = (event: any) => {
    setTextInputForUpdate(event.target.value);
  };

  const handleChangeChildEventCommentId = (event: any) => {
    setChildEventCommentId(event.target.value);
  };

  const createEventCommentClick = () => {
    createEventComment(JSON.parse(textInputForCreate));
  };

  const getAllEventCommentsAPIClick = () => {
    getAllEventComments(JSON.parse(textInputForGetAll));
  };

  const getEventCommentByIdClick = () => {
    getEventCommentById(eventCommentId);
  };

  const getChildEventCommentByIdClick = () => {
    getChildEventComment(childEventCommentId);
  };

  const updateEventCommentByIdClick = () => {
    updateEventCommentById({
      id: updateEventCommentId,
      updatedEventComment: JSON.parse(textInputForUpdate)})
  };

  const deleteEventCommentByIdClick = () => {
    deleteEventCommentById(deleteEventCommentId);
  }

  return (
    <>
      <Row style={{ marginTop: "10px" }}>
        <h1>
          <b>Event comments</b>
        </h1>
        <Checkbox
          ref={checkBox}
          onChange={(e: CheckboxChangeEvent) => {
            setIsShow(e.target.checked);
          }}
          style={{
            marginLeft: "10px",
          }}
          checked={isShow}
        />
      </Row>
      {isShow && (
        <Collapse accordion>
          <Panel header="/event-comments" key="1" extra={METHOD_API.POST}>
            <Button
              type="primary"
              onClick={createEventCommentClick}
              style={{
                marginBottom: "10px",
              }}
            >
              Try it out
            </Button>
            <h1>Request body</h1>
            <TextArea
              style={{
                marginBottom: "10px",
              }}
              value={textInputForCreate}
              defaultValue={textInputForCreate}
              onChange={handleChageTextInputForCreate}
              placeholder=""
              rows={10}
            />
            <Fragment>
              <h1>Result</h1>
              <CollapseShowJson ghost>
                <ReactJson src={JSON.parse(JSON.stringify(createdResult))} />
              </CollapseShowJson>
            </Fragment>
          </Panel>
          <Panel header="/event-comments/alls" key="2" extra={METHOD_API.POST}>
            <Button
              type="primary"
              onClick={getAllEventCommentsAPIClick}
              style={{
                marginBottom: "10px",
              }}
            >
              Try it out
            </Button>
            <h1>Request body</h1>
            <TextArea
              style={{
                marginBottom: "10px",
              }}
              value={textInputForGetAll}
              defaultValue={textInputForGetAll}
              onChange={handleChangeTextInputForGetAll}
              placeholder=""
              rows={10}
            />
            <Fragment>
              <h1>Result</h1>
              <CollapseShowJson ghost>
                <ReactJson src={JSON.parse(JSON.stringify(allEventComments))} />
              </CollapseShowJson>
            </Fragment>
          </Panel>
          <Panel header="/event-comments/{id}" key="3" extra={METHOD_API.GET}>
            <Button
              type="primary"
              onClick={getEventCommentByIdClick}
              style={{
                marginBottom: "10px",
              }}
            >
              Try it out
            </Button>
            <h1>Request body</h1>
            <Input
              placeholder="Enter event id"
              allowClear
              onChange={handleChangeEventCommentId}
              value={eventCommentId}
              style={{
                marginBottom: "10px",
              }}
            />
            <Fragment>
              <h1>Result</h1>
              <CollapseShowJson ghost>
                <ReactJson src={JSON.parse(JSON.stringify(eventComment))} />
              </CollapseShowJson>
            </Fragment>
          </Panel>
          <Panel header="/event-comments/{id}" key="4" extra={METHOD_API.PUT}>
            <Button
              type="primary"
              onClick={updateEventCommentByIdClick}
              style={{
                marginBottom: "10px",
              }}
            >
              Try it out
            </Button>
            <Input
              placeholder="Enter event id"
              allowClear
              onChange={handleChangeUpdateEventCommentId}
              value={updateEventCommentId}
              style={{
                marginBottom: "10px",
              }}
            />
            <h1>Request body</h1>
            <TextArea
              style={{
                marginBottom: "10px",
              }}
              value={textInputForUpdate}
              defaultValue={textInputForUpdate}
              onChange={handleChangeTextInputForUpdate}
              placeholder=""
              rows={10}
            />
            <Fragment>
              <h1>Result</h1>
              <CollapseShowJson ghost>
                <ReactJson src={JSON.parse(JSON.stringify(updateResult))} />
              </CollapseShowJson>
            </Fragment>
          </Panel>
          <Panel
            header="/event-comments/{id}"
            key="5"
            extra={METHOD_API.DELETE}
          >
            <Button
              type="primary"
              onClick={deleteEventCommentByIdClick}
              style={{
                marginBottom: "10px",
              }}
            >
              Try it out
            </Button>
            <h1>Request body</h1>
            <Input
              placeholder="Enter event id"
              allowClear
              onChange={handleChangeDeleteEventCommentId}
              value={deleteEventCommentId}
              style={{
                marginBottom: "10px",
              }}
            />
            <Fragment>
              <h1>Result</h1>
              <CollapseShowJson ghost>
                <ReactJson
                  src={JSON.parse(JSON.stringify(deleteResult))}
                />
              </CollapseShowJson>
            </Fragment>
          </Panel>
          <Panel
            header="/event-comments/children/{id}"
            key="6"
            extra={METHOD_API.GET}
          >
            <Button
              type="primary"
              onClick={getChildEventCommentByIdClick}
              style={{
                marginBottom: "10px",
              }}
            >
              Try it out
            </Button>
            <h1>Request body</h1>
            <Input
              placeholder="Enter event id"
              allowClear
              onChange={handleChangeChildEventCommentId}
              value={childEventCommentId}
              style={{
                marginBottom: "10px",
              }}
            />
            <Fragment>
              <h1>Result</h1>
              <CollapseShowJson ghost>
                <ReactJson
                  src={JSON.parse(JSON.stringify(childEventComment))}
                />
              </CollapseShowJson>
            </Fragment>
          </Panel>
        </Collapse>
      )}
    </>
  );
};

export default memo(EventCommentsAPI);
