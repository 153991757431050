import { FC, memo } from "react";
import { IButtonColorProps } from "./buttonColor.props";
import { ButtonColorContainer } from "./buttonColor.style";

const ButtonColor: FC<IButtonColorProps> = ({
  children,
  ...props
}: IButtonColorProps) => {
  return <ButtonColorContainer {...props}>{children}</ButtonColorContainer>;
};

export default memo(ButtonColor);
