import { Button } from "antd";
import styled from "styled-components";

export const ButtonGradientAntd = styled(Button)`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;

  background: linear-gradient(
    94.18deg,
    rgba(197, 138, 255, 0.1) 1.63%,
    rgba(0, 173, 251, 0.1) 64.37%,
    rgba(0, 213, 201, 0.1) 93.96%,
    rgba(0, 222, 140, 0.1) 125.59%
  );
  box-shadow: 0px 20px 32px rgba(97, 156, 253, 0.2);
  border-radius: 6px;
  color: white;

  &:hover,
  &:focus {
    background: linear-gradient(
      94.18deg,
      rgba(197, 138, 255, 0.1) 1.63%,
      rgba(0, 173, 251, 0.1) 64.37%,
      rgba(0, 213, 201, 0.1) 93.96%,
      rgba(0, 222, 140, 0.1) 125.59%
    );
    color: #efecec;
  }
`;

export const TextContainer = styled.div``;

export const TextContent = styled.span`
  background: linear-gradient(
    94.18deg,
    #c58aff 1.63%,
    #00adfb 64.37%,
    #00d5c9 93.96%,
    #00de8c 125.59%
  );
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
`;
