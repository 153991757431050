import { FC, memo } from "react";
import { IIdentityInfoProps } from "./identityInfo.props";
import {
  IdentityAvatar,
  IdentityBlock,
  IdentityName,
} from "./identityInfo.style";

const IdentityInfo: FC<IIdentityInfoProps> = ({
  avatarUrl,
  name,
  children,
  ...props
}) => {
  return (
    <IdentityBlock>
      <IdentityAvatar src={avatarUrl} />
      <IdentityName> {name}</IdentityName>
    </IdentityBlock>
  );
};

export default memo(IdentityInfo);
