import { Avatar, Col, Typography } from "antd";
import { Header } from "antd/lib/layout/layout";
import { ButtonGradient } from "components/base";
import ButtonTransparent from "components/base/buttonTransparent";
import ButtonWhite from "components/base/buttonWhite";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { THEME } from "utils/theme";

const { Text } = Typography;

export const Container = styled(Header)`
  background: ${THEME.USER.BACKGROUND_GRADIENT};
  height: "89px";
  width: 100%;
  padding: 0px 136px 0px 136px;
`;

export const NavItems = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-right: 64px;

  a {
    margin-left: unset;
  }
`;

export const MenuItem = styled(Link)<{
  $isActive?: boolean;
}>`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  padding: 7px 19px;
  border-radius: 999px;
  border: 1px solid transparent;

  ${({ $isActive }) =>
    $isActive
      ? `
        border-color: white;
      `
      : ""}

  &:hover {
    color: #ede5e5;
  }
`;

export const MenuItemNewLink = styled.a<{
  $isActive?: boolean;
}>`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  margin-left: 32px;
  padding: 7px 19px;

  ${({ $isActive }) =>
    $isActive
      ? `
        background: rgba(255, 255, 255, 0.18);
        box-shadow: 0px 2px 12px rgba(8, 10, 37, 0.05);
        border-radius: 20px;
      `
      : ""}

  &:hover {
    color: #ede5e5;
  }
`;

export const LogoContainer = styled(Col)`
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 64px;
`;

export const LoginContainer = styled(Col)`
  display: flex;
  justify-content: end;
  align-items: center;
`;

export const ButtonLogin = styled(ButtonWhite)`
  border-radius: 999px;
`;

export const ButtonSignup = styled(ButtonTransparent)`
  margin-right: 20px;
  font-weight: 600;
  font-size: 16px;
  border-radius: 999px;
`;

// Logger UI
export const LoggedContainer = styled.div`
  display: flex;
  align-items: center;

  .ant-dropdown {
    width: 233px;
    background: #ffffff;
    border: 1px solid rgba(41, 46, 115, 0.12);
    box-shadow: 0px 16px 32px -12px rgba(56, 105, 178, 0.16);
    border-radius: 4px;

    .ant-dropdown-menu-item {
      :hover {
        background: transparent;
      }

      :first-child {
        border-bottom: 1px solid rgba(156, 138, 193, 0.16);
      }
    }
  }
`;

export const AvatarLogged = styled(Avatar)`
  background: #5bab5142;
  border: 1px solid;
  cursor: pointer;
`;

export const AvatarWrap = styled.div``;

export const ButtonLogout = styled(ButtonGradient)`
  height: 30px;
  width: auto;
`;

export const ProfileDropdown = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: -0.02em;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  align-items: center;

  path {
    stroke: #ffffff;
  }
`;

export const ProfileTextContainer = styled.div`
  max-width: 150px;
`;

export const ProfileText = styled(Text)`
  padding: 5px;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
`;

export const BtnConnectWallet = styled(ButtonGradient)`
  width: 148px;
  height: 32px;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  display: flex;
  align-items: center;

  svg {
    margin-right: 5px;
  }

  span {
    margin-top: 3px;
  }
`;

export const ConnectWalletContainer = styled.div`
  padding: 10px;
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 125%;
    letter-spacing: -0.02em;
    color: rgba(41, 46, 115, 0.82);
    margin-bottom: 7px;
  }
`;

export const ItemMenuText = styled.div`
  padding-left: 10px;
  font-weight: 400;
  font-size: 16px;
  line-height: 125%;
  letter-spacing: -0.02em;
  color: rgba(41, 46, 115, 0.82);
`;

export const ItemMenuProfile = styled(ItemMenuText)`
  margin-top: 10px;
`;

export const ItemMenuTextLogout = styled(ItemMenuText)`
  color: rgba(233, 70, 0, 0.7);
  margin-bottom: 10px;
`;
