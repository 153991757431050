import { Col, Row, Skeleton } from "antd";
import {
  SubscribeContainer,
  SubscribeContainerSkeleton,
  SubscribeModal,
} from "layout/users/footer/newsletterForm/newsletterForm.style";
import { FC, memo, useEffect, useRef, useState } from "react";
import { IFooterProps } from "./newsletterForm.props";

const NewsletterForm: FC<IFooterProps> = ({
  isShowNewsletter,
  setIsShowNewsletter,
  email,
  resetEmail,
}: IFooterProps) => {
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  // const [form] = Form.useForm();

  // const { mutate: postLetters, isLoading } = useMutation(postLettersAPI, {
  //   onSuccess: () => {
  //     setIsSuccess(true);
  //     form.resetFields();
  //     resetEmail();
  //   },
  //   onError: (error: any) => {
  //     message.error(getMessageContent(error?.response?.data?.message));
  //   },
  // });

  // const handleFinishForm = (values: any) => {
  //   postLetters(values);
  // };

  const [isLoadingScript, setIsLoadingScript] = useState<boolean>(true);
  const subscribeAreaRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://eocampaign1.com/form/23c8621a-a6e3-11ed-98fc-f7d524f637f7.js";
    script.async = true;
    script.dataset.form = "23c8621a-a6e3-11ed-98fc-f7d524f637f7";

    const subscribeArea = subscribeAreaRef.current as HTMLDivElement;
    subscribeArea.appendChild(script);

    script.onload = () => {
      setIsLoadingScript(false);

      const emailInput = document.getElementById("field_0") as HTMLInputElement;
      emailInput.value = email;
      resetEmail();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SubscribeModal
      onCancel={() => {
        setIsShowNewsletter(false);
        setIsSuccess(false);
        // form.resetFields();
      }}
      visible={isShowNewsletter}
      closeIcon={true}
      footer={null}
      width={isSuccess ? "498px" : "865px"}
      forceRender
      getContainer={false}
    >
      {/* <Form
        form={form}
        onFinish={handleFinishForm}
        initialValues={{
          full_name: "",
          is_subscribed: true,
          identify: null,
          email: email.trim(),
        }}
      >
        {isSuccess && (
          <ConnectedContainer>
            <ImgClap src={ImageClap} />
            <TitleConnected>We are now connected :)</TitleConnected>
            <DescConnected>
              We are excited to get you onboard the early beta program.
            </DescConnected>
            <ConfirmConnected>
              We shall soon send an invite your way.
            </ConfirmConnected>
          </ConnectedContainer>
        )}

        {!isSuccess && (
          <SignUpNewsletter>
            <NewsletterLeft>
              <SubImage src={ImageSub}></SubImage>
            </NewsletterLeft>
            <NewsletterRight>
              <TitleNewsletter>
                Please connect with us and join our list for the upcoming token
                airdrops and giveaways
              </TitleNewsletter>
              <SubTitleNewsletter>
                We take privacy seriously and will never spam you.
              </SubTitleNewsletter>

              <ModalFormItem
                label={<LabelRequiredForm>Email Address</LabelRequiredForm>}
                name="email"
                rules={[requiredRules, emailRules]}
              >
                <StyledTextInput placeholder="E-mail Address" />
              </ModalFormItem>
              <ModalFormItem label="Full Name" name="full_name">
                <StyledTextInput placeholder="Enter full name" />
              </ModalFormItem>
              <ModalFormItem
                label={
                  <TitleIdentify>You identify yourself as :)</TitleIdentify>
                }
                name="identify"
              >
                <RadioGroup>
                  <Space direction="vertical">
                    <Radio value={0}>Open Source Creator</Radio>
                    <Radio value={1}>Open Source Contributor</Radio>
                    <Radio value={2}>Developer / Business</Radio>
                  </Space>
                </RadioGroup>
              </ModalFormItem>
              <ModalFormItemCheckbox
                label={"Sign me up for newsletter"}
                name="is_subscribed"
                valuePropName="checked"
              >
                <Checkbox />
              </ModalFormItemCheckbox>
              <BtnSubmit
                htmlType="submit"
                disabled={isLoading}
                loading={isLoading}
              >
                Submit
              </BtnSubmit>
            </NewsletterRight>
          </SignUpNewsletter>
        )}
      </Form> */}

      {isLoadingScript && (
        <SubscribeContainerSkeleton>
          <Skeleton.Input active />
          <Skeleton paragraph title={false} active />
          <Row gutter={[16, 16]}>
            <Col span={20}>
              <Skeleton.Input active />
            </Col>
            <Col span={4}>
              <Skeleton.Button active />
            </Col>
          </Row>
        </SubscribeContainerSkeleton>
      )}
      <SubscribeContainer
        ref={subscribeAreaRef}
        isLoading={isLoadingScript}
      ></SubscribeContainer>
    </SubscribeModal>
  );
};

export default memo(NewsletterForm);

