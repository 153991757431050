import { createSlice } from "@reduxjs/toolkit";
import { ProjectStatusEnum } from "api/admin/projects";
import { IContributionsTbl } from "types";
export interface IProjectInfoRedux {
  about: {
    name: string;
    is_owner: boolean;
    is_joined_community: boolean;
    status?: ProjectStatusEnum;
  };
  advert: { big_img: string; small_img: string };
}

export interface IProjectsUsState {
  rewards?: IContributionsTbl[];
  reward?: {
    is_active: boolean;
  };
  finance: {
    is_active: boolean;
  };
  sponsorship: {
    is_active: boolean;
  };
  about: IProjectInfoRedux;
  advert: { big_img: string; small_img: string };
}

export const initialProjectState = {
  rewards: [],
  reward: {
    is_active: false,
  },
  finance: {
    is_active: false,
  },
  sponsorship: {
    is_active: false,
  },
  about: {
    name: "",
    dao_name: "",
    is_owner: false,
    is_admin: false,
    is_joined_community: false,
    status: undefined,
  },
  advert: {
    id: undefined,
    center_home_image: undefined,
    left_column_image: undefined,
  },
};

const projectsSlice = createSlice({
  name: "projects-slice-us",
  initialState: initialProjectState,
  reducers: {
    setProjectsReward: (state, action) => {
      state.rewards = action.payload;
    },
    setProjectsInfo: (state, action) => {
      state.reward = action.payload.reward;
      state.about = action.payload.about;
      state.finance = action.payload.finance;
      state.sponsorship = action.payload.sponsorship;
      state.advert = action.payload.advert;
    },
  },
});

const { actions, reducer } = projectsSlice;

export const projectsUsAction = actions;

export default reducer;

