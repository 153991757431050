import { FC } from "react";
import { IContainerFluidCustomProps } from "./containerFluidCustom.props";
import { Container } from "./containerFluidCustom.style";

const ContainerFluidCustom: FC<IContainerFluidCustomProps> = ({
  children,
  ...props
}: IContainerFluidCustomProps) => {
  return <Container {...props}>{children}</Container>;
};

export default ContainerFluidCustom;
