import { Row } from "antd";
import { CircleCheckFill } from "assets/common/svg/CircleCheckFill.icon";
import styled from "styled-components";

export const WalletPanel = styled(Row)<{ active: boolean }>`
  ${({ active }) => active && ` background: rgba(156, 138, 193, 0.16);`}

  &:hover {
    background: rgba(156, 138, 193, 0.16);
  }

  border: 1px solid rgba(156, 138, 193, 0.16);
  border-radius: 8px;
  padding: 8px 16px;
  cursor: pointer;
  margin: 8px 0;
  transition: all 0.5s;
`;

export const WalletImg = styled.img``;

export const WalletName = styled.span`
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  margin-left: 20px;
`;

export const ActiveIcon = styled(CircleCheckFill)``;
