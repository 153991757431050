import { useMutation } from "@tanstack/react-query";
import { message } from "antd";
import { IResMetadata, useGetProjectsMetadataByID } from "api/users/projects";
import { IJoinCommunityRes, joinCommunityAPI } from "api/users/projects/about";
import RebakedAvatar from "assets/users/projects/rebaked-avatar.png";
import Cookies from "js-cookie";
import { FC, memo, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { projectsUsAction } from "redux/slices/projectsSlice";
import { KEY_COOKIE } from "utils/constant";
import { NOTIFICATION, getMessageContent } from "utils/message";
import { IProjectsHeaderProps } from "./projectsHeader.props";
import {
  Avatar,
  BackgroundContainer,
  ButtonJoinCommunity,
  CommunityProject,
  ContainerInfoProject,
  InfoNameProject,
  InfoProject,
  Name,
  NameProject,
  Role,
  SubHeader,
  SubHeaderProjectsImg,
  TagLabel,
  TotalMember,
} from "./projectsHeader.style";

const defaultBannerUrl =
  "https://soulbound-bucket.s3.amazonaws.com/cover_datasetup--1667471875006.png";

const ProjectsHeader: FC<IProjectsHeaderProps> = ({
  setRefetchAboutPage,
  ...props
}: IProjectsHeaderProps) => {
  const MSG_JOINED = "Joined the community";
  let { id } = useParams();
  const dispatch = useDispatch();
  const isLoggedLocalSt = Cookies.get(KEY_COOKIE.ACCESS_TOKEN);

  const { data: metaData, isLoading } = useGetProjectsMetadataByID(id!, {
    onSuccess: (rs: IResMetadata) => {
      // Set data redux for project information
      dispatch(
        projectsUsAction.setProjectsInfo({
          about: {
            name: rs.name,
            dao_name: rs.dao_name,
            is_owner: rs.is_owner,
            is_admin: rs.is_admin,
            is_joined_community: rs.is_joined_community,
            status: rs.status,
          },
          reward: rs.reward,
          advert: rs.advert,
          finance: rs.finance,
          sponsorship: rs.sponsorship,
        })
      );
    },
    onError: (error: any) => {
      message.error(getMessageContent(error?.message));
    },
  });

  const [members, setMembers] = useState<number | undefined>();

  const {
    mutate: joinCommunity,
    isLoading: isLoadingJoinCommunity,
    isSuccess: joinCommunitySuccess,
  } = useMutation(joinCommunityAPI, {
    onSuccess: ({ contributions_count }: IJoinCommunityRes) => {
      message.success(MSG_JOINED);
      setMembers(contributions_count);
      window.location.reload();
    },
    onError: (error: any) => {
      message.error(getMessageContent(error?.message));
    },
  });

  const joinCommunityClick = () => {
    if (!isLoggedLocalSt) {
      message.warning(NOTIFICATION.NOT_LOGIN);
    } else if (!!id) {
      joinCommunity({ id });
    }
  };

  return (
    <>
      <BackgroundContainer {...props}>
        <ContainerInfoProject>
          <SubHeader>
            <SubHeaderProjectsImg
              src={metaData?.banner_url || defaultBannerUrl}
              alt="background-sub-header-projects"
            />
            {!isLoading && (
              <>
                <InfoProject span="12">
                  <Avatar
                    src={metaData?.avatar_url || RebakedAvatar}
                    alt="logo-projects"
                  />
                  <InfoNameProject>
                    <NameProject>
                      <Name>{metaData?.dao_name || metaData?.github_name}</Name>
                      {metaData?.is_new_project && (
                        <TagLabel>NEWLY ADDED</TagLabel>
                      )}
                    </NameProject>
                    {metaData?.category && (
                      <Role>{metaData?.category.trim()}</Role>
                    )}
                  </InfoNameProject>
                </InfoProject>
                <CommunityProject span="12">
                  <TotalMember>
                    {members ? members : metaData?.contributors_total_count}{" "}
                    Members
                  </TotalMember>
                  <ButtonJoinCommunity
                    onClick={joinCommunityClick}
                    loading={isLoadingJoinCommunity}
                    disabled={
                      metaData?.is_joined_community ||
                      joinCommunitySuccess ||
                      isLoadingJoinCommunity ||
                      metaData?.is_owner
                    }
                    title={metaData?.is_joined_community ? MSG_JOINED : ""}
                  >
                    JOIN COMMUNITY
                  </ButtonJoinCommunity>
                </CommunityProject>{" "}
              </>
            )}
          </SubHeader>
        </ContainerInfoProject>
      </BackgroundContainer>
    </>
  );
};

export default memo(ProjectsHeader);
