import { FC } from "react";
import { IStepProps } from "./steps.props";
import { Step, Text, Icon, Wrapper, Content } from "./steps.style";

const THEME = {
  CURRENT_COLOR: "#7e00fd",
  UNCOMPLETED_COLOR: "#C3C6CF",
  COMPLETED_COLOR: "#00DE8C",
};

const Steps: FC<IStepProps> = ({ configs, currentStep, doneStep, onClick }) => {
  const last = configs.length > 0 ? configs[configs.length - 1] : undefined;

  return (
    <Wrapper>
      <Content>
        {configs.map((item, key) => {
          const onClickStep = () => {
            if (item.id <= doneStep + 1 && onClick) {
              onClick(item.id);
            }
          };

          const getLineColor = (): string => {
            let color1 = THEME.UNCOMPLETED_COLOR;
            let color2 = THEME.UNCOMPLETED_COLOR;

            if (item.id === currentStep) {
              color1 = THEME.CURRENT_COLOR;
            } else if (doneStep >= item.id) {
              color1 = THEME.COMPLETED_COLOR;
            }

            const next =
              key === configs.length - 1 ? undefined : configs[key + 1];
            if (next && next.id === currentStep) {
              color2 = THEME.CURRENT_COLOR;
            } else if (next && doneStep >= item.id) {
              color2 = THEME.COMPLETED_COLOR;
            }
            return `${color1},${color2}`;
          };

          const isDisabled = doneStep >= 2;

          return (
            <Step
              key={key}
              picked={doneStep >= item.id}
              onClick={() => !isDisabled && onClickStep()}
              isDisabled={isDisabled}
            >
              <Icon
                active={item.id === currentStep}
                picked={doneStep >= item.id}
                hasNext={item.id !== last?.id}
                line={getLineColor()}
              >
                {item?.icon}
              </Icon>
              <Text>{item.text}</Text>
            </Step>
          );
        })}
      </Content>
    </Wrapper>
  );
};

export default Steps;
