import { EditOutlined, EllipsisOutlined } from "@ant-design/icons";
import { useForm } from "antd/lib/form/Form";
import { ReportFlag } from "assets/common/svg/ReportFlag.icon";
import { FC, memo, useRef, useState } from "react";
import { IButtonOptionsProps } from "./buttonOptions.props";
import {
  ButtonOptionsAntd,
  ButtonReportFlag,
  OptionsBlock,
  OptionsWrapper,
  PopoverAntd,
} from "./buttonOptions.style";
import ModalReport from "./modalReport";

const ButtonOptions: FC<IButtonOptionsProps> = ({
  children,
  setEditAnswer,
  showEditIcon,
  ...props
}: IButtonOptionsProps) => {
  const optionsRef = useRef(null);
  const [visible, setVisible] = useState<boolean>(false);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  const optionsBlock = (
    <OptionsBlock>
      <ButtonReportFlag
        onClick={(e: any) => {
          // TODO Waiting confirm report
          // setIsOpenModal(true);
          e.stopPropagation();
        }}
        icon={<ReportFlag />}
      >
        Report
      </ButtonReportFlag>
      {showEditIcon && (
        <ButtonReportFlag
          onClick={() => {
            setEditAnswer(true);
          }}
          icon={<EditOutlined />}
        >
          Edit
        </ButtonReportFlag>
      )}
    </OptionsBlock>
  );

  const handleVisibleChange = (visible: boolean) => {
    setVisible(visible);
  };

  const [form] = useForm();

  const handleFinish = (values) => {
    console.log(values);
  };
  return (
    <>
      <OptionsWrapper ref={optionsRef}>
        <PopoverAntd
          placement="bottom"
          getPopupContainer={(node) => optionsRef.current || node}
          content={optionsBlock}
          trigger="click"
          visible={visible}
          onVisibleChange={handleVisibleChange}
        >
          <ButtonOptionsAntd
            icon={<EllipsisOutlined />}
            onClick={() => {
              setVisible(true);
            }}
            $is_done={false}
            {...props}
          ></ButtonOptionsAntd>
        </PopoverAntd>
      </OptionsWrapper>
      <ModalReport
        form={form}
        visible={isOpenModal}
        onOk={() => form.submit()}
        onCancel={() => setIsOpenModal(false)}
        okText="Report Post"
        handleFinish={handleFinish}
      />
    </>
  );
};

export default memo(ButtonOptions);
