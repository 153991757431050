export const FacebookIcon = (props: { style?: React.CSSProperties }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="#fff"
    viewBox="0 0 24 24"
  >
    <path d="M13.397 20.997v-8.196h2.765l.411-3.209h-3.176V7.548c0-.926.258-1.56 1.587-1.56h1.684v-2.86c-.82-.089-1.643-.131-2.467-.128-2.444 0-4.122 1.492-4.122 4.231v2.355H7.332v3.21h2.753v8.201h3.312z"></path>
  </svg>
);
