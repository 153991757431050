import {
  CoinbaseWalletConnector,
  Config,
  MetamaskConnector,
} from "@usedapp/core";
import { ethers } from "ethers";

export const config: Config = {
  multicallVersion: 1 as const,
  connectors: {
    metamask: new MetamaskConnector(),
    coinbase: new CoinbaseWalletConnector(),
  },
};

(window as any).ethers = ethers;

