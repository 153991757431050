import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { IBasicInfoTbl } from "types";
import { getLocalStorage } from "utils/storage";
import { KEY_COOKIE } from "utils/constant";
export interface ProfileState {
  basicInfo?: IBasicInfoTbl;
  githubLogin?: string;
}

const initialState: ProfileState = getLocalStorage(KEY_COOKIE.PROFILE) ?? {};

const profileSlice = createSlice({
  name: "profile-us",
  initialState,
  reducers: {
    saveProfile: (state, action: PayloadAction<ProfileState>) => {
      const data = action.payload;
      return { ...state, ...data };
    },
    removeProfile: () => {
      return {};
    },
  },
});

const { actions, reducer } = profileSlice;

export const profileActions = actions;

export default reducer;
