import { ArrowDownIcon } from "assets/users/dataSetup/ArrowDown.Icon";
import { FC, useState } from "react";
import { DropdownListProps } from "./dropdownList.props";
import { Wrapper } from "./dropdownList.style";

const DropdownList: FC<DropdownListProps> = ({
  children,
  placeholder,
  ...props
}) => {
  const [focus, setFocus] = useState<boolean>(false);
  return (
    <Wrapper
      placeholder={placeholder ?? "Placeholder..."}
      suffixIcon={<ArrowDownIcon />}
      allowClear
      onFocus={() => {
        setFocus(true);
      }}
      onBlur={() => {
        setFocus(false);
      }}
      focus={focus}
      {...props}
    >
      {children}
    </Wrapper>
  );
};

export default DropdownList;
