import { ArrowRightOutlined } from "@ant-design/icons";
import { Form, Input, Radio } from "antd";
import { ButtonGradient, CommonModal } from "components/base";
import styled from "styled-components";

export const SubscribeModal = styled(CommonModal)`
  & {
    display: flex;
    justify-content: center;
  }
`;

export const SubscribeContainerSkeleton = styled.div`
  width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  .ant-row,
  .ant-row .ant-skeleton,
  .ant-row .ant-skeleton .ant-skeleton-input,
  .ant-row .ant-skeleton .ant-skeleton-button {
    width: 100%;
  }
`;

export const SubscribeContainer = styled.div<{ isLoading: boolean }>`
  display: ${({ isLoading }) => (isLoading ? "none" : "block")};
`;

export const SearchEmailIcon = styled(ArrowRightOutlined)``;

// Modal SignUp Newsletter
export const SignUpNewsletter = styled.div`
  display: flex;
  gap: 10px;
  padding: 0px 20px 0px 20px;
`;

export const NewsletterLeft = styled.div`
  flex: 0 0 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SubImage = styled.img``;

export const NewsletterRight = styled.div`
  margin-left: auto;
  display: grid;
  row-gap: 10px;
`;

export const TitleNewsletter = styled.div`
  font-weight: 500;
  font-size: 22px;
  line-height: 120%;
  letter-spacing: -0.02em;
  color: #080a25;
`;

export const SubTitleNewsletter = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 110%;
  letter-spacing: -0.01em;
  color: rgba(8, 10, 37, 0.8);
`;

export const ModalFormItem = styled(Form.Item)`
  display: block;
  margin-bottom: 20px;

  .ant-form-item-label label {
    :after {
      content: none;
    }
  }
`;

export const ModalFormItemCheckbox = styled(ModalFormItem)`
  display: inline-flex;
  flex-direction: row-reverse;
  width: fit-content;

  .ant-form-item-label {
    margin-left: 10px;

    label {
      font-weight: 400;
      font-size: 16px;
      line-height: 120%;
      color: #080a25;
    }
  }
`;

export const StyledTextInput = styled(Input)`
  border-radius: 8px;
  padding: 8px 12px;
`;

export const RadioGroup = styled(Radio.Group)``;

export const TitleIdentify = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 120%;
  letter-spacing: -0.02em;
  color: #080a25;
`;

export const BtnSubmit = styled(ButtonGradient)`
  width: 100%;
`;

// Modal connected
export const ConnectedContainer = styled(SignUpNewsletter)`
  display: grid;
  justify-items: center;
`;

export const ImgClap = styled.img``;

export const TitleConnected = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  text-align: center;
  color: #080a25;
`;

export const DescConnected = styled.div`
  font-weight: 400;
  font-size: 20px;
  line-height: 135%;
  text-align: center;
  letter-spacing: -0.01em;
  color: #080a25;
`;

export const ConfirmConnected = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 156%;
  text-align: center;
  color: rgba(8, 10, 37, 0.6);
`;

