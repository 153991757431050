import { ButtonGradient } from "components/base";
import styled from "styled-components";
export const HeaderBounty = styled.div`
  background: linear-gradient(
    96.27deg,
    rgba(197, 138, 255, 0.1) 1.94%,
    rgba(125, 151, 253, 0.1) 19.04%,
    rgba(0, 173, 251, 0.1) 41.09%,
    rgba(0, 213, 201, 0.1) 68.76%,
    rgba(0, 222, 140, 0.1) 90.53%
  );
  padding: 30px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  margin-left: 40px;
`;

export const HeaderBountyInfo = styled.div``;

export const HeaderBountyInfoTitle = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  letter-spacing: -0.02em;
  color: rgba(8, 10, 37, 0.8);
`;

export const HeaderBountyInfoDesc = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 145%;
  color: rgba(8, 10, 37, 0.6);
`;

export const ButtonCreateBounty = styled(ButtonGradient)`
  height: 40px;
  margin-left: auto;
`;

export const BodyBounty = styled.div`
  margin-left: 40px;
`;

export const BountyCard = styled.div`
  margin-top: 8px;
  height: 60px;
  padding: 0px 12px;
  display: flex;
  align-items: center;
  /* border: 1px solid rgba(115, 137, 149, 0.2); */
  border-radius: 8px;

  background: aliceblue;
`;

export const CardTitle = styled.div`
  font-weight: 800;
  font-size: 16px;
  line-height: 120%;
  letter-spacing: -0.01em;
  color: #080a25;
`;

export const ButtonUpdateBounty = styled(ButtonGradient)`
  height: 40px;
  margin-left: auto;
  width: auto;
  font-weight: 400;
`;
