import { useConnector, useEthers } from "@usedapp/core";
import { Col } from "antd";
import { FC, useEffect, useMemo, useRef } from "react";
import { IConnectionItemProps } from "./connectionItem.props";
import {
  ActiveIcon,
  WalletImg,
  WalletName,
  WalletPanel,
} from "./connectionItem.style";
import WalletConnectProvider from "@walletconnect/web3-provider/dist/umd/index.min.js";
import { INFURA_ID } from "utils/constant";
import { RootState } from "redux/store";
import { useSelector } from "react-redux";
import { useMutation } from "@tanstack/react-query";
import { putProfileWalletAPI } from "api/users/profile";

const ConnectionItem: FC<IConnectionItemProps> = ({
  connector,
  name,
  img,
  setLoading,
  // onSuccess,
}) => {
  const { connector: activeConnector } = useConnector();
  const { account, activateBrowserWallet, deactivate, activate } = useEthers();
  const profile = useSelector((state: RootState) => state.profileUser);
  const { isLoggedIn } = useSelector((state: RootState) => state.authUser);
  const connectedRef = useRef<boolean>(false);

  const { mutateAsync: updateWalletMutate } = useMutation(putProfileWalletAPI);

  const active = useMemo(
    () =>
      !!account &&
      (activeConnector?.connector?.name === name ||
        (activeConnector?.connector?.name === "Injected" &&
          connector === "walletConnect")),
    [account, activeConnector?.connector, name, connector]
  );

  useEffect(() => {
    (async () => {
      if (!connectedRef.current) {
        return;
      }
      if (
        isLoggedIn &&
        account &&
        profile?.basicInfo &&
        account !== profile?.basicInfo?.wallet
      ) {
        if (setLoading) {
          setLoading(true);
        }
        setTimeout(async () => {
          await updateWalletMutate(account);
          window.location.reload();
        });
      }
    })();
  }, [account, profile, isLoggedIn, updateWalletMutate, setLoading]);

  const onClick = async () => {
    if (connector === "walletConnect" && !active) {
      try {
        await connectWalletConnect();
        connectedRef.current = true;
        // window.location.reload();
        // if (onSuccess) {
        //   onSuccess();
        // }
      } catch {}
      return;
    }

    if (active) {
      deactivate();
    } else {
      try {
        await activateBrowserWallet({ type: connector });
        connectedRef.current = true;
        // window.location.reload();
        // if (onSuccess) {
        //   onSuccess();
        // }
      } catch {}
    }
  };

  const connectWalletConnect = async () => {
    const provider = new WalletConnectProvider({
      infuraId: INFURA_ID,
    });
    await provider.enable();
    await activate(provider);
  };

  return (
    <WalletPanel active={active} align="middle" onClick={onClick}>
      <Col flex="auto">
        <WalletImg src={img} />
        <WalletName>{name}</WalletName>
      </Col>
      <Col flex="none">{active && <ActiveIcon />}</Col>
    </WalletPanel>
  );
};

export default ConnectionItem;

