import { useMutation } from "@tanstack/react-query";
import { Button, Checkbox, Collapse, notification, Row } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import {
  getPostByIdAPI,
  IPostsAllReq,
  IPostsAllRes,
  IPostsReq,
  IPostsRes,
  postPostsAllAPI,
  postPostAPI,
  delPostByIdAPI,
  putPostByIdAPI,
  IPostsByIDReq,
} from "api/users/posts";
import { AxiosError } from "axios";
import { FC, memo, useRef, useState } from "react";
import ReactJson from "react-json-view";
import { METHOD_API } from "../apiTest";

const { Panel } = Collapse;

const PostAPI: FC<any> = () => {
  const [isShow, setIsShow] = useState<boolean>(true);
  const checkBox = useRef<any>(null);
  const [initPostsReq] = useState<IPostsReq>({
    project_id: 1,
    type: 1,
    title: "Title",
    content: "Content",
    tag_names: ["tag name 1", "tag name 2", "tag name 3"],
  });
  const [initPostsAllReq] = useState<IPostsAllReq>({
    limit: 10,
    page: 1,
    type: 1,
    sort_by: 0,
  });
  const [initGetByIdReq] = useState<IPostsByIDReq>({
    id: 1,
  });
  const [initDelByIdReq] = useState<IPostsByIDReq>({
    id: 1,
  });
  const [initPutByIdReq] = useState<IPostsReq>({
    project_id: 3,
    type: 1,
    title: "Update title",
    content: "Update content",
    tag_names: ["tag 1, tag 2, tag 3"],
  });

  const [postsSuccess, setPostsSuccess] = useState<IPostsRes>();
  const [postsErr, setPostsErr] = useState<any>();
  const [postsAllSuccess, setPostsAllSuccess] = useState<IPostsAllRes>();
  const [postsAllErr, setPostsAllErr] = useState<any>();
  const [dataByIdSuccess, setDataByIdSuccess] = useState<IPostsRes>();
  const [dataByIdErr, setDataByIdErr] = useState<any>();
  const [putPostsErr, setPutPostsErr] = useState<any>();

  const { mutate: postPosts, isLoading: isLoadingPosts } = useMutation(
    postPostAPI,
    {
      onSuccess: async (rs: any) => {
        setPostsSuccess(rs);
      },
      onError: (err: AxiosError) => {
        const data: any = err.response?.data;
        setPostsErr(data);
      },
    }
  );
  // Get All Posts
  const { mutate: postPostsAll, isLoading: isLoadingPostPostsAll } =
    useMutation(postPostsAllAPI, {
      onSuccess: async (rs: any) => {
        setPostsAllSuccess(rs);
      },
      onError: (err: AxiosError) => {
        const data: any = err.response?.data;
        setPostsAllErr(data);
      },
    });
  // Get posts by ID
  const { mutate: getPostById, isLoading: isLoadinggetPostById } = useMutation(
    getPostByIdAPI,
    {
      onSuccess: async (rs: any) => {
        setDataByIdSuccess(rs);
      },
      onError: (err: AxiosError) => {
        const data: any = err.response?.data;
        setDataByIdErr(data);
      },
    }
  );
  // Delete posts by ID
  const { mutate: delPostsById, isLoading: isLoadingDelPostsById } =
    useMutation(delPostByIdAPI, {
      onSuccess: async (rs: any) => {
        notification.success({
          message: "Delete success",
        });
      },
      onError: (err: AxiosError) => {
        const data: any = err.response?.data;
        notification.error({
          message: "Notification",
          description: data?.message,
        });
      },
    });
  // Put posts by ID
  const { mutate: putPostsById, isLoading: isLoadingPutPostsById } =
    useMutation(putPostByIdAPI, {
      onSuccess: async (rs: any) => {
        notification.success({
          message: "Put success",
        });
      },
      onError: (err: AxiosError) => {
        const data: any = err.response?.data;
        setPutPostsErr(data);
      },
    });

  return (
    <>
      <Row style={{ marginTop: "10px" }}>
        <h1>
          <b>Posts</b>
        </h1>
        <Checkbox
          ref={checkBox}
          onChange={(e: CheckboxChangeEvent) => {
            setIsShow(e.target.checked);
          }}
          style={{
            marginLeft: "10px",
          }}
          checked={isShow}
        />
      </Row>
      {isShow && (
        <Collapse accordion>
          <Panel header="/posts" key="1" extra={METHOD_API.POST}>
            <h1>Parameters</h1>
            <ReactJson
              src={initPostsReq}
              onEdit={(e: any) => {
                initPostsReq[e.name as keyof IPostsReq] = e.new_value;
              }}
              enableClipboard={false}
            />
            <Button
              loading={isLoadingPosts}
              disabled={isLoadingPosts}
              onClick={() => {
                setPostsSuccess(undefined);
                setPostsErr(undefined);
                postPosts(initPostsReq);
              }}
            >
              Try it out
            </Button>
            {postsSuccess && <ReactJson src={postsSuccess} />}
            {postsErr && (
              <ReactJson
                theme={"greenscreen"}
                enableClipboard={false}
                displayDataTypes={false}
                src={postsErr}
              />
            )}
          </Panel>
          <Panel header="/posts/alls" key="2" extra={METHOD_API.POST}>
            <h1>Parameters posts all</h1>
            <ReactJson
              src={initPostsAllReq}
              onEdit={(e: any) => {
                initPostsAllReq[e.name as keyof IPostsAllReq] = e.new_value;
              }}
              enableClipboard={false}
            />
            <Button
              loading={isLoadingPostPostsAll}
              disabled={isLoadingPostPostsAll}
              onClick={() => {
                setPostsAllSuccess(undefined);
                setPostsAllErr(undefined);
                postPostsAll(initPostsAllReq);
              }}
            >
              Try it out
            </Button>
            {postsAllSuccess && <ReactJson src={postsAllSuccess} />}
            {postsAllErr && (
              <ReactJson
                theme={"greenscreen"}
                enableClipboard={false}
                displayDataTypes={false}
                src={postsAllErr}
              />
            )}
          </Panel>
          <Panel header="/posts/{id}" key="3" extra={METHOD_API.GET}>
            <h1>Parameters By ID</h1>
            <ReactJson
              src={initGetByIdReq}
              onEdit={(e: any) => {
                initGetByIdReq[e.name as keyof IPostsByIDReq] = e.new_value;
              }}
              enableClipboard={false}
            />
            <Button
              loading={isLoadinggetPostById}
              disabled={isLoadinggetPostById}
              onClick={() => {
                setDataByIdErr(undefined);
                setDataByIdSuccess(undefined);
                getPostById(initGetByIdReq);
              }}
            >
              Try it out
            </Button>
            {dataByIdSuccess && <ReactJson src={dataByIdSuccess} />}
            {dataByIdErr && (
              <ReactJson
                theme={"greenscreen"}
                enableClipboard={false}
                displayDataTypes={false}
                src={dataByIdErr}
              />
            )}
          </Panel>
          <Panel header="/posts/{id}" key="4" extra={METHOD_API.PUT}>
            <h1>Parameters put Posts</h1>
            <ReactJson
              src={initPutByIdReq}
              onEdit={(e: any) => {
                initPutByIdReq[e.name as keyof IPostsReq] = e.new_value;
              }}
              enableClipboard={false}
            />
            <Button
              loading={isLoadingPutPostsById}
              disabled={isLoadingPutPostsById}
              onClick={() => {
                setPutPostsErr(undefined);
                putPostsById(initPutByIdReq);
              }}
            >
              Try it out
            </Button>
            {putPostsErr && (
              <ReactJson
                theme={"greenscreen"}
                enableClipboard={false}
                displayDataTypes={false}
                src={putPostsErr}
              />
            )}
          </Panel>
          <Panel header="/posts/{id}" key="5" extra={METHOD_API.DELETE}>
            <h1>Parameters By ID</h1>
            <ReactJson
              src={initDelByIdReq}
              onEdit={(e: any) => {
                initDelByIdReq[e.name as keyof IPostsByIDReq] = e.new_value;
              }}
              enableClipboard={false}
            />
            <Button
              loading={isLoadingDelPostsById}
              disabled={isLoadingDelPostsById}
              onClick={() => {
                delPostsById(initDelByIdReq);
              }}
            >
              Try it out
            </Button>
          </Panel>
        </Collapse>
      )}
    </>
  );
};

export default memo(PostAPI);
