export const LogoAdmin = () => {
  return (
    <svg
      width="43"
      height="42"
      viewBox="0 0 43 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M39.6466 6.55044C30.7643 -2.13351 16.3353 -2.13351 7.4156 6.55044C-1.46666 15.2344 -1.46666 29.3412 7.4156 38.0618C7.49056 38.1351 7.60299 38.2084 7.67795 38.2817L39.8715 6.80693C39.7965 6.73365 39.7215 6.62372 39.6466 6.55044Z"
        fill="url(#paint0_linear_1320_2068)"
      />
      <path
        d="M33.6504 13.7321C28.441 8.63895 19.971 8.63895 14.7616 13.7321C9.55214 18.8252 9.55214 27.1061 14.7616 32.1992C14.799 32.2359 14.874 32.3091 14.9115 32.3458L33.8003 13.8786C33.7629 13.842 33.6879 13.7687 33.6504 13.7321Z"
        fill="white"
      />
      <path
        d="M9.85182 35.5335C17.3474 42.8617 29.5652 42.8617 37.0608 35.5335C44.5563 28.2052 44.5563 16.2602 37.0608 8.93199C36.9858 8.8587 36.9109 8.78542 36.8359 8.74878L9.62695 35.3136C9.70191 35.3869 9.77686 35.4602 9.85182 35.5335Z"
        fill="url(#paint1_linear_1320_2068)"
      />
      <path
        d="M16.7482 28.5718C20.3835 32.126 26.305 32.126 29.9404 28.5718C33.5758 25.0176 33.5758 19.2283 29.9404 15.6741C29.9029 15.6375 29.8654 15.6009 29.828 15.5642L16.6357 28.4619C16.6732 28.4985 16.7107 28.5352 16.7482 28.5718Z"
        fill="#080A25"
      />
      <path
        d="M23.5315 25.8237C25.5185 25.8237 27.1293 24.2488 27.1293 22.3061C27.1293 20.3634 25.5185 18.7886 23.5315 18.7886C21.5444 18.7886 19.9336 20.3634 19.9336 22.3061C19.9336 24.2488 21.5444 25.8237 23.5315 25.8237Z"
        fill="url(#paint2_linear_1320_2068)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1320_2068"
          x1="0.753906"
          y1="19.1596"
          x2="39.8715"
          y2="19.1596"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9C8AC1" />
          <stop offset="0.4358" stopColor="#4F9DD6" />
          <stop offset="0.5061" stopColor="#50A2C7" />
          <stop offset="0.6812" stopColor="#4EABA6" />
          <stop offset="0.8278" stopColor="#4BB28C" />
          <stop offset="0.9383" stopColor="#49B67A" />
          <stop offset="1" stopColor="#48B972" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1320_2068"
          x1="9.62695"
          y1="24.8892"
          x2="42.6824"
          y2="24.8892"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F9EC80" />
          <stop offset="0.0757988" stopColor="#F9EA7C" />
          <stop offset="0.165" stopColor="#FAE66E" />
          <stop offset="0.261" stopColor="#FBDE54" />
          <stop offset="0.3611" stopColor="#FDD42C" />
          <stop offset="0.4134" stopColor="#FFCE07" />
          <stop offset="1" stopColor="#F57F20" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1320_2068"
          x1="19.9625"
          y1="22.3061"
          x2="27.1222"
          y2="22.3061"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5786C5" />
          <stop offset="1" stopColor="#3869B2" />
        </linearGradient>
      </defs>
    </svg>
  );
};
