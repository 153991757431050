import { FC, memo } from "react";
import { ITagInfoProps } from "./tagInfo.props";
import { TagBlock } from "./tagInfo.style";

const IdentityInfo: FC<ITagInfoProps> = ({
  content,
  textColor,
  children,
  ...props
}) => {
  return (
    <TagBlock textColor={textColor}>
      {" "}
      {content && content.length < 255
        ? content
        : `${content?.substring(0, 255)}...`}
    </TagBlock>
  );
};

export default memo(IdentityInfo);
