export const CompletedStepIcon = (props: { style?: React.CSSProperties }) => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.0898 3.32534C23.9212 3.1577 23.7231 3.07338 23.4931 3.07338C23.3789 3.07338 23.1379 3.16718 22.7727 3.35228C22.406 3.53788 22.0158 3.74444 21.6067 3.97494C21.1961 4.20395 20.7101 4.4115 20.1488 4.5971C19.5885 4.7822 19.0702 4.876 18.5927 4.876C18.1511 4.876 17.763 4.79218 17.4272 4.62404C16.3151 4.10316 15.3472 3.7145 14.5264 3.45805C13.7052 3.2021 12.8221 3.07338 11.8767 3.07338C10.2442 3.07338 8.38467 3.60424 6.30016 4.66395C5.87009 4.87849 5.56175 5.04463 5.36218 5.16637L5.16411 3.714C5.61165 3.32534 5.90052 2.75906 5.90052 2.11993C5.90052 0.948956 4.95157 0 3.78059 0C2.60961 0 1.66016 0.948956 1.66016 2.11993C1.66016 2.88379 2.06828 3.54786 2.67397 3.92105L5.5378 24.9208C5.62312 25.546 6.15797 25.9995 6.77215 25.9995C6.82803 25.9995 6.8849 25.995 6.94228 25.989C7.62481 25.8952 8.10278 25.2671 8.00948 24.5845L6.85846 16.1447C8.94696 15.1085 10.7536 14.5861 12.2733 14.5861C12.9184 14.5861 13.5476 14.6814 14.1607 14.877C14.7744 15.072 15.2853 15.2826 15.6909 15.5116C16.0961 15.7416 16.5755 15.9541 17.1278 16.1472C17.6801 16.3418 18.2255 16.4391 18.7638 16.4391C20.1239 16.4391 21.7519 15.9267 23.6513 14.9024C23.8898 14.7787 24.0639 14.6569 24.1747 14.5382C24.2854 14.4189 24.3403 14.2488 24.3403 14.0288V3.92155C24.3403 3.69255 24.257 3.49348 24.0898 3.32534Z"
      fill="white"
    />
  </svg>
);
