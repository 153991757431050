import { Collapse as Cls, Drawer } from "antd";
import { ButtonGradient, ButtonWhite } from "components/base";
import styled from "styled-components";

export const Container = styled.div``;

export const DrawerAnt = styled(Drawer)`
  & .ant-drawer-body {
    padding: 0;
  }
`;

export const ActionButtonWrapper = styled.div`
  position: sticky;
  top: 0;
  padding: 24px;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  z-index: 9;
  background: white;
`;

export const CollapseWrapper = styled.div`
  padding: 0px 24px;
  font-weight: 500;
  font-size: 24px;
  line-height: 115%;
  letter-spacing: -0.03em;
  color: #080f6c;
  // border-bottom: 1px solid rgba(41, 46, 115, 0.12);
  display: flex;
  flex-direction: column;
  span {
    color: rgba(41, 46, 115, 0.82);
  }

  & > div:first-child {
    margin-bottom: 4px;
  }
  & > label {
    margin-left: 0 !important;
  }

  & .ant-collapse-header {
    font-size: 24px;
    color: #080f6c;
    padding: 0 !important;
    align-items: center !important;
  }

  & .anticon {
    display: flex !important;
    font-size: 16px !important;
  }

  .ant-checkbox-group {
    label {
      margin: 0 !important;
      padding-top: 4px;
    }
    display: flex;
    flex-direction: column;
  }
`;

export const CollapsePanel = styled(Cls.Panel)``;

export const Collapse = styled(Cls)``;

export const ApplyButton = styled(ButtonGradient)`
  height: 40px;
  width: 100px;
`;

export const ResetButton = styled(ButtonWhite)`
  height: 40px;
  width: 100px;
`;
