import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IBountyTypeUsState {
  text: string;
  id: number;
  currentStep?: number;
}

const initialState = {
  text: "Bounty Type",
  id: 1,
  currentStep: 5,
};

const bountyTypeSlice = createSlice({
  name: "bounty-type-slice-us",
  initialState,
  reducers: {
    setBountyType: (state, action: PayloadAction<IBountyTypeUsState>) => {
      state.text = action.payload.text;
      state.id = action.payload.id;
    },
  },
});

const { actions, reducer } = bountyTypeSlice;

export const bountyTypeUsAction = actions;

export default reducer;
