import axios from "api/axios";
import {
  ILoginParams,
  ISignUpParams,
} from "./types";

const END_POINT = {
  SIGNUP: "/auth-admin/signup",
  LOGIN: "/auth-admin/login",
  LOGOUT: "/auth-admin/logout",

};

export const signupAdAPI = async (params: ISignUpParams) => {
  return await axios({
    url: END_POINT.SIGNUP,
    method: "POST",
    data: params,
  });
};

export const loginAPIAd = async (params: ILoginParams) => {
  return await axios({
    url: END_POINT.LOGIN,
    method: "POST",
    data: params,
  });
};

export const logoutAPIAd = async () => {
  return await axios({
    url: END_POINT.LOGOUT,
    method: "POST",
  });
};