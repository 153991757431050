const MESSAGE_KEY = {
  GS010: "GnosisSafe: Not enough gas to execute Safe transaction",
  GS011: "GnosisSafe: Could not pay gas costs with ether",
  GS012: "GnosisSafe: Could not pay gas costs with token",
  GS013:
    "GnosisSafe: Safe transaction failed when gasPrice and safeTxGas were 0",
  GS020: "GnosisSafe: Signatures data too short",
  GS021: "GnosisSafe: Invalid contract signature location: inside static part",
  GS022: "GnosisSafe: Invalid contract signature location: length not present",
  GS023: "GnosisSafe: Invalid contract signature location: data not complete",
  GS024: "GnosisSafe: Invalid contract signature provided",
  GS025: "GnosisSafe: Hash has not been approved",
  GS026: "GnosisSafe: Invalid owner provided",
  GS027: "GnosisSafe: Data Hash and hash of the pre-image data do not match",
  "ERC20: transfer amount exceeds balance": "Not enough balance",
  "unknown account #0": "Please connect your wallet",
};

export const getBlockchainErrorMessageContent = (key: string) => {
  const processedKey = key.replace("execution reverted: ", "");
  return MESSAGE_KEY[processedKey] ? MESSAGE_KEY[processedKey] : key;
};

