import { useMutation } from "@tanstack/react-query";
import { Button, Checkbox, Collapse, Input, Row } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import TextArea from "antd/lib/input/TextArea";
import {
  createQuestionsAPI,
  deleteQuestionByIdAPI,
  getAllQuestionsAPI,
  getQuestionByIdAPI,
  IGetAllQuestionsRes,
  updateQuestionByIdAPI,
} from "api/users/questions";
import { AxiosError } from "axios";
import { FC, Fragment, memo, useRef, useState } from "react";
import ReactJson from "react-json-view";
import { IQuestionTbl } from "types";
import { METHOD_API } from "../apiTest";
import { CollapseShowJson } from "../commonUi.style";
const { Panel } = Collapse;

const QuestionsAPI: FC<any> = () => {
  const checkBox = useRef<any>(null);
  const [isShow, setIsShow] = useState<boolean>(true);
  const [createdResult, setCreateResult] = useState<IQuestionTbl>({});
  const [updateResult, setUpdateResult] = useState<any>({});
  const [deleteResult, setDeleteResult] = useState<any>({});
  const [question, setQuestion] = useState<IQuestionTbl>({});
  const [questionId, setQuestionId] = useState<string>("");
  const [updateQuestionId, setUpdateQuestionId] = useState<string>("");
  const [deleteQuestionId, setDeleteQuestionId] = useState<string>("");
  const [allQuestions, setAllQuestions] = useState<IGetAllQuestionsRes>({});
  const [textInputForUpdate, setTextInputForUpdate] = useState<string>(`
  {
    "title": "tuan",
    "content": "baoooo",
    "tag_names": ["VietNam", "VoDich2"]
  }
  `);
  const [textInputForGetAll, setTextInputForGetAll] = useState<string>(`{
    "search": "",
    "limit": 10,
    "page": 1,
    "tags": [],
    "sort_by": 1
  }`);
  const [textInputForCreate, setTextInputForCreate] = useState<string>(`{
    "user_id":3,
    "project_id": 1,
    "title": "tuan",
    "content": "baoooo",
    "tag_names": ["VietNam", "VoDich"]
}`);

  const { mutate: createQuestions } = useMutation(createQuestionsAPI, {
    onSuccess: async (rs: IQuestionTbl) => {
      setCreateResult(rs);
    },
    onError: (err: AxiosError) => {
      setCreateResult(JSON.parse(JSON.stringify(err)));
    },
  });

  const { mutate: getAllQuestions } = useMutation(getAllQuestionsAPI, {
    onSuccess: async (rs: IGetAllQuestionsRes) => {
      setAllQuestions(rs);
    },
    onError: (err: any) => {
      setAllQuestions(JSON.parse(JSON.stringify(err)));
    },
  });

  const { mutate: getQuestionById } = useMutation(getQuestionByIdAPI, {
    onSuccess: async (rs: IQuestionTbl) => {
      setQuestion(rs);
    },
    onError: (err: any) => {
      setQuestion(JSON.parse(JSON.stringify(err)));
    },
  });

  const { mutate: updateQuestionById } = useMutation(updateQuestionByIdAPI, {
    onSuccess: async (rs: any) => {
      setUpdateResult(rs);
    },
    onError: (err: any) => {
      setUpdateResult(JSON.parse(JSON.stringify(err)));
    },
  });

  const { mutate: deleteQuestionById } = useMutation(deleteQuestionByIdAPI, {
    onSuccess: async (rs: any) => {
      setDeleteResult(rs);
    },
    onError: (err: any) => {
      setDeleteResult(JSON.parse(JSON.stringify(err)));
    },
  });

  const handleChageTextInputForCreate = (event: any) => {
    setTextInputForCreate(event.target.value);
  };

  const handleChangeTextInputForGetAll = (event: any) => {
    setTextInputForGetAll(event.target.value);
  };

  const handleChangeQuestionId = (event: any) => {
    setQuestionId(event.target.value);
  };

  const handleChangeUpdateQuestionId = (event: any) => {
    setUpdateQuestionId(event.target.value);
  };

  const handleChangeTextInputForUpdate = (event: any) => {
    setTextInputForUpdate(event.target.value);
  };

  const handleChangeDeleteQuestionId = (event: any) => {
    setDeleteQuestionId(event.target.value);
  };

  const createQuestionsClick = () => {
    createQuestions(JSON.parse(textInputForCreate));
  };

  const getAllQuestionsAPIClick = () => {
    getAllQuestions(JSON.parse(textInputForGetAll));
  };

  const getByIdClick = () => {
    getQuestionById(questionId);
  };

  const updateQuestionByIdClick = () => {
    updateQuestionById({
      id: updateQuestionId,
      updatedQuestion: JSON.parse(textInputForUpdate),
    });
  };

  const deleteQuestionByIdClick = () => {
    deleteQuestionById(deleteQuestionId);
  };

  return (
    <>
      <Row style={{ marginTop: "10px" }}>
        <h1>
          <b>Questions</b>
        </h1>
        <Checkbox
          ref={checkBox}
          onChange={(e: CheckboxChangeEvent) => {
            setIsShow(e.target.checked);
          }}
          style={{
            marginLeft: "10px",
          }}
          checked={isShow}
        />
      </Row>
      {isShow && (
        <Collapse accordion>
          <Panel header="/questions" key="1" extra={METHOD_API.POST}>
            <Button
              type="primary"
              onClick={createQuestionsClick}
              style={{
                marginBottom: "10px",
              }}
            >
              Try it out
            </Button>
            <h1>Request body</h1>
            <TextArea
              style={{
                marginBottom: "10px",
              }}
              value={textInputForCreate}
              defaultValue={textInputForCreate}
              onChange={handleChageTextInputForCreate}
              placeholder=""
              rows={10}
            />
            <Fragment>
              <h1>Result</h1>
              <CollapseShowJson ghost>
                <ReactJson src={JSON.parse(JSON.stringify(createdResult))} />
              </CollapseShowJson>
            </Fragment>
          </Panel>
          <Panel header="/questions/alls" key="2" extra={METHOD_API.POST}>
            <Button
              type="primary"
              onClick={getAllQuestionsAPIClick}
              style={{
                marginBottom: "10px",
              }}
            >
              Try it out
            </Button>
            <h1>Request body</h1>
            <TextArea
              style={{
                marginBottom: "10px",
              }}
              value={textInputForGetAll}
              defaultValue={textInputForGetAll}
              onChange={handleChangeTextInputForGetAll}
              placeholder=""
              rows={10}
            />
            <Fragment>
              <h1>Result</h1>
              <CollapseShowJson ghost>
                <ReactJson src={JSON.parse(JSON.stringify(allQuestions))} />
              </CollapseShowJson>
            </Fragment>
          </Panel>
          <Panel header="/questions/{id}" key="3" extra={METHOD_API.GET}>
            <Button
              type="primary"
              onClick={getByIdClick}
              style={{
                marginBottom: "10px",
              }}
            >
              Try it out
            </Button>
            <h1>Request body</h1>
            <Input
              placeholder="Enter question id"
              allowClear
              onChange={handleChangeQuestionId}
              value={questionId}
              style={{
                marginBottom: "10px",
              }}
            />
            <Fragment>
              <h1>Result</h1>
              <CollapseShowJson ghost>
                <ReactJson src={JSON.parse(JSON.stringify(question))} />
              </CollapseShowJson>
            </Fragment>
          </Panel>
          <Panel header="/questions/{id}" key="4" extra={METHOD_API.PUT}>
            <Button
              type="primary"
              onClick={updateQuestionByIdClick}
              style={{
                marginBottom: "10px",
              }}
            >
              Try it out
            </Button>
            <Input
              placeholder="Enter question id"
              allowClear
              onChange={handleChangeUpdateQuestionId}
              value={updateQuestionId}
              style={{
                marginBottom: "10px",
              }}
            />
            <h1>Request body</h1>
            <TextArea
              style={{
                marginBottom: "10px",
              }}
              value={textInputForUpdate}
              defaultValue={textInputForUpdate}
              onChange={handleChangeTextInputForUpdate}
              placeholder=""
              rows={10}
            />
            <Fragment>
              <h1>Result</h1>
              <CollapseShowJson ghost>
                <ReactJson src={JSON.parse(JSON.stringify(updateResult))} />
              </CollapseShowJson>
            </Fragment>
          </Panel>
          <Panel header="/questions/{id}" key="5" extra={METHOD_API.DELETE}>
            <Button
              type="primary"
              onClick={deleteQuestionByIdClick}
              style={{
                marginBottom: "10px",
              }}
            >
              Try it out
            </Button>
            <h1>Request body</h1>
            <Input
              placeholder="Enter question id"
              allowClear
              onChange={handleChangeDeleteQuestionId}
              value={deleteQuestionId}
              style={{
                marginBottom: "10px",
              }}
            />
            <Fragment>
              <h1>Result</h1>
              <CollapseShowJson ghost>
                <ReactJson src={JSON.parse(JSON.stringify(deleteResult))} />
              </CollapseShowJson>
            </Fragment>
          </Panel>
        </Collapse>
      )}
    </>
  );
};

export default memo(QuestionsAPI);
