import { FC } from "react";
import { ISelectUpDownIconProps } from "./selectUpDownIcon.props";
import { FilterByType, IconFilter } from "./selectUpDownIcon.style";
import iconFilter from "assets/users/svg/icon_filter.svg";
import { Select } from "antd";

const { Option } = Select;

const SelectUpDownIcon: FC<ISelectUpDownIconProps> = ({
  defaultValue,
  options,
  onChange,
}: ISelectUpDownIconProps) => {
  return (
    <FilterByType
      defaultValue={defaultValue}
      suffixIcon={<IconFilter src={iconFilter} alt="Icon filter" />}
      onChange={onChange}
    >
      {options?.map((item: any, key: number) => (
        <Option key={key} value={item.value}>
          {item?.label}
        </Option>
      ))}
    </FilterByType>
  );
};

export default SelectUpDownIcon;
