import axios from "api/axios";
import { IChangePassParams } from "pages/users/changePasswordPage/changePasswordPage.props";
import {
  IGetProjectPortfolioReq,
  IGetProjectPortfolioRes,
  ILoginParams,
  IProfile,
  ISignUpParams,
  IVerifyParams,
  IVerifyTokenParams,
} from "./types";

const END_POINT = {
  LOGIN: "/hkt-auth/sign-in",
  LOGOUT: "/auth/logout",
  GET_USER: "/users/profile",
  GET_USER_BASIC_INFO: "/users/profile/basic-info",
  SIGNUP: "/hkt-auth/sign-up",
  RESEND_VERIFY_EMAIL: "/users/resend-verify-email",
  VERIFY_EMAIL: "/users/verify-email",
  FORGOT_PASSWORD: "/users/forgot-password",
  UPDATE_PASSWORD: "/users/change-password",
  GET_PROJECT_PORTFOLIO: "/users/profile/portfolio",
  GET_PUBLIC_PROJECT_PORTFOLIO: "/users/profile/:id/portfolio",
  GET_PROFILE_PREVIEW: "/users/profile/preview",
  GET_PUBLIC_PROFILE_PREVIEW: "/users/profile/:id",
};

export const resendVerifyEmailAPI = async (params: IVerifyParams) => {
  return await axios({
    url: END_POINT.RESEND_VERIFY_EMAIL,
    method: "POST",
    data: params,
  });
};

export const signupAPI = async (params: ISignUpParams) => {
  return await axios({
    url: END_POINT.SIGNUP,
    method: "POST",
    data: params,
  });
};

export const loginAPI = async (params: ILoginParams) => {
  return await axios({
    url: END_POINT.LOGIN,
    method: "POST",
    data: params,
  });
};

export const logoutAPI = async () => {
  return await axios({
    url: END_POINT.LOGOUT,
    method: "POST",
  });
};

export const getUserAPI = async (): Promise<IProfile> => {
  const { data } = await axios({
    url: END_POINT.GET_USER,
    method: "GET",
  });

  return data;
};

export const getUserBasicInfoAPI = async (): Promise<IProfile> => {
  const { data } = await axios({
    url: END_POINT.GET_USER_BASIC_INFO,
    method: "GET",
  });

  return data;
};

export const getUserVerifyEmailAPI = async (
  params: IVerifyTokenParams
): Promise<boolean> => {
  const { data } = await axios({
    url: END_POINT.VERIFY_EMAIL,
    method: "GET",
    params: params,
  });

  return data;
};

export const postUsersForgotPassAPI = async (
  email: string
): Promise<boolean> => {
  const { data } = await axios({
    url: END_POINT.FORGOT_PASSWORD,
    method: "POST",
    data: { email },
  });

  return data;
};

export const postUsersUpdatePassAPI = async (
  params: IChangePassParams
): Promise<boolean> => {
  const { data } = await axios({
    url: END_POINT.UPDATE_PASSWORD,
    method: "POST",
    data: params,
  });

  return data;
};

export const getProjectPortfolioAPI = async (
  params: IGetProjectPortfolioReq
): Promise<IGetProjectPortfolioRes> => {
  let url = `${END_POINT.GET_PROJECT_PORTFOLIO}?limit=${params.limit}&page=${params.page}&keyword=${params.keyword}`;

  if (params.id) {
    url = `${END_POINT.GET_PUBLIC_PROJECT_PORTFOLIO.replace(
      ":id",
      params.id
    )}?limit=${params.limit}&page=${params.page}&keyword=${params.keyword}`;
  }

  const { data } = await axios({
    url: url,
    method: "GET",
  });
  return data;
};

export const getProfilePreviewAPI = async (id?: string): Promise<IProfile> => {
  const { data } = await axios({
    url: id
      ? `${END_POINT.GET_PUBLIC_PROFILE_PREVIEW.replace(":id", id)}`
      : `${END_POINT.GET_PROFILE_PREVIEW}`,
    method: "GET",
  });
  return data;
};
