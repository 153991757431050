import { TreeSelect } from "antd";
import styled from "styled-components";

export const Container = styled.div``;

export const StyledTreeSelect = styled(TreeSelect)`
  &:not(.ant-select-customize-input) > .ant-select-selector {
    border-radius: 4px;
  }

  .ant-select-selection-placeholder,
  .ant-select-arrow {
    color: rgba(8, 10, 37, 1);
  }

  &.ant-select-open {
    /* background-clip: padding-box;
    border: solid 0px transparent;
    border-radius: 4px; */

    /* &:before {
      content: '';
      position: absolute;
      top: 0; right: 0; bottom: 0; left: 0;
      margin: -1px;
      border-radius: 5px;
      background: linear-gradient(to right,
        rgba(197, 138, 255, 1), rgba(125, 151, 253, 1), rgba(0, 173, 251, 1), rgba(0, 212, 201, 1), rgba(0, 222, 140, 1));
    }

    &:not(.ant-select-customize-input) > .ant-select-selector {
      border-style: none;
    } */
  }
`;
