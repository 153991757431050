import { Form } from "antd";
import styled from "styled-components";

export const Container = styled(Form)`
  @keyframes visibleAnimation {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  animation: visibleAnimation 2s;
  .ant-form-item-control {
    flex: auto;
  }
`;

export const Content = styled.div`
  background: #f7f7f7;
  position: relative;
  font-family: "Rubik";
  font-style: normal;
  margin: 32px 0px;
  padding: 50px 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 4px;

  @media (max-width: 992px) {
    height: auto;
  }
`;

export const Heading = styled.h1`
  font-weight: 500;
  font-size: 32px;
  line-height: 120%;
  letter-spacing: -0.02em;
  color: #080a25;
`;

export const Description = styled.div`
  max-width: 620px;
  font-weight: 400;
  font-size: 16px;
  line-height: 156%;
  color: rgba(8, 10, 37, 0.6);
`;

export const ImageWrap = styled.div`
  display: block;
  width: 340px;
  height: 340px;
  position: relative;
`;

export const ButtonWrap = styled.div`
  display: block;
  margin-top: 16px;
`;

export const Image = styled.img`
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform-origin: left;

  @keyframes popper_move {
    0% {
      width: 90%;
      transform: rotate(-5deg) translate(-50%, -50%);
    }
    10% {
      width: 92%;
    }
    40% {
      width: 98%;
    }
    50% {
      width: 100%;
      transform: rotate(5deg) translate(-50%, -50%);
    }
    60% {
      width: 98%;
    }
    90% {
      width: 92%;
    }
    100% {
      width: 90%;
      transform: rotate(-5deg) translate(-50%, -50%);
    }
  }

  animation: popper_move 1s infinite linear;
`;

