import { Layout } from "antd";
import styled from "styled-components";

const { Content } = Layout;

export const Container = styled.div``;

export const ContentBody = styled(Content)`
  background: #f2f7f8;
  span > svg,
  span.ant-breadcrumb-link {
    font-size: 18px;
  }
`;

export const ContainerBodyAd = styled.div<{ isLoading: boolean }>`
  height: calc(100vh - 165px);
  overflow-y: auto;
  ${({ isLoading }: any) => {
    return isLoading
      ? `
    display: flex;
    justify-content: center;
    align-items: center;
    `
      : ``;
  }}
`;

export const LayoutMaster = styled(Layout)`
  background: black;
  .ant-layout-sider {
    flex: 0 0 270px !important;
    max-width: 270px !important;
    min-width: 270px !important;
    width: 270px !important;
  }
  .ant-layout-sider-collapsed {
    flex: 0 0 100px !important;
    max-width: 100px !important;
    min-width: 100px !important;
    width: 100px !important;
  }
`;
