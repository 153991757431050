import { Editor } from "@tinymce/tinymce-react";
import { FC, memo } from "react";
import { IEditorTinyProps } from "./editorTiny.props";
import { useMutation } from "@tanstack/react-query";
import { postUploadsAPI } from "api/users/uploads";
import axios from "axios";

const EditorTiny: FC<IEditorTinyProps> = ({
  width,
  height,
  initValue,
  disabled,
  onChange,
  onInit,
  initCustom,
  ...props
}: IEditorTinyProps) => {
  const { mutateAsync: postUploads } = useMutation(postUploadsAPI);

  const handleUploadImage: any = async (blobInfo: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        const file = blobInfo.blob();
        const rs = await postUploads({
          file_name: file.name,
          file_type: file.type,
        });
        await axios.put(rs.signedRequest, file);
        console.log(rs.url);
        resolve(rs.url);
      } catch (err) {
        reject(err);
      }
    });
  };

  return (
    <Editor
      apiKey={process.env.REACT_APP_TINY_MCU_API_ACCESS}
      initialValue={initValue}
      init={{
        width: width ?? "100%",
        height: height ?? "150px",
        style_formats: [
          {
            title: "Headings",
            items: [
              { title: "Heading 1", format: "h1" },
              { title: "Heading 2", format: "h2" },
              { title: "Heading 3", format: "h3" },
              { title: "Heading 4", format: "h4" },
              { title: "Heading 5", format: "h5" },
              { title: "Heading 6", format: "h6" },
            ],
          },
          {
            title: "Inline",
            items: [
              { title: "Bold", format: "bold" },
              { title: "Italic", format: "italic" },
              { title: "Underline", format: "underline" },
              { title: "Strikethrough", format: "strikethrough" },
              { title: "Superscript", format: "superscript" },
              { title: "Subscript", format: "subscript" },
              { title: "Code", format: "code" },
            ],
          },
          {
            title: "Blocks",
            items: [
              { title: "Paragraph", format: "p" },
              { title: "Blockquote", format: "blockquote" },
              { title: "Div", format: "div" },
              { title: "Pre", format: "pre" },
            ],
          },
        ],
        menubar: false,
        plugins: [
          "autoresize",
          "image",
          "code",
          "table",
          "link",
          "media",
          "codesample",
          "preview",
          "fullscreen",
          "lists",
        ],
        toolbar_mode: "sliding",
        toolbar:
          "undo redo | styleselect  | bold italic underline table link image | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | fullscreen code preview media",
        images_upload_handler: handleUploadImage,
        ...initCustom,
      }}
      {...props}
      onChange={(e: any) => {
        if (onChange) {
          const contentAsText: string = e.target.getContent({ format: "text" });
          if (contentAsText.trim().length === 0) return onChange("");

          onChange(e.target.getContent());
        }
      }}
      disabled={disabled}
      onInit={onInit}
    />
  );
};

export default memo(EditorTiny);

