import { Image } from "antd";
import styled from "styled-components";

export const Container = styled(Image)<{
  width: string | undefined;
  height: string | undefined;
}>`
  ${({ width, height }: any) => {
    return `width: ${width};
    height: ${height}`;
  }};
  display: flex;
`;
