export const LogoAdminFull = () => {
  return (
    <svg
      width="119"
      height="48"
      viewBox="0 0 119 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5209_7970)">
        <path
          d="M109.834 34.4422H18.8012C14.0892 34.4422 10.2656 30.58 10.2656 25.9067C10.2656 21.1947 14.1279 17.3711 18.8012 17.3711H109.834C114.546 17.3711 118.37 21.2333 118.37 25.9067C118.37 30.58 114.546 34.4422 109.834 34.4422Z"
          fill="#102548"
        ></path>
        <path
          d="M40.0805 6.8671C30.9269 -2.28643 16.0573 -2.28643 6.86514 6.8671C-2.28838 16.0206 -2.28838 30.8903 6.86514 40.0824C6.94239 40.1597 7.05826 40.2369 7.1355 40.3142L40.3122 7.13359C40.235 7.05635 40.1577 6.94048 40.0805 6.86323V6.8671Z"
          fill="url(#paint0_linear_5209_7970)"
        ></path>
        <path
          d="M33.9023 14.4326C28.5337 9.06412 19.8051 9.06412 14.4365 14.4326C9.06803 19.8012 9.06803 28.5298 14.4365 33.8984L14.591 34.0529L34.0568 14.5871L33.9023 14.4326Z"
          fill="white"
        ></path>
        <path
          d="M9.37627 37.4127C17.1008 45.1372 29.6917 45.1372 37.4162 37.4127C45.1407 29.6882 45.1407 17.0973 37.4162 9.3728C37.3389 9.29555 37.2617 9.21831 37.1844 9.17969L9.14453 37.1848L9.37627 37.4166V37.4127Z"
          fill="url(#paint1_linear_5209_7970)"
        ></path>
        <path
          d="M16.4831 30.0743C20.2294 33.8206 26.3318 33.8206 30.0782 30.0743C33.8245 26.3279 33.8245 20.2255 30.0782 16.4791L29.9623 16.3633L16.3672 29.9584L16.4831 30.0743Z"
          fill="#102548"
        ></path>
        <path
          d="M23.4734 27.1811C25.5211 27.1811 27.1811 25.5211 27.1811 23.4734C27.1811 21.4256 25.5211 19.7656 23.4734 19.7656C21.4256 19.7656 19.7656 21.4256 19.7656 23.4734C19.7656 25.5211 21.4256 27.1811 23.4734 27.1811Z"
          fill="url(#paint2_linear_5209_7970)"
        ></path>
        <path
          d="M55.8039 21.7739C56.5823 22.6518 56.7856 23.9665 56.4137 25.7179C56.0461 27.4347 55.2828 28.7494 54.1238 29.6618C53.2459 30.4057 52.1237 30.7776 50.7572 30.7776C49.3906 30.7776 48.4241 30.4057 47.8576 29.6618C47.0835 28.7494 46.8803 27.4347 47.2478 25.7179C47.6197 23.9665 48.383 22.6518 49.5377 21.7739C50.4155 21.0301 51.5378 20.6582 52.9043 20.6582C54.2709 20.6582 55.2374 21.0301 55.8039 21.7739ZM52.5475 22.3513C51.7561 22.3513 51.0621 22.6432 50.4653 23.227C49.8728 23.8108 49.462 24.6411 49.2328 25.7179C49.0036 26.7947 49.062 27.625 49.4079 28.2088C49.7539 28.7926 50.3226 29.0845 51.114 29.0845C51.9053 29.0845 52.5951 28.7926 53.1832 28.2088C53.7757 27.625 54.1865 26.7947 54.4157 25.7179C54.6406 24.6454 54.5822 23.8173 54.2406 23.2335C53.9033 22.6453 53.3389 22.3513 52.5475 22.3513Z"
          fill="white"
        ></path>
        <path
          d="M60.5652 23.7589C61.0409 23.4432 61.562 23.2853 62.1285 23.2853C62.9545 23.2853 63.588 23.5902 64.0291 24.2C64.4746 24.8097 64.5719 25.7049 64.321 26.8855C64.0572 28.131 63.5751 29.0802 62.8745 29.7332C62.1782 30.3862 61.389 30.7127 60.5068 30.7127C59.9446 30.7127 59.5078 30.5722 59.1965 30.2911C59.0235 30.1354 58.8721 29.9083 58.7424 29.61L57.9575 33.2944H56.1282L58.2235 23.441H59.9944L59.7738 24.4854C60.0376 24.1784 60.3014 23.9362 60.5652 23.7589ZM61.655 28.5656C62.0096 28.1677 62.2561 27.638 62.3945 26.9763C62.5112 26.4314 62.4896 25.9493 62.3296 25.5298C62.1696 25.1103 61.8107 24.9006 61.2528 24.9006C60.5825 24.9006 60.0549 25.2184 59.67 25.8541C59.4711 26.1914 59.3154 26.6196 59.203 27.1385C59.03 27.9602 59.1251 28.5375 59.4884 28.8705C59.7089 29.0651 59.9965 29.1624 60.3511 29.1624C60.8657 29.1624 61.3004 28.9634 61.655 28.5656Z"
          fill="white"
        ></path>
        <path
          d="M69.0564 28.9224C69.2077 28.8056 69.3569 28.6434 69.504 28.4358H71.3916C71.2532 28.8553 70.944 29.2813 70.464 29.7137C69.7115 30.4013 68.7904 30.7451 67.7006 30.7451C66.8011 30.7451 66.0681 30.4554 65.5016 29.8759C64.9394 29.2964 64.7967 28.3537 65.0735 27.0477C65.333 25.8238 65.8411 24.8854 66.5979 24.2324C67.359 23.5794 68.2347 23.2529 69.225 23.2529C69.8132 23.2529 70.3191 23.3632 70.7429 23.5837C71.1711 23.8043 71.4868 24.1524 71.69 24.6281C71.8803 25.0476 71.956 25.5341 71.9171 26.0876C71.8998 26.412 71.8219 26.879 71.6835 27.4888H66.8444C66.719 28.198 66.8357 28.6953 67.1947 28.9807C67.4109 29.158 67.6985 29.2467 68.0574 29.2467C68.438 29.2467 68.771 29.1386 69.0564 28.9224ZM68.9007 24.7773C68.4726 24.7773 68.1115 24.9114 67.8174 25.1795C67.5277 25.4476 67.305 25.8109 67.1493 26.2693H70.0229C70.0964 25.7806 70.0251 25.4108 69.8088 25.16C69.5969 24.9049 69.2942 24.7773 68.9007 24.7773Z"
          fill="white"
        ></path>
        <path
          d="M77.7162 26.2109C77.7984 25.839 77.8092 25.5536 77.7487 25.3546C77.6449 24.9914 77.3378 24.8097 76.8275 24.8097C76.2005 24.8097 75.714 25.0757 75.368 25.6076C75.1864 25.8887 75.0502 26.2476 74.9594 26.6844L74.1485 30.5116H72.3063L73.8047 23.454H75.5886L75.368 24.4854C75.6837 24.1221 75.9648 23.8605 76.2113 23.7005C76.6524 23.4151 77.1649 23.2724 77.7487 23.2724C78.4795 23.2724 79.0352 23.4648 79.4158 23.8497C79.8006 24.2303 79.9001 24.8638 79.7142 25.7503L78.7022 30.5116H76.8081L77.7162 26.2109Z"
          fill="white"
        ></path>
        <path
          d="M85.0463 24.6735L86.6096 25.0692C87.2929 25.2422 87.788 25.4736 88.0951 25.7633C88.5708 26.213 88.7178 26.8639 88.5362 27.7158C88.3502 28.5894 87.8615 29.3159 87.0702 29.8954C86.2788 30.4705 85.2733 30.7581 84.0538 30.7581C82.8083 30.7581 81.8894 30.4749 81.2969 29.9083C80.7045 29.3375 80.5142 28.5548 80.7261 27.5601H82.6332C82.6029 27.9969 82.6527 28.3234 82.7824 28.5396C83.0246 28.9332 83.5392 29.1299 84.3263 29.1299C84.7976 29.1299 85.1912 29.078 85.5069 28.9743C86.108 28.7753 86.4647 28.4056 86.5772 27.865C86.6464 27.5493 86.5599 27.305 86.3177 27.132C86.0798 26.9633 85.6777 26.8142 85.1112 26.6844L84.1446 26.4574C83.1976 26.2325 82.5619 25.9882 82.2375 25.7244C81.6883 25.2833 81.5132 24.5935 81.7121 23.6551C81.8937 22.7988 82.3564 22.0875 83.1003 21.5209C83.8441 20.9544 84.8193 20.6712 86.0258 20.6712C87.0334 20.6712 87.8356 20.9393 88.4324 21.4755C89.0292 22.0075 89.2367 22.7815 89.0551 23.7978H87.135C87.2258 23.2226 87.0637 22.814 86.6485 22.5718C86.3718 22.4118 86.002 22.3318 85.5393 22.3318C85.0247 22.3318 84.5901 22.4356 84.2354 22.6432C83.8852 22.8507 83.6711 23.1405 83.5933 23.5124C83.5197 23.854 83.617 24.1092 83.8852 24.2778C84.0538 24.3903 84.4409 24.5222 85.0463 24.6735Z"
          fill="white"
        ></path>
        <path
          d="M96.4825 24.3362C96.9193 25.0843 97.0295 25.9687 96.8133 26.9893C96.5928 28.0272 96.1041 28.9159 95.3473 29.6554C94.5948 30.3905 93.611 30.7581 92.3958 30.7581C91.1806 30.7581 90.3525 30.3905 89.9114 29.6554C89.4746 28.9159 89.3665 28.0272 89.587 26.9893C89.8033 25.9687 90.2898 25.0843 91.0466 24.3362C91.8033 23.5881 92.7893 23.214 94.0045 23.214C95.2197 23.214 96.0457 23.5881 96.4825 24.3362ZM93.6607 24.7773C93.1202 24.7773 92.6639 24.9697 92.292 25.3546C91.9201 25.7352 91.6585 26.2801 91.5071 26.9893C91.3558 27.6985 91.3839 28.2456 91.5914 28.6305C91.8033 29.0153 92.1796 29.2078 92.7201 29.2078C93.2607 29.2078 93.7169 29.0153 94.0889 28.6305C94.4608 28.2456 94.7224 27.6985 94.8738 26.9893C95.0251 26.2801 94.9948 25.7352 94.7829 25.3546C94.5754 24.9697 94.2013 24.7773 93.6607 24.7773Z"
          fill="white"
        ></path>
        <path
          d="M102.139 29.5127C102.117 29.5343 102.061 29.5991 101.97 29.7073C101.879 29.8154 101.778 29.9105 101.665 29.9927C101.319 30.2521 100.999 30.4295 100.705 30.5246C100.416 30.6197 100.089 30.6673 99.7259 30.6673C98.6793 30.6673 98.0544 30.2911 97.8512 29.5386C97.7388 29.1234 97.769 28.5115 97.942 27.7028L98.8437 23.441H100.738L99.8361 27.7028C99.7496 28.105 99.7324 28.4077 99.7842 28.611C99.8751 28.9699 100.167 29.1494 100.66 29.1494C101.291 29.1494 101.778 28.8942 102.119 28.384C102.301 28.1072 102.439 27.7418 102.535 27.2877L103.352 23.441H105.227L103.728 30.5116H101.931L102.139 29.5127Z"
          fill="white"
        ></path>
        <path
          d="M105.557 30.5116L107.588 20.9501H109.437L107.406 30.5116H105.557Z"
          fill="white"
        ></path>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_5209_7970"
          x1="20.1561"
          y1="0.00195312"
          x2="20.1561"
          y2="40.3142"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B007FF"></stop>
          <stop offset="1" stopColor="#26DCDC"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear_5209_7970"
          x1="26.177"
          y1="9.17969"
          x2="26.177"
          y2="43.2061"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C4C5D5"></stop>
          <stop offset="1" stopColor="#256E8D"></stop>
        </linearGradient>
        <linearGradient
          id="paint2_linear_5209_7970"
          x1="23.4734"
          y1="19.7656"
          x2="23.4734"
          y2="27.1811"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B007FF"></stop>
          <stop offset="1" stopColor="#26DCDC"></stop>
        </linearGradient>
        <clipPath id="clip0_5209_7970">
          <rect width="118.37" height="48" fill="white"></rect>
        </clipPath>
      </defs>
    </svg>
  );
};

export const NewLogo = () => {
  return (
    <svg
      width="119"
      height="48"
      viewBox="0 0 119 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5209_7970)">
        <path
          d="M109.834 34.4422H18.8012C14.0892 34.4422 10.2656 30.58 10.2656 25.9067C10.2656 21.1947 14.1279 17.3711 18.8012 17.3711H109.834C114.546 17.3711 118.37 21.2333 118.37 25.9067C118.37 30.58 114.546 34.4422 109.834 34.4422Z"
          fill="#102548"
        />
        <path
          d="M40.0805 6.8671C30.9269 -2.28643 16.0573 -2.28643 6.86514 6.8671C-2.28838 16.0206 -2.28838 30.8903 6.86514 40.0824C6.94239 40.1597 7.05826 40.2369 7.1355 40.3142L40.3122 7.13359C40.235 7.05635 40.1577 6.94048 40.0805 6.86323V6.8671Z"
          fill="url(#paint0_linear_5209_7970)"
        />
        <path
          d="M33.9023 14.4326C28.5337 9.06412 19.8051 9.06412 14.4365 14.4326C9.06803 19.8012 9.06803 28.5298 14.4365 33.8984L14.591 34.0529L34.0568 14.5871L33.9023 14.4326Z"
          fill="white"
        />
        <path
          d="M9.37627 37.4127C17.1008 45.1372 29.6917 45.1372 37.4162 37.4127C45.1407 29.6882 45.1407 17.0973 37.4162 9.3728C37.3389 9.29555 37.2617 9.21831 37.1844 9.17969L9.14453 37.1848L9.37627 37.4166V37.4127Z"
          fill="url(#paint1_linear_5209_7970)"
        />
        <path
          d="M16.4831 30.0743C20.2294 33.8206 26.3318 33.8206 30.0782 30.0743C33.8245 26.3279 33.8245 20.2255 30.0782 16.4791L29.9623 16.3633L16.3672 29.9584L16.4831 30.0743Z"
          fill="#102548"
        />
        <path
          d="M23.4734 27.1811C25.5211 27.1811 27.1811 25.5211 27.1811 23.4734C27.1811 21.4256 25.5211 19.7656 23.4734 19.7656C21.4256 19.7656 19.7656 21.4256 19.7656 23.4734C19.7656 25.5211 21.4256 27.1811 23.4734 27.1811Z"
          fill="url(#paint2_linear_5209_7970)"
        />
        <path
          d="M55.8039 21.7739C56.5823 22.6518 56.7856 23.9665 56.4137 25.7179C56.0461 27.4347 55.2828 28.7494 54.1238 29.6618C53.2459 30.4057 52.1237 30.7776 50.7572 30.7776C49.3906 30.7776 48.4241 30.4057 47.8576 29.6618C47.0835 28.7494 46.8803 27.4347 47.2478 25.7179C47.6197 23.9665 48.383 22.6518 49.5377 21.7739C50.4155 21.0301 51.5378 20.6582 52.9043 20.6582C54.2709 20.6582 55.2374 21.0301 55.8039 21.7739ZM52.5475 22.3513C51.7561 22.3513 51.0621 22.6432 50.4653 23.227C49.8728 23.8108 49.462 24.6411 49.2328 25.7179C49.0036 26.7947 49.062 27.625 49.4079 28.2088C49.7539 28.7926 50.3226 29.0845 51.114 29.0845C51.9053 29.0845 52.5951 28.7926 53.1832 28.2088C53.7757 27.625 54.1865 26.7947 54.4157 25.7179C54.6406 24.6454 54.5822 23.8173 54.2406 23.2335C53.9033 22.6453 53.3389 22.3513 52.5475 22.3513Z"
          fill="white"
        />
        <path
          d="M60.5652 23.7589C61.0409 23.4432 61.562 23.2853 62.1285 23.2853C62.9545 23.2853 63.588 23.5902 64.0291 24.2C64.4746 24.8097 64.5719 25.7049 64.321 26.8855C64.0572 28.131 63.5751 29.0802 62.8745 29.7332C62.1782 30.3862 61.389 30.7127 60.5068 30.7127C59.9446 30.7127 59.5078 30.5722 59.1965 30.2911C59.0235 30.1354 58.8721 29.9083 58.7424 29.61L57.9575 33.2944H56.1282L58.2235 23.441H59.9944L59.7738 24.4854C60.0376 24.1784 60.3014 23.9362 60.5652 23.7589ZM61.655 28.5656C62.0096 28.1677 62.2561 27.638 62.3945 26.9763C62.5112 26.4314 62.4896 25.9493 62.3296 25.5298C62.1696 25.1103 61.8107 24.9006 61.2528 24.9006C60.5825 24.9006 60.0549 25.2184 59.67 25.8541C59.4711 26.1914 59.3154 26.6196 59.203 27.1385C59.03 27.9602 59.1251 28.5375 59.4884 28.8705C59.7089 29.0651 59.9965 29.1624 60.3511 29.1624C60.8657 29.1624 61.3004 28.9634 61.655 28.5656Z"
          fill="white"
        />
        <path
          d="M69.0564 28.9224C69.2077 28.8056 69.3569 28.6434 69.504 28.4358H71.3916C71.2532 28.8553 70.944 29.2813 70.464 29.7137C69.7115 30.4013 68.7904 30.7451 67.7006 30.7451C66.8011 30.7451 66.0681 30.4554 65.5016 29.8759C64.9394 29.2964 64.7967 28.3537 65.0735 27.0477C65.333 25.8238 65.8411 24.8854 66.5979 24.2324C67.359 23.5794 68.2347 23.2529 69.225 23.2529C69.8132 23.2529 70.3191 23.3632 70.7429 23.5837C71.1711 23.8043 71.4868 24.1524 71.69 24.6281C71.8803 25.0476 71.956 25.5341 71.9171 26.0876C71.8998 26.412 71.8219 26.879 71.6835 27.4888H66.8444C66.719 28.198 66.8357 28.6953 67.1947 28.9807C67.4109 29.158 67.6985 29.2467 68.0574 29.2467C68.438 29.2467 68.771 29.1386 69.0564 28.9224ZM68.9007 24.7773C68.4726 24.7773 68.1115 24.9114 67.8174 25.1795C67.5277 25.4476 67.305 25.8109 67.1493 26.2693H70.0229C70.0964 25.7806 70.0251 25.4108 69.8088 25.16C69.5969 24.9049 69.2942 24.7773 68.9007 24.7773Z"
          fill="white"
        />
        <path
          d="M77.7162 26.2109C77.7984 25.839 77.8092 25.5536 77.7487 25.3546C77.6449 24.9914 77.3378 24.8097 76.8275 24.8097C76.2005 24.8097 75.714 25.0757 75.368 25.6076C75.1864 25.8887 75.0502 26.2476 74.9594 26.6844L74.1485 30.5116H72.3063L73.8047 23.454H75.5886L75.368 24.4854C75.6837 24.1221 75.9648 23.8605 76.2113 23.7005C76.6524 23.4151 77.1649 23.2724 77.7487 23.2724C78.4795 23.2724 79.0352 23.4648 79.4158 23.8497C79.8006 24.2303 79.9001 24.8638 79.7142 25.7503L78.7022 30.5116H76.8081L77.7162 26.2109Z"
          fill="white"
        />
        <path
          d="M85.0463 24.6735L86.6096 25.0692C87.2929 25.2422 87.788 25.4736 88.0951 25.7633C88.5708 26.213 88.7178 26.8639 88.5362 27.7158C88.3502 28.5894 87.8615 29.3159 87.0702 29.8954C86.2788 30.4705 85.2733 30.7581 84.0538 30.7581C82.8083 30.7581 81.8894 30.4749 81.2969 29.9083C80.7045 29.3375 80.5142 28.5548 80.7261 27.5601H82.6332C82.6029 27.9969 82.6527 28.3234 82.7824 28.5396C83.0246 28.9332 83.5392 29.1299 84.3263 29.1299C84.7976 29.1299 85.1912 29.078 85.5069 28.9743C86.108 28.7753 86.4647 28.4056 86.5772 27.865C86.6464 27.5493 86.5599 27.305 86.3177 27.132C86.0798 26.9633 85.6777 26.8142 85.1112 26.6844L84.1446 26.4574C83.1976 26.2325 82.5619 25.9882 82.2375 25.7244C81.6883 25.2833 81.5132 24.5935 81.7121 23.6551C81.8937 22.7988 82.3564 22.0875 83.1003 21.5209C83.8441 20.9544 84.8193 20.6712 86.0258 20.6712C87.0334 20.6712 87.8356 20.9393 88.4324 21.4755C89.0292 22.0075 89.2367 22.7815 89.0551 23.7978H87.135C87.2258 23.2226 87.0637 22.814 86.6485 22.5718C86.3718 22.4118 86.002 22.3318 85.5393 22.3318C85.0247 22.3318 84.5901 22.4356 84.2354 22.6432C83.8852 22.8507 83.6711 23.1405 83.5933 23.5124C83.5197 23.854 83.617 24.1092 83.8852 24.2778C84.0538 24.3903 84.4409 24.5222 85.0463 24.6735Z"
          fill="white"
        />
        <path
          d="M96.4825 24.3362C96.9193 25.0843 97.0295 25.9687 96.8133 26.9893C96.5928 28.0272 96.1041 28.9159 95.3473 29.6554C94.5948 30.3905 93.611 30.7581 92.3958 30.7581C91.1806 30.7581 90.3525 30.3905 89.9114 29.6554C89.4746 28.9159 89.3665 28.0272 89.587 26.9893C89.8033 25.9687 90.2898 25.0843 91.0466 24.3362C91.8033 23.5881 92.7893 23.214 94.0045 23.214C95.2197 23.214 96.0457 23.5881 96.4825 24.3362ZM93.6607 24.7773C93.1202 24.7773 92.6639 24.9697 92.292 25.3546C91.9201 25.7352 91.6585 26.2801 91.5071 26.9893C91.3558 27.6985 91.3839 28.2456 91.5914 28.6305C91.8033 29.0153 92.1796 29.2078 92.7201 29.2078C93.2607 29.2078 93.7169 29.0153 94.0889 28.6305C94.4608 28.2456 94.7224 27.6985 94.8738 26.9893C95.0251 26.2801 94.9948 25.7352 94.7829 25.3546C94.5754 24.9697 94.2013 24.7773 93.6607 24.7773Z"
          fill="white"
        />
        <path
          d="M102.139 29.5127C102.117 29.5343 102.061 29.5991 101.97 29.7073C101.879 29.8154 101.778 29.9105 101.665 29.9927C101.319 30.2521 100.999 30.4295 100.705 30.5246C100.416 30.6197 100.089 30.6673 99.7259 30.6673C98.6793 30.6673 98.0544 30.2911 97.8512 29.5386C97.7388 29.1234 97.769 28.5115 97.942 27.7028L98.8437 23.441H100.738L99.8361 27.7028C99.7496 28.105 99.7324 28.4077 99.7842 28.611C99.8751 28.9699 100.167 29.1494 100.66 29.1494C101.291 29.1494 101.778 28.8942 102.119 28.384C102.301 28.1072 102.439 27.7418 102.535 27.2877L103.352 23.441H105.227L103.728 30.5116H101.931L102.139 29.5127Z"
          fill="white"
        />
        <path
          d="M105.557 30.5116L107.588 20.9501H109.437L107.406 30.5116H105.557Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_5209_7970"
          x1="20.1561"
          y1="0.00195312"
          x2="20.1561"
          y2="40.3142"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B007FF" />
          <stop offset="1" stopColor="#26DCDC" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_5209_7970"
          x1="26.177"
          y1="9.17969"
          x2="26.177"
          y2="43.2061"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C4C5D5" />
          <stop offset="1" stopColor="#256E8D" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_5209_7970"
          x1="23.4734"
          y1="19.7656"
          x2="23.4734"
          y2="27.1811"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B007FF" />
          <stop offset="1" stopColor="#26DCDC" />
        </linearGradient>
        <clipPath id="clip0_5209_7970">
          <rect width="118.37" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
