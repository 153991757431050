import { IPostTbl, IProjectTbl, ITagTbl, IUserTbl } from "types";
import { IPostCommentRes } from "../postsComments";

export enum PostType {
  AMA = 1,
  PODCAST = 2,
  BLOG = 3,
}

export enum SortByPostEnum {
  POPULAR = 0,
  LATEST = 1,
}

export interface IPostsReq {
  project_id?: number;
  type?: SortByPostEnum;
  title?: string;
  content?: string;
  tag_names?: string[];
}

export interface IPostsAllReq {
  limit?: number;
  page?: number;
  type?: PostType;
  sort_by?: SortByPostEnum;
}

export interface IPostsByIDReq {
  id?: number;
}

export interface IPostsRes extends IPostTbl {
  tags?: ITagTbl[];
  user?: IUserTbl;
  project?: IProjectTbl;
}

export interface IPostResult extends IPostsRes {
  post_comments?: IPostCommentRes[];
  count_upvotes?: number;
  is_voted?: boolean;
  is_marked?: boolean;
  count_comments: number;
}

export interface IPostsAllRes extends IPostsRes {
  page?: number;
  page_size?: number;
  total_page?: number;
  total_item?: number;
  result?: IPostResult[];
}

export interface IPostsByProjectIdReq {
  project_id?: string;
  body: IPostsAllReq;
}
