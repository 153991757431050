import { Form } from "antd";
import styled from "styled-components";

export const AntdFormItem = styled(Form.Item)`
  .ant-form-item-label {
    padding: 0px;
    &
      > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
      display: none;
    }
  }

  .ant-form-item-label > label.ant-form-item-required::after {
    display: inline-block;
    margin-left: 4px;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    line-height: 1;
    content: "*";
  }
  .ant-form-item-with-help .ant-form-item-explain {
    height: 24px;
  }
`;
