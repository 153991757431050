import { createSlice } from "@reduxjs/toolkit";

export interface IHomeUsState {
  isAddProjectModal?: boolean
}

const initialState = {
  isAddProjectModal: false,
};

const homeSlice = createSlice({
  name: "home-us",
  initialState,
  reducers: {
    showAddProjectModal: (state) => {
      state.isAddProjectModal = true;
      return state;
    },
    hideAddProjectModalOut: (state) => {
      return initialState;
    },
  },
});

const { actions, reducer } = homeSlice;

export const homeActions = actions;

export default reducer;
